import { grey } from "@mui/material/colors";

const styles = {
  whiteFixedBackGround: (isSavedBrandOpen: boolean) => ({
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    pr: isSavedBrandOpen ? "410px" : "55px",
    background: "white",
  }),
  brandListContainer: {
    overflowY: "scroll",
    flexGrow: 1,
    paddingBottom: "20px", // To leave space for the "Bento reloads message"
  },
  columnContainer: {
    overflowY: "auto",
    height: "calc(100vh - 70px)", // Leave space for navigation bar
    border: `1px solid ${grey[300]}`,
    backgroundColor: "white",
  },
  columnContainerMobile: {
    paddingBottom: 8,
  },
  explanation: {
    backgroundColor: "secondary.light",
    padding: 1,
    fontSize: 12,
    marginBottom: { xs: 10, md: 0 },
  },
  subtitle: {
    color: grey[600],
    fontStyle: "italic",
  },
};

export default styles;
