const styles = {
  button: {
    textTransform: "none",
    fontSize: 13,
  },
  filterColor: {
    backgroundColor: "#ebdef0",
    color: "black",
    "&:hover": {
      backgroundColor: "#f5eef8",
    },
  },
  sortColor: {
    backgroundColor: "#d6eaf8",
    color: "black",
    "&:hover": {
      backgroundColor: "#ebf5fb",
    },
  },
  clearButton: {
    fontSize: 14,
    height: 25,
    width: 25,
    ml: 1,
  },
};

export default styles;
