import { Node, mergeAttributes } from "@tiptap/core";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";

import { trackEvent } from "utils/tracking";

export type VariableOptions = {
  toggleTooltip?: (
    view?: EditorView | null,
    pos?: number,
    node?: ProseMirrorNode,
  ) => void;
};

export type VariableAttributes = {
  example: string;
  label: string;
  id: string;
  subtype: VariableSubtype;
  value?: string;
  instruction?: string;
};

export enum VariableSubtype {
  email_specific = "email-specific",
  user_specific = "user-specific",
}

export const Variable = Node.create<VariableOptions>({
  name: "variable",

  group: "inline",

  inline: true,

  selectable: true,

  atom: false,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            "data-id": attributes.id,
          };
        },
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            "data-label": attributes.label,
          };
        },
      },
      subtype: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-subtype"),
        renderHTML: (attributes) => {
          if (!attributes.subtype) {
            return {};
          }

          return {
            "data-subtype": attributes.subtype,
          };
        },
      },
      example: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-example"),
        renderHTML: (attributes) => {
          if (!attributes.example) {
            return {};
          }

          return {
            "data-example": attributes.example,
          };
        },
      },
      instruction: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-instruction"),
        renderHTML: (attributes) => {
          if (!attributes.instruction) {
            return {};
          }

          return {
            "data-instruction": attributes.instruction,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
        getAttrs: (element: HTMLElement | string) => {
          if (typeof element === "string") {
            return false;
          }
          if (
            element.textContent === `{${element.getAttribute("data-label")}}`
          ) {
            return null;
          }
          return false;
        },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(
        { "data-type": this.name, class: this.name },
        HTMLAttributes,
      ),
      `{${node.attrs.label}}`,
    ];
  },

  renderText({ node }) {
    return `{${node.attrs.label}}`;
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isVariable = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isVariable = true;
              tr.insertText("", pos, pos + node.nodeSize);

              return false;
            }
          });

          return isVariable;
        }),
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("variableTooltip"),
        props: {
          handleDOMEvents: {
            mouseover: (view, event) => {
              const { toggleTooltip } = this.options;
              if (!view || !toggleTooltip) return;
              const target = event.target as HTMLElement;
              const pos = view.posAtDOM(target, 0);
              const node = view.state.doc.nodeAt(pos);
              if (node?.type.name === this.name) {
                if (node?.attrs?.subtype === VariableSubtype.email_specific) {
                  trackEvent("Email Template Hovered Over Variable", {
                    label: node?.attrs?.label,
                  });
                  toggleTooltip(view, pos, node);
                } else {
                  toggleTooltip();
                }
              }
            },
          },
          handleClickOn: (view, pos, node, nodePos, event, direct) => {
            event.preventDefault();
            if (!direct || !view) return;

            if (node.type.name === this.name) {
              trackEvent("Email Template Clicked On Variable", {
                label: node?.attrs?.label,
              });
            }
          },
        },
      }),
    ];
  },
});
