import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "schemas/routes";

import {
  IMPORT_FLOW_EXITED_EARLY,
  IMPORT_FLOW_SELECTED_TYPE,
} from "../../events";

export default function UploadType() {
  const navigate = useNavigate();

  const { selectedView, trackTable } = useContext(ContactViewContext);
  const [uploadType, setUploadType] = useState<"gmail" | "csv" | undefined>();

  const handleClose = () => {
    trackTable(IMPORT_FLOW_EXITED_EARLY, { Step: "Select Import Type" });
    navigate(`/${routes.trackingView}/${selectedView?.id}`);
  };

  const goNext = () => {
    trackTable(IMPORT_FLOW_SELECTED_TYPE, { uploadType });

    if (uploadType) {
      navigate(uploadType);
    }
  };

  return (
    <>
      <DialogTitle>How do you want to import your contacts?</DialogTitle>
      <DialogContent>
        <Typography color="textSecondary" paragraph>
          Select which method you'd like to use to import contacts into your
          pitch tracker.
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              sx={{ textTransform: "none" }}
              variant="outlined"
              disableElevation
              color={uploadType === "csv" ? "primary" : "secondary"}
              onClick={() => setUploadType("csv")}
            >
              <Grid container sx={{ my: 2 }}>
                <Grid item xs={12}>
                  <Box component="i" className="fa-solid fa-file" />
                </Grid>
                <Grid item xs={12}>
                  Upload from a file
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              sx={{ textTransform: "none" }}
              variant="outlined"
              disableElevation
              color={uploadType === "gmail" ? "primary" : "secondary"}
              onClick={() => setUploadType("gmail")}
            >
              <Grid container sx={{ my: 2 }}>
                <Grid item xs={12}>
                  <Box component="i" className="fa-solid fa-envelope" />
                </Grid>
                <Grid item xs={12}>
                  Import from Gmail
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={goNext}
          variant="contained"
          disableElevation
          disabled={uploadType === undefined}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}
