import { Box, Grid, Link, Typography } from "@mui/material";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

const styles = {
  root: (showActions: boolean) => ({
    maxWidth: showActions ? 120 : "90%",
  }),
  brandName: {
    fontSize: 14,
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  instagramUsername: {
    fontSize: 12,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: "18px",
  },
};

interface Props {
  bentoBrand: BentoBrand;
  showActions: boolean;
}

export default function CardTitle({ bentoBrand, showActions }: Props) {
  return (
    <Box sx={styles.root(showActions)}>
      <Grid container alignItems="center">
        <Box sx={styles.brandName}>{bentoBrand?.brandName}</Box>
      </Grid>

      <Typography component="div" sx={styles.instagramUsername}>
        {bentoBrand?.instagramUsername && (
          <Link
            target="_blank"
            href={`https://instagram.com/${bentoBrand?.instagramUsername}`}
            onClick={(e: CustomEvent) => e.stopPropagation()}
          >
            @{bentoBrand?.instagramUsername}
          </Link>
        )}
      </Typography>
    </Box>
  );
}
