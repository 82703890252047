import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { ContactProperty, OutreachContact } from "schemas/dashboard";
import { Map } from "schemas/functions";

import {
  COLUMN_ID,
  COLUMN_NAME,
  COLUMN_TYPE,
  TABLE_CELL_CLICKED,
} from "features/Influencer/Tracking/events";
import {
  ContactView,
  ContactViewProperty,
  SelectOption,
} from "features/Influencer/Tracking/schema";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

export type UpdateOutreachContactBody = {
  isImportant?: boolean;
  isArchived?: boolean;
  contactName?: string;
  contactTitle?: string;
  brandName?: string;
  email?: string;
};

interface ContactViewContextInterface {
  selectedView: ContactView | null;
  setSelectedView: Dispatch<SetStateAction<ContactView | null>>;
  outreachContacts: OutreachContact[];
  setOutreachContacts: Dispatch<SetStateAction<OutreachContact[]>>;
  updateOutreachContactPropertyValues: (
    outreachContactId: number,
    contactPropertyId: number,
    value: any,
  ) => void;
  updateProperty: (
    property: ContactProperty,
    selectOptions?: SelectOption[],
    action?: string,
  ) => void;
  updateOutreachContactDefaultValues: (
    outreachContact: OutreachContact,
    body: UpdateOutreachContactBody,
  ) => Promise<OutreachContact>;
  trackTable: (eventName: string, data?: Map) => void;
  trackCell: (
    property: ContactViewProperty,
    outreachContact?: OutreachContact,
    data?: Map,
  ) => void;
  total: number;
  setTotal: Dispatch<SetStateAction<number>>;
  highlightedNewRow: number | undefined;
  setHighlightedNewRow: Dispatch<SetStateAction<number | undefined>>;
  newContact: OutreachContact | null;
  setNewContact: Dispatch<SetStateAction<OutreachContact | null>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  selectedView: null,
  setSelectedView: defaultContextMissingFunction,
  outreachContacts: [],
  setOutreachContacts: defaultContextMissingFunction,
  contactProperties: [],
  seContactProperties: defaultContextMissingFunction,
  updateOutreachContactPropertyValues: defaultContextMissingFunction,
  updateOutreachContactDefaultValues: defaultContextMissingFunction,
  updateProperty: defaultContextMissingFunction,
  addAttachmentToContact: defaultContextMissingFunction,
  trackTable: defaultContextMissingFunction,
  trackCell: defaultContextMissingFunction,
  total: 0,
  setTotal: defaultContextMissingFunction,
  highlightedNewRow: undefined,
  setHighlightedNewRow: defaultContextMissingFunction,
  newContact: null,
  setNewContact: defaultContextMissingFunction,
};

const ContactViewContext =
  createContext<ContactViewContextInterface>(defaultInterface);

interface ContactViewProviderProps {
  children: React.ReactNode;
}

const ContactViewProvider = ({ children }: ContactViewProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);
  const [selectedView, setSelectedView] = useState<ContactView | null>(null);

  const [outreachContacts, setOutreachContacts] = useState<OutreachContact[]>(
    [],
  );
  const [newContact, setNewContact] = useState<OutreachContact | null>(null);
  const [highlightedNewRow, setHighlightedNewRow] = useState<
    number | undefined
  >();
  const [total, setTotal] = useState<number>(0);

  const updateOutreachContactDefaultValues = async (
    outreachContact: OutreachContact,
    body: UpdateOutreachContactBody,
  ) => {
    try {
      let url = `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContact.id}`;
      if (body?.brandName) {
        url = `/api/organization/${currentOrg?.id}/brands/${outreachContact?.brand?.id}`;
      }
      const res = await fetcherAuth(
        getToken,
        url,
        "PATCH",
        {},
        { ...body, source: "Tracking Table" },
      );
      return res.outreachContact;
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const updateOutreachContactPropertyValues = async (
    outreachContactId: number,
    contactPropertyId: number,
    value: any,
  ) => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/property-value`,
        "PUT",
        {},
        { contactPropertyId, value },
      );
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const updateProperty = async (
    property: ContactProperty,
    selectOptions?: SelectOption[],
    action = "update",
  ) => {
    // Update the ContactProperty (such as adding new tag option / deleting tag option)
    const { name, id, type } = { ...property };
    const isCreated = action === "create";
    try {
      const res = await fetcherAuth(
        getToken,
        isCreated
          ? `/api/organization/${currentOrg?.id}/contact-properties`
          : `/api/organization/${currentOrg?.id}/contact-properties/${id}`,
        isCreated ? "POST" : "PUT",
        {},
        {
          name,
          selectOptions,
          type,
          contactViewId: selectedView?.id,
        },
      );
      const { contactProperty, contactViewProperty } = res;

      setSelectedView((prevState) => {
        if (!prevState) return null;
        const updatedView = { ...prevState };

        const updatedProperties = updatedView.contactViewProperties.map(
          (prop) =>
            prop.contactPropertyId === contactProperty.id
              ? { ...prop, contactProperty }
              : prop,
        );

        if (isCreated) {
          updatedProperties.push({
            ...contactViewProperty,
            contactProperty,
          });
        }

        updatedView.contactViewProperties = updatedProperties;
        return updatedView;
      });
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const trackTable = (eventName: string, data?: Map) => {
    trackEvent(eventName, {
      ...data,
      "Contact View ID": selectedView?.id,
      "Contact View Name": selectedView?.name,
    });
  };

  const trackCell = useCallback(
    (
      property: ContactViewProperty,
      outreachContact?: OutreachContact,
      data?: Map,
    ) => {
      const name = property?.contactPropertyName || property?.contactPropertyId;
      const id = property?.id;
      const type = property?.contactProperty?.type;

      const columnData = {
        [COLUMN_ID]: id,
        [COLUMN_NAME]: name,
        [COLUMN_TYPE]: type,
        "Outreach Contact ID": outreachContact?.id,
        "Outreach Contact Email": outreachContact?.email,
        ...data,
      };
      trackTable(TABLE_CELL_CLICKED, columnData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ContactViewContext.Provider
      value={{
        selectedView,
        setSelectedView,
        outreachContacts,
        setOutreachContacts,
        updateOutreachContactPropertyValues,
        updateProperty,
        updateOutreachContactDefaultValues,
        trackTable,
        trackCell,
        total,
        setTotal,
        highlightedNewRow,
        setHighlightedNewRow,
        newContact,
        setNewContact,
      }}
    >
      {children}
    </ContactViewContext.Provider>
  );
};

export { ContactViewProvider, ContactViewContext };
