import { useAuth } from "@clerk/clerk-react";
import { useMediaQuery, useTheme } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  PortfolioSite,
  PortfolioSiteRevision,
  PortfolioSiteSection,
} from "schemas/portfolioSite";

import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

const MAX_REVISION_SIZE = 20;

export enum ViewType {
  mobile = "Mobile",
  desktop = "Desktop",
}

export type ElementOpen = {
  sectionId: string;
  elementId: string;
};

interface PortfolioSitesInterface {
  portfolioSiteLoading: boolean;
  portfolioSite: PortfolioSite | undefined;
  portfolioSiteRevisions: PortfolioSiteRevision[] | undefined;
  latestRevision: PortfolioSiteRevision | undefined;
  addRevision: (newRevision: PortfolioSiteRevision) => void;
  openEditElement: ElementOpen | undefined;
  setOpenEditElement: Dispatch<SetStateAction<ElementOpen | undefined>>;
  redo: () => void;
  undo: () => void;
  undoEnabled: boolean;
  redoEnabled: boolean;
  textFocused: boolean;
  setTextFocused: Dispatch<SetStateAction<boolean>>;
  templateSections: PortfolioSiteSection[];
  templateSectionLoading: boolean;
  fetchTemplateSections: () => void;
  selectNewSectionLoading: boolean;
  addPortfolioSection: (
    section: PortfolioSiteSection,
    sectionId?: string,
  ) => void;
  portfolioSiteSaveHistory: PortfolioSiteRevision[];
  savePortfolioSite: (version: PortfolioSiteRevision) => void;
  viewType: ViewType;
  setViewType: Dispatch<SetStateAction<ViewType>>;
  isPublished: boolean;
  updatePortfolioSitePublishStatus: (isPublished: boolean) => void;
  updatePortfolioSite: (
    newPortfolioSite: PortfolioSite,
    faviconFile?: File | null,
  ) => Promise<boolean>;
  portfolioEditLoading: boolean;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  portfolioSiteLoading: false,
  portfolioSite: undefined,
  portfolioSiteRevisions: undefined,
  latestRevision: undefined,
  addRevision: defaultContextMissingFunction,
  openEditElement: undefined,
  setOpenEditElement: defaultContextMissingFunction,
  redo: defaultContextMissingFunction,
  undo: defaultContextMissingFunction,
  undoEnabled: false,
  redoEnabled: false,
  textFocused: false,
  setTextFocused: defaultContextMissingFunction,
  templateSections: [],
  templateSectionLoading: false,
  fetchTemplateSections: defaultContextMissingFunction,
  selectNewSectionLoading: false,
  addPortfolioSection: defaultContextMissingFunction,
  portfolioSiteSaveHistory: [],
  savePortfolioSite: defaultContextMissingFunction,
  viewType: ViewType.desktop,
  setViewType: defaultContextMissingFunction,
  isPublished: false,
  updatePortfolioSitePublishStatus: defaultContextMissingFunction,
  updatePortfolioSite: defaultContextMissingFunction,
  portfolioEditLoading: false,
};

const PortfolioSitesEditContext =
  createContext<PortfolioSitesInterface>(defaultInterface);

interface PortfolioSitesEditProviderProps {
  children: React.ReactNode;
}

const PortfolioSitesEditProvider = ({
  children,
}: PortfolioSitesEditProviderProps) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { getToken } = useAuth();
  const [portfolioSiteLoading, setPortfolioSiteLoading] =
    useState<boolean>(false);
  const [portfolioEditLoading, setPortfolioEditLoading] =
    useState<boolean>(false);
  const [portfolioSite, setPortfolioSite] = useState<PortfolioSite>();
  // first index is the latest version
  const [portfolioSiteSaveHistory, setPortfolioSiteSaveHistory] = useState<
    PortfolioSiteRevision[]
  >([]);
  // last index is the latested edit of the current version
  const [portfolioSiteRevisions, setPortfolioSiteRevisions] =
    useState<PortfolioSiteRevision[]>();
  const [positionFromArrayEnd, setPositionFromArrayEnd] = useState<number>(-1);
  const [openEditElement, setOpenEditElement] = useState<
    ElementOpen | undefined
  >();
  const [viewType, setViewType] = useState<ViewType>(ViewType.desktop);
  const [textFocused, setTextFocused] = useState<boolean>(false);
  const [templateSections, setTemplateSections] = useState<
    PortfolioSiteSection[]
  >([]);
  const [templateSectionLoading, setTemplateSectionLoading] =
    useState<boolean>(false);
  const [selectNewSectionLoading, setSelectNewSectionLoading] =
    useState<boolean>(false);

  const { portfolioSiteId } = useParams();

  const fetchPortfolioSite = async () => {
    if (!currentOrg?.id) {
      return;
    }
    try {
      setPortfolioSiteLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/portfolio-sites/${portfolioSiteId}`,
      );
      const portfolioSite = res.portfolioSite;
      setPortfolioSite(portfolioSite);
      setPortfolioSiteSaveHistory(portfolioSite.revisions);
      setPortfolioSiteRevisions([portfolioSite.revisions[0]]);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setPortfolioSiteLoading(false);
    }
  };

  const savePortfolioSite = async (version: PortfolioSiteRevision) => {
    if (!currentOrg?.id || !latestRevision) {
      return;
    }
    try {
      setPortfolioSiteLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/portfolio-sites/${portfolioSiteId}/revisions`,
        "POST",
        {},
        { ...version },
      );
      const portfolioSite = res.portfolioSite;
      setPortfolioSite(portfolioSite);
      setPortfolioSiteSaveHistory(portfolioSite.revisions);
      addRevision(portfolioSite.revisions[0], false);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setPortfolioSiteLoading(false);
    }
  };

  const updatePortfolioSite = async (
    newPortfolioSite: PortfolioSite,
    faviconFile?: File | null,
  ) => {
    if (!currentOrg?.id) {
      return false;
    }
    try {
      setPortfolioEditLoading(true);

      const data = new FormData();
      data.append("portfolioSite", JSON.stringify(newPortfolioSite));
      if (faviconFile) {
        data.append("favicon", faviconFile);
      }

      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/portfolio-sites/${portfolioSiteId}`,
        "PATCH",
        {},
        data,
        false,
        true,
      );
      const portfolioSite = res.portfolioSite;
      setPortfolioSite(portfolioSite);
      setPortfolioSiteSaveHistory(portfolioSite.revisions);
      setPortfolioSiteRevisions([portfolioSite.revisions[0]]);
      return true;
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setPortfolioEditLoading(false);
    }

    return false;
  };

  const updatePortfolioSitePublishStatus = async (isPublished: boolean) => {
    if (!currentOrg?.id) {
      return;
    }
    try {
      setPortfolioEditLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/portfolio-sites/${portfolioSiteId}/published-status`,
        "PATCH",
        {},
        { isPublished },
      );
      const portfolioSite = res.portfolioSite;
      setPortfolioSite(portfolioSite);
      setPortfolioSiteSaveHistory(portfolioSite.revisions);
      setPortfolioSiteRevisions([portfolioSite.revisions[0]]);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setPortfolioEditLoading(false);
    }
  };

  const fetchTemplateSections = async () => {
    if (!currentOrg?.id) {
      return;
    }
    try {
      setTemplateSectionLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/portfolio-sites/template-sections`,
      );
      setTemplateSections(res.sections);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setTemplateSectionLoading(false);
    }
  };

  const addPortfolioSection = async (
    section: PortfolioSiteSection,
    sectionId?: string,
  ) => {
    if (!currentOrg?.id) {
      return;
    }
    try {
      setSelectNewSectionLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/portfolio-sites/template-sections/select`,
        "POST",
        {},
        { section },
      );
      const newSection = res.selectedSection;
      if (!latestRevision || !sectionId) {
        return;
      }

      const newSectionIdx = latestRevision.sections.findIndex(
        (s) => s.id === sectionId,
      );
      const newRevision = {
        ...latestRevision,
        sections: [
          ...latestRevision.sections.slice(0, newSectionIdx),
          newSection,
          ...latestRevision.sections.slice(newSectionIdx),
        ],
      };
      addRevision(newRevision);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setSelectNewSectionLoading(false);
    }
  };

  const addRevision = async (
    newRevision: PortfolioSiteRevision,
    isEdited: boolean = true,
  ) => {
    const saveRevision = { ...newRevision, isEdited };
    if (!portfolioSiteRevisions) {
      setPortfolioSiteRevisions([saveRevision]);
      setPositionFromArrayEnd(-1);
    } else {
      let revisions = [...portfolioSiteRevisions];
      if (positionFromArrayEnd !== -1) {
        revisions = revisions.slice(
          0,
          revisions.length + positionFromArrayEnd + 1,
        );
      }
      revisions.push(saveRevision);
      if (revisions.length > MAX_REVISION_SIZE) {
        revisions = revisions.slice(revisions.length - MAX_REVISION_SIZE);
      }
      setPortfolioSiteRevisions(revisions);
      setPositionFromArrayEnd(-1);
    }
  };

  const getRevision = useCallback(
    (positionFromEnd: number) => {
      return portfolioSiteRevisions?.[
        portfolioSiteRevisions?.length + positionFromEnd
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [portfolioSiteRevisions],
  );

  const redo = () => {
    setPositionFromArrayEnd((prev) => prev + 1);
  };

  const undo = () => {
    setPositionFromArrayEnd((prev) => prev - 1);
  };

  const redoEnabled = useMemo(() => {
    return !!getRevision(positionFromArrayEnd + 1);
  }, [getRevision, positionFromArrayEnd]);

  const undoEnabled = useMemo(() => {
    return !!getRevision(positionFromArrayEnd - 1);
  }, [getRevision, positionFromArrayEnd]);

  const latestRevision = useMemo(() => {
    return getRevision(positionFromArrayEnd);
  }, [getRevision, positionFromArrayEnd]);

  const isPublished = useMemo(() => {
    return portfolioSiteSaveHistory.findIndex((r) => r.isPublished) > -1;
  }, [portfolioSiteSaveHistory]);

  const handleControlZ = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === "z" &&
        (event.ctrlKey || event.metaKey) &&
        !textFocused
      ) {
        if (undoEnabled) {
          undo();
        }
      }
    },
    [textFocused, undoEnabled],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleControlZ);
    return () => {
      document.removeEventListener("keydown", handleControlZ);
    };
  }, [handleControlZ]);

  useEffect(() => {
    fetchTemplateSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  useEffect(() => {
    fetchPortfolioSite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, portfolioSiteId]);

  useEffect(() => {
    if (isSmallScreen && viewType === ViewType.mobile) {
      setViewType(ViewType.desktop);
    }
  }, [isSmallScreen, viewType]);

  return (
    <PortfolioSitesEditContext.Provider
      value={{
        portfolioSiteLoading,
        portfolioSite,
        portfolioSiteRevisions,
        latestRevision,
        addRevision,
        openEditElement,
        setOpenEditElement,
        redo,
        undo,
        redoEnabled,
        undoEnabled,
        textFocused,
        setTextFocused,
        templateSections,
        templateSectionLoading,
        fetchTemplateSections,
        selectNewSectionLoading,
        addPortfolioSection,
        portfolioSiteSaveHistory,
        savePortfolioSite,
        viewType,
        setViewType,
        isPublished,
        updatePortfolioSitePublishStatus,
        updatePortfolioSite,
        portfolioEditLoading,
      }}
    >
      {children}
    </PortfolioSitesEditContext.Provider>
  );
};

export { PortfolioSitesEditProvider, PortfolioSitesEditContext };
