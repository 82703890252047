import { grey, red } from "@mui/material/colors";

import { drawerStyles } from "components/Drawer/reusableDrawerStyles";

const noBorder = {
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
};

const outreachChip = {
  textTransform: "none",
  py: 2,
  borderRadius: 0,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  color: "primary.main",
  border: `1px solid ${grey[300]}`,
  "&:hover": {
    background: "none",
  },
};

const styles = {
  ...drawerStyles,
  reduceMargin: {
    overflowY: "auto",
    mx: { xs: 0, md: -2 },
    mt: { xs: 0, md: -2 },
  },
  editButton: {
    backgroundColor: "green.dark",
    position: "absolute",
    bottom: 10,
    right: 10,
    "&:hover": {
      backgroundColor: "green.main",
    },
  },
  editIcon: { color: "white", p: 1 },
  errorContent: {
    border: "1px solid",
    borderColor: "red",
    borderRadius: 2,
    overflow: "hidden",
  },
  errorText: { color: "red" },
  instagramText: {
    width: { xs: "100%", md: 500 },
  },
  previewContent: {
    fontSize: 12,
    ".variable": {
      color: red[700],
    },
  },
  backdrop: {
    color: "#fff",
    zIndex: 100,
    position: "absolute",
    borderRadius: 2,
  },
  editorWrapper: {
    cursor: "text",
    pb: "125px",
  },
  chip: { backgroundColor: "primary.light", fontSize: 10, ml: 1 },
  addTemplateButton: {
    height: 35,
  },
  border: {
    borderBottom: "1px solid",
    borderColor: grey[300],
    height: 40,
  },
  borderTop: {
    borderBottom: "1px solid",
    borderTop: "1px solid",
    borderColor: grey[300],
  },
  noSelectBorder: {
    color: "primary.main",
    ...noBorder,
  },
  lastContacted: { fontSize: 12, color: grey[400], ml: 1 },
  loadingContactDate: {
    color: grey[700],
    mr: 1,
  },
  blurred: {
    filter: "blur(3px)",
  },
  whiteTooltip: {
    bgcolor: "white",
    color: "black",
    border: "1px black solid",
  },
  helperText: {
    fontSize: 12,
  },
  viewScore: {
    color: "info.main",
    textTransform: "none",
    textDecoration: "none",
    ml: { xs: 1, md: 0 },
    fontSize: 12,
    cursor: "pointer",
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    fontSize: 14,
    color: "green.main",
    width: 20,
    height: 20,
  },
  templateWidth: {
    maxWidth: { xs: "auto", md: "15vw" },
  },
  errorWarningContainer: {
    px: 2,
    pb: 0,
    mb: 6,
    color: "error.light",
  },

  // SelectOutreach.tsx
  divider: {
    px: 2,
    borderBottom: `1px solid ${grey[300]}`,
  },
  active: {
    ...outreachChip,
    background: "white",
    borderBottom: "2px white solid",
  },
  inactive: {
    ...outreachChip,
  },
  addButton: {
    textTransform: "none",
  },
  attachmentContainer: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    ml: 2,
    mr: 8,
    mb: 3,
    color: "text.secondary",
  },
  attachmentName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  // Select Template
  templateSelect: {
    ml: -2,
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
    },
  },
  templateMenuItem: {
    display: "flex",
    alignItems: "center",
  },
  templateMenuName: (isAutogenerated?: boolean) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: { xs: 240, md: 500 },
    color: isAutogenerated ? "info.main" : "primary.main",
  }),
};

export default styles;
