import { useAuth } from "@clerk/clerk-react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Organization } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import Alert from "components/Alert";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

import styles from "./styles";

const AdminCsvUpload = () => {
  const { getToken } = useAuth();
  const [message, severity, setAlert, closeAlert] = useAlert();

  const [loading, setLoading] = useState(false);

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);

  const fetchOrgs = async () => {
    setLoading(true);
    try {
      const { organizations } = await fetcherAuth(
        getToken,
        "/api/admin/organizations",
        "GET",
      );

      setOrganizations(organizations);
    } catch (error) {
      setAlert(
        "An error occurred, you do not have access to this page",
        "warning",
      );
    }
    setLoading(false);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setFile(selectedFile || null);
  };

  const handleOrgChange = (event: CustomEvent) => {
    setSelectedOrg(event.target.value as number | 0);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = new FormData();
      if (!selectedOrg || !file) {
        throw new Error(
          "You must select an organization and a CSV file to upload",
        );
      }

      const fileExt = file.name.split(".").pop()?.toLowerCase();
      if (fileExt !== "csv") {
        throw new Error("You must select a CSV file");
      }
      data.append("file", file);

      await fetcherAuth(
        getToken,
        `/api/admin/organizations/${selectedOrg}/brand-recommendations/csv`,
        "POST",
        {},
        data,
        false,
        true,
      );
      setAlert(`Uploaded to outreach list`, "success");
    } catch (error) {
      setAlert(error?.message, "warning");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage
      content={
        <Grid sx={{ mt: 2 }}>
          <Typography variant="h3" gutterBottom>
            <strong>Upload Contact CSV</strong>
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormHelperText>
              This will overwrite any contact CSV that already exists for the
              organization.
            </FormHelperText>
            <FormControl sx={styles.select}>
              <Select
                variant="standard"
                value={selectedOrg}
                onChange={handleOrgChange}
              >
                <MenuItem value={0} disabled>
                  Select an organization
                </MenuItem>
                {organizations.map((org) => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={styles.file}>
              <Input type="file" onChange={handleFileChange} />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={styles.submit}
              disabled={loading}
            >
              Submit
            </Button>
          </form>
          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
        </Grid>
      }
    />
  );
};

export default AdminCsvUpload;
