import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { useContext, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes } from "schemas/routes";

import ConfirmDialog from "components/Dialogs/ConfirmDialog";

import { IMPORT_CSV_FLOW_EXITED_EARLY } from "../events";
import MatchColumns from "./MatchColumns";
import MergeExisting from "./MergeExisting";
import UploadSummaryComponent from "./Summary";
import UploadCsv from "./UploadCsv";
import UploadGmail from "./UploadGmail";
import UploadType from "./UploadType";

export default function ImportDialog() {
  const location = useLocation();
  const navigate = useNavigate();

  const { trackTable } = useContext(ContactViewContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const { selectedView } = useContext(ContactViewContext);

  const handleClose = () => {
    navigate(`/${routes.trackingView}/${selectedView?.id}`);
  };

  const getStepName = () => {
    if (location.pathname.includes("match")) return "Matching Columns";
    if (location.pathname.includes("existing"))
      return "Merging Existing Contacts";
    if (location.pathname.includes("summary")) return "Contact Import Summary";
    return location.pathname;
  };
  const handleConfirm = () => {
    handleClose();
    trackTable(IMPORT_CSV_FLOW_EXITED_EARLY, { Step: getStepName() });
  };

  const showConfirmDialog = () => {
    if (
      location.pathname.includes("match") ||
      location.pathname.includes("existing")
    ) {
      setConfirmDialogOpen(true);
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth
      onClose={showConfirmDialog}
      maxWidth="lg"
      fullScreen={isMobileScreen}
    >
      <Routes>
        <Route path="" element={<UploadType />} />
        <Route path="/gmail" element={<UploadGmail />} />
        <Route path="/csv" element={<UploadCsv />} />
        <Route path="/csv/:contactUploadId/match" element={<MatchColumns />} />
        <Route
          path="/csv/:contactUploadId/existing"
          element={<MergeExisting />}
        />
        <Route
          path="/csv/:contactUploadId/summary"
          element={<UploadSummaryComponent />}
        />
      </Routes>
      <ConfirmDialog
        header={`Are you sure?`}
        subheader={
          "You have an incomplete import. Are you sure you want to navigate away from this page?"
        }
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={handleConfirm}
      />
    </Dialog>
  );
}
