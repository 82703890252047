import { Autocomplete, TextField } from "@mui/material";
import { CustomEvent } from "schemas/functions";

import { camelToTitleCase } from "utils/string";

import { CATEGORIES } from "..";

interface IProps {
  categories: string[];
  name: "categories";
  handleAutocompleteChange: (e: CustomEvent, value: string[] | null) => void;
}

const MultiCategorySelect = ({
  name,
  handleAutocompleteChange,
  categories,
}: IProps) => {
  return (
    <Autocomplete
      multiple
      autoSelect
      autoHighlight
      fullWidth
      options={CATEGORIES}
      forcePopupIcon={false}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={camelToTitleCase(name)}
          name={name}
          placeholder="Add Category..."
        />
      )}
      onChange={handleAutocompleteChange}
      value={categories}
    />
  );
};

export default MultiCategorySelect;
