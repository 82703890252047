import { LoadingButton } from "@mui/lab";
import { DialogActions, useMediaQuery, useTheme } from "@mui/material";

import {
  SelectedTemplates,
  SurveyFollowUpStep,
  SurveyStep,
} from "../TemplatesTable/CreateTemplate/PresetFlow/schema";

interface NextButtonProps {
  activeStep: number;
  handleNext: () => void;
  loading: boolean;
  selectedTemplates?: SelectedTemplates;
  isGeneratingFollowUp?: boolean;
}
export default function NextButton({
  activeStep,
  handleNext,
  loading,
  selectedTemplates,
  isGeneratingFollowUp,
}: NextButtonProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const disableNext = () => {
    if (isGeneratingFollowUp) {
      return (
        activeStep === SurveyFollowUpStep.PICKED_TEMPLATE &&
        selectedTemplates?.followup === null
      );
    } else {
      return (
        activeStep === SurveyStep.PICKED_TEMPLATE &&
        selectedTemplates?.outreach === null
      );
    }
  };

  const getButtonText = () => {
    if (!isGeneratingFollowUp && activeStep === SurveyStep.PICKED_TEMPLATE) {
      return "Customize";
    } else if (
      isGeneratingFollowUp &&
      activeStep === SurveyFollowUpStep.SAVE_FOLLOWUP
    ) {
      return "Save";
    } else if (
      !isGeneratingFollowUp &&
      activeStep === SurveyStep.SAVE_OUTREACH
    ) {
      return "Save";
    } else {
      return "Next";
    }
  };

  const styles = {
    dialogAction: {
      position: "absolute",
      bottom: 0,
      background: "white",
      zIndex: 10,
      width: "95%",
      py: 2,
    },
  };

  return (
    <DialogActions sx={isMobileScreen ? {} : styles.dialogAction}>
      <LoadingButton
        size="small"
        onClick={handleNext}
        variant="contained"
        disableElevation
        loading={loading}
        disabled={disableNext()}
        sx={{ mt: 2 }}
      >
        {getButtonText()}
      </LoadingButton>
    </DialogActions>
  );
}
