import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import {
  AdminNewBrand,
  Continents,
  Location,
  LocationSource,
} from "../schemas";
import styles from "./styles";

interface CountriesProps {
  brand: AdminNewBrand;
  setBrand: Dispatch<SetStateAction<AdminNewBrand>>;
  setAlert: SetAlertType;
}

interface LocationProps {
  index: number;
}

const Countries = ({ brand, setBrand, setAlert }: CountriesProps) => {
  const { getToken } = useAuth();

  const handleAddAnotherLocation = () => {
    setBrand((prev: AdminNewBrand) => {
      return {
        ...prev,
        bentoBrandLocations: [
          ...(prev.bentoBrandLocations ?? []),
          {
            country: "",
            continent: Continents.north_america,
            isArchived: false,
            source: LocationSource.MANUAL,
          },
        ],
      };
    });
  };

  const LocationForm = ({ index }: LocationProps) => {
    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState<Location>(
      brand?.bentoBrandLocations?.[index] || {
        country: "",
        continent: Continents.north_america,
        isArchived: false,
        source: LocationSource.MANUAL,
      },
    );

    const handleChange = (e: CustomEvent) => {
      const { name, value } = e.target;
      setValue((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    };

    const handleToggleChange = (e: CustomEvent) => {
      setValue((prev) => {
        return {
          ...prev,
          isArchived: e.target.checked,
        };
      });
    };

    const handleSave = async () => {
      try {
        setLoading(true);
        const res = await fetcherAuth(
          getToken,
          value?.id
            ? `/api/admin/bento-brand-locations/${value.id}`
            : `/api/admin/bento-brand-locations`,
          value?.id ? "PUT" : "POST",
          {},
          {
            bentoBrandId: brand.id,
            country: value.country,
            continent: value.continent,
            isArchived: value.isArchived,
          },
        );
        if (res.bentoBrandLocation) {
          setBrand((prev: AdminNewBrand) => {
            return {
              ...prev,
              bentoBrandLocations: prev.bentoBrandLocations
                ? prev.bentoBrandLocations.map((loc, locIndex) =>
                    locIndex === index ? res.bentoBrandLocation : loc,
                  )
                : [res.bentoBrandLocation],
            };
          });
        }
        setAlert("Successfully saved location", "success");
      } catch (error) {
        setAlert(
          error?.message ||
            "Unable to save location. Please reload and retry again",
          "error",
        );
      } finally {
        setLoading(false);
      }
    };

    const handleDelete = async () => {
      if (!value.id) {
        const copy = makeDeepCopy(brand);
        copy.bentoBrandLocations = copy.bentoBrandLocations.filter(
          (loc: Location, idx: number) => idx !== index,
        );
        setBrand(copy);
        return;
      }
      try {
        setLoading(true);
        await fetcherAuth(
          getToken,
          `/api/admin/bento-brand-locations/${value.id}`,
          "DELETE",
        );
        const copy = makeDeepCopy(brand);
        copy.bentoBrandLocations = copy.bentoBrandLocations.filter(
          (loc: Location) => loc.id !== value.id,
        );
        setBrand(copy);
        setAlert("Successfully deleted location", "success");
      } catch (error) {
        setAlert(
          error?.message ||
            "Unable to delete location. Please reload and retry again",
          "error",
        );
      } finally {
        setLoading(false);
      }
    };

    return (
      <Grid item container xs={12} gap={1} alignItems="center">
        <Grid item xs={12}>
          <FormHelperText sx={styles.formHelperText}>
            Source: {value?.source}
          </FormHelperText>
        </Grid>
        <TextField
          sx={{ width: 300 }}
          label="Country Name"
          placeholder="Canada"
          name="country"
          onChange={handleChange}
          value={value.country}
          size="small"
        />

        <FormControl sx={{ width: 200 }}>
          <InputLabel>Continent</InputLabel>
          <Select
            value={value.continent}
            onChange={handleChange}
            label="Continent"
            name="continent"
            size="small"
          >
            {Object.values(Continents)?.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {value?.id && (
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={value?.isArchived}
                onChange={handleToggleChange}
              />
            }
            label={<>Is Archived</>}
          />
        )}

        <LoadingButton
          onClick={handleSave}
          disableElevation
          variant="contained"
          loading={loading}
          disabled={!value.country || !value.continent}
          size="small"
        >
          Save
        </LoadingButton>

        <LoadingButton
          disableElevation
          loading={loading}
          size="small"
          onClick={handleDelete}
          sx={styles.delete}
        >
          Delete
        </LoadingButton>

        <Divider sx={styles.divider} />
      </Grid>
    );
  };
  return (
    <Grid item sx={{ my: 4 }}>
      <Typography variant="h6">Locations</Typography>

      {brand?.bentoBrandLocations?.map((location, index) => (
        <LocationForm key={index} index={index} />
      ))}

      <Button variant="outlined" onClick={handleAddAnotherLocation}>
        Add Location{" "}
      </Button>
    </Grid>
  );
};

export default Countries;
