import { drawerStyles } from "./reusableDrawerStyles";

const styles = {
  ...drawerStyles,
  paper: {
    position: { xs: "auto", sm: "fixed" },
    right: { xs: "auto", sm: 0 },
    m: 0,
    maxHeight: "100svh",
    minHeight: { xs: "calc(100% - 100px)", lg: "100svh" },
  },
};

export default styles;
