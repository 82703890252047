import { Box, Button, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BubbleMenu, Editor as TipTapEditor } from "@tiptap/react";
import { PortfolioSitesEditContext } from "contexts/PortfolioSitesEdit";
import { useContext, useRef, useState } from "react";
import { PaletteType } from "schemas/portfolioSite";

import { EditColorDialog } from "components/EditColor/EditColorDialog";
import { convertToColor } from "components/PortfolioSite/helpers";
import StyledButton from "components/Styled/Button";
import { usePortfolioSite } from "utils/usePortfolioSite";

import { VariableSubtype } from "../Variable";
import styles from "../styles";

interface MenuOptionsProps {
  editor: TipTapEditor;
  openModal?: () => void;
  openVariableFilledModal?: () => void;
  handleDeleteVariable?: () => void;
  isBubble?: boolean;
  portfolioTextId?: string;
  allowAttachment?: boolean;
  allowImage?: boolean;
  addImage?: () => void;
  openAttachmentManager?: () => void;
  hideLink?: boolean;
  fromQuickSend?: boolean;
}

export default function MenuOptions({
  editor,
  openModal,
  openVariableFilledModal,
  handleDeleteVariable,
  isBubble,
  portfolioTextId,
  allowAttachment,
  allowImage,
  addImage,
  openAttachmentManager = undefined,
  hideLink = false,
  fromQuickSend = false,
}: MenuOptionsProps) {
  const [expandTextFormatting, setExpandTextFormatting] =
    useState<boolean>(false);
  const menuBarRef = useRef<HTMLDivElement>();
  const { latestRevision } = useContext(PortfolioSitesEditContext);
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
  const { deleteElement } = usePortfolioSite();

  const updateColor = (color: string | PaletteType | null) => {
    // @ts-ignore
    return editor.chain().focus().setColor(color).run();
  };

  const deletePortfolioText = () => {
    if (!portfolioTextId) return;
    deleteElement(portfolioTextId);
  };

  const expandedContent = () => {
    return (
      <>
        <IconButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          sx={[styles.button, editor.isActive("bold") ? styles.active : {}]}
        >
          <Box component="i" className="fa-solid fa-bold" />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          sx={[styles.button, editor.isActive("italic") ? styles.active : {}]}
        >
          <Box component="i" className="fa-solid fa-italic" />
        </IconButton>

        <IconButton
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          sx={[styles.button, editor.isActive("code") ? styles.active : {}]}
        >
          <Box component="i" className="fa-solid fa-code" />
        </IconButton>

        <IconButton
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          sx={[
            styles.button,
            editor.isActive("heading", { level: 1 }) ? styles.active : {},
          ]}
        >
          h1
        </IconButton>
        <IconButton
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          sx={[
            styles.button,
            editor.isActive("heading", { level: 2 }) ? styles.active : {},
          ]}
        >
          h2
        </IconButton>
        <IconButton
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          sx={[
            styles.button,
            editor.isActive("heading", { level: 3 }) ? styles.active : {},
          ]}
        >
          h3
        </IconButton>

        <IconButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          sx={[
            styles.button,
            editor.isActive("bulletList") ? styles.active : {},
          ]}
        >
          <Box component="i" className="fa-solid fa-list" />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          sx={[
            styles.button,
            editor.isActive("orderedList") ? styles.active : {},
          ]}
        >
          <Box component="i" className="fa-solid fa-list-ol" />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          sx={[
            styles.button,
            editor.isActive("codeBlock") ? styles.active : {},
          ]}
        >
          <Box component="i" className="fa-solid fa-square-code" />
        </IconButton>

        <IconButton
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          sx={[
            styles.button,
            editor.isActive("blockquote") ? styles.active : {},
          ]}
        >
          <Box component="i" className="fa-solid fa-quote-left" />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          sx={[
            styles.button,
            editor.isActive({ textAlign: "left" }) ? styles.active : {},
          ]}
        >
          <Box component="i" className="fa-solid fa-align-left" />
        </IconButton>

        <IconButton
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          sx={[
            styles.button,
            editor.isActive({ textAlign: "center" }) ? styles.active : {},
          ]}
        >
          <Box component="i" className="fa-solid fa-align-center" />
        </IconButton>

        <IconButton
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          sx={[
            styles.button,
            editor.isActive({ textAlign: "right" }) ? styles.active : {},
          ]}
        >
          <Box component="i" className="fa-solid fa-align-right" />
        </IconButton>
        <IconButton onClick={() => setOpenColorPicker(true)}>
          <Box
            sx={{
              border: `1px solid ${grey[700]}`,
              width: "20px",
              height: "20px",
              borderRadius: "4px",
              backgroundColor: convertToColor(
                editor.getAttributes("textStyle").colorId,
                latestRevision?.palette,
                "black",
              ),
            }}
          />
        </IconButton>
      </>
    );
  };

  const unexpandedContent = () => {
    return (
      <>
        {!hideLink && (
          <IconButton
            onClick={openModal}
            sx={[styles.button, editor.isActive("link") ? styles.active : {}]}
          >
            <Box component="i" className="fa-solid fa-link" />
          </IconButton>
        )}

        {allowImage && (
          <IconButton
            onClick={addImage}
            sx={[
              styles.button,
              editor.isActive("custom-image") ? styles.active : {},
            ]}
          >
            <Box component="i" className="fa-solid fa-image" />
          </IconButton>
        )}

        {allowAttachment && (
          <IconButton onClick={openAttachmentManager} sx={styles.button}>
            <Box component="i" className="fa-solid fa-paperclip" />
          </IconButton>
        )}

        <IconButton
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          sx={styles.button}
        >
          <Box component="i" className="fa-solid fa-rotate-left" />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          sx={styles.button}
        >
          <Box component="i" className="fa-solid fa-rotate-right" />
        </IconButton>
        {!!portfolioTextId && (
          <IconButton
            onClick={deletePortfolioText}
            sx={[styles.button, styles.trashButton]}
          >
            <Box component="i" className="fa-regular fa-trash-can" />
          </IconButton>
        )}
      </>
    );
  };

  return (
    <>
      {isBubble && (
        <Box sx={styles.bubbleMenuBar(!!portfolioTextId)} ref={menuBarRef}>
          {expandedContent()}
          {unexpandedContent()}
        </Box>
      )}
      {!isBubble && (
        <Box sx={styles.menuBar} ref={menuBarRef}>
          {expandTextFormatting ? (
            <>
              <IconButton
                onClick={() => setExpandTextFormatting(false)}
                sx={styles.button}
              >
                <Box component="i" className="fa-solid fa-circle-x" />
              </IconButton>
              {expandedContent()}
            </>
          ) : (
            <>
              <IconButton
                onClick={() => setExpandTextFormatting(true)}
                sx={[
                  styles.button,
                  editor.isActive("bold") ? styles.active : {},
                ]}
              >
                <Box component="i" className="fa-solid fa-text" />
              </IconButton>

              {unexpandedContent()}
            </>
          )}
        </Box>
      )}

      {/* Display BubbleMenu for links. */}
      <BubbleMenu
        tippyOptions={{ duration: 50, zIndex: 1201, placement: "bottom" }}
        editor={editor}
        shouldShow={({ editor, from, to }) => {
          return from === to && editor.isActive("link");
        }}
      >
        <StyledButton onClick={openModal}>
          Edit Link{" "}
          <Box component="i" className="fa-regular fa-pen" sx={{ ml: 1 }} />
        </StyledButton>
      </BubbleMenu>

      {/* Display BubbleMenu when user click on Variable */}
      {openVariableFilledModal && handleDeleteVariable && (
        <BubbleMenu
          tippyOptions={{ duration: 50, zIndex: 1201, placement: "bottom" }}
          editor={editor}
          shouldShow={({ editor }) => {
            return editor.isActive(
              "variable",
              fromQuickSend
                ? {}
                : {
                    subtype: VariableSubtype.user_specific,
                  },
            );
          }}
        >
          <Button
            onMouseDown={(e) => e.preventDefault()}
            sx={styles.fillVariable}
            onClick={openVariableFilledModal}
          >
            Fill
            <Box component="i" className="fa-regular fa-pen" sx={{ ml: 1 }} />
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="secondary"
            onClick={handleDeleteVariable}
            onMouseDown={(e) => e.preventDefault()}
          >
            Delete
            <Box component="i" className="fa-regular fa-trash" sx={{ ml: 1 }} />
          </Button>
        </BubbleMenu>
      )}

      <EditColorDialog
        color={editor.getAttributes("textStyle").colorId || "black"}
        open={openColorPicker}
        setOpen={setOpenColorPicker}
        onSave={updateColor}
      />
    </>
  );
}
