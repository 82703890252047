import { grey } from "@mui/material/colors";

const styles = {
  scheduledAtContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: { xs: 11, md: 12 },
    color: grey[700],
  },
  changeButton: {
    color: "secondary.main",
    textTransform: "uppercase",
    textDecoration: "none",
    pl: 0.5,
    pr: 1,
    cursor: "pointer",
  },
  removeScheduled: {
    color: grey[500],
    fontSize: 12,
  },
};

export default styles;
