import { Grid, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Email } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";

import { hideEmail } from "utils/string";
import { formatEpochTimestamp } from "utils/time";
import { trackEvent } from "utils/tracking";

import CancelMessageButton from "./CancelMessageButton";
import styles from "./styles";

interface Props {
  scheduledEmail: Email;
  setAlert: SetAlertType;
}

const ScheduledEmailRow = ({ scheduledEmail, setAlert }: Props) => {
  const { emailSettings } = useContext(OrganizationUserContext);
  const navigate = useNavigate();

  const toField = hideEmail(scheduledEmail.toEmail || "");

  const subject = scheduledEmail.subject || "(no subject)";
  const snippet = (scheduledEmail.body || "").replace(/(<([^>]+)>)/gi, " ");
  const formattedDate = formatEpochTimestamp(
    scheduledEmail.scheduledAt || scheduledEmail.createdAt,
    true,
    emailSettings?.timezone,
  );
  const { currentContact } = useContext(OutreachContactsContext);

  const openScheduledEmail = () => {
    trackEvent("Outreach Contact Scheduled Email Opened", {
      contactId: currentContact?.id,
      scheduledEmailId: scheduledEmail?.id,
    });
    navigate(`scheduled/${scheduledEmail.id}`);
  };

  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={1}
      sx={styles.accordion}
      onClick={openScheduledEmail}
    >
      <Grid item xs>
        <Typography component="div" sx={styles.toField}>
          {toField}{" "}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Typography
          noWrap
          variant="caption"
          color="textSecondary"
          sx={styles.formattedDate}
        >
          {formattedDate}
        </Typography>
      </Grid>
      <Grid item sx={styles.subjectAndSnippet} xs={11}>
        <Typography variant="caption">{subject}</Typography>
      </Grid>
      <Grid item sx={styles.subjectAndSnippet} xs={11}>
        <Typography
          variant="caption"
          color="textSecondary"
          dangerouslySetInnerHTML={{
            __html: snippet,
          }}
          noWrap
        ></Typography>
      </Grid>
      {currentContact && (
        <Grid item xs={12} container justifyContent="flex-end" sx={{ pt: 1 }}>
          <CancelMessageButton
            contact={currentContact}
            scheduledEmail={scheduledEmail}
            setAlert={setAlert}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default ScheduledEmailRow;
