import { Box, Grid, IconButton, TableCell, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ContactViewContext } from "contexts/ContactView";
import { TrackingColumnsContext } from "contexts/TrackingColumns";
import { useContext, useState } from "react";
import { BuiltInContactPropertyName } from "schemas/dashboard";
import { Map } from "schemas/functions";

import {
  COLUMN_NAME,
  HEADER_COLUMN_MENU_OPENED,
} from "features/Influencer/Tracking/events";
import {
  ContactViewProperty,
  EMAIL_COLUMN,
  NotEditableFields,
} from "features/Influencer/Tracking/schema";

import CustomColumnDialog from "../AddColumn/CustomColumn";
import HeaderMenu from "./HeaderMenu";
import styles from "./styles";

const DraggableTableHeader = ({ prop }: { prop: ContactViewProperty }) => {
  const { trackTable, selectedView } = useContext(ContactViewContext);
  const { getTitleForHeader } = useContext(TrackingColumnsContext);

  const name = prop?.contactPropertyName || prop?.contactProperty?.name;
  const [openEditColumn, setOpenEditColumn] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    trackTable(HEADER_COLUMN_MENU_OPENED, {
      [COLUMN_NAME]: name,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const wand = (
    <Box
      component="i"
      className="fa-solid fa-wand-magic-sparkles"
      sx={{ color: "#2362C1", mr: 1 }}
    />
  );

  const hasIcons = ["smart_status", "smart_interactions", "smart_task"];
  const notEdit = name && NotEditableFields?.includes(name);
  const isSticky = prop?.contactPropertyName === EMAIL_COLUMN;

  const getToolTip = () => {
    if (name === "last_gmail_message_date") {
      return "Last Interaction Date";
    } else if (notEdit) {
      return "These fields are not editable because they are automated by Bento";
    } else {
      return "";
    }
  };

  const getZIndex = () => {
    if (isSticky) return 5;
    else return 4;
  };

  const isAutomated = Boolean(prop?.contactProperty?.automatedProperty);
  const displayWand = (name && hasIcons?.includes(name)) || isAutomated;

  const hasSort = selectedView?.contactViewProperties?.find(
    (x) => x.sortDirection !== null,
  );

  const getStyles = () => {
    const arr: Map[] = [styles.th];
    if (prop?.filters?.length > 0 && prop?.sortDirection) {
      arr.push(styles.normalBg);
    } else if (prop?.filters?.length > 0) {
      arr.push(styles.filterColor);
    } else if (
      prop?.sortDirection ||
      (!hasSort &&
        prop?.contactPropertyName === BuiltInContactPropertyName.createdAt)
    ) {
      arr.push(styles.sortColor);
    } else {
      arr.push(styles.normalBg);
    }
    return arr;
  };

  return (
    <>
      <Tooltip title={getToolTip()}>
        <TableCell
          style={{
            position: "sticky",
            zIndex: getZIndex(),
            left: isSticky ? 0 : undefined,
            borderRight: `1px solid ${grey[300]}`,
          }}
          sx={getStyles()}
          onClick={handleClick}
        >
          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            alignItems="center"
            gap={1}
          >
            <Grid container alignItems="center" item xs>
              {displayWand && wand} {getTitleForHeader(name)}
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={styles.icon}>
                <Box component="i" className="fa-thin fa-ellipsis-vertical" />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </Tooltip>
      {prop && (
        <>
          <HeaderMenu
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            prop={prop}
            setOpenEditColumn={setOpenEditColumn}
          />
          {openEditColumn && (
            <CustomColumnDialog
              open={openEditColumn}
              handleClose={() => setOpenEditColumn(false)}
              editedProperty={prop?.contactProperty}
            />
          )}
        </>
      )}
    </>
  );
};

export default DraggableTableHeader;
