import { Box, Grid, Typography } from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { SetAlertType } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface ReferralProps {
  setAlert?: SetAlertType;
}

export default function Referral({ setAlert }: ReferralProps) {
  const { referralCode } = useContext(SubscriptionContext);

  const handleCopyToClipboard = () => {
    if (referralCode) {
      navigator.clipboard.writeText(referralCode);
    }

    trackEvent("Referral Link Copied to Clipboard");

    if (setAlert) setAlert("Copied to clipboard!", "success");
  };

  return (
    <>
      <Typography variant="h4" paragraph>
        Get more e-mail credits, for free!
      </Typography>

      <Grid container columnGap={2} rowGap={2} alignItems="center">
        <Grid item xs={12} md>
          <Typography variant="h6" paragraph>
            🎁 +5 for you, +5 for a friend... forever!
          </Typography>
          <Typography sx={{ mr: 4 }} color="textSecondary">
            When your friend signs up, you <strong>both</strong> receive an
            additional 5 email credits <strong>per month forever.</strong>{" "}
            Currently only accepting creators with 5K+ followers on any
            platform. You can invite up to 6 friends.
          </Typography>
        </Grid>
        <Grid item xs={12} md="auto" sx={{ alignItems: "center" }}>
          <Typography variant="caption" color="textSecondary" paragraph>
            Use your unique referral code:
          </Typography>
          <Box sx={styles.referralCode} onClick={handleCopyToClipboard}>
            <Box sx={styles.code}>{referralCode}</Box>
            <Box
              component="i"
              sx={{ lineHeight: "1.25" }}
              className="fa-regular fa-copy fa-lg"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
