import { FormHelperText, Typography } from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";

import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";

import { HandleSurveyChangeFunction, TemplateSurvey } from "../schema";

interface SignatureStepProps {
  handleSurveyChange: HandleSurveyChangeFunction;
  survey: TemplateSurvey;
  orgProfileField?: string;
}

export default function SignatureStep({
  handleSurveyChange,
  survey,
  orgProfileField,
}: SignatureStepProps) {
  const editor = useEditor(
    {
      extensions: extensions({
        hardBreaksOnly: true,
        placeholderText: "Type your signature here...",
      }),
      content: survey.signature,
      onUpdate({ editor }) {
        handleSurveyChange(
          {
            target: { name: "signature", value: editor.getHTML() },
          },
          orgProfileField,
        );
      },
    },
    [!!survey.signature || survey.signature === ""],
  ) as TipTapEditor;

  return (
    <>
      <Typography gutterBottom>
        <strong>Do you have a personalized signature you'd like to use?</strong>
      </Typography>

      <FormHelperText sx={{ mb: 2 }}>
        Tip: You can copy and paste below your Gmail signature (with images).
      </FormHelperText>
      <Editor editor={editor} allowImage={true} />
    </>
  );
}
