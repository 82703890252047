import { grey } from "@mui/material/colors";

import { drawerStyles } from "components/Drawer/reusableDrawerStyles";

const styles = {
  ...drawerStyles,
  text: {
    pt: 3,
    px: 2,
    alignItems: "center",
  },
  dialogTitleText: {
    backgroundColor: "yellow.light",
    borderBottom: `1px solid ${grey[300]}`,
  },
  dialogActions: {
    borderTop: `1px solid ${grey[300]}`,
  },
  dialogContent: {
    my: 0,
    mx: 0,
    px: { xs: 0, sm: 1, md: 2 },
    pt: `10px !important`,
  },
  brandName: { width: { xs: 100, md: "auto" } },
  backdrop: {
    color: "#fff",
    zIndex: 100,
    position: "absolute",
    borderRadius: 2,
  },
  wrapper: { height: "500px", p: 1, overflow: "scroll", background: grey[100] },
  emailRoot: { my: 3, border: `1px solid ${grey[200]}` },
};

export default styles;
