import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OutreachContact, OutreachContactStatus } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import OutreachStatusChip from "components/OutreachStatusChip";
import { sanitize } from "utils/sanitize";
import { hideEmail } from "utils/string";
import { trackEvent } from "utils/tracking";

import IconAction from "./components/IconAction";
import OpenedColumn from "./components/OpenedColumn";
import { getFormattedDate } from "./helpers";
import styles from "./styles";

interface Props {
  contact: OutreachContact;
  setAlert: SetAlertType;
}

const ContactRow = ({ contact, setAlert }: Props) => {
  const formattedDate = getFormattedDate(contact);
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const openEmailDrawer = (event: CustomEvent, contact: OutreachContact) => {
    event.stopPropagation();
    trackEvent("Inbox Table Row Clicked", {
      contactId: contact?.id,
      lastGmailThreadId: contact.lastGmailThreadId,
    });
    if (!isSmallScreen && contact.status === OutreachContactStatus.pending) {
      navigate(`${contact.id}/scheduled`);
    } else if (!isSmallScreen && contact.lastGmailThreadId) {
      navigate(`${contact.id}/email/${contact.lastGmailThreadId}`);
    } else {
      navigate(`${contact.id}`);
    }
  };

  const participants =
    contact.lastGmailThreadParticipants ||
    `To: ${
      contact.contactName?.split(" ")?.[0] ||
      contact.email?.split("@")?.[0] ||
      ""
    }`;

  const snippet = `${
    contact.lastGmailMessageSnippet
      ? sanitize(contact.lastGmailMessageSnippet).substring(0, 170)
      : ""
  }...`;

  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={1}
      onClick={(event) => openEmailDrawer(event, contact)}
      sx={[styles.accordion, contact.isPinned ? styles.rowPinned : {}]}
    >
      {isSmallScreen && (
        <>
          <Box sx={styles.mobilePinned}>
            <IconAction
              fieldName="isPinned"
              contact={contact}
              setAlert={setAlert}
            />
          </Box>
          <Grid item xs={12} container columnSpacing={1}>
            <Grid item xs>
              <Typography component="div" sx={styles.mobileTitle}>
                <Typography
                  component="span"
                  sx={{
                    mr: 1,
                    fontWeight: contact.isPinned ? 700 : "inherit",
                  }}
                >
                  {contact?.brand?.name}
                </Typography>
                {contact?.status && (
                  <OutreachStatusChip sx={{ mb: 0 }} status={contact.status} />
                )}
              </Typography>
              <Grid container alignItems="center">
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  sx={{ mr: 1, mb: 0.5 }}
                >
                  <Typography component="div" sx={styles.participants}>
                    {participants}
                  </Typography>
                  {contact.lastGmailThreadLength &&
                    contact.lastGmailThreadLength > 1 && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={styles.lastThreadLength}
                      >
                        {contact.lastGmailThreadLength}
                      </Typography>
                    )}
                </Grid>
              </Grid>
              <Typography sx={styles.subject} gutterBottom component="div">
                {contact?.lastGmailMessageSubject}
              </Typography>
              <Typography sx={styles.email} gutterBottom component="div">
                {formattedDate}
              </Typography>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{
                  __html: snippet,
                }}
                sx={styles.snippet}
              ></Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box sx={styles.openedColumnMobile}>
              <OpenedColumn contact={contact} />
            </Box>
          </Grid>
        </>
      )}
      {!isSmallScreen && (
        <>
          <Grid item md="auto">
            <IconAction
              fieldName="isPinned"
              contact={contact}
              setAlert={setAlert}
            />
          </Grid>
          <Grid item md="auto">
            <Box sx={styles.nameWrapper}>
              <Box
                component="div"
                sx={{ fontWeight: contact.isPinned ? 700 : "inherit" }}
              >
                {contact?.brand?.name}
              </Box>

              <Typography sx={styles.email}>{contact?.contactName}</Typography>
              <Typography sx={styles.email}>{contact?.contactTitle}</Typography>
              <Typography sx={styles.email}>
                {contact?.status === OutreachContactStatus.pending
                  ? hideEmail(contact.email || "")
                  : contact.email}
              </Typography>
            </Box>
          </Grid>
          <Grid item md>
            <Box component="div">
              <Grid container alignItems="center">
                <Grid
                  item
                  container
                  alignItems="center"
                  xs="auto"
                  sx={{ mr: 1, mb: 0.5 }}
                >
                  <Typography component="div" sx={styles.participants}>
                    {participants}
                  </Typography>
                  {contact.lastGmailThreadLength &&
                    contact.lastGmailThreadLength > 1 && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={styles.lastThreadLength}
                      >
                        {contact.lastGmailThreadLength}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs="auto">
                  {contact?.status && (
                    <OutreachStatusChip
                      status={contact.status}
                      sx={{ mb: 0.5 }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
            <Typography sx={styles.subject}>
              {contact?.lastGmailMessageSubject}
            </Typography>

            <Typography
              dangerouslySetInnerHTML={{
                __html: snippet,
              }}
              sx={styles.snippet}
            ></Typography>
          </Grid>

          <Grid item md="auto">
            <OpenedColumn contact={contact} />
          </Grid>
          <Grid item md="auto">
            <Typography
              variant="caption"
              color="textSecondary"
              sx={styles.formattedDate}
              component="div"
            >
              {formattedDate}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default ContactRow;
