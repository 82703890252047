import {
  Box,
  Grid,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { Task, TaskType } from "schemas/dashboard";

import { getTaskTime } from "utils/time";

import TaskAction from "./Action";
import styles from "./styles";

interface Props {
  task: Task;
}

export default function SingleTask({ task }: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getTaskDescription = () => {
    if (
      !task.description &&
      (task?.type === TaskType.OUTREACH || task?.type === TaskType.EMAIL) &&
      task?.brand?.name
    ) {
      return `You need to send an email to ${task?.brand?.name}`;
    }
    const shortenedDescription =
      task?.description && task?.description?.length > 100
        ? task?.description.substring(0, 100) + "..."
        : task?.description;
    return shortenedDescription || "You have a pending task for this project";
  };

  const renderHeader = () => {
    const contact =
      task?.outreachContact?.contactTitle ||
      task?.outreachContact?.contactName ||
      task?.outreachContact?.email;
    return (
      <Typography sx={{ color: "primary.main" }}>
        {task?.brand?.name || task?.project?.name}{" "}
        {contact && (
          <>
            &middot;{" "}
            <Box sx={{ color: "secondary.main" }} component="span">
              {contact}
            </Box>
          </>
        )}
      </Typography>
    );
  };

  const description = (
    <Typography sx={styles.subheader}>
      {task?.dueAt && (
        <>
          <Box component="i" sx={{ color: "green.light" }}>
            {getTaskTime(task)}
          </Box>{" "}
          <Box component="span" sx={{ mx: 0.5 }}>
            &middot;
          </Box>
        </>
      )}
      {getTaskDescription()}
    </Typography>
  );

  const renderTaskForDesktop = () => {
    return (
      <Box sx={styles.list}>
        <ListItem
          key={task.id}
          secondaryAction={<TaskAction task={task} />}
          disablePadding
        >
          <ListItem sx={{ minHeight: 60 }}>
            <ListItemText primary={renderHeader()} secondary={description} />
          </ListItem>
        </ListItem>
      </Box>
    );
  };

  return (
    <Box key={`task-manager-${task?.id}`}>
      {isMobileScreen ? (
        <Grid sx={styles.mobileList}>
          {renderHeader()}
          {description}

          <Grid container alignItems="center" gap={1}>
            <TaskAction task={task} />
          </Grid>
        </Grid>
      ) : (
        renderTaskForDesktop()
      )}
    </Box>
  );
}
