import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import {
  BentoBrand,
  BrandRecommendation,
  SavedBrand,
  SavedBrandStatus,
} from "schemas/dashboard";

import { trackEvent } from "utils/tracking";

import { DiscoverTabView, DiscoverViewContext } from "./DiscoverView";
import { QuickSendDrawerContext } from "./QuickSendDrawer";
import { SavedBrandContext } from "./SavedBrand";
import { TopRecommendationsContext } from "./TopRecommendations";

export type UpdateLocalPagination = {
  bentoBrand: BentoBrand;
  handleClose: () => void;
};

export type NavigationBody = {
  bentoBrand: BentoBrand;
  handleClose: () => void;
  currentPosition: number;
  paginationSource?: PaginationSource;
};

export enum PaginationSource {
  TOP_RECOMMENDATION = "Top Recommendation",
  SAVED_BRANDS = "Saved Brands",
  DRAFTED_BRANDS = "Drafted Brands",
}

export type PaginationType = SavedBrand | BentoBrand | BrandRecommendation;
export type PaginationListType =
  | SavedBrand[]
  | BrandRecommendation[]
  | BentoBrand[];

interface QuickSendPaginationContextInterface {
  paginationSource: PaginationSource | undefined;
  setPaginationSource: Dispatch<SetStateAction<PaginationSource | undefined>>;
  updatePaginationLocalState: (body: UpdateLocalPagination) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  paginationSource: undefined,
  setPaginationSource: defaultContextMissingFunction,
  updatePaginationLocalState: defaultContextMissingFunction,
};

const QuickSendPaginationContext =
  createContext<QuickSendPaginationContextInterface>(defaultInterface);

interface QuickSendPaginationProviderProps {
  children: React.ReactNode;
}

const QuickSendPaginationProvider = ({
  children,
}: QuickSendPaginationProviderProps) => {
  const [paginationSource, setPaginationSource] = useState<
    PaginationSource | undefined
  >(undefined);

  const {
    getFavoritesList,
    getId,
    setSavedBrands,
    setDraftBentoBrands,
    setSavedBrandTotal,
    setDraftTotal,
    isBrandInList,
    setTab,
  } = useContext(SavedBrandContext);
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);
  const { setTopRecommendations } = useContext(TopRecommendationsContext);
  const { setDiscoverTab, discoverTab } = useContext(DiscoverViewContext);

  const getPaginationList = (source?: PaginationSource) => {
    if (!source) return [];
    return getFavoritesList(source);
  };

  const updatePaginationLocalState = (body: UpdateLocalPagination) => {
    let { bentoBrand, handleClose } = body;

    const source = getPaginationSource(Number(bentoBrand.id));
    const brandsList = getPaginationList(source);
    setPaginationSource(source);

    if (!source) {
      handleClose();
      return;
    }

    const index = brandsList.findIndex(
      (brand: PaginationType) => Number(bentoBrand.id) === getId(brand),
    );
    const currentPosition = Math.max(index, 0);

    let updatedBody = {
      ...body,
      currentPosition,
      paginationSource: source,
    };

    if (source === PaginationSource.SAVED_BRANDS) {
      setSavedBrands((prev) => update(updatedBody, prev) as SavedBrand[]);
    } else if (source === PaginationSource.DRAFTED_BRANDS) {
      setDraftBentoBrands((prev) => update(updatedBody, prev) as BentoBrand[]);
    } else if (source === PaginationSource.TOP_RECOMMENDATION) {
      setTopRecommendations(
        (prev) => update(updatedBody, prev) as BrandRecommendation[],
      );
    }
  };

  const update = (body: NavigationBody, prevBrands: PaginationListType) => {
    const { bentoBrand, handleClose, currentPosition, paginationSource } = body;

    const index = prevBrands?.findIndex(
      (brand: SavedBrand | BentoBrand) =>
        Number(bentoBrand?.id) === getId(brand),
    );
    if (index === -1) {
      return prevBrands;
    }
    let positionToMoveTo = currentPosition;

    prevBrands.splice(index, 1);
    if (paginationSource === PaginationSource.SAVED_BRANDS) {
      setSavedBrandTotal((prev) => (prev || 0) - 1);
    } else if (paginationSource === PaginationSource.DRAFTED_BRANDS) {
      setDraftTotal((prev) => (prev || 0) - 1);
    }

    // Close drawer or move to the next brand
    if (
      positionToMoveTo < 0 ||
      positionToMoveTo >= prevBrands?.length ||
      !paginationSource
    ) {
      handleClose();
    } else {
      handleNavigate(positionToMoveTo, prevBrands, paginationSource);
    }
    return prevBrands;
  };

  const handleNavigate = (
    index: number,
    brands: SavedBrand[] | BrandRecommendation[] | BentoBrand[],
    paginationSource: PaginationSource,
  ) => {
    // Move to the next brand on the list;
    const brandAtThatPosition = brands?.[index];
    const bentoBrandId = getId(brandAtThatPosition);
    const bentoBrand =
      "bentoBrand" in brandAtThatPosition
        ? brandAtThatPosition.bentoBrand
        : brandAtThatPosition;
    trackEvent("Next Brand Redirected", { "Bento Brand ID": bentoBrandId });
    handleOpenQuickSendIndividual(
      bentoBrandId,
      "Navigate Next",
      undefined,
      true,
      bentoBrand,
    );
    if (
      paginationSource !== PaginationSource.TOP_RECOMMENDATION &&
      discoverTab !== DiscoverTabView.SAVED_BRANDS
    ) {
      setDiscoverTab(DiscoverTabView.SAVED_BRANDS);
    }
    if (paginationSource === PaginationSource.DRAFTED_BRANDS) {
      setTab(SavedBrandStatus.DRAFTS);
    } else if (paginationSource === PaginationSource.SAVED_BRANDS) {
      setTab(SavedBrandStatus.SAVED);
    }
  };

  const getPaginationSource = (id: number) => {
    const isSaved = isBrandInList(id, PaginationSource.SAVED_BRANDS);
    if (isSaved) return PaginationSource.SAVED_BRANDS;

    const isDraft = isBrandInList(id, PaginationSource.DRAFTED_BRANDS);
    if (isDraft) return PaginationSource.DRAFTED_BRANDS;

    const isTopRecommendations = isBrandInList(
      id,
      PaginationSource.TOP_RECOMMENDATION,
    );
    if (isTopRecommendations) return PaginationSource.TOP_RECOMMENDATION;
  };

  return (
    <QuickSendPaginationContext.Provider
      value={{
        paginationSource,
        setPaginationSource,
        updatePaginationLocalState,
      }}
    >
      {children}
    </QuickSendPaginationContext.Provider>
  );
};

export { QuickSendPaginationProvider, QuickSendPaginationContext };
