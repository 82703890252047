import { useAuth } from "@clerk/clerk-react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";

interface BrandSearchAutoCompleteProps {
  setAlert: SetAlertType;
  onBrandChange: (event: CustomEvent, newValue: BentoBrand | null) => void;
  value: BentoBrand | null;
}

const BrandSearchAutoComplete = ({
  setAlert,
  onBrandChange,
  value,
}: BrandSearchAutoCompleteProps) => {
  const { getToken } = useAuth();
  const [searchBrandResults, setSearchBrandResults] = useState<BentoBrand[]>(
    [],
  );
  const [searchBrandLoading, setSearchBrandLoading] = useState<boolean>(false);

  const handleOnInputChange = (event: CustomEvent, newInputValue: string) => {
    setSearchBrandLoading(true);
    changeInput(newInputValue);
  };

  const changeInput = useMemo(
    () =>
      debounce(async (newSearchQuery: string) => {
        await searchBrands(newSearchQuery);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const searchBrands = async (queryString: string) => {
    try {
      const encodedQuery = encodeURIComponent(queryString);
      let url = `/api/admin/bento-brands?query=${encodedQuery}`;
      const { bentoBrands } = await fetcherAuth(getToken, url);
      setSearchBrandResults(bentoBrands);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    } finally {
      setSearchBrandLoading(false);
    }
  };

  return (
    <Autocomplete
      id="brands"
      options={searchBrandResults}
      disableCloseOnSelect
      value={value || null}
      onChange={onBrandChange}
      loading={searchBrandLoading}
      getOptionLabel={(option: BentoBrand) =>
        `${option.brandName} (${option.instagramUsername}, ${option.id})`
      }
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
        >{`${option.brandName} (${option.instagramUsername}, ${option.id})`}</li>
      )}
      onInputChange={handleOnInputChange}
      // Override Autocomplete Default Filter Function with BE searching.
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Brand *"}
          placeholder="Type brand name or website to search..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {searchBrandLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default BrandSearchAutoComplete;
