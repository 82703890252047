import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { OutreachDraft } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";

import { OrganizationUserContext } from "./Organization";

interface AutogeneratedDraftsContextInterface {
  drafts: OutreachDraft[];
  setDrafts: Dispatch<SetStateAction<OutreachDraft[]>>;
  hitsLimit: boolean;
  setHitsLimit: Dispatch<SetStateAction<boolean>>;
  removeLoading: number;
  fetchDrafts: () => Promise<boolean>;
  pollForNewDrafts: () => void;
  setRemoveLoading: Dispatch<SetStateAction<number>>;
  isPolling: boolean;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  drafts: [],
  setDrafts: defaultContextMissingFunction,
  hitsLimit: false,
  setHitsLimit: defaultContextMissingFunction,
  removeLoading: -1,
  fetchDrafts: defaultContextMissingFunction,
  pollForNewDrafts: defaultContextMissingFunction,
  setRemoveLoading: defaultContextMissingFunction,
  isPolling: false,
};

const AutogeneratedDraftsContext =
  createContext<AutogeneratedDraftsContextInterface>(defaultInterface);

interface AutogeneratedDraftsProviderProps {
  children: React.ReactNode;
}

const RESULTS_PER_PAGE = 5;

const AutogeneratedDraftsProvider = ({
  children,
}: AutogeneratedDraftsProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const [removeLoading, setRemoveLoading] = useState<number>(-1);
  const [drafts, setDrafts] = useState<OutreachDraft[]>([]);
  const [cursor, setCursor] = useState<number[] | null>(null);
  const [hitsLimit, setHitsLimit] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const fetchDrafts = async (): Promise<boolean> => {
    try {
      let url = `/api/organization/${currentOrg?.id}/outreach-drafts/autogenerated?per_page=${RESULTS_PER_PAGE}`;
      if (cursor) {
        url += `&cursor=${JSON.stringify(cursor)}`;
      }
      const res = await fetcherAuth(getToken, url);
      if (res.outreachDrafts?.length > 0) {
        setDrafts((prev: OutreachDraft[]) => {
          return [...prev, ...res.outreachDrafts];
        });
        const lastDraft = res.outreachDrafts[res.outreachDrafts.length - 1];
        setCursor([lastDraft.createdAt, lastDraft.id]);
        setHitsLimit(false);
        return true;
      } else {
        setHitsLimit(true);
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const pollForNewDrafts = async () => {
    if (isPolling) return;
    setIsPolling(true);
    const foundResult = await fetchDrafts();
    if (!foundResult) {
      const intervalId = setInterval(async () => {
        const foundResult = await fetchDrafts();
        if (foundResult) {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          setIsPolling(false);
        }
      }, 5000);
      // Poll every 5 seconds for 1 minute. If no new drafts are found nothing will happen on FE.
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setIsPolling(false);
      }, 30000);
    } else {
      setIsPolling(false);
    }
  };

  return (
    <AutogeneratedDraftsContext.Provider
      value={{
        drafts,
        setDrafts,
        hitsLimit,
        setHitsLimit,
        pollForNewDrafts,
        fetchDrafts,
        removeLoading,
        setRemoveLoading,
        isPolling,
      }}
    >
      {children}
    </AutogeneratedDraftsContext.Provider>
  );
};

export { AutogeneratedDraftsProvider, AutogeneratedDraftsContext };
