import { FocusEvent, useCallback, useRef, useState } from "react";

export default function useTableMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
    if (
      popperRef.current &&
      popperRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setAnchorEl(null);
  }, []);

  const menuOnBlur = useCallback((e: FocusEvent) => {
    if (popperRef.current && popperRef.current.contains(e.relatedTarget)) {
      return;
    }
    setAnchorEl(null);
  }, []);

  return { anchorEl, setAnchorEl, open, handleClose, popperRef, menuOnBlur };
}
