import { Box, Grid } from "@mui/material";
import { SetAlertType } from "schemas/functions";

import FileUpload from "components/Attachments/FileUpload";

import styles from "./styles";

interface Props {
  imageUrl: string | undefined;
  uploadText: string;
  setAlert: SetAlertType;
  file: File | null;
  setFile: (file: File | null) => void;
  isRounded?: boolean;
  allowPaste?: boolean;
}

const ImageUpload = ({
  setAlert,
  file,
  setFile,
  uploadText,
  imageUrl,
  allowPaste,
  isRounded = true,
}: Props) => {
  return (
    <Grid container alignItems="center" sx={{ p: 3 }} gap={2}>
      {(file || imageUrl) && (
        <Box
          component="img"
          alt="img preview"
          sx={isRounded ? styles.roundImage : styles.headerImage}
          src={file ? URL.createObjectURL(file) : imageUrl || ""}
        />
      )}

      <FileUpload
        id={`logo-picker-${uploadText}`}
        name="logo"
        setFile={setFile}
        setAlert={setAlert}
        limit={25000000} // 25 MB
        buttonText={uploadText}
        acceptType={"image/*"}
        allowPaste={allowPaste}
      />
    </Grid>
  );
};

export default ImageUpload;
