import { Editor as TipTapEditor } from "@tiptap/react";
import { useCallback, useState } from "react";

export type Url = {
  href: string;
  openNewTab: boolean;
};

interface Props {
  editor: TipTapEditor;
}

export const useLinkEditor = ({ editor }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [url, setUrl] = useState<Url>({
    href: "",
    openNewTab: true,
  });

  const openModal = useCallback(() => {
    setUrl({
      href: editor.getAttributes("link").href,
      openNewTab: editor.getAttributes("link").target === "_blank",
    });
    setModalIsOpen(true);
  }, [editor]);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setUrl({
      href: "",
      openNewTab: true,
    });
  }, []);

  const saveLink = useCallback(() => {
    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({
          href: url.href.trim(),
          target: url.openNewTab ? "_blank" : "_self",
        })
        .run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    closeModal();
  }, [editor, url, closeModal]);

  const removeLink = useCallback(() => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    closeModal();
  }, [editor, closeModal]);

  return {
    modalIsOpen,
    setModalIsOpen,
    url,
    setUrl,
    openModal,
    closeModal,
    saveLink,
    removeLink,
  };
};
