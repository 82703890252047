import {
  Box,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import influencerImage from "assets/auth/influencer_collage.png";
import powerByLogo from "assets/logos/logo-transparent.png";
import { ReactNode } from "react";

import { LANDING_PAGE_LINK } from "constants/influencer";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface LayoutProps {
  children: ReactNode;
}

const InfluencerLayout = ({ children }: LayoutProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const goToLandingPage = () => {
    trackEvent("Landing Page Logo Clicked", {
      "Clicked From": "Login/Signup Page",
    });
    window.open(LANDING_PAGE_LINK, "_self");
  };

  return (
    <Grid container sx={styles.background}>
      <Grid item sx={styles.maxWidthContainer}>
        {!isMobileScreen && (
          <Box
            component="img"
            src={powerByLogo}
            sx={styles.topBarLogo}
            onClick={goToLandingPage}
          />
        )}
        <Grid container>
          {!isMobileScreen && (
            <Grid item xs={6} sx={styles.collageContainer}>
              <Grid item>
                <Typography sx={styles.header} gutterBottom textAlign="center">
                  <strong>Brand Partnerships on Autopilot</strong>
                </Typography>

                <Typography
                  sx={styles.subtitle}
                  color="textSecondary"
                  textAlign="center"
                  gutterBottom
                >
                  An AI-driven platform for content creators, streamlining
                  company outreach and inbox management
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Box component="img" src={influencerImage} sx={styles.image} />
              </Grid>
            </Grid>
          )}

          <Grid item container xs={12} lg={6} sx={styles.signUpContainer}>
            <Grid item sx={{ lg: { pr: 5 } }}>
              {children}
            </Grid>
          </Grid>

          <Box sx={styles.footer}>
            <Box sx={styles.poweredByContainer}>
              <Typography sx={styles.poweredByText}>Powered By</Typography>
              <Box
                component="img"
                src={powerByLogo}
                sx={styles.poweredByLogo}
                onClick={goToLandingPage}
              />

              <Box sx={styles.poweredByText}>
                <Link
                  href="https://www.onbento.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfluencerLayout;
