import { AlertColor } from "@mui/material";
import React, { createContext } from "react";
import { Map } from "schemas/functions";

import Alert from "components/Alert";
import { useAlert } from "utils/useAlert";

interface AlertContextInterface {
  setAlert: (
    customMessage: string | JSX.Element,
    customType: AlertColor,
  ) => void;
  setErrorAlert: (error?: Map) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  setAlert: defaultContextMissingFunction,
  setErrorAlert: defaultContextMissingFunction,
};

const AlertContext = createContext<AlertContextInterface>(defaultInterface);

interface AlertProviderProps {
  children: React.ReactNode;
}

const AlertProvider = ({ children }: AlertProviderProps) => {
  const [message, severity, setAlert, closeAlert] = useAlert();

  const setErrorAlert = (error?: Map) => {
    let errorMessage = error?.message;
    if (error?.message?.toLowerCase()?.includes("json")) {
      errorMessage = "";
    }
    setAlert(
      errorMessage ||
        "Something went wrong, please refresh the page and retry again. Contact hello@onbento.com if the problem persists.",
      "error",
    );
  };

  return (
    <AlertContext.Provider
      value={{
        setAlert,
        setErrorAlert,
      }}
    >
      {children}
      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </AlertContext.Provider>
  );
};

export { AlertProvider, AlertContext };
