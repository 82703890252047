const styles = {
  requestButton: {
    backgroundColor: "primary.light",
    color: "primary.dark",
    borderRadius: 2,
    mr: 2,
    "&:hover": {
      backgroundColor: "#EAFAF1",
    },
  },
  close: {
    color: "primary.main",
    fontSize: 14,
    position: "absolute",
    top: 10,
    right: 10,
    width: 20,
    height: 20,
  },
  notifyText: {
    wordBreak: "break-all",
  },
};

export default styles;
