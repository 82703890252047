const styles = {
  root: {
    mt: 2,
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
};

export default styles;
