import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext, useEffect, useState } from "react";
import { OutreachContact } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

interface Props {
  contact: OutreachContact;
  setAlert: SetAlertType;
}

const ArchiveButton = ({ contact, setAlert }: Props) => {
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { contacts, setContacts, setCurrentContact } = useContext(
    OutreachContactsContext,
  );

  const [originalIndex, setOriginalIndex] = useState<number>(-1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOriginalIndex(contacts.findIndex((c) => c.id === contact?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.id]);

  const updateLocalState = (isArchived: boolean) => {
    setCurrentContact((prev) => {
      const copy = makeDeepCopy(prev);
      copy.isArchived = isArchived;
      return copy;
    });
    if (originalIndex < 0) return;
    if (isArchived) {
      setContacts((prev) => {
        const copy = makeDeepCopy(prev);
        for (const c of copy) {
          if (c.id === contact.id) {
            c.isArchived = isArchived;
          }
        }
        copy.splice(originalIndex, 1);
        return copy;
      });
    } else {
      setContacts((prev) => {
        const copy = makeDeepCopy(prev);
        const contactCopy = makeDeepCopy(contact);
        contactCopy.isArchived = isArchived;
        copy.splice(originalIndex, 0, contactCopy);
        return copy;
      });
    }
  };

  const archive = async () => {
    if (!currentOrg?.id || !contact?.id) {
      return;
    }

    const newArchiveValue = !contact.isArchived;
    updateLocalState(newArchiveValue);

    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${contact?.id}`,
        "PATCH",
        {},
        {
          isArchived: newArchiveValue,
        },
      );
    } catch (error) {
      setErrorAlert(error);
      updateLocalState(!newArchiveValue);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <CircularProgress color="secondary" size={20} />
  ) : (
    <>
      <LoadingButton
        color="primary"
        variant="outlined"
        disableElevation
        size="small"
        onClick={archive}
      >
        {contact.isArchived ? "Unarchive" : "Archive"}
        <Box
          component="i"
          className="fa-regular fa-arrow-down-to-line"
          sx={{ ml: 1 }}
        />
      </LoadingButton>
    </>
  );
};
export default ArchiveButton;
