export const LANDING_PAGE_LINK = "https://www.onbento.com/";
export const BENTO_RESOURCES_GUIDE_LINK =
  "https://hatchways.notion.site/Bento-Resource-Guide-b43c00e8980347fca5de92cd71c51818";
export const instagramInboxLink = "https://www.instagram.com/direct/inbox/";
export const instagramConnectLink = "https://www.instagram.com/#bento-connect";
export const instagramReconnectLink =
  "https://www.instagram.com/#bento-reconnect";
export const UNSPECIFIED = "unspecifed";
export const PITCH_TRACKER_GUIDE =
  "https://hatchways.notion.site/Guide-to-Pitch-Tracking-41915814d9ed439cb4db923889012bfa?pvs=4";
