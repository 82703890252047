import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
} from "react";
import { BrandRecommendation } from "schemas/dashboard";

interface TopRecommendationsContextInterface {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  topRecommendations: BrandRecommendation[];
  setTopRecommendations: Dispatch<SetStateAction<BrandRecommendation[]>>;
  hitsLimit: boolean;
  setHitsLimit: Dispatch<SetStateAction<boolean>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  page: 1,
  setPage: defaultContextMissingFunction,
  topRecommendations: [],
  setTopRecommendations: defaultContextMissingFunction,
  hitsLimit: false,
  setHitsLimit: defaultContextMissingFunction,
};

const TopRecommendationsContext =
  createContext<TopRecommendationsContextInterface>(defaultInterface);

interface TopRecommendationsProviderProps {
  children: React.ReactNode;
}

const TopRecommendationsProvider = ({
  children,
}: TopRecommendationsProviderProps) => {
  const [topRecommendations, setTopRecommendations] = useState<
    BrandRecommendation[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [hitsLimit, setHitsLimit] = useState(false);

  return (
    <TopRecommendationsContext.Provider
      value={{
        topRecommendations,
        setTopRecommendations,
        page,
        setPage,
        hitsLimit,
        setHitsLimit,
      }}
    >
      {children}
    </TopRecommendationsContext.Provider>
  );
};

export { TopRecommendationsProvider, TopRecommendationsContext };
