import { Grid } from "@mui/material";
import { BentoBrand, BentoBrandContent } from "schemas/dashboard";

import BrandSocialPost from "components/BrandSocialPost";

import styles from "./styles";

interface Props {
  bentoBrand: BentoBrand;
  brandContents: BentoBrandContent[];
}

export default function PartnershipPosts({ bentoBrand, brandContents }: Props) {
  const addWeight = (a: BentoBrandContent) => {
    if (a.thumbnailUrl) {
      return { ...a, weight: 2 };
    } else if (a.socialMediaPostId) {
      return { ...a, weight: 1 };
    } else {
      return { ...a, weight: 0 };
    }
  };

  const contents = brandContents?.map((x) => addWeight(x));
  const sortedContents = contents?.sort((a, b) => b.weight - a.weight);

  return (
    <Grid container justifyContent="flex-start" gap={2} sx={styles.container}>
      {sortedContents?.map((item, index) => (
        <BrandSocialPost
          index={index}
          key={index}
          item={item}
          bentoBrand={bentoBrand}
          size={180}
        />
      ))}
    </Grid>
  );
}
