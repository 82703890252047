import { grey } from "@mui/material/colors";

const emptyNote = {
  p: 1,
  cursor: "default",
  fontSize: 12,
};

const taskCellStyles = {
  upcoming: {
    fontSize: 10,
    color: "green.main",
    textTransform: "none",
    cursor: "pointer",
    textDecoration: "none",
  },
  pending: {
    fontSize: 10,
    color: "warning.main",
    textTransform: "none",
    cursor: "pointer",
    textDecoration: "none",
  },
};

const selectCellStyles = (isEditable?: boolean, open?: boolean) => {
  return {
    container: {
      cursor: isEditable ? "pointer" : "default",
      width: "150px",
      display: open ? "flex" : "grid",
      gridAutoFlow: "column",
      overflowX: "auto",
      height: 35,
    },
    chevron: {
      fontSize: 10,
      color: grey[700],
      width: 20,
      height: 20,
    },
    chip: {
      borderRadius: 0,
      fontSize: 12,
      border: `1px solid ${grey[300]} `,
    },
    tagColor: {
      width: 30,
      height: 30,
      mt: 1,
      mb: 2,
      borderRadius: 2,
    },
    editTag: { fontSize: 12, color: "black" },
    save: { mt: 1, fontSize: 12 },
    popper: { zIndex: 3 },
    popperPaper: {
      p: 1,
      my: 1,
      overflow: "scroll",
      maxHeight: "calc(100vh - 30px)",
    },
  };
};

const noteStyles = {
  emptyNote,
  notEditable: {
    height: 35,
  },
  notFocused: (isMultiline: boolean) => ({
    fontSize: 12,
    lineHeight: "1.4375em",
    p: isMultiline ? "8.5px 14px" : 0,
    whiteSpace: isMultiline ? "pre-wrap" : "nowrap",
    textOverflow: isMultiline ? "ellipsis" : "clip",
    wordWrap: "break-word",
    height: "auto",
    overflow: isMultiline ? "inherit" : "hidden",
  }),
  input: {
    fontSize: 12,
    p: 0,
    lineHeight: "1.4375em",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  longTextContainer: {
    cursor: "text",
    width: 200,
  },
  logo: {
    width: 20,
    height: 20,
    borderRadius: 50,
    mr: 1,
  },
  textLogo: {
    border: `1px ${grey[300]} solid`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
  },
  text: {
    fontSize: 12,
    overflowY: "auto",
    textOverflow: "ellipsis",
    maxHeight: 35,
    cursor: "text",
  },
  dateInput: {
    border: 0,
    outline: 0,
    background: "none",
    fontFamily: "Arial",
    fontSize: 12,
  },
  loader: {
    position: "absolute",
    right: "4px",
    top: "50%",
    mt: "-5px",
  },
};

const attachmentStyles = {
  ...noteStyles,
  attachmentBox: {
    px: 1,
    wrap: "nowrap",
    width: 270,
  },
  attachmentName: {
    fontSize: 12,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: 185,
  },
};

const buttonStyles = {
  smallButton: { textTransform: "none", fontSize: 12, height: 25 },
};

const emailThreadStyles = {
  container: { fontSize: 12, cursor: "pointer", width: "fit-content", px: 1 },
  linkText: { color: grey[800], fontSize: 12, textDecoration: "underline" },
};

export {
  taskCellStyles,
  selectCellStyles,
  noteStyles,
  attachmentStyles,
  buttonStyles,
  emailThreadStyles,
};
