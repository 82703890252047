import { grey } from "@mui/material/colors";

const styles = {
  backdrop: { color: "#fff", zIndex: 100 },
  followupButton: {
    backgroundColor: "primary.light",
    color: "green.dark",
    "&:hover": {
      backgroundColor: "primary.light",
    },
  },
  list: {
    p: 0,
    backgroundColor: "white",
    border: `1px solid ${grey[300]}`,
    my: 1,
    borderRadius: 2,
  },
  mobileList: {
    px: 2,
    py: 1,
    borderBottom: `1px solid ${grey[300]}`,
  },
  actionButton: {
    textTransform: "none",
    width: 150,
    my: { xs: 1, md: 0 },
  },
  subheader: {
    color: grey[500],
    fontSize: 12,
  },
  deleteIcon: { fontSize: 14, color: "primary.main", mx: 1 },
};

export default styles;
