import { Box } from "@mui/material";
import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useRef, useState } from "react";
import { Map } from "schemas/functions";

import { AddressComponent, UserLocation } from "./schema";
import styles from "./styles";
import { useAutoCompletePrediction } from "./useAutoCompletePrediction";

interface Props {
  userLocation?: UserLocation;
  required?: boolean;
  handleLocationChange: (userLocation: UserLocation) => void;
  customStyling?: Map;
  placeholderText?: string;
}

const constructPrefillText = (userLocation?: UserLocation): string => {
  if (!userLocation) return "";
  const addressComponents = userLocation.address_components;
  if (!addressComponents) {
    let prefillText = "";
    if (userLocation?.address && userLocation?.country_code) {
      prefillText = `${userLocation?.address}, ${userLocation?.country_code}`;
    } else if (userLocation?.address) {
      prefillText = userLocation?.address;
    }
    return prefillText;
  }

  const neighborhood = addressComponents.find((x) =>
    x.types?.includes("neighborhood"),
  )?.longText;

  const locality = addressComponents.find(
    (x) =>
      x.types?.includes("locality") ||
      x.types?.includes("administrative_area_level_3"),
  )?.longText;

  const adminArea = addressComponents.find((x) =>
    x.types?.includes("administrative_area_level_1"),
  )?.shortText;

  const postalCode = addressComponents.find((x) =>
    x.types?.includes("postal_code"),
  )?.longText;

  const country = addressComponents.find((x) =>
    x.types?.includes("country"),
  )?.longText;

  // Construct the prefill text based on available components
  let prefillText = neighborhood || "";
  if (locality) {
    prefillText += prefillText ? `, ${locality}` : locality;
  }
  if (adminArea) {
    prefillText += prefillText ? `, ${adminArea}` : adminArea;
  }
  if (postalCode) {
    prefillText += prefillText ? ` ${postalCode}` : postalCode;
  }
  if (country) {
    prefillText += prefillText ? `, ${country}` : country;
  }

  return prefillText;
};

const Autocomplete = ({
  handleLocationChange,
  userLocation,
  required,
  customStyling = styles.textField,
  placeholderText = "",
}: Props) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  const prefillText = constructPrefillText(userLocation);

  const parsePlace = (selectedPlace: google.maps.places.PlaceResult | null) => {
    /**
     * Get city, country code, longitude, latitude and address components from Google Maps API.
     * Reference: https://developers.google.com/maps/documentation/places/web-service/supported_types
     */
    let city = "";
    let countryCode = "";

    const latitude = selectedPlace?.geometry?.location?.lat();
    const longitude = selectedPlace?.geometry?.location?.lng();

    // Get city
    const cityField = selectedPlace?.address_components?.find(
      (x) =>
        x?.types?.includes("administrative_area_level_3") ||
        x?.types?.includes("locality"),
    );
    if (cityField) {
      city = cityField?.long_name;
    }
    // Get country
    const countryField = selectedPlace?.address_components?.find((x) =>
      x?.types?.includes("country"),
    );
    if (countryField) {
      countryCode = countryField.short_name;
    }

    let addressComponents = selectedPlace?.address_components;
    if (addressComponents) {
      addressComponents = addressComponents.map((item: any) => {
        item.longText = item.long_name;
        delete item.long_name;

        item.shortText = item.short_name;
        delete item.short_name;

        return item;
      });
    }

    return {
      address: city,
      address_components: addressComponents as AddressComponent[] | undefined,
      country_code: countryCode,
      latitude,
      longitude,
    };
  };

  useEffect(() => {
    if (!places || !inputRef.current) return;
    const options = {
      fields: ["geometry", "address_components"],
      types: ["(regions)"],
    };
    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;
    placeAutocomplete.addListener("place_changed", () => {
      const place = placeAutocomplete.getPlace();
      handleLocationChange(parsePlace(place));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeAutocomplete]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      handleLocationChange({});
    }
  };

  return (
    <Box
      component="input"
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === "Enter") {
          e.preventDefault(); // Prevent form submission as we need to process info in useEffects
        }
      }}
      ref={inputRef}
      required={required}
      sx={customStyling}
      defaultValue={prefillText}
      placeholder={placeholderText || "90210, Beverly Hills, CA, USA"}
      onChange={handleInputChange}
    />
  );
};

export default function LocationAutocomplete({
  handleLocationChange,
  userLocation,
  required,
  customStyling,
  placeholderText,
}: Props) {
  const { API_KEY } = useAutoCompletePrediction();
  return (
    <APIProvider apiKey={API_KEY || ""}>
      <Autocomplete
        handleLocationChange={handleLocationChange}
        userLocation={userLocation}
        required={required}
        customStyling={customStyling}
        placeholderText={placeholderText}
      />
    </APIProvider>
  );
}
