import { useAuth } from "@clerk/clerk-react";
import React, {
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChatMessage, ChatMessageAction } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";
import { QuickSendContext } from "./QuickSend";
import { QuickSendDrawerContext } from "./QuickSendDrawer";

interface ChatHelperContextInterface {
  chatMessages: ChatMessage[];
  setChatMessages: React.Dispatch<SetStateAction<ChatMessage[]>>;
  fetchChatMessages: () => void;
  messageLoading: boolean;
  messageLimit: boolean;
  isTyping: boolean;
  performChatAction: (
    action: ChatMessageAction,
    bentoBrandId?: number,
    message?: string,
  ) => void;
  personalizeAction: (draft: string) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  chatMessages: [],
  setChatMessages: defaultContextMissingFunction,
  fetchChatMessages: defaultContextMissingFunction,
  messageLoading: false,
  messageLimit: false,
  isTyping: false,
  performChatAction: defaultContextMissingFunction,
  personalizeAction: defaultContextMissingFunction,
};

const ChatHelperContext =
  createContext<ChatHelperContextInterface>(defaultInterface);

interface ChatHelperProviderProps {
  children: React.ReactNode;
}

const ChatHelperProvider = ({ children }: ChatHelperProviderProps) => {
  const { getToken } = useAuth();
  const { setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  let { contactName, bentoBrand } = useContext(QuickSendContext);
  const { quickSendBrandId } = useContext(QuickSendDrawerContext);
  bentoBrand = quickSendBrandId ? bentoBrand : null;

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [messageLoading, setMessageLoading] = useState(false);
  const [chatMessagesPage, setChatMessagesPage] = useState(1);
  const [messageLimit, setMessageLimit] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const scrollContainerRef: MutableRefObject<HTMLDivElement | null> =
    useRef(null);

  const fetchChatMessages = async () => {
    if (!currentOrg?.id) return;

    setMessageLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/chat-messages?page=${chatMessagesPage}`,
        "GET",
        {},
      );
      if (res.messages.length === 0) {
        setMessageLimit(true);
        return;
      }
      if (chatMessagesPage === 1) {
        setChatMessages(res.messages);
      } else {
        setChatMessages((prev) => [...prev, ...res.messages]);
      }
      setChatMessagesPage(res.page + 1);
    } catch (error) {
      setAlert(
        error?.message ||
          "Something went wrong. Please reach out to hello@onbento.com for assistance",
        "error",
      );
    } finally {
      setMessageLoading(false);
    }
  };

  const performChatAction = async (
    action: ChatMessageAction,
    bentoBrandId?: number,
    message?: string,
  ) => {
    if (!currentOrg?.id) return;

    setIsTyping(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/chat-messages`,
        "POST",
        {},
        {
          bentoBrandId,
          action,
          message,
          contactName,
        },
      );
      if (res.message) {
        setChatMessages((prev) => [res.message, ...prev]);
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({
            top: scrollContainerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      }
    } catch (error) {
      setAlert(
        error?.message ||
          "Something went wrong. Please reach out to hello@onbento.com for assistance",
        "error",
      );
    } finally {
      setIsTyping(false);
    }
  };

  useEffect(() => {
    if (currentOrg?.id) {
      fetchChatMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const personalizeAction = (draft: string) => {
    if (isTyping) return;
    setChatMessages((prev) => [
      {
        message: `Customize a pitch email for ${bentoBrand?.brandName}`,
        role: "user",
        countsTowardQuota: false,
      },
      ...prev,
    ]);
    performChatAction(ChatMessageAction.personalize, bentoBrand?.id, draft);
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTo({
          top: scrollContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 200);
  };

  return (
    <ChatHelperContext.Provider
      value={{
        chatMessages,
        setChatMessages,
        fetchChatMessages,
        messageLoading,
        messageLimit,
        isTyping,
        performChatAction,
        personalizeAction,
      }}
    >
      {children}
    </ChatHelperContext.Provider>
  );
};

export { ChatHelperProvider, ChatHelperContext };
