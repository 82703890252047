import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

export interface OptionListProps {
  items: string[];
  command: (item: { id: string }) => void;
}

export interface OptionListHandle {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

const OptionList = forwardRef<OptionListHandle, OptionListProps>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const selectItem = (index: number): void => {
      const item = props.items[index];

      if (item) {
        trackEvent("Email Template Selected Merge Tag", {
          label: item,
        });
        props.command({ id: item });
      }
    };

    const upHandler = (): void => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length,
      );
    };

    const downHandler = (): void => {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = (): void => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }

        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }

        if (event.key === "Enter") {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    return (
      <Box component="div" sx={styles.root}>
        <List>
          {props.items.length ? (
            props.items.map((item, index) => (
              <ListItemButton
                sx={{
                  ...styles.item,
                  ...(index === selectedIndex ? styles.isSelected : {}),
                }}
                key={index}
                onClick={() => selectItem(index)}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            ))
          ) : (
            <Box component="div" sx={styles.item}>
              No result
            </Box>
          )}
        </List>
      </Box>
    );
  },
);

export default OptionList;
