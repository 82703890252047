import { capitalize } from "@mui/material";
import { AllPlans } from "contexts/Subscription";
import { Subscription, SubscriptionType } from "schemas/dashboard";
import { Plans } from "schemas/payments";

import { titleCase } from "utils/string";

export const disabledCurrentPlan = (
  plan: Plans,
  subscription: Subscription | null,
) => {
  return (
    plan === Plans.FREE ||
    (plan === Plans.STARTER && subscription?.overrideSubscription)
  );
};

export const getCurrentPlanButton = (
  plan: Plans,
  subscription: Subscription | null,
) => {
  const isButtonDisabled = disabledCurrentPlan(plan, subscription);
  if (isButtonDisabled) {
    return "Current Plan";
  } else if (subscription?.subscription?.status === SubscriptionType.CANCELED) {
    return "Resubscribe";
  } else {
    return "Manage Current Plan";
  }
};

export const getOtherPlanButton = (plan: Plans, isProPlan: boolean) => {
  if (isProPlan && plan === Plans.STARTER) {
    return "Choose this plan";
  } else {
    return "Upgrade";
  }
};

export const getStatusChipLabel = (subscription: Subscription | null) => {
  const stripePlan = subscription?.subscription;
  if (stripePlan?.status === SubscriptionType.TRIALING) {
    return "Active";
  } else {
    return titleCase(stripePlan?.status || "");
  }
};

export const getSubscriptionInformation = (
  plan: Plans,
  plans: AllPlans | null,
  subscription: Subscription | null,
) => {
  const stripePlan = subscription?.subscription;
  const isCurrentPlan = stripePlan?.planName === capitalize(plan);
  const price = plans ? plans[plan].price : 0;
  const description = plans ? (
    <>
      Send {plans[plan].monthly_limit} outreach emails per month
      <br />
      Send {plans[plan].chat_monthly_limit} chat messages to our AI bot per
      month
      <br />
      Get {plans[plan].brand_requests_monthly_limit} brand requests per month
      {plan === Plans.PRO && (
        <>
          <br />
          Access to "Reply Score" feature
        </>
      )}
    </>
  ) : (
    <></>
  );
  const emailSent = subscription?.monthlyLimit
    ? subscription?.monthlyLimit - subscription?.remainingMessages
    : 0;
  const resubscribe =
    getCurrentPlanButton(plan, subscription) === "Resubscribe";
  const downGradeToFreeTier =
    !isCurrentPlan &&
    stripePlan?.status === SubscriptionType.CANCELED &&
    plan === Plans.FREE;
  const leftOverOnDowngrade = plans
    ? plans[Plans.FREE]?.monthly_limit - emailSent
    : 0;

  return {
    stripePlan,
    isCurrentPlan,
    price,
    description,
    emailSent,
    resubscribe,
    downGradeToFreeTier,
    leftOverOnDowngrade,
  };
};
