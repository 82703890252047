import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  Order,
  OrderBy,
  OutreachContactsContext,
} from "contexts/OutreachContacts";
import { useContext, useMemo } from "react";
import { CustomEvent } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

type Sort = {
  order: Order;
  orderBy: OrderBy;
  name: string;
};

const SORTS = [
  {
    order: Order.desc,
    orderBy: OrderBy.last_gmail_message_date,
    name: "Most Recent First",
  },
  {
    order: Order.asc,
    orderBy: OrderBy.last_gmail_message_date,
    name: "Oldest First",
  },
  {
    order: Order.desc,
    orderBy: OrderBy.status,
    name: "Most Important Status",
  },
  {
    order: Order.asc,
    orderBy: OrderBy.brand_name,
    name: "Brand Name: A to Z",
  },
  {
    order: Order.desc,
    orderBy: OrderBy.brand_name,
    name: "Brand Name: Z to A",
  },
  {
    order: Order.asc,
    orderBy: OrderBy.contact,
    name: "Email: A to Z",
  },
  {
    order: Order.desc,
    orderBy: OrderBy.contact,
    name: "Email: Z to A",
  },
];

export default function SortSelect() {
  const { fetchContactsParams, setFetchContactsParams } = useContext(
    OutreachContactsContext,
  );

  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    const newValue = SORTS.find((sort) => sort.name === value) || SORTS[0];
    setFetchContactsParams((prev) => {
      const copy = makeDeepCopy(prev);
      copy.page = 1;
      copy.order = newValue.order;
      copy.orderBy = newValue.orderBy;
      return copy;
    });
  };

  const sortValue = useMemo(() => {
    return (
      SORTS.find(
        (sort) =>
          sort.order === fetchContactsParams.order &&
          sort.orderBy === fetchContactsParams.orderBy,
      ) || SORTS[0]
    );
  }, [fetchContactsParams.order, fetchContactsParams.orderBy]);

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>Sort By</InputLabel>
      <Select onChange={handleChange} label="Sort By" value={sortValue.name}>
        {SORTS?.map((sort: Sort) => (
          <MenuItem key={sort.name} value={sort.name}>
            {sort.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
