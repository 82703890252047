import {
  Dialog,
  Slide,
  SlideProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { forwardRef } from "react";

import styles from "./styles";

const Transition = forwardRef(function Transition(
  props: SlideProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  children?: any;
  open: boolean;
  onClose: () => void;
  sx?: any;
  width?: any;
  fullScreenMobile?: boolean;
  direction?: "left" | "right";
}

export default function Drawer({
  children,
  onClose,
  open,
  sx = {},
  width = { xs: "auto", md: 800 },
  fullScreenMobile = true,
  direction = "left",
}: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      TransitionProps={{ direction } as SlideProps}
      keepMounted
      onClose={onClose}
      PaperProps={{
        sx: { ...styles.paper, ...sx, minWidth: width },
      }}
      fullScreen={isMobileScreen && fullScreenMobile}
    >
      {children}
    </Dialog>
  );
}
