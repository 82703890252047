import { Editor as TipTapEditor } from "@tiptap/react";
import { OrganizationUserContext } from "contexts/Organization";
import { camelCase } from "lodash";
import { useCallback, useContext, useState } from "react";

import {
  VariableAttributes,
  VariableSubtype,
} from "components/Editor/Variable";

import { trackEvent } from "./tracking";

interface Props {
  editor: TipTapEditor;
}

export const useVariablEditor = ({ editor }: Props) => {
  const { setProfile } = useContext(OrganizationUserContext);
  const [variableModalIsOpen, setVariableModalIsOpen] = useState(false);

  const [variable, setVariable] = useState<VariableAttributes>({
    id: "",
    example: "",
    label: "",
    subtype: VariableSubtype.user_specific,
    instruction: "",
  });

  const openVariableFilledModal = useCallback(() => {
    const variable = editor.getAttributes("variable");

    const { label, id, example, subtype, instruction } = variable;
    trackEvent("Email Template Variable Fill Button Clicked", {
      label,
    });

    setVariable({
      label,
      id,
      example,
      subtype,
      instruction,
    });
    setVariableModalIsOpen(true);
  }, [editor]);

  const closeVariableModal = useCallback(() => {
    trackEvent("Email Template Variable Dialog Closed");
    setVariableModalIsOpen(false);
    setVariable({
      id: "",
      example: "",
      label: "",
      subtype: VariableSubtype.user_specific,
    });
  }, []);

  const handleSaveVariable = (value: string) => {
    trackEvent("Email Template Variable Saved", {
      label: variable.label,
      value,
    });
    // Update OrganizationProfile so user don't have to fill the same information next time
    if (variable?.subtype === VariableSubtype.user_specific && variable?.id) {
      setProfile((prev) => ({
        ...prev,
        [camelCase(variable.id)]: value,
      }));
    }
    const selection = editor.view.state.selection;
    editor
      .chain()
      .focus()
      .insertContentAt(
        {
          from: selection.from,
          to: selection.to,
        },
        value,
      )
      .run();
    closeVariableModal();
  };

  const deleteVariableFilled = () => {
    trackEvent("Email Template Variable Deleted", { label: variable.label });
    const selection = editor.view.state.selection;
    editor
      .chain()
      .focus()
      .deleteRange({
        from: selection.from,
        to: selection.to,
      })
      .run();
  };

  return {
    variableModalIsOpen,
    setVariableModalIsOpen,
    variable,
    setVariable,
    openVariableFilledModal,
    deleteVariableFilled,
    handleSaveVariable,
    closeVariableModal,
  } as const;
};
