import { Box, Checkbox } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { camelCase } from "lodash";
import { forwardRef, useContext, useImperativeHandle } from "react";
import { OutreachContact } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { ContactViewProperty } from "features/Influencer/Tracking/schema";

interface Props {
  property: ContactViewProperty;
  isChecked?: boolean;
  isCustom: boolean;
  outreachContact: OutreachContact;
}
const TableCheckbox = forwardRef((props: Props, ref) => {
  const { property, isChecked, isCustom, outreachContact } = props;

  const {
    setOutreachContacts,
    updateOutreachContactDefaultValues,
    updateOutreachContactPropertyValues,
    trackCell,
  } = useContext(ContactViewContext);

  useImperativeHandle(ref, () => ({
    childRefClick(e: CustomEvent) {
      handleChange(e);
    },
    childRefKeyPressed(e: CustomEvent) {
      handleChange(e);
    },
  }));

  const handleChange = (e: CustomEvent) => {
    e.stopPropagation();
    if (!outreachContact?.id) {
      return;
    }
    trackCell(property, outreachContact, {
      Action: "Checkbox Clicked",
    });
    setOutreachContacts((prev) => {
      return prev.map((contact) => {
        if (contact.id !== outreachContact.id) {
          return contact;
        }
        if (isCustom && property?.contactPropertyId) {
          const checked = !Boolean(
            contact.propertyValues?.[property?.contactPropertyId],
          );
          if (outreachContact.id) {
            updateOutreachContactPropertyValues(
              outreachContact.id,
              property?.contactPropertyId,
              checked,
            );
          }
          return {
            ...contact,
            propertyValues: {
              ...contact.propertyValues,
              [property?.contactPropertyId]: checked,
            },
          };
        } else {
          const name = camelCase(property?.contactPropertyName || "");
          // @ts-ignore
          const checked = !Boolean(contact[name]);
          updateOutreachContactDefaultValues(outreachContact, {
            isImportant: checked,
          });
          return {
            ...contact,
            [name]: checked,
          };
        }
      });
    });
  };
  return (
    <Box
      sx={{ px: 1, width: "100%", cursor: "pointer" }}
      onClick={handleChange}
    >
      <Checkbox
        tabIndex={-1}
        icon={<Box component="i" className="fa-regular fa-square" />}
        checkedIcon={<Box component="i" className="fa-solid fa-check-square" />}
        checked={isChecked}
      />
    </Box>
  );
});

export default TableCheckbox;
