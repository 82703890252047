import { FormControl, MenuItem, Select } from "@mui/material";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext } from "react";
import { CustomEvent } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

export default function StarredSelect() {
  const { fetchContactsParams, setFetchContactsParams } = useContext(
    OutreachContactsContext,
  );

  const handleChange = (e: CustomEvent) => {
    setFetchContactsParams((prev) => {
      const copy = makeDeepCopy(prev);
      copy.page = 1;
      copy.importantOnly = !copy.importantOnly;
      return copy;
    });
  };

  return (
    <FormControl variant="outlined" size="small">
      <Select
        name="filter"
        sx={styles.filter}
        onChange={handleChange}
        value={fetchContactsParams.importantOnly ? "Needs Response" : "All"}
      >
        <MenuItem value="All">All</MenuItem>
        <MenuItem value="Needs Response">Needs Response</MenuItem>
      </Select>
    </FormControl>
  );
}
