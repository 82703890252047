import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { Dispatch, SetStateAction, useContext, useState } from "react";

import Editor from "components/Editor";
import { useMergeTags } from "components/Editor/MergeTag/useMergeTags";
import { extensions } from "components/Editor/utils";
import { MERGE_TAGS } from "constants/templates";
import { Template } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy, updateList } from "utils/updateLocalState";
import useTemplate from "utils/useTemplate";

import styles from "./styles";

interface Props {
  selectedTemplate: Template | null;
  setSelectedTemplate: Dispatch<SetStateAction<Template | null>>;
}

const SingleTemplate = ({ selectedTemplate, setSelectedTemplate }: Props) => {
  const { filledInOrgProfile } = useTemplate();
  const { templates, setTemplates } = useContext(OutreachTemplatesContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const [toggleMergeTagTooltip, toggleVariableTooltip] = useMergeTags();
  const [loading, setLoading] = useState(false);

  const editor = useEditor(
    {
      extensions: extensions({
        toggleVariableTooltip,
        toggleMergeTagTooltip,
        suggestionOptions: MERGE_TAGS,
      }),
      content: selectedTemplate?.body,
      onUpdate({ editor }) {
        setSelectedTemplate((prev) => {
          const copy = makeDeepCopy(prev);
          copy.body = editor.getHTML();
          return copy;
        });
      },
    },
    [!!selectedTemplate?.body],
  ) as TipTapEditor;

  const handleSaveTemplate = async () => {
    setLoading(true);
    trackEvent("Save Template Button Clicked", { from: "Sidebar" });
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/${selectedTemplate?.id}`,
        "PUT",
        {},
        {
          ...selectedTemplate,
        },
      );
      setAlert("Template Saved", "success");
      updateList(templates, setTemplates, res.template, "id");
      filledInOrgProfile();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ pb: 15 }}>
      <Box sx={styles.editor}>
        <Editor editor={editor} hasBorder={false} />
      </Box>

      <Grid container justifyContent="flex-end" sx={{ pr: 2 }}>
        <LoadingButton
          loading={loading}
          onClick={handleSaveTemplate}
          disableElevation
          size="small"
          sx={{ mt: 2 }}
          variant="contained"
        >
          Save Changes{" "}
        </LoadingButton>
      </Grid>
    </Box>
  );
};

export default SingleTemplate;
