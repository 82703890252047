import {
  Box,
  Button,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  capitalize,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { ALL } from "schemas/forms";
import { CustomEvent } from "schemas/functions";

import { TemplateContentType } from "features/Influencer/ContactList/schema";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface FilterMenuProps {
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
}

export default function FilterMenu({ filter, setFilter }: FilterMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeFilter = (e: CustomEvent, option: string) => {
    trackEvent("Filter Button Clicked", { option });
    setFilter(option);
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        variant="outlined"
        onClick={handleClick}
      >
        Filter
        <Box component="i" className="fa-solid fa-filter" sx={{ ml: 1 }} />
      </Button>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ width: 320, maxWidth: "100%" }}
      >
        <MenuList>
          {[
            ALL,
            TemplateContentType.OUTREACH,
            TemplateContentType.FOLLOWUP,
          ]?.map((filterOption) => (
            <MenuItem
              onClick={(e: CustomEvent) => handleChangeFilter(e, filterOption)}
              sx={filter === filterOption ? styles.active : {}}
              key={filterOption}
            >
              <ListItemText>{capitalize(filterOption)}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
