import grey from "@mui/material/colors/grey";

const styles = {
  arrowContainer: {
    fontSize: 18,
    background: "white",
    border: `1px solid ${grey[300]}`,
    borderLeft: 0,
    borderRight: 0,
    p: 0.5,
  },
  arrowIcon: {
    color: grey[400],
    background: "white",
    borderRadius: 0,
    fontWeight: 400,
  },
};

export default styles;
