import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { CustomEvent } from "schemas/functions";

import { Url } from "utils/useLinkEditor";

interface IProps {
  url: Url;
  closeModal: () => void;
  open: boolean;
  onChangeUrl: (url: Url) => void;
  onSaveLink: () => void;
  onRemoveLink: () => void;
}

export function LinkModal({
  url,
  closeModal,
  open,
  onChangeUrl,
  onSaveLink,
  onRemoveLink,
}: IProps) {
  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={closeModal}>
      <IconButton
        sx={{
          color: "primary.main",
          fontSize: 14,
          position: "absolute",
          right: 10,
          top: 10,
        }}
        onClick={closeModal}
      >
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle>Modify Link </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          To add or edit a link, press Save. To unset a link, press Remove.
        </Typography>
        <TextField
          sx={{ width: { xs: "100%", md: 400 }, mb: 1 }}
          value={url.href || ""}
          onChange={(event: CustomEvent) => {
            const newUrl = { ...url };
            newUrl.href = event.target.value;
            onChangeUrl(newUrl);
          }}
          placeholder="https://www.google.com"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newUrl = { ...url };
                newUrl.openNewTab = event.target.checked;
                onChangeUrl(newUrl);
              }}
              checked={url.openNewTab}
              disableRipple
              icon={
                <Box
                  sx={{ color: "green.main" }}
                  component="i"
                  className="fa-regular fa-square"
                />
              }
              checkedIcon={
                <Box
                  component="i"
                  className="fa-solid fa-square-check"
                  sx={{ color: "green.main" }}
                />
              }
            />
          }
          label="Open link in a new tab"
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onRemoveLink}>
          Remove
        </Button>
        <Button onClick={onSaveLink}>Save Link</Button>
      </DialogActions>
    </Dialog>
  );
}
