import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import {
  BentoBrand,
  BrandRecommendationArchiveReason,
} from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";
import { CustomEvent } from "schemas/functions";

import { UNSPECIFIED } from "constants/influencer";
import { fetcherAuth } from "utils/api";
import { removeFromListById } from "utils/updateLocalState";

import { BrandCardSource } from "../../schema";
import { getStyles } from "./styles";

interface BrandCardMenuProps {
  brand: BentoBrand;
  setAlert: SetAlertType;
  source: BrandCardSource;
}

export default function BrandCardMenu({
  brand,
  setAlert,
  source,
}: BrandCardMenuProps) {
  const { lowerWidth } = useContext(DiscoverViewContext);
  const styles = getStyles(lowerWidth);

  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const { setBrands, setSimilarBrands } = useContext(BrandsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { setErrorAlert } = useContext(AlertContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleArchive = async (e: CustomEvent, archiveReason: string) => {
    e.stopPropagation();
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/brand-recommendations/${brand?.id}/archive`,
        "POST",
        {},
        {
          archiveReason,
        },
      );
      if (source === BrandCardSource.DISCOVER_BRAND) {
        removeFromListById(setBrands, brand.id);
      } else {
        removeFromListById(setSimilarBrands, brand.id);
      }
      setAlert("Removed this brand from your list", "success");
      handleClose();
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const reportBrokenLink = async () => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${brand.id}/report-link`,
        "POST",
        {},
        { link: UNSPECIFIED },
      );
      setAlert("Report submitted.", "success");
    } catch (error) {
      setErrorAlert(error);
    }
  };

  return (
    <>
      <IconButton sx={styles.iconButton} onClick={handleClick}>
        <Box component="i" className="fa-regular fa-ellipsis-vertical" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ width: 250, maxWidth: "100%" }}
      >
        <MenuList>
          <MenuItem
            onClick={(e: CustomEvent) =>
              handleArchive(
                e,
                BrandRecommendationArchiveReason.DO_NOT_RECOMMEND,
              )
            }
          >
            <ListItemIcon>
              <Box component="i" className="fa-regular fa-ban" />
            </ListItemIcon>
            <Typography variant="body2" color="text.secondary">
              Do not recommend
            </Typography>
          </MenuItem>
          <MenuItem onClick={reportBrokenLink}>
            <ListItemIcon>
              <Box component="i" className="fa-regular fa-flag" />
            </ListItemIcon>
            <Typography variant="body2" color="text.secondary">
              Report broken link
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
