import { TableCell, styled, tableCellClasses } from "@mui/material";
import { grey } from "@mui/material/colors";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#014339",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const tableStyles = {
  tableHeight: {
    maxHeight: { xs: "auto", md: "600px" },
    borderRadius: 4,
    border: "1px solid #014339",
  },
  tableContainer: {
    borderRadius: 2,
    border: "1px solid",
    borderColor: grey[200],
  },
  row: {
    "&:last-child td, &:last-child th": { border: 0 },
    "&:hover": {
      backgroundColor: grey[100],
      cursor: "pointer",
    },
  },
};
