import { useAuth } from "@clerk/clerk-react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Alert from "components/Alert";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

type Referral = {
  id: number;
  instagramUrl: string;
  tiktokUrl: string;
  youtubeUrl: string;
  twitchUrl: string;
  referredEmail: string;
  referredName: string;
  referrerEmail: string;
  referrerName: string;
  isValid: boolean;
};

export default function Referrals() {
  const [message, severity, setAlert, closeAlert] = useAlert();

  const { getToken } = useAuth();
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [currentReferral, setCurrentReferral] = useState<
    Referral | undefined
  >();

  const updateReferralStatus = async () => {
    try {
      await fetcherAuth(
        getToken,
        `/api/admin/referrals/${currentReferral?.id}`,
        "PATCH",
        {},
        {
          isValid: true,
        },
      );
      setOpen(false);
    } catch (error) {
      setAlert(`${error}`, "error");
    }
  };

  const fetchReferrals = async () => {
    try {
      const { referrals } = await fetcherAuth(
        getToken,
        `/api/admin/referrals/invalid`,
        "GET",
        {},
      );
      setReferrals(referrals);
    } catch (error) {
      setAlert(`${error}`, "error");
    }
  };

  useEffect(() => {
    fetchReferrals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage
      content={
        <>
          <Grid sx={{ mt: 2 }} container spacing={1}>
            {referrals.map((referral) => (
              <Grid item key={referral.id} xs={12}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography>
                      <strong>Referred By:</strong> {referral.referrerName} (
                      {referral.referrerEmail})
                    </Typography>
                    <Typography>
                      <strong>Referred:</strong> {referral.referredName} (
                      {referral.referredEmail})
                    </Typography>
                    <Typography paragraph>
                      <strong>Referred Social Media URL:</strong>{" "}
                      {referral.instagramUrl} {referral.tiktokUrl}{" "}
                      {referral.youtubeUrl} {referral.twitchUrl}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setCurrentReferral(referral);
                        setOpen(true);
                      }}
                    >
                      Set as valid
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <ConfirmDialog
            header={`Are you sure?`}
            subheader={`This action will send an email to the referrer (${currentReferral?.referrerEmail}). Are you sure you want to do this?`}
            open={open}
            handleClose={() => setOpen(false)}
            handleConfirm={updateReferralStatus}
          />
          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
        </>
      }
    />
  );
}
