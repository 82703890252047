export const getStyles = (lowerWidth = false) => {
  const styles = {
    iconButton: {
      color: "green.dark",
      fontSize: 14,
      width: 20,
      height: 20,
    },
  };
  return styles;
};
