import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";

import DropzoneUpload from "components/DropzoneUpload";
import { fetcherAuth } from "utils/api";
import { navigateBackPath } from "utils/navigation";
import { trackEvent } from "utils/tracking";

import { IMPORT_CSV_FLOW_EXITED_EARLY } from "../../events";
import styles from "./styles";

export default function UploadCsv() {
  const navigate = useNavigate();

  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const location = useLocation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [file, setFile] = useState<File | null>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { trackTable } = useContext(ContactViewContext);
  const { getToken } = useAuth();

  const onBack = () => {
    trackTable(IMPORT_CSV_FLOW_EXITED_EARLY, { Step: "Upload A File" });
    navigate(navigateBackPath(location.pathname, 1));
  };

  const wrongFileAlert = (fileRejections: FileRejection[]) => {
    const errorText =
      fileRejections.length > 1
        ? "Only one CSV file is allowed to be imported."
        : "Only CSV files are allowed to be imported.";
    setAlert(
      <>
        {errorText} Not sure how to use CSV files?{" "}
        <Link
          href="https://www.notion.so/hatchways/Guide-to-Pitch-Tracking-41915814d9ed439cb4db923889012bfa?pvs=4#bf1c0ca06d2b4572955cab8e51608726"
          sx={styles.link}
          target="_blank"
          onClick={() =>
            trackEvent("Pitch Tracker Import CSV Learn How Clicked", {
              from: "errorAlert",
            })
          }
        >
          Learn How
        </Link>
      </>,
      "error",
    );
  };

  const handleUpload = async () => {
    if (!currentOrg?.id || !file) {
      return;
    }

    const data = new FormData();
    data.append("file", file);

    setUploadLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-uploads`,
        "POST",
        {},
        data,
        false,
        true,
      );
      if (res.contactUpload?.id) {
        navigate(`${res.contactUpload.id}/match`);
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setUploadLoading(false);
    }
  };

  return (
    <>
      <DialogTitle>Upload a File</DialogTitle>
      <DialogContent>
        <Typography color="textSecondary" paragraph>
          Importing from Notion, Airtable or Google Sheets? Not sure how to
          format your file?{" "}
          <Link
            href="https://www.notion.so/hatchways/Guide-to-Pitch-Tracking-41915814d9ed439cb4db923889012bfa?pvs=4#bf1c0ca06d2b4572955cab8e51608726"
            onClick={() =>
              trackEvent("Pitch Tracker Import CSV Learn How Clicked", {
                from: "description",
              })
            }
            target="_blank"
          >
            Learn How
          </Link>
        </Typography>
        <Grid container spacing={1}>
          {file && (
            <Grid item xs={12}>
              <Alert
                severity="success"
                onClose={() => {
                  setFile(null);
                }}
              >
                <Typography>{file.name}</Typography>
                <Typography variant="caption">{file.size}b</Typography>
              </Alert>
            </Grid>
          )}
          {!file && (
            <>
              <Grid item xs={12} sm={6}>
                <DropzoneUpload
                  onError={wrongFileAlert}
                  setFile={setFile}
                  dragEnabled={false}
                />
              </Grid>
              {!isMobileScreen && (
                <Grid item xs={12} sm={6}>
                  <DropzoneUpload
                    onError={wrongFileAlert}
                    setFile={setFile}
                    dragEnabled
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onBack}>
          Back
        </Button>{" "}
        <LoadingButton
          onClick={handleUpload}
          loading={uploadLoading}
          variant="contained"
          disableElevation
          disabled={!file}
        >
          Next
        </LoadingButton>
      </DialogActions>
    </>
  );
}
