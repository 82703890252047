import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { red } from "@mui/material/colors";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import * as React from "react";

import { CONSENT_READING_EMAIL_OPTION_SELECTED } from "features/Influencer/Tracking/events";
import { DISPLAYED_CONSENT_DIALOG } from "utils/localStorage";

const styles = {
  formControl: {
    ml: 2,
  },
  checkbox: {
    color: "green.main",
  },
  error: {
    color: red[500],
    "& .MuiFormControlLabel-label": {
      color: red[500],
    },
  },
  checkboxError: {
    color: red[500],
  },
};

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export default function ConsentDialog({
  open,
  handleClose,
  handleConfirm,
}: Props) {
  const { currentOrg } = React.useContext(OrganizationUserContext);
  const handleSaveConsentLocally = () => {
    if (!consent) {
      setConsentError(true);
      return;
    }
    localStorage.setItem(
      `${DISPLAYED_CONSENT_DIALOG}-${currentOrg?.id}`,
      "true",
    );
    handleConfirm();
    handleClose();
  };

  const { trackTable } = React.useContext(ContactViewContext);
  const [consent, setConsent] = React.useState<boolean>(false);
  const [consentError, setConsentError] = React.useState<boolean>(false);
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Row</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            To ensure your contacts are imported correctly, Bento connects with
            your Gmail to update statuses automatically and remind you about
            follow-up tasks by processing past email exchanges.
            <Box component="strong" sx={{ ml: 0.5 }}>
              Do you agree to let Bento process these contacts?
            </Box>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                edge="start"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setConsent(event.target.checked);
                  setConsentError(false);
                  trackTable(CONSENT_READING_EMAIL_OPTION_SELECTED, {
                    "Clicked From": "Adding New Row",
                    "User Consented": event.target.checked,
                  });
                }}
                checked={consent}
                disableRipple
                required
                icon={
                  <Box
                    sx={[styles.checkbox, consentError && styles.checkboxError]}
                    component="i"
                    className="fa-regular fa-square"
                  />
                }
                checkedIcon={
                  <Box
                    component="i"
                    className="fa-solid fa-square-check"
                    sx={styles.checkbox}
                  />
                }
              />
            }
            sx={[styles.formControl, consentError && styles.error]}
            label="Yes, I agree"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton onClick={handleSaveConsentLocally}>Save</LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
