import { grey } from "@mui/material/colors";

export const styles = (size: number) => {
  return {
    imageBox: {
      borderRadius: 4,
      width: size,
      height: size,
      border: `1px solid ${grey[200]}`,
      "&:hover": {
        border: `1px solid ${grey[300]}`,
      },
    },
    img: {
      display: "block",
      objectFit: "cover",
    },
    postBy: {
      color: grey[500],
      my: 1,
      fontSize: 12,
    },
    textHolder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      p: 2,
      fontSize: 12,
    },
  };
};
