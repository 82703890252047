import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { APIProvider } from "@vis.gl/react-google-maps";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext, useEffect, useState } from "react";
import {
  BentoBrandContent,
  BentoBrandProduct,
  TikTokAd,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { useAutoCompletePrediction } from "components/LocationAutocomplete/useAutoCompletePrediction";
import TikTokAdContent from "components/TikTokAdContent";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";

import BrandCardActions from "../Results/BrandCard/BrandCardActions";
import BrandIcons from "../Shared/BrandIcons";
import DetailedBrandViewWrapper from "./Wrapper";
import BrandAnnotation from "./components/BrandAnnotation";
import BrandDemographics from "./components/BrandDemographics";
import BrandLinks from "./components/BrandLinks/BrandLinks";
import BrandLocations from "./components/BrandLocations";
import BrandProducts from "./components/BrandProducts";
import PartnershipPosts from "./components/PartnershipPosts";
import { getIconsCondition } from "./helpers";
import styles from "./styles";

function DetailedBrandView() {
  const { API_KEY } = useAutoCompletePrediction();
  const { isTwoColumnView, isLargeRecommendedPage } = usePageSize();
  const { hideBrandInfoViewDiscoverTab } = usePageSize();
  const { getToken } = useAuth();

  // Contexts
  const { setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);
  const { selectedBrand, setSelectedBrand } = useContext(BrandsContext);
  const {
    setBrandDetailsId,
    brandDetailsId,
    brandView,
    setBrandView,
    setView,
  } = useContext(QuickSendDrawerContext);
  const { discoverTab } = useContext(DiscoverViewContext);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [listOfContent, setListOfContent] = useState<BentoBrandContent[]>([]);
  const [tikTokAds, setTikTokAds] = useState<TikTokAd[]>([]);
  const [products, setProducts] = useState<BentoBrandProduct[]>([]);

  const onClose = () => {
    setSelectedBrand(null);
    setBrandDetailsId(-1);
    setView(QuickSendTabView.EXPAND);
  };

  const [fetchLoading, setFetchLoading] = useState(true);

  let hasIcons = false;
  if (selectedBrand) {
    const {
      addedWithinLastWeek,
      hasPaidPartnership,
      worksWithUgc,
      noRecentContacts,
      hasTiktokAds,
      hasNonGenericEmails,
    } = getIconsCondition(selectedBrand);
    hasIcons =
      addedWithinLastWeek ||
      hasPaidPartnership ||
      worksWithUgc ||
      noRecentContacts ||
      hasTiktokAds ||
      hasNonGenericEmails;
  }

  const brandLogo = selectedBrand?.logoUrl && (
    <Box component="img" src={selectedBrand?.logoUrl} sx={styles.logo} />
  );

  const fetchPaidPartnershipContent = async () => {
    try {
      const result = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${brandDetailsId}/paid-partnership`,
        "GET",
        {},
        true,
      );
      setListOfContent(result.bentoBrandContents);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const fetchTikTokAds = async () => {
    try {
      const result = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${brandDetailsId}/tiktok-ads`,
        "GET",
        {},
        true,
      );
      setTikTokAds(result.tikTokAds);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const fetchProducts = async () => {
    try {
      const result = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${brandDetailsId}/products`,
        "GET",
      );
      setProducts(result.bentoBrandProducts);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const _fetchSelected = async () => {
    const res = await fetcherAuth(
      getToken,
      `/api/organization/${currentOrg?.id}/bento-brands/detailed-brand-view/${brandDetailsId}`,
    );
    setSelectedBrand(res.bentoBrand);
  };

  const fetchSelectedBentobrand = async () => {
    if (!currentOrg?.id) {
      return;
    }
    setFetchLoading(true);
    setTikTokAds([]);
    setListOfContent([]);
    setProducts([]);
    try {
      await Promise.all([
        _fetchSelected(),
        fetchPaidPartnershipContent(),
        fetchTikTokAds(),
        fetchProducts(),
      ]);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleHideDrawer = (e: CustomEvent) => {
    trackEvent("Hide Brand Info Button Clicked", {
      "Bento Brand ID": selectedBrand?.id,
      button: "Hide View",
    });
    if (isMobileScreen) {
      onClose();
    } else {
      setBrandView(QuickSendTabView.COLLAPSE);
    }
  };

  useEffect(() => {
    if (brandDetailsId > -1) {
      setBrandView(QuickSendTabView.EXPAND);
      fetchSelectedBentobrand();
    }
    trackEvent("Detailed Brand View Drawer Shown", {
      "Bento Brand ID": selectedBrand?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDetailsId, currentOrg?.id]);

  // Hide and show the brand view if user open Discover View on Recommendations page
  useEffect(() => {
    hideBrandInfoViewDiscoverTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discoverTab]);

  if (brandDetailsId < 0 || brandView === QuickSendTabView.COLLAPSE) {
    return <></>;
  }

  return (
    <APIProvider apiKey={API_KEY || ""}>
      <DetailedBrandViewWrapper handleClose={onClose}>
        {isLargeRecommendedPage && !isTwoColumnView ? (
          <></>
        ) : (
          <DialogTitle sx={[styles.dialogTitleText, styles.dialogWrapper]}>
            <Grid
              onClick={handleHideDrawer}
              sx={styles.dialogGrid}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item container alignItems="center" xs>
                {/* If hasContacts is undefined, we don't have information on if the brand has contacts or not, so don't render anything yet */}
                {!isLargeRecommendedPage &&
                  selectedBrand &&
                  selectedBrand.hasContacts !== undefined && (
                    <BrandCardActions
                      setAlert={setAlert}
                      brand={selectedBrand}
                    />
                  )}
                <Grid item xs sx={styles.brandName}>
                  {selectedBrand && (
                    <Typography component="span" noWrap>
                      {selectedBrand.brandName}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Box>
                <IconButton sx={styles.icon} onClick={onClose}>
                  <Box component="i" className="fa-solid fa-xmark" />
                </IconButton>
              </Box>
            </Grid>
          </DialogTitle>
        )}
        <>
          <Box sx={{ p: 2, pt: 0 }}>
            <Grid container gap={2} alignItems="center" sx={styles.section}>
              {brandLogo}{" "}
              <Typography variant="h3">{selectedBrand?.brandName} </Typography>
            </Grid>

            {selectedBrand && (
              <Box sx={styles.section}>{selectedBrand?.aboutSummary}</Box>
            )}

            <Grid container sx={styles.section}>
              <Grid item xs={12} sx={{ pr: 2 }}>
                {selectedBrand && (
                  <BrandLocations selectedBrand={selectedBrand} />
                )}
                <BrandAnnotation
                  header="Category"
                  selectedBrand={selectedBrand}
                />
                <BrandAnnotation
                  header="Price Point"
                  selectedBrand={selectedBrand}
                />
                <BrandAnnotation
                  header="Company Size"
                  selectedBrand={selectedBrand}
                />
                <BrandAnnotation
                  header="Instagram Followers"
                  selectedBrand={selectedBrand}
                  stringValue={selectedBrand?.instagramFollowerCount?.toString()}
                />
              </Grid>

              {selectedBrand && (
                <Grid item xs sx={styles.section}>
                  <Typography variant="h6">Social Media Links</Typography>
                  <BrandLinks bentoBrand={selectedBrand} />
                </Grid>
              )}
            </Grid>

            {hasIcons && selectedBrand && (
              <Box sx={styles.section}>
                <Typography variant="h6">
                  Recommended for you because
                </Typography>
                <BrandIcons bentoBrand={selectedBrand} isBrandView={true} />
              </Box>
            )}
            {selectedBrand && (
              <Box sx={styles.section}>
                <BrandDemographics bentoBrand={selectedBrand} />
              </Box>
            )}
            {tikTokAds?.length > 0 && (
              <Box sx={styles.section}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Related TikTok Ads
                </Typography>
                <Grid container columnSpacing={2}>
                  {tikTokAds.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <TikTokAdContent item={item} bentoBrand={selectedBrand} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {selectedBrand && listOfContent?.length > 0 && (
              <Box sx={styles.section}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Recent Brand Partnerships
                </Typography>
                {selectedBrand && (
                  <PartnershipPosts
                    bentoBrand={selectedBrand}
                    brandContents={listOfContent}
                  />
                )}
              </Box>
            )}

            {selectedBrand && products && products?.length > 0 && (
              <Box sx={styles.section}>
                <BrandProducts products={products} bentoBrand={selectedBrand} />
              </Box>
            )}
          </Box>

          {fetchLoading && (
            <Grid container gap={2} sx={{ px: 3 }}>
              <Skeleton
                animation="wave"
                variant="rounded"
                height={30}
                width="100%"
              />
              <Skeleton
                animation="wave"
                variant="rounded"
                height={100}
                width="100%"
              />
            </Grid>
          )}
        </>
      </DetailedBrandViewWrapper>
    </APIProvider>
  );
}

export default DetailedBrandView;
