import { AlertContext } from "contexts/Alert";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import StyledPage from "components/Styled/Page";

import TemplatesTable from "./TemplatesTable";

export default function TemplatesPage() {
  const { setAlert } = useContext(AlertContext);

  return (
    <StyledPage
      content={
        <>
          <Routes>
            <Route path="*" element={<TemplatesTable setAlert={setAlert} />} />
          </Routes>
        </>
      }
    />
  );
}
