import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface NoMergeTagsWarningDialogProps {
  open: boolean;
  handleClose: () => void;
  onProceed: () => void;
}

export default function NoMergeTagsWarningDialog({
  open,
  handleClose,
  onProceed,
}: NoMergeTagsWarningDialogProps) {
  const renderMergeTag = (mergeTag: string) => {
    return (
      <span
        style={{
          background: `rgb(175, 191, 176)`,
          borderRadius: "0.4rem",
          boxDecorationBreak: "clone",
          padding: "0.2rem 0.3rem",
        }}
      >
        {mergeTag}
      </span>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>👋 Before you finish!</DialogTitle>
      <DialogContent>
        To save you time in the future, use {renderMergeTag("{First Name}")} and{" "}
        {renderMergeTag("{Brand Name}")} in your email templates, and we will
        automatically fill them in for you for every brand and contact you reach
        out to!
      </DialogContent>
      <DialogActions>
        <Button onClick={onProceed}>Save as is</Button>
        <Button variant="contained" onClick={handleClose}>
          Continue to edit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
