import { useAuth } from "@clerk/clerk-react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { debounce } from "lodash";
import { useContext, useMemo, useRef, useState } from "react";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";

import styles from "./styles";

interface BentoBrandsAutocompleteProps {
  selectedBentoBrands: string[];
  updateSelectedBentoBrands: (newBrands: string[]) => void;
}

interface BrandSuggestedOptionType {
  brandName: string;
}

const filter = createFilterOptions<BrandSuggestedOptionType | BentoBrand>();

export default function BentoBrandsAutocomplete({
  selectedBentoBrands,
  updateSelectedBentoBrands,
}: BentoBrandsAutocompleteProps) {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAlert } = useContext(AlertContext);

  const abortController = useRef<AbortController | undefined>(undefined);
  const [filteredBrands, setFilteredBrands] = useState<
    (BentoBrand | BrandSuggestedOptionType)[]
  >([]);
  const [fetchBrandLoading, setFetchBrandLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const convertToString = (
    option: string | BentoBrand | BrandSuggestedOptionType,
  ) => {
    if (!option) return "";
    return typeof option === "string" ? option.trim() : option.brandName.trim();
  };

  const handleAutocompleteChange = (
    e: CustomEvent,
    brands: (string | BentoBrand | BrandSuggestedOptionType)[],
  ) => {
    updateSelectedBentoBrands(
      brands
        .map((bentoBrand) => convertToString(bentoBrand))
        .filter((bentoBrand) => !!bentoBrand),
    );
    setFilteredBrands([]);
  };

  const fetchBrandByName = async (name: string) => {
    if (!currentOrg?.id) {
      setFetchBrandLoading(false);
      return;
    }

    if (abortController?.current) {
      abortController.current?.abort();
    }

    try {
      const encodedQuery = encodeURIComponent(name);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/autocomplete?query=${encodedQuery}`,
        "GET",
        {},
        {},
        false,
        false,
        true,
        abortController.current?.signal,
      );
      if (res?.bentoBrands) {
        setFilteredBrands(res.bentoBrands);
      }
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to retrieve brands. Please reload and retry again",
        "error",
      );
    } finally {
      setFetchBrandLoading(false);
    }
  };

  const handleOnInputChange = (event: CustomEvent, newInputValue: string) => {
    setInputValue(newInputValue);

    if (newInputValue.endsWith(",")) {
      abortController.current?.abort();
      const newBrand = newInputValue.slice(0, -1).trim();
      if (newBrand) {
        const newSelectedBentoBrands = [...selectedBentoBrands, newBrand];
        updateSelectedBentoBrands(newSelectedBentoBrands);
      }
      setInputValue("");
    } else if (newInputValue.trim() !== "") {
      setFetchBrandLoading(true);
      changeInput(newInputValue.trim());
    }
  };

  const changeInput = useMemo(
    () =>
      debounce(async (newSearchQuery: string) => {
        await fetchBrandByName(newSearchQuery);
      }, 100),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id],
  );

  return (
    <>
      <Autocomplete
        multiple
        autoSelect
        autoHighlight
        freeSolo
        clearOnBlur
        selectOnFocus
        fullWidth
        disablePortal
        slotProps={{
          popper: {
            placement: "top",
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
            ],
          },
        }}
        disableClearable
        forcePopupIcon={false}
        sx={styles.autocomplete}
        value={selectedBentoBrands}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => {
          return convertToString(option) === convertToString(value);
        }}
        options={filteredBrands}
        filterOptions={(options, params) => {
          if (fetchBrandLoading) {
            return options;
          }
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(
            (option) =>
              inputValue.toLowerCase() === option.brandName.toLowerCase(),
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              brandName: inputValue,
            });
          }
          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Add Brand..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {fetchBrandLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        getOptionLabel={(option) => convertToString(option)}
        ChipProps={{
          size: "small",
        }}
        onInputChange={handleOnInputChange}
        onChange={handleAutocompleteChange}
      />
    </>
  );
}
