import { useAuth } from "@clerk/clerk-react";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext, Suggestion } from "contexts/OutreachContacts";
import { debounce } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  setAlert: SetAlertType;
}

export default function SearchBar({ setAlert }: Props) {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { fetchContactsParams, setFetchContactsParams } = useContext(
    OutreachContactsContext,
  );
  const [searchResults, setSearchResults] = useState<Suggestion[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const handleOnInputChange = (event: CustomEvent, newInputValue: string) => {
    setSearchLoading(true);
    changeInput(newInputValue);
  };

  const changeInput = useMemo(
    () =>
      debounce(async (newSearchQuery: string) => {
        await searchInbox(newSearchQuery);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id],
  );

  const searchInbox = async (queryString: string) => {
    if (!currentOrg?.id) {
      setSearchLoading(false);
      return;
    }
    try {
      const encodedQuery = encodeURIComponent(queryString);
      let url = `/api/organization/${currentOrg?.id}/outreach-contacts/autocomplete?query=${encodedQuery}`;
      const { autocompleteSuggestions } = await fetcherAuth(getToken, url);
      setSearchResults(autocompleteSuggestions);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    } finally {
      setSearchLoading(false);
    }
  };

  const onSelectionChange = (
    event: CustomEvent,
    newValue: Suggestion | null,
  ) => {
    setFetchContactsParams((prev) => {
      const copy = makeDeepCopy(prev);
      copy.page = 1;
      copy.selectedSuggestion = newValue;
      return copy;
    });
  };

  useEffect(() => {
    searchInbox("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <Autocomplete
      id="inbox-suggestions"
      options={searchResults}
      clearOnBlur
      sx={styles.autocomplete}
      freeSolo={false}
      value={fetchContactsParams.selectedSuggestion}
      onChange={onSelectionChange}
      loading={searchLoading}
      isOptionEqualToValue={(option: Suggestion, value: Suggestion) =>
        option.id === value.id && option.type === value.type
      }
      getOptionLabel={(option: Suggestion) => option.suggestion}
      renderOption={(props, option) => <li {...props}>{option.suggestion}</li>}
      onInputChange={handleOnInputChange}
      // Override Autocomplete Default Filter Function with BE searching.
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search Inbox"
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Box
                component="i"
                className="fa-regular fa-search"
                sx={{ px: 2 }}
              />
            ),
            endAdornment: (
              <>
                {searchLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
