import { useAuth } from "@clerk/clerk-react";
import {
  AppBar,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomEvent } from "schemas/functions";

import styles from "./styles";

interface NavigationBarProps {
  routes: string[];
}

function NavigationBar({ routes }: NavigationBarProps) {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSwitchTab = (e: CustomEvent, path: string) => {
    switch (e.detail) {
      case 1:
        navigate(path);
        break;
      case 2:
        window.open(path, "_blank");
        break;
    }
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            component="a"
            href="https://app.bento.com"
            sx={styles.header}
          >
            Bento
          </Typography>
          <Grid container sx={styles.pageBox}>
            {routes?.map((route) => (
              <Button
                key={route}
                onClick={(e: CustomEvent) => handleSwitchTab(e, route)}
                sx={styles.page}
              >
                {route}
              </Button>
            ))}
            <Button onClick={() => signOut()} sx={styles.page}>
              Log Out
            </Button>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavigationBar;
