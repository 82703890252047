import { grey } from "@mui/material/colors";

const styles = {
  key: {
    color: grey[600],
    fontSize: 14,
  },
};

export default styles;
