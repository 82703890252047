import { Theme, darken } from "@mui/material";
import { grey } from "@mui/material/colors";

export const drawerStyles = {
  root: { height: { xs: "100svh", lg: "auto" } },
  drawerHeader: {
    justifyContent: { xs: "auto", md: "space-between" },
    alignItems: "center",
    pt: 2,
    px: { xs: 2, sm: 4, md: 8 },
  },
  drawerContent: {
    p: { xs: 1, sm: 3, md: 5 },
  },
  previewContainer: {
    px: { xs: 0, sm: 2, lg: 8 },
    flexGrow: 1,
  },
  section: {
    rowGap: 2,
  },
  saveButton: {
    height: "53px",
  },
  tabContainer: {
    mt: 2,
    borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
    px: 8,
  },
  button: {
    color: "rgba(255, 255, 255, 0.5)",
  },
  header: {
    backgroundColor: "yellow.light",
    borderBottom: `1px solid ${grey[300]}`,
    textAlign: { xs: "center", md: "auto" },
    p: { xs: 2 },
    position: "sticky",
    zIndex: 2,
    top: 0,
    fontSize: { xs: 12, sm: 16 },
  },
  footer: {
    backgroundColor: "yellow.light",
    borderTop: `1px solid ${grey[300]}`,
    textAlign: { xs: "center", md: "auto" },
    p: { xs: 2 },
    position: "sticky",
    zIndex: { xs: "2", md: "auto" },
    bottom: 0,
    width: "100%",
  },
  headerButton: {
    position: "absolute",
    left: { xs: 8, md: 16 },
    top: { xs: 8, md: 16 },
  },
  actionButton: {
    width: { xs: "max-content", md: 100 },
    maxWidth: { xs: 80, md: "none" },
    fontSize: 12,
  },
  customizeButton: { fontSize: 12 },

  headerText: {
    fontSize: { xs: 18 },
    lineHeight: { xs: "20px", md: "40px" },
  },
  disabledSection: {
    opacity: 0.3,
    pointerEvents: "none",
    backgroundColor: grey[500],
  },

  // Preview experience
  emailBox: {
    overflowX: "hidden",
    mb: 1,
  },
  sendTo: {
    backgroundColor: (theme: Theme) =>
      darken(theme.palette.secondary.light, 0.04),
    color: "green.dark",
    px: 2,
    py: 1,
    display: "flex",
    alignItems: "center",
    fontSize: 12,
  },
  subjectWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    px: 2,
  },
  subjectText: {
    ml: 1,
    width: "100%",
  },
  annotation: {
    fontSize: "12px",
    color: grey[500],
  },
  emailBody: {
    backgroundColor: "white",
    color: "green.dark",
    p: { xs: 1, sm: 2 },
    display: "flex",
    flexDirection: "column",
  },
  selectMenuItem: {
    fontSize: 12,
  },
};
