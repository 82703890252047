import { barWidth } from "styles/theme";

import { pageStyles } from "components/Styled/Page/styles";

const styles = {
  ...pageStyles,
  mobileContainer: {
    position: "absolute",
    right: 0,
    bottom: `${barWidth}px`,
    left: 0,
    top: 0,
  },
};

export default styles;
