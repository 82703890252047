import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { PAGE_VISITED } from "constants/trackingProps";
import { trackEvent } from "utils/tracking";

import OutreachTable from "./NewOutreachTable";

export default function InfluencerContacts() {
  const { setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <Routes>
      <Route path="*" element={<OutreachTable setAlert={setAlert} />} />
    </Routes>
  );
}
