import { Box, Grid, Link, Tooltip } from "@mui/material";
import { QuickSendContext } from "contexts/QuickSend";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { BentoContact, NewBentoContact } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";

import { getScoreColor } from "utils/color";
import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";

import ViewScoreDialog from "./ViewScoreDialog";
import { ReplyScoreLabel } from "./helpers";
import styles from "./styles";

interface ContactScoreProps {
  contacts?: (BentoContact | NewBentoContact)[];
  setAlert: SetAlertType;
}

export default function ContactScore({
  contacts,
  setAlert,
}: ContactScoreProps) {
  const { isOnboardingPage } = usePageSize();
  const [openViewScore, setOpenViewScore] = useState(false);
  const { bentoContact } = useContext(QuickSendContext);

  const { subscription, isProPlan } = useContext(SubscriptionContext);
  const displayScore = subscription?.isSubscribed && isProPlan;

  const handleViewScore = () => {
    trackEvent("View Score Button Clicked");
    setOpenViewScore(true);
  };

  const renderToolTip = (contact: BentoContact | NewBentoContact) => {
    const score = (contact as BentoContact)?.score;
    if (!score) {
      return;
    }
    return displayScore
      ? `Reply Score: ${ReplyScoreLabel(score || 0)}`
      : "To view how likely this contact will respond to you, click on the View Score button below.";
  };

  return (
    <>
      {contacts &&
        contacts?.length > 0 &&
        bentoContact &&
        !isOnboardingPage &&
        bentoContact?.score !== undefined && (
          <Grid item xs={6} container alignItems="center">
            {displayScore && bentoContact ? (
              <Tooltip title={renderToolTip(bentoContact)}>
                <div>
                  <Box
                    sx={{ color: getScoreColor(bentoContact?.score), ml: 0.5 }}
                  >
                    {bentoContact?.score}{" "}
                    <Box component="i" className="fa-regular fa-circle-info" />
                  </Box>
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                enterTouchDelay={0}
                title="View how likely this contact will respond to you with Bento's 'Reply Score' feature. Upgrade to unlock"
              >
                <Link sx={styles.viewScore} onClick={handleViewScore}>
                  Reply Score
                </Link>
              </Tooltip>
            )}
          </Grid>
        )}

      <ViewScoreDialog
        open={openViewScore}
        setAlert={setAlert}
        handleClose={() => setOpenViewScore(false)}
      />
    </>
  );
}
