import {
  Box,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AutogeneratedDraftsContext } from "contexts/AutogenerateDrafts";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { OutreachDraft } from "schemas/dashboard";
import { routes } from "schemas/routes";

import styles from "../styles";
import SelectedBrand from "./SelectedBrand";

const REF_OFFSET = 5;

interface Props {
  endRef: any;
}

const DraftsBrandList = ({ endRef }: Props) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { drafts, setDrafts, isPolling } = useContext(
    AutogeneratedDraftsContext,
  );

  return (
    <>
      <Grid item xs sx={isMobileScreen ? {} : styles.brandListContainer}>
        <Box sx={{ p: 2 }}>
          <Typography gutterBottom>
            <strong>Personalized Recommendations</strong>
          </Typography>

          <Typography sx={styles.subtitle} variant="body2">
            20 personalized email drafts to brands you'll love, all the time.
            Want different recommendations? Update your{" "}
            <Link to={`/${routes.profile}`}>profile</Link>
          </Typography>
        </Box>

        {drafts.map((draft: OutreachDraft, index: number) => (
          <SelectedBrand
            key={draft.bentoBrand.id}
            ref={drafts.length - REF_OFFSET === index ? endRef : null}
            bentoBrand={draft.bentoBrand}
            outreachDraft={draft}
            drafts={drafts}
            setDrafts={setDrafts}
          />
        ))}
        {isPolling && drafts?.length === 0 && (
          <Grid sx={{ px: 2 }}>
            {Array.from({ length: 10 }, (x, i) => (
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={80}
                sx={{ my: 1 }}
                key={i}
              />
            ))}
          </Grid>
        )}
      </Grid>
      {drafts?.length > 0 && (
        <Grid item sx={styles.explanation}>
          Bento reloads your list with a new draft every time you send an email
          from here.
        </Grid>
      )}
    </>
  );
};

export default DraftsBrandList;
