import { useAuth } from "@clerk/clerk-react";
import { AlertContext } from "contexts/Alert";
import { AutogeneratedDraftsContext } from "contexts/AutogenerateDrafts";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { BentoBrand, OutreachDraft } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "./api";
import { usePageSize } from "./usePageSize";

export const useDrafts = () => {
  const { getToken } = useAuth();
  const { isTwoColumnView } = usePageSize();
  const { currentOrg } = useContext(OrganizationUserContext);

  const { bentoBrand } = useContext(QuickSendContext);
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { handleOpenQuickSendIndividual, closeDrawer, setIsEdited } =
    useContext(QuickSendDrawerContext);
  const { pollForNewDrafts, setRemoveLoading, setDrafts } = useContext(
    AutogeneratedDraftsContext,
  );

  const openNextDraft = (updatedDraftList?: OutreachDraft[], index = 0) => {
    let bentoBrandToOpen = bentoBrand;
    if (updatedDraftList) {
      bentoBrandToOpen = updatedDraftList[index].bentoBrand;
    }
    if (!bentoBrandToOpen) return;
    handleOpenQuickSendIndividual(
      bentoBrandToOpen.id,
      "Autogenerated Drafts",
      undefined,
      !isTwoColumnView,
      bentoBrandToOpen,
    );
  };

  const handleRemoveDraft = async (
    e: CustomEvent,
    bentoBrandToDelete: BentoBrand,
  ) => {
    if (!bentoBrandToDelete) return;
    e.stopPropagation();
    setRemoveLoading(Number(bentoBrandToDelete.id));
    setIsEdited(false);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-drafts/bento-brands/${bentoBrandToDelete.id}`,
        "DELETE",
        {},
        { source: "Recommendations" },
      );
      setAlert("Removed this brand from your list", "success");
      refreshDraftsList(bentoBrandToDelete);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setRemoveLoading(-1);
    }
  };

  const refreshDraftsList = async (bentoBrandToDelete: BentoBrand) => {
    // Clear out the deleted draft and refetching the list. Move to the next brand
    // after the list if brand is deleted.
    setDrafts((prev) => {
      let nextIndex = 0;
      const index = prev.findIndex(
        (draft) => Number(draft.bentoBrandId) === Number(bentoBrandToDelete.id),
      );
      if (index > -1) {
        prev.splice(index, 1);
        nextIndex = Math.min(index, prev.length - 1);
      }
      if (prev?.length > 0) {
        openNextDraft(prev, nextIndex);
      } else {
        closeDrawer();
      }
      return prev;
    });
    pollForNewDrafts();
  };

  return { openNextDraft, handleRemoveDraft, refreshDraftsList };
};
