import { Box } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { OutreachContact } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import AttachmentManager from "features/Influencer/ContactList/AttachmentManager";
import { ContactViewProperty } from "features/Influencer/Tracking/schema";

import AttachmentDisplay from "./AttachmentDisplay";
import { attachmentStyles as styles } from "./styles";

interface Props {
  property: ContactViewProperty;
  value: any;
  outreachContact: OutreachContact;
}

const TableAttachment = forwardRef((props: Props, ref) => {
  const { property, value, outreachContact } = props;
  const { trackCell } = useContext(ContactViewContext);
  const [open, setOpen] = useState<boolean>(false);
  const { setOutreachContacts, updateOutreachContactPropertyValues } =
    useContext(ContactViewContext);

  useImperativeHandle(ref, () => ({
    childRefClick() {
      openAttachment();
    },
    childRefKeyPressed(e: KeyboardEvent) {
      if (e.key === "Enter") {
        openAttachment();
      }
    },
  }));

  const openAttachment = (action = "Open Attachment") => {
    trackCell(property, outreachContact, {
      Action: action,
    });
    setOpen(true);
  };

  const handleOpenEdit = () => {
    openAttachment("Edit Attachment");
  };

  const removeAttachment = () => {
    trackCell(property, outreachContact, {
      Action: "Remove Attachment",
    });
    setOutreachContacts((prev) => {
      return prev.map((contact) => {
        if (contact.id !== outreachContact.id) {
          return contact;
        }
        if (!outreachContact.id || !property?.contactPropertyId) {
          return contact;
        }
        updateOutreachContactPropertyValues(
          outreachContact?.id,
          property?.contactPropertyId,
          "",
        );
        return {
          ...contact,
          propertyValues: {
            ...contact.propertyValues,
            [property.contactPropertyId]: "",
          },
        };
      });
    });
  };

  const addAttachmentToContact = (attachment: string) => {
    setOutreachContacts((prev) => {
      return prev.map((contact) => {
        if (contact.id !== outreachContact.id) {
          return contact;
        }
        if (!property.contactPropertyId || !outreachContact.id) {
          return contact;
        }
        updateOutreachContactPropertyValues(
          outreachContact?.id,
          property?.contactPropertyId,
          attachment,
        );
        return {
          ...contact,
          propertyValues: {
            ...contact.propertyValues,
            [property.contactPropertyId]: attachment,
          },
        };
      });
    });
  };

  const renderCell = () => {
    if (value?.length > 0) {
      return (
        <Box onClick={(e: CustomEvent) => e.stopPropagation()}>
          <AttachmentDisplay
            attachment={value}
            styles={styles}
            openAttachmentManager={handleOpenEdit}
            removeAttachment={removeAttachment}
          />
        </Box>
      );
    } else {
      return <Box sx={styles.emptyNote} />;
    }
  };

  return (
    <>
      {renderCell()}
      {open && (
        <AttachmentManager
          enteredSelection={addAttachmentToContact}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
});

export default TableAttachment;
