import { useAuth } from "@clerk/clerk-react";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext } from "react";

import { fetcherAuth } from "./api";

export const useTracking = () => {
  const { getToken } = useAuth();

  const { currentOrg } = useContext(OrganizationUserContext);
  const trackUserHasOnboarded = async (action: string) => {
    await fetcherAuth(
      getToken,
      `/api/organization/${currentOrg?.id}/onboarding/track`,
      "POST",
      {},
      { action },
    );
  };

  return { trackUserHasOnboarded };
};
