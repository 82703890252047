import { Dialog, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendContext } from "contexts/QuickSend";
import { useContext, useEffect, useState } from "react";

import { trackEvent } from "utils/tracking";

import ImportFlow from "./ImportFlow";
import PresetFlow from "./PresetFlow";

export default function CreateTemplate() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { newlyAddedTemplateIndex } = useContext(QuickSendContext);
  const { createTemplateDialogOpen, setCreateTemplateDialogOpen } = useContext(
    OutreachTemplatesContext,
  );
  const [isImport, setIsImport] = useState<boolean>(false);
  const createFollowUp = newlyAddedTemplateIndex > 0;

  const onSuccess = () => {
    setCreateTemplateDialogOpen(false);
  };

  const handleClose = () => {
    setCreateTemplateDialogOpen(false);
  };

  const renderFlow = () => {
    if (isImport) {
      return <ImportFlow setIsImport={setIsImport} onSuccess={onSuccess} />;
    } else {
      return (
        <PresetFlow
          setIsImport={setIsImport}
          handleCloseDialog={handleClose}
          isGeneratingFollowUp={createFollowUp}
          onSuccess={onSuccess}
        />
      );
    }
  };

  useEffect(() => {
    trackEvent("Template Generation Drawer Shown");
  }, []);

  return (
    <Dialog
      open={createTemplateDialogOpen}
      onClose={handleClose}
      fullScreen={isMobileScreen}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "900px!important",
        },
      }}
    >
      <DialogContent>{renderFlow()}</DialogContent>
    </Dialog>
  );
}
