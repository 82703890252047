import { useAuth } from "@clerk/clerk-react";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Organization } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import Alert from "components/Alert";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

import AdminOrganizationView from "./SingleView";

const AdminOrganization = () => {
  const { getToken } = useAuth();
  const [message, severity, setAlert, closeAlert] = useAlert();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(0);

  const fetchOrgs = async () => {
    setLoading(true);
    try {
      const { organizations } = await fetcherAuth(
        getToken,
        "/api/admin/organizations",
        "GET",
      );

      setOrganizations(organizations);
    } catch (error) {
      setAlert(
        "An error occurred, you do not have access to this page",
        "warning",
      );
    }
    setLoading(false);
  };

  const handleOrgChange = (event: CustomEvent) => {
    const organizationId = event.target.value as number | 0;
    navigate(`/admin/organizations/${organizationId}`, { replace: true });
    setSelectedOrgId(organizationId);
  };

  useEffect(() => {
    fetchOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage
      content={
        <Grid sx={{ mt: 2 }}>
          <Typography variant="h3" gutterBottom>
            <strong>Organization</strong>
          </Typography>
          <Select
            variant="standard"
            value={selectedOrgId}
            onChange={handleOrgChange}
          >
            <MenuItem value={0} disabled>
              Select an organization
            </MenuItem>
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name} (ID: {org.id})
              </MenuItem>
            ))}
          </Select>

          <Routes>
            <Route
              path="/:id"
              element={
                loading ? (
                  <CircularProgress />
                ) : (
                  <AdminOrganizationView
                    setAlert={setAlert}
                    setSelectedOrgId={setSelectedOrgId}
                  />
                )
              }
            />
          </Routes>

          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
        </Grid>
      }
    />
  );
};

export default AdminOrganization;
