import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useState } from "react";

import { DiscoverSearchGeopoint } from "./schema";

export const useAutoCompletePrediction = () => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_JS_KEY;

  const places = useMapsLibrary("places");
  const div = document.createElement("div");

  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();
  const [placeDetails, setPlaceDetails] =
    useState<google.maps.places.PlacesService | null>(null);
  const [geopoint, setGeopoint] = useState<DiscoverSearchGeopoint | null>(null);

  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (!places) return;

    setPlaceDetails(new places.PlacesService(div));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setPlaceDetails(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places]);

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!inputValue || !placeDetails) {
        return;
      }
      placeDetails.findPlaceFromQuery(
        { query: inputValue, fields: ["geometry", "place_id"] },
        (predictions: google.maps.places.PlaceResult[] | null) => {
          const place = predictions?.[0];

          // google.maps.places.PlaceDetailsRequest
          if (place && place?.place_id) {
            placeDetails.getDetails(
              { placeId: place?.place_id, fields: ["address_component"] },
              (address: google.maps.places.PlaceResult | null) => {
                const countryField = address?.address_components?.find((x) =>
                  x?.types?.includes("country"),
                )?.short_name;
                setGeopoint({
                  latitude: place?.geometry?.location?.lat(),
                  longitude: place?.geometry?.location?.lng(),
                  country_code: countryField,
                });
              },
            );
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionToken, placeDetails],
  );

  useEffect(() => {
    if (inputValue) fetchPredictions(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return { inputValue, setInputValue, geopoint, API_KEY };
};
