import { useAuth } from "@clerk/clerk-react";
import { inRange } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  BentoBrand,
  BentoContact,
  NewBentoContact,
  PreviewMessage,
} from "schemas/dashboard";
import { HandleFetchPreviewFunction } from "schemas/outreach";
import { routes } from "schemas/routes";

import { Template } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { isPage } from "utils/general";
import { makeDeepCopy } from "utils/updateLocalState";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";
import { OutreachTemplatesContext } from "./OutreachTemplates";
import { QuickSendContactsContext } from "./QuickSendContacts";
import { QuickSendOutreachTabsContext } from "./QuickSendOutreachTabs";
import { SubscriptionContext } from "./Subscription";
import { getContactName, getEmailTemplates } from "./helpers/QuickSendHelpers";

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

export type LastContactedDate = Date | null;

interface QuickSendContextInterface {
  templateForQuickSend: Template[];
  setTemplateForQuickSend: Dispatch<SetStateAction<Template[]>>;
  handleFetchPreviews: HandleFetchPreviewFunction;
  handleRefetchPreviews: (
    templateForQuickSend: Template[],
    contactEmail?: string,
  ) => void;
  previewLoading: number;
  setNewlyAddedTemplateIndex: Dispatch<SetStateAction<number>>;
  newlyAddedTemplateIndex: number;
  bentoContact: BentoContact | null;
  bentoBrand: BentoBrand | null;
  outreachMessages: PreviewMessage[];
  setOutreachMessages: Dispatch<SetStateAction<PreviewMessage[]>>;
  setBentoContact: Dispatch<SetStateAction<BentoContact | null>>;
  templatesToUpdate: number[];
  setTemplatesToUpdate: Dispatch<SetStateAction<number[]>>;
  contactName?: string;
}

const defaultInterface = {
  templateForQuickSend: [],
  setTemplateForQuickSend: defaultContextMissingFunction,
  handleFetchPreviews: defaultContextMissingFunction,
  handleRefetchPreviews: defaultContextMissingFunction,
  previewLoading: -1,
  setNewlyAddedTemplateIndex: defaultContextMissingFunction,
  newlyAddedTemplateIndex: -1,
  bentoContact: null,
  newContact: null,
  bentoBrand: null,
  outreachMessages: [],
  setOutreachMessages: defaultContextMissingFunction,
  setBentoContact: defaultContextMissingFunction,
  templatesToUpdate: [],
  setTemplatesToUpdate: defaultContextMissingFunction,
  contactName: "",
};

const QuickSendContext =
  createContext<QuickSendContextInterface>(defaultInterface);

interface QuickSendProviderProps {
  children: React.ReactNode;
}

const QuickSendProvider = ({ children }: QuickSendProviderProps) => {
  const { getToken } = useAuth();

  const navigate = useNavigate();

  const { outreachTab, setOutreachTab } = useContext(
    QuickSendOutreachTabsContext,
  );
  const { getPreviewContacts, updateBentoContacts } = useContext(
    QuickSendContactsContext,
  );

  // Contexts
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setUpgradeDialogSource } = useContext(SubscriptionContext);
  const { defaultTemplates } = useContext(OutreachTemplatesContext);
  const { bentoContacts } = useContext(QuickSendContactsContext);

  // States
  const [bentoBrand, setBentoBrand] = useState<BentoBrand | null>(null);
  const [bentoContact, setBentoContact] = useState<
    BentoContact | NewBentoContact | null
  >(null);
  const [outreachMessages, setOutreachMessages] = useState<PreviewMessage[]>(
    [],
  );
  const [templateForQuickSend, setTemplateForQuickSend] = useState<Template[]>(
    [],
  );
  const [previewLoading, setPreviewLoading] = useState<number>(-1);
  const [newlyAddedTemplateIndex, setNewlyAddedTemplateIndex] =
    useState<number>(-1);
  const [templatesToUpdate, setTemplatesToUpdate] = useState<number[]>([]);

  const contactName = getContactName(bentoBrand, bentoContact);

  const handleRefetchPreviews = async (
    templateForQuickSend: Template[],
    contactEmail?: string,
  ) => {
    if (!bentoBrand?.id) {
      return;
    }
    const selectedNewContact = bentoContacts?.find(
      (x) => x.email === contactEmail,
    );
    await handleFetchPreviews(
      bentoBrand?.id,
      selectedNewContact || bentoContact,
      templateForQuickSend,
    );
  };

  const handleFetchPreviews = async (
    brandId: number,
    specificContact?: BentoContact | NewBentoContact | null,
    specificTemplate?: Template[],
  ) => {
    if (!currentOrg?.id || !brandId) return;
    setPreviewLoading(brandId);

    const resetBentoContacts = bentoBrand?.id !== brandId;
    const { contacts, data } = await getPreviewContacts(
      brandId,
      {
        bentoBrandId: brandId,
        emailTemplates: [],
      },
      specificContact,
      resetBentoContacts,
    );

    const emailTemplates = getEmailTemplates(
      setTemplateForQuickSend,
      specificTemplate,
      templateForQuickSend,
      defaultTemplates,
    );
    data["emailTemplates"] = emailTemplates;

    if (contactName) {
      data["previousContactName"] = contactName;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach/preview-quick-send`,
        "POST",
        {},
        data,
        true,
        false,
        true,
      );

      if (res.ok) {
        const json = await res.json();
        const { previewContact, outreachMessages, bentoBrand } = json.preview;
        setBentoContact(previewContact);

        setOutreachMessages((prev) => {
          let copy = makeDeepCopy(prev);
          copy = outreachMessages;
          return copy;
        });
        // Makes sure outreachTab index is always in range with the list of outreach messages
        const isInRange = inRange(outreachTab, 0, outreachMessages?.length);
        if (!isInRange) {
          setOutreachTab(0);
        }
        setBentoBrand(bentoBrand);
        updateBentoContacts(
          contacts,
          previewContact,
          Boolean(resetBentoContacts),
        );
      } else if (res.status === 403) {
        setUpgradeDialogSource("Hits Limit");
        if (isPage(routes.tasks)) {
          navigate(`/${routes.tasks}`);
        } else if (isPage(routes.brands)) {
          navigate(`/${routes.brands}`);
        } else {
          navigate(-1);
        }
      } else if (res.status === 404) {
        setAlert("Brand not found", "error");
        navigate("/");
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setPreviewLoading(-1);
    }
  };

  return (
    <QuickSendContext.Provider
      value={{
        templateForQuickSend,
        setTemplateForQuickSend,
        handleFetchPreviews,
        previewLoading,
        setNewlyAddedTemplateIndex,
        newlyAddedTemplateIndex,
        handleRefetchPreviews,
        bentoContact,
        bentoBrand,
        outreachMessages,
        setOutreachMessages,
        setBentoContact,
        templatesToUpdate,
        setTemplatesToUpdate,
        contactName,
      }}
    >
      {children}
    </QuickSendContext.Provider>
  );
};

export { QuickSendProvider, QuickSendContext };
