import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { useContext } from "react";
import { BentoBrand } from "schemas/dashboard";

import ReportLink from "features/Influencer/Discover/DetailedBrandView/ReportLink";
import { copyToClipboard } from "utils/general";
import { trackEvent } from "utils/tracking";

import { BentoBrandLinks } from "./helpers";
import styles from "./styles";

interface Props {
  bentoBrand: BentoBrand;
}

export default function BrandLinks({ bentoBrand }: Props) {
  const { setAlert } = useContext(AlertContext);

  const renderLink = (key: string) => {
    const fieldValue = bentoBrand[key as keyof BentoBrand];

    const link = `${BentoBrandLinks[key]?.prefix}${fieldValue}`;

    const icon = BentoBrandLinks[key]?.icon;
    const tooltip = BentoBrandLinks[key]?.tooltip;

    const clickLink = () => {
      window.open(link, "_blank");
      trackEvent("Link Clicked On", { link });
    };

    const isLongLink = key?.includes("Url");

    if (!fieldValue) return null;

    return (
      <Grid
        container
        key={icon}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item xs alignItems="center">
          <Typography
            component="div"
            noWrap
            sx={styles.link}
            onClick={clickLink}
          >
            <Box component="i" className={icon} sx={{ mr: 1 }} />{" "}
            {isLongLink ? `View link` : fieldValue?.toString()}
          </Typography>

          <ReportLink link={link} />
        </Grid>

        <Grid item xs={1}>
          <Tooltip title={`Copy ${tooltip}`}>
            <IconButton
              onClick={() => copyToClipboard(link, setAlert)}
              sx={styles.button}
            >
              <Box component="i" className="fa-regular fa-clipboard" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>{Object.keys(BentoBrandLinks).map((key) => renderLink(key))}</Box>
  );
}
