import { grey } from "@mui/material/colors";

const styles = {
  header: {
    py: 1.5,
    px: 2,
    borderBottom: `1px solid ${grey[300]}`,
  },
  close: {
    fontSize: 14,
    width: 30,
    height: 30,
  },
  body: {
    overflowY: "scroll",
    pb: 3,
    width: "100%",
    minHeight: "calc(100vh - 140px)",
    borderBottom: `1px solid ${grey[300]}`,
  },
  typing: {
    p: 2,
  },
  action: {
    position: "fixed",
    maxWidth: 350,
    bottom: 10,
    right: 0,
    py: 1,
    px: 2,
  },
  actionChip: {
    backgroundColor: "white",
    py: "25px",
    "& .MuiChip-label": {
      display: "block",
      maxWidth: "290px",
    },
  },
  chipIcon: {
    color: "primary.main",
    p: 1,
  },
  emptyText: {
    mt: 3,
    px: 1,
    textAlign: "center",
  },
  draft: {
    px: 2,
  },
};

export default styles;
