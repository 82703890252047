import { useAuth } from "@clerk/clerk-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { FEEDBACK_GIVEN } from "utils/localStorage";

const OPTIONS: string[] = [
  "It's too expensive",
  "A poor experience",
  "I found an alternative",
  "I no longer need it",
  "Maybe later",
  "Other reason",
];

interface IProps {
  open: boolean;
  close: () => void;
  setAlert: SetAlertType;
}

export function NotInterested({ open, close, setAlert }: IProps) {
  const { setErrorAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const [feedback, setFeedback] = useState<string | undefined>();
  const [comment, setComment] = useState<string>("");

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback((event.target as HTMLInputElement).value);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment((event.target as HTMLInputElement).value);
  };

  const submitForm = async () => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/feedback`,
        "POST",
        {},
        {
          feedback,
          comment,
        },
      );
      setAlert("Thank you for the feedback!", "success");
      close();
      localStorage.setItem(`${FEEDBACK_GIVEN}-${currentOrg?.id}`, "true");
    } catch (error) {
      setErrorAlert(error);
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Not Interested?</DialogTitle>
      <DialogContent>
        <Typography color="text.secondary" paragraph>
          We'd love to gather your feedback on Bento - tell us why
        </Typography>
        <FormControl sx={{ mb: 2 }}>
          <RadioGroup
            aria-labelledby="radio-buttons-feedback-group-label"
            name="radio-buttons-feedback-group"
            value={feedback}
            onChange={handleFeedbackChange}
          >
            {OPTIONS.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <TextField
          variant="outlined"
          name="additionalFeedback"
          placeholder="Any additional feedback?"
          fullWidth
          multiline
          minRows={4}
          value={comment}
          onChange={handleCommentChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={close}>
          No Thanks
        </Button>
        <Button onClick={submitForm}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
