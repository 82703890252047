import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { CustomEvent } from "schemas/functions";

interface IProps {
  value: string[];
  setValue: (newValue: string[]) => void;
}

const styles = {
  autocomplete: { backgroundColor: "white", mb: 3 },
};

export default function ProductsAutocomplete({ value, setValue }: IProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const handleAutocompleteChange = (_: CustomEvent, products: string[]) => {
    setValue(
      products
        .map((p) => p?.trim())
        .filter((product) => !!product && product !== ""),
    );
  };

  const handleOnInputChange = (_: CustomEvent, newInputValue: string) => {
    setInputValue(newInputValue);

    if (newInputValue.endsWith(",")) {
      const newInput = newInputValue.slice(0, -1).trim();
      if (newInput) {
        const newValue = [...value, newInput];
        setValue(newValue);
      }
      setInputValue("");
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      autoSelect
      fullWidth
      disablePortal
      sx={styles.autocomplete}
      value={value}
      inputValue={inputValue}
      options={[]}
      renderInput={(params) => (
        <TextField {...params} placeholder="Add Product..." />
      )}
      ChipProps={{
        size: "small",
      }}
      onInputChange={handleOnInputChange}
      onChange={handleAutocompleteChange}
    />
  );
}
