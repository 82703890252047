import { Box, CircularProgress } from "@mui/material";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import PortfolioSitesList from "./PortfolioSitesList";
import styles from "./styles";

const PortfolioSitesEdit = lazy(() => import("./PortfolioSitesEdit"));

export default function PortfolioSitesPage() {
  return (
    <Routes>
      <Route path="/" element={<PortfolioSitesList />} />
      <Route
        path=":portfolioSiteId/edit/*"
        element={
          <Suspense
            fallback={
              <Box sx={styles.wrapper}>
                <CircularProgress sx={styles.loader} />
              </Box>
            }
          >
            <PortfolioSitesEdit />
          </Suspense>
        }
      />
    </Routes>
  );
}
