import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Task } from "schemas/dashboard";

import Alert from "components/Alert";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

import { OrganizationUserContext } from "./Organization";

interface TasksContextInterface {
  tasks: Task[];
  setTasks: Dispatch<SetStateAction<Task[]>>;
  taskLoading: boolean;
  setTaskLoading: Dispatch<SetStateAction<boolean>>;
  fetchTasks: (status?: string, sort?: string, include?: string) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  tasks: [],
  setTasks: defaultContextMissingFunction,
  taskLoading: false,
  setTaskLoading: defaultContextMissingFunction,
  fetchTasks: defaultContextMissingFunction,
};

const TasksContext = createContext<TasksContextInterface>(defaultInterface);

interface TasksProviderProps {
  children: React.ReactNode;
}

const TasksProvider = ({ children }: TasksProviderProps) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskLoading, setTaskLoading] = useState(false);

  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const [message, severity, setAlert, closeAlert] = useAlert();

  const fetchTasks = async (
    status?: string,
    sort?: string,
    include?: string,
  ) => {
    if (!currentOrg) {
      return;
    }
    try {
      setTaskLoading(true);

      let url = `/api/organization/${currentOrg?.id}/tasks?`;
      if (status) {
        url += `&status=${status}`;
      }
      if (include) {
        url += `&include=${include}`;
      }
      if (sort) {
        url += `&sort=${sort}`;
      }
      const res = await fetcherAuth(getToken, url);
      setTasks(res.tasks);
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to fetch your tasks. Please reload and retry again",
        "error",
      );
    } finally {
      setTaskLoading(false);
    }
  };

  return (
    <TasksContext.Provider
      value={{ tasks, setTasks, taskLoading, setTaskLoading, fetchTasks }}
    >
      {children}
      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </TasksContext.Provider>
  );
};

export { TasksProvider, TasksContext };
