const BUTTON_COLOR = "#33885f";

const styles = {
  productOption: {
    textTransform: "none",
    py: 0,
  },
  active: {
    py: 0,
    textTransform: "none",
    background: BUTTON_COLOR,
    color: "white",
    "&:hover": {
      color: BUTTON_COLOR,
    },
  },
};

export default styles;
