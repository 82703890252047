import { green, grey } from "@mui/material/colors";

const styles = {
  root: {
    width: "20px",
  },
  icon: {
    p: 0,
    height: "20px",
    width: "20px",
  },
  notTracked: {
    color: grey[400],
  },
  noOpens: { color: grey[500] },
  opens: { color: green[500] },
};

export default styles;
