import parse from "html-react-parser";
import {
  GeneralSection,
  PaletteColor,
  PaletteType,
  PortfolioSiteRevision,
  SectionType,
  SiteHref,
} from "schemas/portfolioSite";

export const convertToColor = (
  color?: string | PaletteType | null,
  palette?: { [key in PaletteType]: PaletteColor },
  fallbackColor: string = "inherit",
) => {
  if (!color) return fallbackColor;
  if (!palette) return color;
  if (Object.values(PaletteType).includes(color as PaletteType)) {
    return palette[color as PaletteType]?.color;
  }
  return color;
};

export const partition = (blocks: any[], maxNumberOfColumns: number) => {
  const result: any[][] = [];
  for (let i = 0; i < blocks.length; i += maxNumberOfColumns) {
    result.push(blocks.slice(i, i + maxNumberOfColumns));
  }
  return result;
};

export const getSectionName = (
  sectionId: string | null,
  latestRevision: PortfolioSiteRevision,
) => {
  const sectionIdx = latestRevision.sections.findIndex(
    (section) => section.id === sectionId,
  );
  if (sectionIdx < 0) {
    return null;
  }
  const section = latestRevision.sections[sectionIdx];
  let sectionName;
  if (
    section.section_type === SectionType.gallery ||
    section.section_type === SectionType.general
  ) {
    const parseData = parse((section as GeneralSection).title?.html || "");
    if (typeof parseData === "string") {
      sectionName = parseData;
    } else if ("props" in parseData) {
      sectionName = parseData.props.children;
    }
  } else if (section.section_type === SectionType.navigation) {
    sectionName = "Navigation Bar";
  } else if (section.section_type === SectionType.footer) {
    sectionName = "Footer";
  }
  // count from zero, since first section is always navigation bar
  return !!sectionName
    ? `Section ${sectionIdx}: ${sectionName}`
    : `Section ${sectionIdx}`;
};

export const getToName = (
  href: SiteHref,
  latestRevision: PortfolioSiteRevision,
) => {
  if (href.to) {
    if (href.to.startsWith("mailto:")) {
      return href.to.split("mailto:")[1];
    } else {
      return href.to;
    }
  } else {
    return getSectionName(href.anchor, latestRevision);
  }
};
