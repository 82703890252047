import { useAuth } from "@clerk/clerk-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GmailScope, UserIntegration } from "schemas/dashboard";
import { Map } from "schemas/functions";

import Alert from "components/Alert";
import GoogleConnect from "features/Settings/GoogleConnect";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { useAlert } from "utils/useAlert";

import { OrganizationUserContext } from "./Organization";

interface UserIntegrationContextInterface {
  emailHealth: boolean;
  fetchIntegrationHealth: (organizationId: number) => void;
  setOpenIntegrationDialog: React.Dispatch<SetStateAction<boolean>>;
  setIntegrationDialogText: React.Dispatch<SetStateAction<string | undefined>>;
  userIntegration: UserIntegration | null;
  fetchIntegrationLoading: boolean;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  emailHealth: false,
  fetchIntegrationHealth: defaultContextMissingFunction,
  setOpenIntegrationDialog: defaultContextMissingFunction,
  setIntegrationDialogText: defaultContextMissingFunction,
  userIntegration: null,
  fetchIntegrationLoading: true,
};

const UserIntegrationsContext =
  createContext<UserIntegrationContextInterface>(defaultInterface);

interface UserIntegrationsProviderProps {
  children: React.ReactNode;
}

const UserIntegrationsProvider = ({
  children,
}: UserIntegrationsProviderProps) => {
  const { getToken } = useAuth();
  const [message, severity, setAlert, closeAlert] = useAlert();

  const { userIntegrations } = useContext(OrganizationUserContext);

  const [emailHealth, setEmailHealth] = useState<boolean>(false);
  const [openIntegrationDialog, setOpenIntegrationDialog] =
    useState<boolean>(false);
  const [integrationDialogText, setIntegrationDialogText] = useState<string>();
  const [userIntegration, setUserIntegration] =
    useState<UserIntegration | null>(null);
  const [fetchIntegrationLoading, setFetchIntegrationLoading] = useState(true);
  const [scopes, setScopes] = useState<Map>();

  useEffect(() => {
    // on closing the dialog, reset integrationDialogText
    if (!openIntegrationDialog) {
      setIntegrationDialogText(undefined);
    } else {
      trackEvent("Connect To Gmail Dialog Opened");
    }
  }, [openIntegrationDialog]);

  const fetchIntegrationHealth = async (organizationId: number) => {
    if (!organizationId) {
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${organizationId}/user-integrations/health-check`,
      );

      setEmailHealth(res.emailHealth);
      setScopes(res.scopes);
      setUserIntegration(res.userIntegration);
    } catch (error) {
      setAlert(
        "Unable to check if you are connected to Google. Please refresh the page and try again. Contact hello@onbento.com if the problem persists.",
        "error",
      );
    } finally {
      setFetchIntegrationLoading(false);
    }
  };

  const determineMissingScope = () => {
    if (!scopes) return;
    if (!scopes[GmailScope.SEND]) {
      return "In order for you to send emails you must provide additional access. Tap the button above and ✅ Send Email on your behalf.";
    } else if (!scopes[GmailScope.READ]) {
      return "In order to schedule follow ups you must provide additional access. Tap the button above and ✅ View your email messages.";
    }
  };

  return (
    <UserIntegrationsContext.Provider
      value={{
        emailHealth,
        fetchIntegrationHealth,
        setOpenIntegrationDialog,
        setIntegrationDialogText,
        userIntegration,
        fetchIntegrationLoading,
      }}
    >
      {children}

      <Dialog
        maxWidth="sm"
        open={openIntegrationDialog}
        onClose={() => setOpenIntegrationDialog(false)}
      >
        <DialogTitle>Connect your Gmail account to send emails</DialogTitle>

        <DialogContent>
          {emailHealth ? (
            <>
              <Typography>Success! You are connected.</Typography>
            </>
          ) : (
            <>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography sx={{ my: 2 }}>
                    {!!integrationDialogText ? (
                      integrationDialogText
                    ) : (
                      <>
                        {userIntegrations.length === 0 && (
                          <>
                            You must allow access so we can send emails and
                            schedule followup emails on your behalf ✨ You can
                            revoke your access at any time.
                          </>
                        )}
                        {userIntegrations &&
                          userIntegrations.length >= 1 &&
                          scopes &&
                          determineMissingScope()}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <GoogleConnect setAlert={setAlert} />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>

        <DialogActions>
          {userIntegrations.length === 0 ? (
            <Button
              color="secondary"
              onClick={() => setOpenIntegrationDialog(false)}
            >
              Close
            </Button>
          ) : (
            <Button onClick={() => setOpenIntegrationDialog(false)}>
              Done
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </UserIntegrationsContext.Provider>
  );
};

export { UserIntegrationsProvider, UserIntegrationsContext };
