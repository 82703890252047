import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { PortfolioSitesEditContext } from "contexts/PortfolioSitesEdit";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ChromePicker, ColorResult } from "react-color";
import { CustomEvent } from "schemas/functions";
import { PaletteType } from "schemas/portfolioSite";

import { convertToColor } from "components/PortfolioSite/helpers";

import styles from "./styles";

interface EditColorDialogProps {
  color: string | PaletteType | null;
  onSave: (color: string | PaletteType | null) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function EditColorDialog({
  color,
  open,
  setOpen,
  onSave,
}: EditColorDialogProps) {
  const [editColor, setEditColor] = useState<string | PaletteType>("#000000");
  const { latestRevision } = useContext(PortfolioSitesEditContext);

  useEffect(() => {
    if (open) {
      setEditColor(color || "#000000");
    }
  }, [open, color]);

  const handleClose = () => {
    setOpen(false);
  };

  const saveColor = (event: CustomEvent) => {
    event.preventDefault();
    onSave(editColor);
    handleClose();
  };

  const selectPalette = (paletteType: PaletteType) => {
    setEditColor(paletteType);
  };

  const onColorChange = (color: ColorResult) => {
    setEditColor(color.hex);
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <IconButton sx={styles.closeIcon} onClick={handleClose}>
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle>Modify Color</DialogTitle>
      <form onSubmit={saveColor}>
        <DialogContent>
          <Typography
            display="flex"
            alignItems="center"
            paragraph
            component="div"
          >
            Color:{" "}
            <Box
              component="div"
              sx={[
                styles.patch,
                {
                  backgroundColor: convertToColor(
                    editColor,
                    latestRevision?.palette,
                  ),
                },
              ]}
            />
          </Typography>
          <Box sx={styles.pickerWrapper}>
            <ChromePicker
              color={convertToColor(editColor, latestRevision?.palette)}
              disableAlpha
              onChange={onColorChange}
              styles={{
                default: {
                  picker: {
                    boxShadow: "none",
                  },
                },
              }}
            />
            {latestRevision?.palette && (
              <>
                <Typography gutterBottom sx={{ ml: 1 }} color="textSecondary">
                  Palette:
                </Typography>
                <Grid
                  container
                  gap={1}
                  sx={{ my: 1, ml: -2 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {Object.values(PaletteType).map((paletteType) => (
                    <Grid key={paletteType} item xs="auto">
                      <Box
                        onClick={() => selectPalette(paletteType)}
                        sx={[
                          styles.paletteOption,
                          {
                            backgroundColor:
                              latestRevision?.palette[paletteType].color,
                          },
                        ]}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
