import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { TasksContext } from "contexts/Tasks";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Task, TaskStatus, TaskType } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import {
  makeDeepCopy,
  removeFromList,
  updateList,
} from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  task: Task;
}

export default function TaskAction({ task }: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);
  const { setErrorAlert } = useContext(AlertContext);

  const { tasks, setTasks } = useContext(TasksContext);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const handleGoToEmail = (e: CustomEvent) => {
    e.stopPropagation();
    trackEvent("Send Gmail Thread Drawer Opened", {
      task,
    });
    const emailRoute = task.gmailThreadId
      ? `contact/${task.outreachContactId}/email/${task.gmailThreadId}`
      : `contact/${task.outreachContactId}`;
    navigate(emailRoute);
  };

  const handleCheckTask = async () => {
    const body = makeDeepCopy(task);
    body["status"] = TaskStatus.COMPLETED;
    trackEvent("Task Status Changed", {
      status: TaskStatus.COMPLETED,
      task,
    });

    try {
      setLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/tasks/${task?.id}`,
        "PUT",
        {},
        body,
      );
      updateList(tasks, setTasks, res.task, "id");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTask = async () => {
    try {
      setDeleteLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/tasks/${task?.id}`,
        "DELETE",
      );
      removeFromList(tasks, setTasks, task, "id");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const getActionButton = () => {
    if (task.type === TaskType.OUTREACH && task?.brand?.bentoBrandId) {
      return (
        <Button
          disableElevation
          size="small"
          onClick={() =>
            task?.brand?.bentoBrandId
              ? handleOpenQuickSendIndividual(
                  task?.brand?.bentoBrandId,
                  "Tasks Manager",
                )
              : null
          }
          sx={styles.actionButton}
          variant="contained"
        >
          Email{" "}
          <Box
            component="i"
            className="fa-regular fa-envelope"
            sx={{ ml: 1 }}
          />
        </Button>
      );
    } else if (task.type === TaskType.EMAIL && task?.outreachContact) {
      return (
        <Button
          size="small"
          variant="contained"
          sx={[styles.actionButton, styles.followupButton]}
          disableElevation
          onClick={handleGoToEmail}
        >
          Email{" "}
          <Box
            component="i"
            className="fa-regular fa-envelope"
            sx={{ ml: 1 }}
          />
        </Button>
      );
    } else {
      return (
        <LoadingButton
          size="small"
          variant="outlined"
          sx={styles.actionButton}
          disableElevation
          loading={loading}
          onClick={handleCheckTask}
        >
          Mark done{" "}
          <Box component="i" className="fa-regular fa-check" sx={{ ml: 1 }} />
        </LoadingButton>
      );
    }
  };

  return (
    <Grid container alignItems="center">
      <Backdrop
        sx={styles.backdrop}
        open={(loading || deleteLoading) && isMobileScreen}
      >
        <CircularProgress />
      </Backdrop>

      {getActionButton()}
      <Tooltip title="Remove Task">
        {!isMobileScreen && deleteLoading ? (
          <Box sx={{ mx: 2 }}>
            <CircularProgress size={15} />
          </Box>
        ) : (
          <IconButton sx={styles.deleteIcon} onClick={handleDeleteTask}>
            <Box component="i" className="fa-regular fa-circle-xmark" />
          </IconButton>
        )}
      </Tooltip>
    </Grid>
  );
}
