import "@emotion/react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { SavedBrandStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import BrandList from "./BrandList";
import ProgressBar from "./ProgressBar";
import FavoritesListMobile from "./mobile";
import styles from "./styles";

interface FavoritesListProps {
  closeDialog: () => void;
}

export default function FavoritesList({ closeDialog }: FavoritesListProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    tab,
    setTab,
    savedBrands,
    draftBentoBrands,
    setSavedBrandPage,
    setDraftPage,
    savedBrandTotal,
    draftTotal,
    savedBrandsLoading,
    draftBrandsLoading,
  } = useContext(SavedBrandContext);
  const [paginationRef, isPaginationRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const handleChange = (event: CustomEvent, newValue: SavedBrandStatus) => {
    event.stopPropagation();
    trackEvent("Saved Brand Tab Clicked", { tab: newValue });
    setTab(newValue);
  };

  const getBrandsLoading = (status: SavedBrandStatus) => {
    if (status === SavedBrandStatus.SAVED) {
      return savedBrandsLoading;
    } else {
      return draftBrandsLoading;
    }
  };

  const getDisplayedBrands = () => {
    if (tab === SavedBrandStatus.DRAFTS) {
      return draftBentoBrands;
    } else {
      return savedBrands?.map((x) => x.bentoBrand);
    }
  };

  const getTotal = (status: SavedBrandStatus) => {
    if (status === SavedBrandStatus.DRAFTS) {
      return draftTotal;
    } else {
      return savedBrandTotal;
    }
  };

  const getPageLoadingFunc = () => {
    if (tab === SavedBrandStatus.DRAFTS) {
      return setDraftPage;
    } else {
      return setSavedBrandPage;
    }
  };

  const displayedBrands = getDisplayedBrands();

  useEffect(() => {
    if (isPaginationRefVisible) {
      if (
        displayedBrands.length < (getTotal(tab) || 0) &&
        !getBrandsLoading(tab)
      ) {
        getPageLoadingFunc()((prev) => prev + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaginationRefVisible, tab]);

  const renderTab = (status: SavedBrandStatus, label: string) => {
    const total = getTotal(status);
    return (
      <Tab
        sx={styles.tab}
        value={status}
        label={
          <Grid container justifyContent="center" alignItems="center" gap={1}>
            {label}{" "}
            {total === undefined ? (
              <CircularProgress size={10} />
            ) : (
              `(${total})`
            )}
          </Grid>
        }
      />
    );
  };

  return isMobileScreen ? (
    <FavoritesListMobile
      displayedBrands={displayedBrands}
      loading={getBrandsLoading(tab)}
      ref={paginationRef}
    />
  ) : (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={styles.desktopHeader}
      >
        <Typography variant="h6" sx={{ fontSize: 14 }}>
          SAVED BRANDS
        </Typography>
        <IconButton sx={styles.close} onClick={closeDialog}>
          <Box component="i" className="fa-regular fa-xmark" />
        </IconButton>
      </Grid>

      <Grid item xs={12} sx={{ width: "101%" }}>
        <Tabs
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          value={tab}
          onChange={handleChange}
        >
          {renderTab(SavedBrandStatus.SAVED, "Saved")}
          {renderTab(SavedBrandStatus.DRAFTS, "Drafts")}
        </Tabs>
      </Grid>

      <BrandList
        displayedBrands={displayedBrands}
        loading={getBrandsLoading(tab)}
        ref={paginationRef}
      />

      <Grid
        container
        justifyContent="center"
        sx={{
          position: "absolute",
          backgroundColor: "white",
          bottom: 0,
          pb: "8px",
        }}
      >
        <ProgressBar />
      </Grid>
    </Box>
  );
}
