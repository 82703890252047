import { Box, Grid, Typography } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { QuickSendContext } from "contexts/QuickSend";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import { getStyles } from "./styles";

function QuickSendEmailBar() {
  const { bentoBrand } = useContext(QuickSendContext);
  const { view, setView, handleOpenQuickSendIndividual } = useContext(
    QuickSendDrawerContext,
  );

  const { discoverTab } = useContext(DiscoverViewContext);

  const styles = getStyles(discoverTab !== null);

  if (!bentoBrand || view !== QuickSendTabView.COLLAPSE) {
    return <></>;
  }

  const handleToggleExpand = (e: CustomEvent) => {
    e.stopPropagation();
    handleOpenQuickSendIndividual(
      bentoBrand?.id,
      "Toggle Open",
      undefined,
      false,
    );
    trackEvent("QuickSend Email Bar Clicked", {
      button: "Expand View",
    });
    setView(QuickSendTabView.EXPAND);
  };

  return (
    <Box sx={styles.root}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        onClick={handleToggleExpand}
      >
        <Typography sx={styles.text} noWrap>
          {bentoBrand?.brandName} (Email)
        </Typography>
      </Grid>
    </Box>
  );
}

export default QuickSendEmailBar;
