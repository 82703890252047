import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext, useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Email, MessageStatus } from "schemas/dashboard";
import { isOnPage, routes } from "schemas/routes";

import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import Drawer from "components/Drawer";
import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";
import { fetcherAuth } from "utils/api";
import { generateColorFromString } from "utils/color";
import { navigateBackPath } from "utils/navigation";
import { hideEmail } from "utils/string";
import { formatEpochTimestamp } from "utils/time";
import { removeFromList } from "utils/updateLocalState";

import AttachmentManager from "../../AttachmentManager";
import styles from "./styles";

export default function ScheduledEmail() {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const { scheduledId, outreachContactId } = useParams();
  const scheduledEmailId = scheduledId ? Number(scheduledId) : null;
  const { currentOrg, currentUser } = useContext(OrganizationUserContext);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const {
    currentContact,
    currentContactScheduledEmailsLoading,
    currentContactScheduledEmails,
    setCurrentContactScheduledEmails,
    contacts,
    fetchContact,
    setContacts,
    fetchEmailThreads,
    fetchScheduledEmails,
  } = useContext(OutreachContactsContext);
  const [scheduledEmail, setScheduledEmail] = useState<Email | undefined>();
  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const { discoverTab } = useContext(DiscoverViewContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const fullScreenEmail = isLargeScreen && discoverTab !== null;

  const handleBack = () => {
    if (scheduledEmail?.isChanged) {
      setConfirmDialogOpen(true);
      return;
    }
    navigate(navigateBackPath(location.pathname, 2));
  };

  const closeDrawer = () => {
    navigate(`/${routes.inbox}`);
  };

  useEffect(() => {
    setScheduledEmail(
      currentContactScheduledEmails.find(
        (email: Email) => email.id === scheduledEmailId,
      ),
    );
  }, [currentContactScheduledEmails, scheduledEmailId]);

  const handleSaveChanges = async () => {
    if (!currentOrg?.id || !scheduledEmail?.id) {
      return;
    }

    try {
      setSaveLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/${scheduledEmail?.id}`,
        "PATCH",
        {},
        {
          overrideBody: scheduledEmail?.body,
          overrideAttachment: scheduledEmail?.attachment,
        },
      );
      setAlert("Successfully saved changes!", "success");
      if (currentContact?.id) {
        fetchScheduledEmails(currentContact.id);
      }
    } catch (error) {
      setAlert(
        "Something went wrong, unable to save changes. Please refresh page and retry again.",
        "error",
      );
    } finally {
      setSaveLoading(false);
    }
  };

  const handleCancelMessage = async () => {
    if (!currentOrg?.id || !currentContact?.id) {
      return;
    }

    setCancelLoading(true);
    try {
      const result = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/outreach-contacts/${currentContact?.id}/cancel-message`,
        "POST",
        {},
        {},
        true,
        false,
        false,
      );
      if (result.ok) {
        removeFromList(contacts, setContacts, currentContact, "id");
        removeFromList(
          currentContactScheduledEmails,
          setCurrentContactScheduledEmails,
          scheduledEmail,
          "id",
        );
        // navigate back to inbox or tracking tab as all scheduled emails are canceled
        if (isOnPage("tracking")) {
          navigate(`/${routes.tracking}`);
        } else {
          navigate(`/${routes.inbox}`);
        }
        setAlert("Successfully cancelled message", "success");
      } else {
        const contentType = result.headers.get("content-type");
        if (contentType !== "application/json") {
          throw new Error(await result.text());
        }
        const body = await result.json();
        // refetch the current contact, which will also update the contacts
        if (currentContact?.id) {
          fetchContact(currentContact.id);
        }
        throw new Error(body.message);
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCancelLoading(false);
    }
  };

  const handleSend = async () => {
    if (!currentOrg?.id || !scheduledEmail?.id) {
      return;
    }

    try {
      setSendLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/${scheduledEmail?.id}/send`,
        "POST",
        {},
        {
          overrideBody: scheduledEmail?.body,
          overrideAttachment: scheduledEmail?.attachment,
        },
      );
      if (res.email.status === MessageStatus.COMPLETED) {
        setAlert("Successfully sent email!", "success");
        if (currentContact?.id) {
          fetchEmailThreads(currentContact.id, true);
          fetchScheduledEmails(currentContact.id);
          fetchContact(currentContact.id);
        }
        if (res.email.gmailThreadId && currentContact?.id) {
          navigate(
            `/${routes.inbox}/${currentContact.id}/email/${res.email.gmailThreadId}`,
          );
        }
      } else {
        setAlert(
          <>
            Something went wrong, unable to send email. This is most likely
            because you need to reconnect to Google. You can reconnect to Google
            in your{" "}
            <Link style={{ color: "black" }} to={`/${routes.settings}`}>
              settings page
            </Link>
          </>,
          "warning",
        );
      }
    } catch (error) {
      setAlert(
        "Something went wrong, unable to send email. Please refresh page and retry again.",
        "error",
      );
    } finally {
      setSendLoading(false);
    }
  };

  const editor = useEditor(
    {
      extensions: extensions(),
      content: scheduledEmail?.body || "",
      onUpdate({ editor }) {
        setScheduledEmail((prev) => {
          if (prev) {
            return { ...prev, isChanged: true, body: editor.getHTML() };
          }
        });
      },
    },
    [scheduledEmail?.id],
  ) as TipTapEditor;

  useEffect(() => {
    if (!scheduledEmailId) {
      if (
        currentContactScheduledEmails.length > 0 &&
        currentContactScheduledEmails[0].outreachContactId ===
          Number(outreachContactId)
      ) {
        navigate(`${currentContactScheduledEmails[0].id}`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContactScheduledEmails, scheduledEmailId, outreachContactId]);

  if (currentContactScheduledEmailsLoading) {
    return (
      <Grid container justifyContent="center">
        <Skeleton variant="rounded" width="90%" height={50} />
      </Grid>
    );
  }

  if (
    (!currentContactScheduledEmails ||
      currentContactScheduledEmails.length === 0) &&
    !scheduledEmailId
  ) {
    return <Navigate to={navigateBackPath(location.pathname, 1)} replace />;
  }

  if (!scheduledEmailId) {
    return <></>;
  }

  const getDialogContent = () => (
    <>
      {(fullScreenEmail || isSmallScreen) && (
        <DialogTitle sx={styles.dialogTitleText}>
          <Grid container alignItems="center" justifyContent={"space-between"}>
            <IconButton color="secondary" size="small" onClick={handleBack}>
              <Box component="i" className="fa-solid fa-arrow-left" />
            </IconButton>
            <Box>
              <Typography noWrap sx={styles.brandName}>
                {scheduledEmail?.subject}
              </Typography>
            </Box>
            <Box>
              <Typography
                component="span"
                noWrap
                variant="caption"
                color="textSecondary"
              >
                {currentContact?.brand?.name}
              </Typography>
            </Box>
          </Grid>
        </DialogTitle>
      )}

      <DialogContent sx={styles.dialogContent}>
        <Accordion
          disableGutters
          square
          expanded={true}
          elevation={0}
          sx={{
            width: "100%",
            borderTop: "none",
            borderBottom: "none",
          }}
        >
          {!isSmallScreen && (
            <Grid item xs={12} sx={styles.subjectWrapper}>
              <Typography variant="h6" sx={styles.subject}>
                {scheduledEmail?.subject}
              </Typography>
            </Grid>
          )}
          <AccordionSummary sx={styles.summary}>
            <Grid container item xs={12}>
              <Grid item xs="auto">
                <Avatar
                  sx={{
                    background: generateColorFromString(
                      currentUser?.email || "",
                    ),
                    textTransform: "uppercase",
                    mr: 1,
                  }}
                >
                  {currentUser?.name?.charAt(0) || currentUser?.email.charAt(0)}
                </Avatar>
              </Grid>

              <Grid item xs sx={styles.summaryText}>
                <Typography>
                  {currentUser?.name || currentUser?.email}{" "}
                  <Typography
                    component="span"
                    color="textSecondary"
                    variant="caption"
                    sx={{ ml: 1 }}
                  >
                    now
                  </Typography>
                </Typography>

                <Typography color="textSecondary" sx={styles.snippet}>
                  to {hideEmail(scheduledEmail?.toEmail || "")}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Editor
                editor={editor}
                allowAttachment={true}
                openAttachmentManager={() => {
                  setAttachmentDialogOpen(true);
                }}
                attachment={scheduledEmail?.attachment}
                removeAttachment={() => {
                  setScheduledEmail((prev) => {
                    if (prev) {
                      return { ...prev, isChanged: true, attachment: "" };
                    }
                  });
                }}
                allowImage={true}
                editorDecoratorBelowMenuBar={
                  <Box sx={styles.scheduledAtContainer}>
                    Scheduled to send:{" "}
                    {formatEpochTimestamp(
                      scheduledEmail?.scheduledAt ||
                        scheduledEmail?.createdAt ||
                        0,
                    )}
                  </Box>
                }
              />
              <Grid
                container
                justifyContent="flex-end"
                item
                xs={12}
                gap={1}
                sx={{ my: 2 }}
              >
                <LoadingButton
                  loading={cancelLoading}
                  onClick={handleCancelMessage}
                >
                  Delete
                </LoadingButton>
                <LoadingButton onClick={handleSend} loading={sendLoading}>
                  Send Now
                </LoadingButton>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  disabled={!scheduledEmail?.isChanged}
                  loading={saveLoading}
                  onClick={handleSaveChanges}
                >
                  Save changes
                </LoadingButton>
              </Grid>

              <AttachmentManager
                enteredSelection={(attachment: string) => {
                  setScheduledEmail((prev) => {
                    if (prev) {
                      return { ...prev, isChanged: true, attachment };
                    }
                  });
                }}
                open={attachmentDialogOpen}
                setOpen={setAttachmentDialogOpen}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </>
  );

  if (!isSmallScreen) {
    return getDialogContent();
  }

  return (
    <Drawer open={true} onClose={closeDrawer}>
      {getDialogContent()}
      <ConfirmDialog
        header={`Are you sure?`}
        subheader={
          "You have unsaved changes. Are you sure you want to navigate away from this page?"
        }
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={() => navigate(-1)}
      />
    </Drawer>
  );
}
