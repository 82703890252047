import { grey } from "@mui/material/colors";

const styles = {
  bottomBarAbsolute: {
    position: "absolute",
    right: 0,
    left: 0,
    bottom: 0,
    px: 1,
  },
  sendSection: {
    backgroundColor: "white",
    pb: 1,
    px: 0,
    pt: 0.5,
    borderTop: `1px solid ${grey[200]}`,
  },
  iconButton: {
    color: "black",
    fontSize: 13,
    width: 20,
    height: 20,
  },
  customButton: {
    fontSize: 12,
    textTransform: "none",
  },
  button: {
    color: "green.dark",
    fontSize: 14,
    display: "inline-block",
    fontFamily: "monospace",
    fontWeight: 800,
    "&:disabled": {
      color: grey[500],
    },
  },
  open: {
    fontWeight: "bold",
    color: "secondary.main",
    backgroundColor: grey[200],
    borderRadius: 1,
  },
};

export default styles;
