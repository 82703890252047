export type PortfolioSite = {
  id: number;
  subdomain: string | null;
  title: string | null;
  description: string | null;
  favicon: string | null;
};

export enum SectionType {
  navigation = "navigation",
  footer = "footer",
  general = "general",
  gallery = "gallery",
}

export const ColumnSizes = ["xs", "sm", "md", "lg", "xl"];

export type SectionColumnSize = {
  xs: number | null;
  sm: number | null;
  md: number | null;
  lg: number | null;
  xl: number | null;
};

export type SectionStyle = {
  background_color?: string | PaletteType | null;
  background_image?: string | null;
  max_number_of_columns?: SectionColumnSize | null;
  column_gap: number;
};

export type PortfolioSiteSection = {
  section_type: string;
  section_style: SectionStyle;
  id: string;
  preview_img: string | null;
  preview_name: string | null;
};

export enum ElementType {
  text = "text",
  media = "media",
  button = "button",
  link = "link",
}

export type SiteElement = {
  element_type: ElementType;
  id: string;
};

export type SiteHref = {
  to: string | null;
  anchor: string | null;
  open_new_tab: boolean;
};

export interface SiteLink extends SiteElement {
  element_type: ElementType.link;
  href: SiteHref;
  text: string | null;
  icon: string | null;
  color: string | PaletteType | null;
}

export interface SiteButton extends SiteElement {
  element_type: ElementType.button;
  text: string;
  href: SiteHref;
  button_type: ButtonType;
}

export interface SiteText extends SiteElement {
  element_type: ElementType.text;
  html: string;
  default_color: string | PaletteType | null;
}

export enum MediaFit {
  contain = "contain",
  cover = "cover",
}

export enum MediaBorderType {
  rounded = "rounded",
  square = "square",
  phone = "phone",
}

export interface SiteMedia extends SiteElement {
  element_type: ElementType.media;
  aspect_ratio: number;
  fit: MediaFit;
  asset: string | null;
  border_type: MediaBorderType;
  alt_text: string;
  href: SiteHref | null;
}

export interface NavigationSection extends PortfolioSiteSection {
  section_type: SectionType.navigation;
  title: string | null;
  logo: string | null;
  links: SiteLink[];
  buttons: SiteButton[];
  color: string | PaletteType | null;
}

export enum GalleryType {
  scroll = "scroll",
  grid = "grid",
}

export interface GallerySection extends PortfolioSiteSection {
  section_type: SectionType.gallery;
  gallery_type: GalleryType;
  title: SiteText | null;
  media: SiteMedia[];
}

export type SectionBlock = {
  elements: (SiteMedia | SiteText | SiteButton)[];
  column_width_percentage: number | null;
};

export interface GeneralSection extends PortfolioSiteSection {
  section_type: SectionType.general;
  title: SiteText | null;
  columns: SectionBlock[];
}

export enum FooterType {
  logoLeft = "logo_left",
  logoRight = "logo_right",
}

export interface FooterSection extends PortfolioSiteSection {
  section_type: SectionType.footer;
  footer_type: FooterType;
  title: SiteText | null;
  socials: SiteLink[];
  links: SiteLink[];
}

export enum TypographyType {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  p1 = "p1",
  p2 = "p2",
}

export const TypographyTypeLabel: { [key in TypographyType]: string } = {
  [TypographyType.h1]: "Heading 1",
  [TypographyType.h2]: "Heading 2",
  [TypographyType.h3]: "Heading 3",
  [TypographyType.p1]: "Paragraph",
  [TypographyType.p2]: "Links",
};

export type SiteFont = {
  font_size_rem: number;
  line_height_em: number;
  weight: string;
  family: string;
};

export const WeightLabels: { [key in string]: string } = {
  "100": "100 Thin",
  "200": "200 Extra Light",
  "300": "300 Light",
  "400": "400 Normal",
  "500": "500 Medium",
  "600": "600 Semi Bold",
  "700": "700 Bold",
  "800": "800 Extra Bold",
  "900": "900 Heavy",
  regular: "400 Regular",
};

export type SiteFonts = {
  fonts: { [key in TypographyType | ButtonType]: SiteFont };
  base_font_size: number;
};

export enum ButtonType {
  primary = "primary_button",
  secondary = "secondary_button",
  tertiary = "tertiary_button",
}

export const ButtonTypeLabel: { [key in ButtonType]: string } = {
  [ButtonType.primary]: "Primary Button",
  [ButtonType.secondary]: "Secondary Button",
  [ButtonType.tertiary]: "Tertiary Button",
};

export enum ButtonVariant {
  filled = "filled",
  outlined = "outlined",
}

export enum ButtonShape {
  square = "square",
  rounded = "rounded",
  pill = "pill",
}

export type ButtonStyle = {
  variant: ButtonVariant;
  shape: ButtonShape;
  border_size: number;
  border_color: string | PaletteType | null;
  fill_color: string | PaletteType | null;
  color: string | PaletteType | null;
};

export enum PaletteType {
  primaryLight = "primary_light",
  primaryMain = "primary_main",
  primaryDark = "primary_dark",
  secondaryLight = "secondary_light",
  secondaryMain = "secondary_main",
  secondaryDark = "secondary_dark",
}

export type PaletteColor = {
  color: string;
};

export type PortfolioSiteRevision = {
  id: number;
  createdAt: number;
  isPublished: boolean;
  sections: PortfolioSiteSection[];
  siteFonts: SiteFonts;
  buttonStyles: { [key in ButtonType]: ButtonStyle };
  palette: { [key in PaletteType]: PaletteColor };

  // front-end variable to know if we should warn about unsaved progress
  isEdited?: boolean;
};
