import { Box } from "@mui/material";
import { EditorContent, Editor as TipTapEditor } from "@tiptap/react";
import { Dispatch, SetStateAction, useRef } from "react";

import { ImageBubbleMenu } from "./ImageBubbleMenu";
import MergeTagMenuBar from "./MergeTag/MergeTagMenuBar";
import styles from "./styles";

export type AttachmentProps = {
  openAttachmentManager: () => void;
  attachment?: string;
  removeAttachment?: () => void;
  setSelectImageOpen: Dispatch<SetStateAction<boolean>>;
};

interface MenuBarProps {
  editor: any;
  mergeTags?: string[];
}

const MenuBar = ({ editor, mergeTags }: MenuBarProps) => {
  const editorContainerRef = useRef<HTMLDivElement>(null);

  if (!editor) {
    return null;
  }

  return (
    <Box sx={[styles.wrapper]}>
      <MergeTagMenuBar editor={editor} mergeTags={mergeTags} />

      <Box
        sx={[styles.editor, styles.emailEditor(true), styles.plainEditor]}
        ref={editorContainerRef}
      >
        <EditorContent spellCheck={false} editor={editor} />
      </Box>

      {editor && <ImageBubbleMenu editor={editor} />}
    </Box>
  );
};

interface EditorProps {
  editor: TipTapEditor;
  mergeTags?: string[];
}

const Editor = ({ editor, mergeTags = [] }: EditorProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      {editor && <MenuBar editor={editor} mergeTags={mergeTags} />}
    </Box>
  );
};

export default Editor;
