import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  InboxSidebarOption,
  InboxSidebarOptions,
  OutreachContactsContext,
} from "contexts/OutreachContacts";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "schemas/routes";

import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface IProps {
  onSelect?: () => void;
  isSmallScreen?: boolean;
}

export default function Sidebar({ onSelect, isSmallScreen = false }: IProps) {
  const { currentSidebarOption, setFetchContactsParams } = useContext(
    OutreachContactsContext,
  );
  const navigate = useNavigate();

  const openCompose = () => {
    trackEvent("Inbox Table Opened Compose Email");
    navigate(`/${routes.inbox}/compose`);
    if (onSelect) {
      onSelect();
    }
  };

  const handleChange = (option: InboxSidebarOption) => {
    trackEvent("Inbox Table Changed Folder", {
      folder: option.label,
    });
    if (onSelect) {
      onSelect();
    }
    setFetchContactsParams((prev) => {
      const copy = makeDeepCopy(prev);
      copy.statuses = option.statuses;
      copy.page = 1;
      return copy;
    });
  };

  return (
    <>
      <Box sx={[isSmallScreen && styles.smallScreenButton]}>
        <Button
          onClick={openCompose}
          disableElevation
          size="small"
          sx={styles.button}
          variant="contained"
        >
          <Box
            component="i"
            className="fa-regular fa-pen"
            sx={{ mr: 1, minWidth: "25px" }}
          />
          Compose
        </Button>
      </Box>
      <List sx={{ width: "100%" }}>
        {InboxSidebarOptions.map((option) => (
          <ListItem key={option.key} disablePadding>
            <ListItemButton
              onClick={() => handleChange(option)}
              selected={currentSidebarOption?.key === option.key}
            >
              <ListItemIcon sx={{ minWidth: "25px" }}>
                <Box component="i" className={option.icon} />
              </ListItemIcon>
              <ListItemText sx={styles.textItem} primary={option.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
