import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";

import Drawer from "components/Drawer";
import { usePageSize } from "utils/usePageSize";

import { getStyles } from "./styles";

interface Props {
  children?: JSX.Element | JSX.Element[];
  handleClose: () => void;
}

export default function QuickSendWrapper({ children, handleClose }: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isLargeRecommendedPage, isOnboardingPage } = usePageSize();

  const { view } = useContext(QuickSendDrawerContext);
  const { discoverTab } = useContext(DiscoverViewContext);
  const { quickSendBrandId } = useContext(QuickSendDrawerContext);
  const { upgradeDialogSource } = useContext(SubscriptionContext);

  const styles = getStyles(discoverTab !== null);

  if (isMobileScreen && !isOnboardingPage) {
    return (
      <Drawer
        open={quickSendBrandId > -1 && !(upgradeDialogSource !== "")}
        onClose={handleClose}
      >
        {children}
      </Drawer>
    );
  } else if (isLargeRecommendedPage || isOnboardingPage) {
    return <Box sx={styles.recommendationRoot}>{children}</Box>;
  } else if (view === QuickSendTabView.EXPAND) {
    return <Box sx={styles.root}>{children}</Box>;
  } else {
    return <></>;
  }
}
