import { blue, green, orange, red } from "@mui/material/colors";
import {
  MessageStatus,
  OpportunityStage,
  OutreachContactStatus,
  SearchParams,
  SubscriptionType,
} from "schemas/dashboard";
import { Map, StringMap } from "schemas/functions";

export const darkColorPalette = ["#186F65", "#B5CB99", "#186F65", "#B5CB99"];

export const lightColorPalette = [
  "#FAF3F0",
  "#D4E2D4",
  "#FFCACC",
  "#DBC4F0",
  "#C4DFDF",
  "#F8EAD8",
  "#D4E2D4",
  "#FFCACC",
];

export const templateBackgroundPalette = [
  "#F9EBEA",
  "#F5EEF8",
  "#EAF2F8",
  "#E9F7EF",
  "#FBEEE6",
  "#EBEDEF",
  "#F8F9F9",
];

export const colorsPalette: Map = {
  darkColorPalette,
  lightColorPalette,
  templateBackgroundPalette,
};

export const generateColorFromString = (
  label: string,
  paletteName = "darkColorPalette",
) => {
  let hash = 0;
  label.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  return generateColorFromPalette(hash, paletteName);
};

export const generateColorFromPalette = (
  index: number,
  paletteName = "darkColorPalette",
) => {
  const palette =
    paletteName in colorsPalette
      ? colorsPalette[paletteName]
      : darkColorPalette;
  const paletteIndex = index % palette?.length;
  return palette[paletteIndex];
};

export const StageColorPalette: Map = {
  [OpportunityStage.NEW]: "#E1EEDD",
  [OpportunityStage.DISCOVERY]: "#D2E9E9",
  [OpportunityStage.SCHEDULING_DEMO]: "#FAF1E4",
  [OpportunityStage.DEMO]: "#F7E6C4",
  [OpportunityStage.NEGOTIATION]: "#B9EDDD",
  [OpportunityStage.WON]: "#E5E0FF",
  [OpportunityStage.LOST]: "#FFDDCC",

  [OpportunityStage.OPPORTUNITIES]: "#E1EEDD",
  [OpportunityStage.FULFILLING]: "#E5E0FF",
  [OpportunityStage.COMPLETED]: "#B9EDDD",
};

export const OutreachContactStatusPalette: Map = {
  [OutreachContactStatus.no_response]: "#FAF1E4",
  [OutreachContactStatus.received_response]: "#D6EAF8",
  [OutreachContactStatus.interested]: "#E5E0FF",
  [OutreachContactStatus.not_interested]: "#FFDDCC",
  [OutreachContactStatus.bounced]: "#FADBD8",
  [OutreachContactStatus.pending]: "primary.light",
};

export const MessageStatusColor: Map = {
  [MessageStatus.PROCESSING]: "#F0F7EC",
  [MessageStatus.PENDING]: "#F7F7EC",
  [MessageStatus.FAILED]: "#F7EDEC",
};

export const SubscriptionColor: Map = {
  [SubscriptionType.CANCELED]: "#F5B7B1",
  [SubscriptionType.INCOMPLETE]: "#F5B7B1",
  [SubscriptionType.INCOMPLETE_EXPIRED]: "#F5B7B1",
  [SubscriptionType.PAST_DUE]: "#F5B7B1",
  [SubscriptionType.UNPAID]: "#F5B7B1",
  [SubscriptionType.ACTIVE]: "#E9F7EF",
  [SubscriptionType.TRIALING]: "#E9F7EF",
  [SubscriptionType.PAUSED]: "#FDF2E9",
};

export const getScoreColor = (score: number) => {
  if (score < 60) {
    return red[500];
  } else if (score === 60) {
    return orange[600];
  } else if (score <= 75) {
    return blue[700];
  } else if (score <= 88) {
    return green[500];
  } else {
    return green[800];
  }
};

export const DiscoverSearchChipColor: StringMap = {
  [SearchParams.CATEGORY_DISCOVER]: "#F5EEF8",
  [SearchParams.TYPE_DISCOVER]: "#EAF2F8",
  [SearchParams.SIZE_DISCOVER]: "#FEF9E7",
  [SearchParams.LOCATION_DISCOVER]: "#FBEEE6",
  [SearchParams.FOLLOWING_DISCOVER]: "#A3E4D7",
  [SearchParams.SORT_DISCOVER]: "#F9EBEA",
  [SearchParams.CITY_DISCOVER]: "#FBEEE6",
  [SearchParams.GEOPOINTS_DISCOVER]: "#FBEEE6",
};
