import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";

import Drawer from "components/Drawer";
import { usePageSize } from "utils/usePageSize";

import { getStyles } from "./styles";

interface Props {
  children?: JSX.Element | JSX.Element[];
  handleClose: () => void;
}

export default function DetailedBrandViewWrapper({
  children,
  handleClose,
}: Props) {
  const theme = useTheme();
  const { quickSendBrandId, brandDetailsId } = useContext(
    QuickSendDrawerContext,
  );
  const { discoverTab, displayedBrandInfo } = useContext(DiscoverViewContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isLargeRecommendedPage } = usePageSize();

  const styles = getStyles(discoverTab !== null, quickSendBrandId >= 0);

  if (isLargeRecommendedPage && !isMobileScreen) {
    return <Box>{children}</Box>;
  } else if (displayedBrandInfo) {
    return <Box sx={styles.root}>{children}</Box>;
  } else if (isMobileScreen) {
    return (
      <Drawer open={brandDetailsId > -1} onClose={handleClose}>
        {children}
      </Drawer>
    );
  } else {
    return <></>;
  }
}
