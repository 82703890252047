import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UserRequestSource } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface RequestBrandProps {
  setAlert: SetAlertType;
  isToggle?: boolean;
}

export default function RequestBrand({
  setAlert,
  isToggle,
}: RequestBrandProps) {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const handleClose = () => {
    setOpen(false);
  };

  const openRequest = () => {
    trackEvent("Request Brand Clicked");
    setOpen(true);
  };

  const handleChange = (e: CustomEvent) => {
    setText(e.target.value);
  };

  const handleRequest = async (requestFor: string) => {
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-requests`,
        "POST",
        {},
        {
          description: requestFor,
          source: isToggle
            ? UserRequestSource.SOURCE_TOGGLE
            : UserRequestSource.SOURCE_REQUEST_BRAND,
        },
      );
      setAlert("Request submitted successfully!", "success");
      setOpen(false);
    } catch (error) {
      setAlert("Failed to submit request. Please try again later.", "error");
    } finally {
      setLoading(false);
      setText("");
    }
  };

  return (
    <>
      {isToggle ? (
        <FormControlLabel
          sx={{ mx: 1 }}
          control={
            <Switch
              size="small"
              onChange={() =>
                handleRequest(searchParams.get("queryBrand") || "")
              }
            />
          }
          label={
            <Typography sx={styles.notifyText} component="span">
              Notify me when results for{" "}
              <strong>"{searchParams.get("queryBrand")}"</strong> are found.
            </Typography>
          }
        />
      ) : (
        <Button
          size="small"
          variant="contained"
          sx={styles.requestButton}
          disableElevation
          onClick={openRequest}
        >
          <Box component="i" className="fa-regular fa-message" sx={{ mr: 1 }} />
          Request Brand
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography textAlign="center" variant="h6">
            Can't Find A Specific Brand?
          </Typography>

          <IconButton onClick={handleClose} sx={styles.close}>
            <Box component="i" className="fa-regular fa-xmark" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ justifyContent: "center" }}>
          Share a link to their website or Instagram, and we can look for you.
          <FormControl fullWidth sx={{ mt: 1 }}>
            <TextField
              placeholder="Website or Instagram Page link"
              value={text}
              onChange={handleChange}
            />
          </FormControl>
          <Typography textAlign="center" sx={{ mt: 2 }}>
            🔔 We'll notify you when we add them to the dashboard.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <Grid container justifyContent="center">
            <LoadingButton
              disableElevation
              variant="contained"
              onClick={() => handleRequest(text)}
              disabled={!text || text?.trim()?.length === 0}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
