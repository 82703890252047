import { Box } from "@mui/material";
import { BubbleMenu, Editor as TipTapEditor } from "@tiptap/react";

import styles from "./styles";

interface IProps {
  editor: TipTapEditor;
}

export type ImageOptions = "small" | "medium" | "large" | "original";

export function ImageBubbleMenu({ editor }: IProps) {
  const handleClick = (command?: ImageOptions) => () => {
    if (editor) {
      editor.chain().focus().setImage({ size: command }).run();
    }
  };

  const isActive = (size: string) => {
    return editor && editor.isActive("custom-image", { size });
  };

  const imageOptions: ImageOptions[] = ["small", "medium", "large", "original"];

  return (
    <BubbleMenu
      shouldShow={({ editor }) => editor && editor.isActive("custom-image")}
      editor={editor}
      tippyOptions={{
        placement: "bottom",
        zIndex: 1202,
      }}
    >
      {imageOptions.map((size) => (
        <Box component="span" key={size}>
          <Box
            component="a"
            sx={{ ...(isActive(size) ? styles.active : styles.button) }}
            onClick={handleClick(size)}
          >
            {size}
          </Box>
          {size !== "original" && (
            <Box component="span" sx={styles.separator}>
              -
            </Box>
          )}
        </Box>
      ))}
    </BubbleMenu>
  );
}
