import { LoadingButton } from "@mui/lab";
import { Avatar, Box, CircularProgress, IconButton, Link } from "@mui/material";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SavedBrandContext } from "contexts/SavedBrand";
import { forwardRef, useContext } from "react";
import { BentoBrand, SavedBrandStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { generateInitials } from "utils/string";

import styles from "./styles";

interface SelectedBrandProps {
  bentoBrand: BentoBrand;
}

const SelectedBrand = forwardRef(({ bentoBrand }: SelectedBrandProps, ref) => {
  const { handleRemoveDebounced, removeLoading, handleDeleteDraft, tab } =
    useContext(SavedBrandContext);
  const { handleOpenQuickSendIndividual, quickSendBrandId } = useContext(
    QuickSendDrawerContext,
  );

  const handleDelete = (e: CustomEvent) => {
    e.stopPropagation();
    if (tab === SavedBrandStatus.DRAFTS) {
      handleDeleteDraft(bentoBrand);
    } else {
      handleRemoveDebounced(bentoBrand);
    }
  };

  const openQuickSend = () => {
    handleOpenQuickSendIndividual(
      bentoBrand?.id,
      "Favorites List",
      undefined,
      true,
    );
  };

  const goToInstagram = (e: CustomEvent) => {
    e.stopPropagation();
    window.open(
      `https://instagram.com/${bentoBrand.instagramUsername}`,
      "_blank",
    );
  };

  const isSelected = quickSendBrandId === bentoBrand?.id;

  return (
    <Box
      ref={ref}
      sx={[styles.brandContainer, isSelected && styles.selected]}
      onClick={openQuickSend}
      key={`cart-brand-${bentoBrand.id}`}
    >
      {removeLoading === bentoBrand?.id ? (
        <CircularProgress size={20} />
      ) : (
        <IconButton onClick={handleDelete} sx={styles.removeButton}>
          <Box component="i" className="fa-regular fa-circle-xmark" />
        </IconButton>
      )}
      <Box sx={styles.brandImage}>
        {bentoBrand.logoUrl ? (
          <img src={bentoBrand.logoUrl} alt={`${bentoBrand.brandName} logo`} />
        ) : (
          <Avatar sx={styles.avatar}>
            {generateInitials(bentoBrand.brandName)}
          </Avatar>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={styles.brandName}>
          {bentoBrand.brandName}
          {tab === SavedBrandStatus.DRAFTS && (
            <>
              ,{" "}
              <Box component="span" sx={styles.draft}>
                Draft{" "}
              </Box>
            </>
          )}
        </Box>

        {bentoBrand.instagramUsername && (
          <Link target="_blank" onClick={goToInstagram} sx={{ fontSize: 12 }}>
            @{bentoBrand.instagramUsername}
          </Link>
        )}
      </Box>

      {!(tab === SavedBrandStatus.DRAFTS) ? (
        <Box>
          <LoadingButton
            sx={{ textTransform: "none" }}
            variant="contained"
            disableElevation
            size="small"
            onClick={openQuickSend}
          >
            Email
            <Box
              component="i"
              className="fa-regular fa-envelope"
              sx={{ ml: 1 }}
            />
          </LoadingButton>
        </Box>
      ) : (
        <IconButton sx={{ color: "primary.main", fontSize: 14 }}>
          <Box component="i" className="fa-regular fa-pencil" />
        </IconButton>
      )}
    </Box>
  );
});

export default SelectedBrand;
