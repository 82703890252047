import { Editor } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import tippy, { Instance } from "tippy.js";

import OptionList, { OptionListProps } from "./OptionList";

const suggestions = (options: string[]): Omit<SuggestionOptions, "editor"> => ({
  items: ({ query }: { query: string; editor: Editor }): any[] => {
    return options
      .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
      .slice(0, 5);
  },

  render: () => {
    let component: ReactRenderer<typeof OptionList, OptionListProps>;
    let popup: Instance[];

    return {
      onStart: (props: any) => {
        component = new ReactRenderer<typeof OptionList, OptionListProps>(
          OptionList,
          {
            props,
            editor: props.editor,
          },
        );

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate: (props: any) => {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown: (props: any) => {
        if (props.event.key === "Escape") {
          if (popup[0]) popup[0].hide();

          return true;
        }

        // @ts-ignore
        return component.ref?.onKeyDown(props);
      },

      onExit: () => {
        if (popup[0]) popup[0].destroy();
        if (component) component.destroy();
      },
    };
  },
});

export default suggestions;
