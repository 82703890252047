import { Box, Chip, Grid, Typography } from "@mui/material";
import { BentoBrand, MetadataType, SearchParams } from "schemas/dashboard";

import { DiscoverSearchChipColor } from "utils/color";
import { useSearchBrand } from "utils/useSearchBrand";

import styles from "./styles";

interface Props {
  selectedBrand?: BentoBrand | null;
  header: string;
  stringValue?: string;
}

const BrandAnnotation = ({ header, selectedBrand, stringValue }: Props) => {
  const { handleClickOnTag } = useSearchBrand();

  let initialized = false;

  const renderChip = (
    index: number,
    value: string,
    backgroundColor: string,
    type: MetadataType,
  ) => {
    return (
      <Chip
        key={index}
        size="small"
        sx={{
          backgroundColor,
          my: 0.5,
        }}
        label={value}
        onClick={(e) =>
          handleClickOnTag(e, {
            type,
            value,
          })
        }
      />
    );
  };

  let value: JSX.Element[] | JSX.Element | undefined | string = <></>;
  if (header === "Category") {
    initialized = true;
    value = selectedBrand?.categories?.map((category, index) =>
      renderChip(
        index,
        category,
        DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER],
        MetadataType.tags,
      ),
    );
  } else if (header === "Price Point" && selectedBrand?.pricePoint) {
    initialized = true;
    value = renderChip(
      0,
      selectedBrand?.pricePoint,
      DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER],
      MetadataType.tags,
    );
  } else if (header === "Company Size" && selectedBrand?.size) {
    initialized = true;
    value = renderChip(
      0,
      selectedBrand?.size,
      DiscoverSearchChipColor[SearchParams.SIZE_DISCOVER],
      MetadataType.size,
    );
  } else if (stringValue) {
    initialized = true;
    value = stringValue;
  }

  if (!selectedBrand || !initialized) return <></>;

  return (
    <Grid item xs={12}>
      <Typography gutterBottom>
        <Box component="span" sx={styles.key}>
          {header}:{" "}
        </Box>{" "}
        <Box sx={styles.value} component="span">
          {value}
        </Box>
      </Typography>
    </Grid>
  );
};

export default BrandAnnotation;
