import personLaptop from "assets/auth/person_laptop.png";

const styles = {
  image: {
    width: 600,
  },
  signInImage: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "45vw",
    backgroundColor: "white",
    backgroundImage: `url(${personLaptop})`,
    backgroundSize: "cover",
    boxShadow: "inset 0 0 0 2000px rgba(255, 255, 255, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "top center",
  },
  signInContent: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: { xs: 0, lg: "45vw" },
    backgroundColor: "green.dark",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "radial-gradient(#ffffff 0.5px, #151e16 0.5px)",
    backgroundSize: "20px 20px",
    backgroundPosition: "0 0,10px 10px",
    opacity: 1,
    px: { xs: 3, lg: 0 },
  },
  quote: {
    fontFamily: `Balgin, "Open Sans", Arial, sans-serif`,
    fontSize: 50,
    color: "green.dark",
    textAlign: "center",
    maxWidth: "400px",
  },
};

export default styles;
