import { Button, Grid } from "@mui/material";
import { forwardRef, useImperativeHandle } from "react";
import { CustomEvent } from "schemas/functions";

import { buttonStyles as styles } from "./styles";

interface Props {
  onClick: (e?: CustomEvent) => void;
  text: string;
}

const TableButton = forwardRef(({ onClick, text }: Props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      childRefKeyPressed(e: KeyboardEvent) {
        if (e.key === "Enter") {
          onClick(e);
        }
      },
    };
  });

  return (
    <Grid
      container
      justifyContent="center"
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <Button
        tabIndex={-1}
        size="small"
        sx={styles.smallButton}
        variant="contained"
        disableElevation
      >
        {text}
      </Button>
    </Grid>
  );
});

export default TableButton;
