import { Box, Button, Grid } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { ColumnDialog, TrackingColumnsContext } from "contexts/TrackingColumns";
import { TrackingColumnsFiltersContext } from "contexts/TrackingColumnsFilters";
import { TrackingColumnsSortContext } from "contexts/TrackingColumnsSort";
import { useContext } from "react";
import { BuiltInContactPropertyName } from "schemas/dashboard";

import { TABLE_HIDE_COLUMN } from "features/Influencer/Tracking/events";
import { PropertySortDirection } from "features/Influencer/Tracking/schema";

import TableFilterPopupMenu from "../TableFilterPopupMenu";
import TableSortPopupMenu from "../TableSortPopUpMenu";
import SearchBar from "./SearchBar";
import styles from "./styles";

const TableProperties = () => {
  const { selectedView, trackTable } = useContext(ContactViewContext);
  const { sortedMenuItems, setDialogType, getTitleForHeader } = useContext(
    TrackingColumnsContext,
  );
  const { handleOpenFilterMenu, filterButtonRef } = useContext(
    TrackingColumnsFiltersContext,
  );
  const { handleOpenSortMenu } = useContext(TrackingColumnsSortContext);

  const totalHiddenColumns = sortedMenuItems?.filter((x) => !x.checked)?.length;
  const props = selectedView?.contactViewProperties;
  const totalFiltersSet = props?.filter((x) => x.filters?.length > 0)?.length;
  let sortedColumn = props?.find((x) => x.sortDirection !== null);
  if (!sortedColumn) {
    sortedColumn = props?.find(
      (x) => x?.contactPropertyName === BuiltInContactPropertyName.createdAt,
    );
  }

  const filtersBeingSet = props?.filter((x) => x.filters?.length > 0);
  const filterNames = filtersBeingSet?.map((x) =>
    getTitleForHeader(x.contactPropertyName || x?.contactProperty?.name),
  );

  const openSideBar = () => {
    trackTable(TABLE_HIDE_COLUMN);
    setDialogType(ColumnDialog.edit);
  };

  const renderButton = () => {
    if (totalHiddenColumns === 0) {
      return (
        <Button
          onClick={openSideBar}
          size="small"
          sx={styles.button}
          variant="outlined"
        >
          <Box component="i" className="fa-regular fa-eye" sx={{ mr: 1 }} />
          Hide Columns{" "}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={openSideBar}
          sx={styles.button}
          disableElevation
          size="small"
          variant="contained"
        >
          <Box
            component="i"
            className="fa-regular fa-eye-slash"
            sx={{ mr: 1 }}
          />{" "}
          {totalHiddenColumns} Hidden Columns
        </Button>
      );
    }
  };

  const renderFilterButton = () => {
    if (totalFiltersSet && totalFiltersSet > 0) {
      return (
        <Button
          onClick={handleOpenFilterMenu}
          ref={filterButtonRef}
          sx={[styles.button, styles.filterColor]}
          disableElevation
          size="small"
          variant="contained"
        >
          <Box component="i" className="fa-regular fa-filter" sx={{ mr: 1 }} />{" "}
          Filtered by {filterNames?.join(", ")}
        </Button>
      );
    } else {
      return (
        <Button
          sx={styles.button}
          ref={filterButtonRef}
          disableElevation
          size="small"
          variant="outlined"
          onClick={handleOpenFilterMenu}
        >
          <Box component="i" className="fa-regular fa-filter" sx={{ mr: 1 }} />{" "}
          Add Filter +
        </Button>
      );
    }
  };

  const renderSortButton = () => {
    if (sortedColumn) {
      return (
        <Button
          onClick={handleOpenSortMenu}
          sx={[styles.button, styles.sortColor]}
          disableElevation
          size="small"
          variant="contained"
        >
          <Box component="i" className="fa-regular fa-sort" sx={{ mr: 1 }} />{" "}
          Sort by{" "}
          {getTitleForHeader(
            sortedColumn?.contactPropertyName ||
              sortedColumn?.contactProperty?.name,
          )}{" "}
          {sortedColumn?.sortDirection === PropertySortDirection.asc ? (
            <Box
              sx={{ ml: 1 }}
              component="i"
              className="fa-thin fa-arrow-up-short-wide"
            />
          ) : (
            <Box
              sx={{ ml: 1 }}
              component="i"
              className="fa-thin fa-arrow-down-short-wide"
            />
          )}
        </Button>
      );
    } else {
      return (
        <Button
          sx={styles.button}
          disableElevation
          size="small"
          variant="outlined"
          onClick={handleOpenSortMenu}
        >
          <Box component="i" className="fa-regular fa-sort" sx={{ mr: 1 }} />{" "}
          Sort
        </Button>
      );
    }
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2 }}
      gap={1}
    >
      <Grid container gap={1} item md={6} xs>
        {renderButton()}
        {renderFilterButton()}
        {renderSortButton()}
        <TableFilterPopupMenu />
        <TableSortPopupMenu />
      </Grid>
      <SearchBar />
    </Grid>
  );
};

export default TableProperties;
