const styles = {
  summary: {
    width: "100%",
    "& .MuiAccordionSummary-content": { width: "100%" },
  },
  summaryText: {
    width: "calc(100% - 50px)",
  },
  checkbox: {
    ml: 0,
  },
  snippet: {
    textOverflow: "ellipsis",
    fontSize: "12px",
    mt: 0.5,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
};

export default styles;
