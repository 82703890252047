const styles = {
  wrapper: {
    position: "relative",
    height: "100vh",
    width: "100vw",
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    ml: "-20px",
    mt: "-20px",
  },
};

export default styles;
