import {
  Box,
  Chip,
  Grid,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { BrandsContext, DiscoverParams, TagLabel } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import lodash from "lodash";
import { useContext, useState } from "react";
import {
  BentoBrandMetadataTags,
  CATEGORIES,
  COMPANY_TYPES,
  FOLLOWING_SIZES,
  MetadataType,
  SORTS,
  SearchParams,
} from "schemas/dashboard";
import { Map } from "schemas/functions";
import theme from "styles/theme";

import DropdownSelect from "components/DropdownSelect";
import { DiscoverSearchChipColor } from "utils/color";
import { useSearchBrand } from "utils/useSearchBrand";

import CompanySizeFilter from "./CompanySizeFilter";
import LocationFilter from "./LocationFilter";
import { ALL, companyTypeIcons } from "./helpers";
import styles from "./styles";

export default function CategorySelect() {
  const {
    deleteCategoryFromSearch,
    deleteMetadataFromSearch,
    addCategoryQueryToSearch,
  } = useSearchBrand();
  const [close, setClose] = useState(false);

  const { discoverFilterParams, selectedMetadataTags } =
    useContext(BrandsContext);
  const { discoverTab } = useContext(DiscoverViewContext);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen =
    isSmallScreen || (isLargeScreen && discoverTab !== null);

  const selectedTags = Object.keys(discoverFilterParams).reduce(
    (acc: TagLabel[], key: string) => {
      return acc.concat(discoverFilterParams[key as keyof DiscoverParams]);
    },
    [],
  );

  const isOptionSelected = (key: string, params: string) => {
    return Boolean(
      selectedTags?.find((x) => x?.key === key && x.params === params),
    );
  };

  const handleAddOption = (name: string, value: string) => {
    addCategoryQueryToSearch(name, value);
    setClose(true);
  };

  const selectedOptionIcon = (
    <Box
      component="i"
      className="fa-regular fa-check"
      sx={{ mr: 2, color: "success.main" }}
    />
  );

  const renderSelect = (
    label: string,
    options: { key: string; name: string; label?: string }[],
    params: string,
    iconsMap?: Map,
  ) => {
    const getIcon = (key: string) => {
      if (iconsMap && key in iconsMap) {
        return iconsMap[key];
      }
    };
    return (
      <DropdownSelect
        label={label}
        id={label}
        close={close}
        setClose={setClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.key}
            value={option.key}
            onClick={() => handleAddOption(params, option.key)}
          >
            <Tooltip title={option.label} placement="right">
              <Grid container>
                {!iconsMap &&
                  isOptionSelected(option.key, params) &&
                  selectedOptionIcon}{" "}
                {getIcon(option.key)}
                {option.name}
              </Grid>
            </Tooltip>
          </MenuItem>
        ))}
      </DropdownSelect>
    );
  };

  const filterOptions = () => {
    return (
      <Grid sx={styles.filterOptionContainer}>
        {renderSelect("Category", CATEGORIES, SearchParams.CATEGORY_DISCOVER)}
        {renderSelect(
          "Type",
          COMPANY_TYPES,
          SearchParams.TYPE_DISCOVER,
          companyTypeIcons,
        )}

        {/* Location filter */}
        <LocationFilter
          handleAddOption={handleAddOption}
          close={close}
          setClose={setClose}
          selectedOptionIcon={selectedOptionIcon}
          isOptionSelected={isOptionSelected}
        />

        {/* Company Size Filter */}
        <CompanySizeFilter
          handleAddOption={handleAddOption}
          close={close}
          setClose={setClose}
          selectedOptionIcon={selectedOptionIcon}
          isOptionSelected={isOptionSelected}
        />

        {/* Instagram Following Filters */}
        {renderSelect(
          "Instagram Following",
          FOLLOWING_SIZES,
          SearchParams.FOLLOWING_DISCOVER,
        )}

        {isMobileScreen &&
          renderSelect("Sort By", SORTS, SearchParams.SORT_DISCOVER)}
      </Grid>
    );
  };

  return (
    <Box sx={styles.root}>
      <Grid container justifyContent="space-between">
        {filterOptions()}
        {!isMobileScreen &&
          renderSelect("Sort By", SORTS, SearchParams.SORT_DISCOVER)}
      </Grid>

      <Grid container gap={1} sx={styles.chipsContainer}>
        {selectedTags?.map(
          (chip: TagLabel, index: number) =>
            chip?.name &&
            chip?.params !== SearchParams.SORT_DISCOVER &&
            chip?.key !== ALL && (
              <Chip
                key={index}
                label={chip.name}
                onDelete={() => deleteCategoryFromSearch(chip)}
                sx={[
                  styles.selectedChip,
                  { backgroundColor: DiscoverSearchChipColor[chip.params] },
                ]}
                size={isMobileScreen ? "small" : "medium"}
              />
            ),
        )}
        {selectedMetadataTags?.map(
          (tag: BentoBrandMetadataTags, index: number) => (
            <Chip
              key={index}
              label={lodash.startCase(tag.value)}
              onDelete={() => deleteMetadataFromSearch(tag)}
              sx={[
                styles.selectedChip,
                {
                  backgroundColor:
                    tag.type === MetadataType.location
                      ? DiscoverSearchChipColor[SearchParams.LOCATION_DISCOVER]
                      : DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER],
                },
              ]}
              size={isMobileScreen ? "small" : "medium"}
            />
          ),
        )}
      </Grid>
    </Box>
  );
}
