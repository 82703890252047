import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CustomEvent, Map, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";

interface AdminEmailVerificationProps {
  setAlert: SetAlertType;
}

const AdminEmailVerification = ({ setAlert }: AdminEmailVerificationProps) => {
  const { getToken } = useAuth();

  const [loading, setLoading] = useState(false);

  const [option, setOption] = useState<Map>({
    days: 90,
    score: 70,
    limit: 1,
  });

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    setOption((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRunMigration = async () => {
    if (option.days < 0 || option.score < 0 || option.score >= 100) {
      setAlert(
        "Days must be larger than 0. Confidence score must be between 0 and 100.",
        "error",
      );
      return;
    }

    const data: Map = {
      days: option.days,
      score: option.score,
    };

    if (option?.limit > 0) {
      data["limit"] = option.limit;
    }

    try {
      setLoading(true);
      await fetcherAuth(
        getToken,
        `/api/admin/bento-contacts/hunter-email-migrations`,
        "POST",
        {},
        data,
      );
      setAlert(
        "Successfully starting migration to verify BentoContact",
        "success",
      );
    } catch (error) {
      setAlert(error?.message || "Something went wrong.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid>
      <Typography variant="h4" gutterBottom>
        <strong>Hunter Email Verification</strong>
      </Typography>

      <Grid item sx={{ mb: 2 }}>
        <Typography component="div">
          <li>
            Run a migration on all BentoContact that has not been verified
            within the last <strong>{option.days}</strong> days.
          </li>
          <li>
            Archive any BentoContact that has a "Hunter email confidence score"
            below <strong>{option.score}</strong>
          </li>
        </Typography>

        <Grid container sx={{ my: 1 }} gap={2}>
          <TextField
            onChange={handleChange}
            name="days"
            type="number"
            value={option.days}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
          />
          <TextField
            onChange={handleChange}
            name="score"
            type="number"
            value={option.score}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">score</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid item sx={{ mb: 2 }}>
        <Typography>
          {option.limit > 0 ? (
            <>
              Run this migration on the first found{" "}
              <strong>{option.limit}</strong> contacts that met the criteria
              above.
            </>
          ) : (
            "Run this migration on all contacts"
          )}
        </Typography>
        <TextField
          onChange={handleChange}
          name="limit"
          type="number"
          value={option.limit}
        />
        <FormHelperText>
          Put 0 if you want to run on all contacts.
        </FormHelperText>
      </Grid>

      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={handleRunMigration}
      >
        Run Migration
      </LoadingButton>
    </Grid>
  );
};

export default AdminEmailVerification;
