import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";

export const validateTemplateBody = (template: Template) => {
  let errorMessage = "";
  let isValidTemplate = true;
  if (!(template?.name?.trim()?.length > 0)) {
    errorMessage = "Please fill out template name";
    isValidTemplate = false;
  }
  if (!template.body || !(template?.body?.trim()?.length > 10)) {
    errorMessage = "Please fill out template body";
    isValidTemplate = false;
  }
  if (
    !(template?.subject?.trim()?.length > 5) &&
    template?.contentType === TemplateContentType.OUTREACH
  ) {
    errorMessage = "Please fill out template subject";
    isValidTemplate = false;
  }
  return {
    isValidTemplate,
    errorMessage,
  };
};
