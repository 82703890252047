import { Grid, InputLabel } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { CustomEvent, Map, SetAlertType } from "schemas/functions";

import styles from "./styles";

interface FileUploadProps {
  id: string;
  buttonText?: string | JSX.Element;
  setFile: Dispatch<SetStateAction<any>>;
  limit?: number;
  setAlert?: SetAlertType;
  acceptType?: string;
  hasMultiple?: boolean;
  allowPaste?: boolean;
  customFilePickerStyles?: Map;
  [rest: string]: any;
}

const FileUpload = ({
  id,
  setFile,
  buttonText,
  limit,
  setAlert,
  acceptType,
  hasMultiple,
  customFilePickerStyles,
  allowPaste,
  rest,
}: FileUploadProps) => {
  const handlePaste = (event: ClipboardEvent) => {
    if (!event.clipboardData) return;
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file") {
        const blob = item.getAsFile();
        setFile(blob);
      }
    }
  };

  useEffect(() => {
    if (allowPaste) document.addEventListener("paste", handlePaste);
    return () => {
      if (allowPaste) document.removeEventListener("paste", handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowPaste]);

  return (
    <Grid>
      <InputLabel htmlFor={id} sx={customFilePickerStyles || styles.filePicker}>
        {buttonText || "UPLOAD"}
      </InputLabel>

      <input
        {...rest}
        id={id}
        style={{ visibility: "hidden" }}
        type="file"
        onChange={(event: CustomEvent) => {
          const files: File[] = event.target.files;
          const sizeOfAllFiles = Array.from(files).reduce(
            (acc, file) => acc + file.size,
            0,
          );
          if (limit && sizeOfAllFiles > limit && setAlert) {
            setAlert(
              `Oops! Your file is too large. Please upload a file smaller than ${
                limit / 1000000
              }MB.`,
              "error",
            );
            return;
          }
          if (!hasMultiple) {
            setFile(files[0]);
          } else {
            setFile(files);
          }
        }}
        accept={acceptType}
      />
    </Grid>
  );
};

export default FileUpload;
