import { Theme, lighten } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { VARIABLE_TAG_BACKGROUND } from "styles/oneOffColor";

const variableStyle = {
  borderRadius: "0.4rem",
  padding: "0.2rem 0.3rem",
  boxDecorationBreak: "clone",
  cursor: "pointer",
};

const styles = {
  emailEditContainer: { height: { xs: "100svh", lg: "auto" } },
  dialogTitleText: {
    backgroundColor: "yellow.light",
    borderBottom: `1px solid ${grey[300]}`,
  },
  wrapper: {
    overflow: "hidden",
    color: "green.dark",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  borderWrapper: {
    borderRadius: 2,
    borderColor: grey[300],
    borderWidth: "1px",
    borderStyle: "solid",
  },
  addAttachmentButton: {
    ml: 2,
    mb: 2,
    fontSize: { xs: 12, md: 14 },
    textTransform: { xs: "none", md: "uppercase" },
  },
  normalEditorWrapper: {
    backgroundColor: "white",
  },
  bubbleMenuBar: (inPortfolioEditor: boolean) => ({
    backgroundColor: inPortfolioEditor ? grey[100] : "white",
    width: inPortfolioEditor ? "575px" : "fit-content",
    maxWidth: "100%",
  }),
  menuBar: {
    overflow: "hidden",
  },
  button: {
    color: "green.dark",
    fontSize: 14,
    display: "inline-block",
    borderRadius: 0,
    fontFamily: "monospace",
    fontWeight: 800,
    "&:disabled": {
      color: grey[500],
    },
  },
  trashButton: {
    color: red[900],
  },
  active: (theme: Theme) => ({
    color: lighten(theme.palette.primary.main, 0.2),
  }),
  plainEditor: {
    ".ProseMirror": {
      minHeight: "0px",
    },
  },
  emailEditor: (isFromQuickSend?: boolean) => ({
    px: 2,
    py: 0,
    "&:focus": {
      outline: "none !important",
    },
    ".merge-tag": {
      color: grey[400],
    },
    ".variable[data-subtype='user-specific']": {
      color: "black",
      background: VARIABLE_TAG_BACKGROUND,
      ...variableStyle,
    },
    ".variable[data-subtype='email-specific']": {
      color: isFromQuickSend ? "black" : grey[400],
      background: isFromQuickSend ? VARIABLE_TAG_BACKGROUND : "none",
      ...variableStyle,
    },
    fontSize: 14,
    ".ProseMirror": {
      minHeight: "150px",
    },
  }),
  editor: {
    ".lt": {
      borderBottom: "2px solid #e86a69",
      transition: "background 0.25s ease-in-out",
      "&:hover": {
        background: "rgba($color: #e86a69, $alpha: 0.2)",
      },
      "&-style": {
        borderBottom: "2px solid #9d8eff",

        "&:hover": {
          background: "rgba($color: #9d8eff, $alpha: 0.2) !important",
        },
      },

      "&-typographical, &-grammar": {
        borderBottom: "2px solid #eeb55c",

        "&:hover": {
          background: "rgba($color: #eeb55c, $alpha: 0.2) !important",
        },
      },

      "&-misspelling": {
        borderBottom: "2px solid #e86a69",

        "&:hover": {
          background: "rgba($color: #e86a69, $alpha: 0.2) !important",
        },
      },
      "&-focused": {
        outline: "none !important",
      },
    },
    table: {
      borderCollapse: "collapse",
      margin: 0,
    },
    "table td": {
      minWidth: "1em",
      boxSizing: "border-box",
      padding: "0",
      position: "relative",
      verticalAlign: "top",
    },
    "table th": {
      minWidth: "1em",
      padding: "0",
      position: "relative",
      verticalAlign: "top",
    },
    "table th > *": {
      marginBottom: 0,
    },
    "table td > *": {
      marginBottom: 0,
    },
    "table .selectedCell:after": {
      background: "rgba(200, 200, 255, 0.4)",
      content: '""',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      pointerEvents: "none",
      position: "absolute",
      zIndex: 2,
    },
    "table .column-resize-handle": {
      backgroundColor: "#adf",
      bottom: "-2px",
      position: "absolute",
      right: "-2px",
      pointerEvents: "none",
      top: 0,
      width: "4px",
    },
    "table p": {
      margin: 0,
    },
    ".tableWrapper": {
      padding: "0",
      overflowX: "auto",
    },
    ".resize-cursor": {
      cursor: "col-resize",
    },
  },
  portfolioEditor: (theme: Theme) => ({
    px: 0,
    ".ProseMirror": {
      minHeight: "0px",
      minWidth: "100px",
      ...theme.typography,
      p: {
        ...theme.typography.body1,
      },
      "h1, h2, h3, p": {
        m: 0,
        mb: 1,
      },
    },
  }),
  attachmentContainer: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    ml: 2,
    mr: 8,
    mb: 3,
    color: "text.secondary",
  },
  attachmentName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  actionButton: {
    width: 75,
  },
  emailContainer: { p: 0, height: "100svh", width: { xs: "auto", md: 500 } },
  emailHeaderContainer: { p: 1 },
  fillVariable: {
    textTransform: "none",
    borderRight: `1px solid ${grey[200]}`,
    borderRadius: 0,
  },
};

export default styles;
