const styles = {
  select: {
    width: 300,
    ml: 2,
    mt: 2,
  },
  file: {
    ml: 1,
    mt: 2,
  },
  submit: {
    ml: 1,
    mt: 2,
  },
};

export default styles;
