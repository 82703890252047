import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
} from "react";

interface QuickSendOutreachTabsContextInterface {
  outreachTab: number;
  setOutreachTab: Dispatch<SetStateAction<number>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  outreachTab: 0,
  setOutreachTab: defaultContextMissingFunction,
};

const QuickSendOutreachTabsContext =
  createContext<QuickSendOutreachTabsContextInterface>(defaultInterface);

interface QuickSendOutreachTabsProviderProps {
  children: React.ReactNode;
}

const QuickSendOutreachTabsProvider = ({
  children,
}: QuickSendOutreachTabsProviderProps) => {
  const [outreachTab, setOutreachTab] = useState<number>(0);

  return (
    <QuickSendOutreachTabsContext.Provider
      value={{
        outreachTab,
        setOutreachTab,
      }}
    >
      {children}
    </QuickSendOutreachTabsContext.Provider>
  );
};

export { QuickSendOutreachTabsProvider, QuickSendOutreachTabsContext };
