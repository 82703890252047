import { Box, Button, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import DOMPurify from "dompurify";
import { useContext, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { generateColorFromPalette } from "utils/color";
import { trackEvent } from "utils/tracking";
import useTemplate from "utils/useTemplate";

import { previewTemplate, replaceMergeTag } from "../../helpers";
import { BentoTemplate } from "../../schema";
import styles from "./styles";

interface Props {
  template: BentoTemplate;
  index: number;
}

export default function TemplatePreview({ template, index }: Props) {
  const [preview, setPreview] = useState<boolean>(false);

  const { getTemplateName } = useTemplate();
  const { profile, currentUser } = useContext(OrganizationUserContext);

  const original = template?.body?.map((x) => x.text || `{${x.placeholder}}`);
  const originalText = original?.join("");

  let replacedWithPlaceholderExample = "";
  replacedWithPlaceholderExample = previewTemplate(template, profile);
  replacedWithPlaceholderExample = replaceMergeTag(
    replacedWithPlaceholderExample,
    currentUser?.name,
  );

  const templateName = getTemplateName(template, index);

  const handleView = (e: CustomEvent) => {
    trackEvent("Template Generation - Variables Tab Clicked", { template });
    e.stopPropagation();
    setPreview(false);
  };

  const handlePreviewExample = (e: CustomEvent) => {
    trackEvent("Template Generation - Example Tab Clicked", { template });
    e.stopPropagation();
    setPreview(true);
  };

  return (
    <>
      <Typography sx={{ mb: 1, fontSize: 16 }}>{templateName}</Typography>
      <Box sx={styles.templateContainer}>
        <Button
          sx={!preview ? styles.active : styles.inactive}
          onClick={handleView}
          size="small"
        >
          Template
        </Button>
        <Button
          sx={preview ? styles.active : styles.inactive}
          onClick={handlePreviewExample}
          size="small"
        >
          Example
        </Button>
        <Box
          component="div"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              preview ? replacedWithPlaceholderExample : originalText,
            ),
          }}
          sx={[
            styles.exampleBody,
            {
              backgroundColor: generateColorFromPalette(
                index,
                "templateBackgroundPalette",
              ),
            },
          ]}
          key={index}
        />
      </Box>
    </>
  );
}
