import { grey } from "@mui/material/colors";
import { HEART_COLOR } from "styles/oneOffColor";

const styles = {
  brandCard: {
    width: "100%",
    position: "relative",
    maxWidth: { xs: "auto", xl: 1200 },
    display: "block",
    margin: "auto",
  },
  savedHeart: {
    color: HEART_COLOR,
    fontSize: 16,
  },
  regularHeart: {
    fontSize: 16,
  },
  notifyLabel: {
    "> .MuiFormControlLabel-label": {
      color: grey[700],
      fontSize: 12,
    },
  },
  switch: {
    fontSize: 12,
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        color: "#fff",
        "& .MuiSwitch-thumb:before": {
          content: "'\\f0f3'",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "primary.main",
      fontFamily: "'Font Awesome 6 Pro'",
      "&::before": {
        content: "'\\f1f6'",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
  },
  quickSendButton: {
    textTransform: "none",
    color: "white",
    mx: 1,
  },
};

export default styles;
