import { Chip } from "@mui/material";
import { OutreachContactStatus } from "schemas/dashboard";
import { OutreachStatusLabel } from "schemas/outreach";

import { OutreachContactStatusPalette } from "utils/color";

import styles from "./styles";

interface Props {
  status: OutreachContactStatus;
  sx?: any;
}

const OutreachStatusChip = ({ status, sx }: Props) => {
  return (
    <Chip
      size="small"
      sx={[
        styles.chip,
        {
          backgroundColor:
            status in OutreachContactStatusPalette
              ? OutreachContactStatusPalette[status]
              : "primary.light",
        },
        sx,
      ]}
      label={OutreachStatusLabel[status]}
    />
  );
};
export default OutreachStatusChip;
