import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import React, {
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";

import AttachmentUpload from "components/Attachments/AttachmentUpload";
import Loading from "components/Loading";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import { uploadToGCP } from "./helper";
import styles from "./styles";

interface AttachmentManagerProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  enteredSelection: (attachmentUrl: string) => void;
  imageOnly?: boolean;
  anyMedia?: boolean;
}

export default function AttachmentManager({
  open,
  setOpen,
  enteredSelection,
  imageOnly = false,
  anyMedia = false,
}: AttachmentManagerProps) {
  const { setAlert } = useContext(AlertContext);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [selectedAttachment, setSelectedAttachment] = useState<
    string | undefined
  >();
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [xhrUploadRequest, setXhrUploadRequest] =
    useState<XMLHttpRequest | null>(null);

  const handleClose = () => {
    if (xhrUploadRequest) {
      xhrUploadRequest.abort();
    }
    setUploadLoading(false);
    setUploadProgress(0);
    setXhrUploadRequest(null);
    setOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    trackEvent("Attachment Tab Changed", { tab: newValue });
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const handleListAttachments = async () => {
      if (!currentOrg?.id) {
        return;
      }

      try {
        const jsonRes = await fetcherAuth(
          getToken,
          `/api/organization/${currentOrg?.id}/attachments`,
          "GET",
          {},
        );
        setAttachments(jsonRes.attachments);
      } catch (error) {
        setAlert(
          "Something went wrong. Please reload and retry again.",
          "error",
        );
      } finally {
        setLoading(false);
      }
    };
    if (open) {
      handleListAttachments();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, currentOrg?.id]);

  const handleEnter = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (currentTab === 1) {
      if (!selectedAttachment) {
        setAlert(
          "You need to either select at least one attachment",
          "warning",
        );
        return;
      }
      enteredSelection(selectedAttachment);
      handleClose();
      return;
    }

    if (!file) {
      setAlert("You need to upload a file", "warning");
      return;
    }

    try {
      setUploadLoading(true);
      setUploadProgress(0);
      const data = new FormData();
      data.append("file", file);
      let attachment;

      if (process.env.NODE_ENV === "development") {
        const res = await fetcherAuth(
          getToken,
          `/api/organization/${currentOrg?.id}/attachments`,
          "POST",
          {},
          data,
          false,
          true,
        );
        attachment = res.attachment;
      } else {
        const { signedUrl, filePath } = await fetcherAuth(
          getToken,
          `/api/organization/${currentOrg?.id}/attachments/signed-url`,
          "POST",
          {},
          {
            filename: file.name,
            contentType: file.type,
          },
        );
        const xhr = new XMLHttpRequest();
        setXhrUploadRequest(xhr);
        await uploadToGCP(xhr, file, signedUrl, (progress: number) => {
          setUploadProgress(progress);
        });

        const attachmentRes = await fetcherAuth(
          getToken,
          `/api/organization/${currentOrg?.id}/attachments/public`,
          "PUT",
          {},
          {
            filePath,
          },
        );
        attachment = attachmentRes.attachment;
      }
      if (attachment) {
        enteredSelection(attachment);
        setAttachments([attachment, ...attachments]);
      } else {
        setAlert("Something went wrong, please try again", "warning");
      }
      setFile(null);
      handleClose();
    } catch (error) {
      setAlert(error?.message, "warning");
    } finally {
      setUploadLoading(false);
    }
  };

  const handleSelectAttachment = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    attachment: string,
  ) => {
    setSelectedAttachment(attachment);
  };

  const getExtension = (attachment: string) => {
    return attachment.split(".")[attachment.split(".").length - 1];
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={(e: SyntheticEvent) => {
        e.stopPropagation();
        handleClose();
      }}
    >
      <DialogTitle>
        {imageOnly
          ? "Select Image"
          : anyMedia
            ? "Select Image or Video"
            : "Attachments"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="upload tabs"
          >
            <Tab
              label="Upload"
              id="attachment-manager-tab-0"
              aria-controls="attachment-manager-tab-0"
              sx={styles.tab}
            />
            <Tab
              label={
                imageOnly
                  ? "Previous Images"
                  : anyMedia
                    ? "Previous Media"
                    : "Previous Attachments"
              }
              id="attachment-manager-tab-0"
              aria-controls="attachment-manager-tab-0"
              sx={styles.tab}
            />
          </Tabs>
        </Box>
        {currentTab === 0 && (
          <>
            <AttachmentUpload
              file={file}
              setFile={setFile}
              setAlert={setAlert}
              buttonText={
                imageOnly
                  ? "New Image"
                  : anyMedia
                    ? "New Image or Video"
                    : "New Attachment"
              }
              imageOnly={imageOnly}
              anyMedia={anyMedia}
            />
          </>
        )}

        {currentTab === 1 && (
          <>
            {loading && <Loading backgroundColor="transparent" />}
            {!loading && (
              <List>
                {attachments.map((attachment) => {
                  if (anyMedia && getExtension(attachment) === "pdf")
                    return null;
                  if (
                    imageOnly &&
                    (getExtension(attachment) === "pdf" ||
                      getExtension(attachment) === "mp4" ||
                      getExtension(attachment) === "mov")
                  )
                    return null;
                  return (
                    <ListItem key={attachment} disablePadding>
                      <ListItemButton
                        selected={selectedAttachment === attachment}
                        onClick={(event) => {
                          handleSelectAttachment(event, attachment);
                        }}
                      >
                        <ListItemIcon>
                          {getExtension(attachment) === "pdf" ? (
                            <Box
                              component="i"
                              sx={styles.pdfIcon}
                              className="fa-regular fa-file-pdf fa-2x"
                            />
                          ) : getExtension(attachment) === "mp4" ||
                            getExtension(attachment) === "mov" ? (
                            <Box
                              component="i"
                              sx={styles.videoIcon}
                              className="fa-regular fa-file-video fa-2x"
                            />
                          ) : (
                            <img
                              src={attachment}
                              width={30}
                              alt="attachment preview"
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            attachment.split("/")[
                              attachment.split("/").length - 1
                            ]
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </>
        )}
        {uploadLoading && (
          <LinearProgress
            sx={{ mt: 2 }}
            variant="determinate"
            value={uploadProgress}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation();
            handleClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <LoadingButton onClick={handleEnter} loading={uploadLoading}>
          Enter
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
