const styles = {
  summary: {
    width: "100%",
    "& .MuiAccordionSummary-content": { width: "100%" },
    userSelect: "text",
  },
  summaryText: {
    width: "calc(100% - 50px)",
  },
  snippet: {
    textOverflow: "ellipsis",
    fontSize: "12px",
    mt: 0.5,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  attachmentHeader: {
    marginTop: 1,
  },
  attachment: { paddingLeft: 1, paddingBottom: 1 },
  attachmentIcon: { fontSize: 16, width: 25, height: 25, color: "green.dark" },
  emailActivityIcon: { display: "inline-block", ml: 1 },
};

export default styles;
