import { Dispatch, SetStateAction } from "react";

export const makeDeepCopy = (object: any) => {
  return JSON.parse(JSON.stringify(object));
};

export const updateList = (
  oldList: Array<any>,
  setList: (newList: Array<any>) => void,
  newItem: any,
  newItemKey = "id",
) => {
  /**
   * Given a list, update the list with a new item.
   *
   * @param oldList: The list to edit (e.g companyAssessments)
   * @param setList: The state update function for that list (e.g setCompanyAssessments)
   * @param newItem: A new item to replace / or add to the list (e.g a new CompanyAssessment)
   * @param newItemKey: The key of the new item to find its index in the list (usually "id")
   */
  if (!newItem || !(newItemKey in newItem)) {
    return;
  }
  const copy = makeDeepCopy(oldList);
  const updatedList = findAndUpdate(copy, newItem, newItemKey);
  setList(updatedList);
};

export const updateListProperty = (
  item: any,
  setItem: (newItem: any) => void,
  itemProperty: any,
  newListItem: any,
  newListItemKey = "id",
) => {
  /**
   * Given an object and its property, update its property with a new item.
   *
   * @param item: The object to edit (e.g CandidateAssessment)
   * @param setItem: The state update function for that item (e.g setCandidateAssessment)
   * @param itemProperty: A property of the object that must be a list (e.g automatedMarkingResponses)
   * @param newListItem: A new object to be add / edit in the list (e.g a new AutomatedMarkingResponse)
   * @param newListItemKey: The key of the newItem to find its index in the list (usually "id")
   */
  if (
    !newListItem ||
    !(newListItemKey in newListItem) ||
    !(itemProperty in item) ||
    !Array.isArray(item[itemProperty])
  ) {
    return;
  }
  const copy = makeDeepCopy(item);
  const updatedList = findAndUpdate(
    copy[itemProperty],
    newListItem,
    newListItemKey,
  );
  copy[itemProperty] = updatedList;
  setItem(copy);
};

export const removeFromListProperty = (
  item: any,
  setItem: (newItem: any) => void,
  itemProperty: any,
  itemToDelete: any,
  itemToDeleteKey = "id",
) => {
  /**
   * Given an object (e.g CandidateAssessment), and its property (e.g automatedMarkingResponses)
   * remove an item from its property.
   *
   * @param item: The object to edit (e.g CandidateAssessment)
   * @param setItem: The state update function for that item (e.g setCandidateAssessment)
   * @param itemProperty: A property of the object that must be a list (e.g automatedMarkingResponses)
   * @param itemToDelete: An object to be deleted in the list (e.g an AutomatedMarkingResponse)
   * @param itemToDeleteKey: The key of the delete item to find its index in the list (usually "id")
   */
  if (
    !(itemProperty in item) ||
    !Array.isArray(item[itemProperty]) ||
    !itemToDelete
  ) {
    return;
  }

  const copy = makeDeepCopy(item);
  const index = copy[itemProperty].findIndex((o: any) =>
    o ? o[itemToDeleteKey] === itemToDelete[itemToDeleteKey] : false,
  );

  if (index > -1) {
    copy[itemProperty].splice(index, 1);
    setItem(copy);
  }
};

export const findAndUpdate = (copy: any, newItem: any, key: string) => {
  /***
   * Helper function to find an item's index in a list.
   * If the item is found, update it with a new version.
   * Else, add it to the list.
   */
  const index = copy.findIndex((o: any) =>
    o ? o[key] === newItem[key] : false,
  );

  if (index !== -1) {
    copy[index] = newItem;
  } else {
    copy.push(newItem);
  }
  return copy;
};

export const removeFromList = (
  oldList: Array<any>,
  setList: Dispatch<SetStateAction<any>> | undefined,
  itemToDelete: any,
  key: string,
) => {
  if (!itemToDelete) {
    return;
  }
  const copy = makeDeepCopy(oldList);
  const index = copy.findIndex((o: any) =>
    o ? o[key] === itemToDelete[key] : false,
  );

  if (index !== -1 && setList) {
    copy.splice(index, 1);
    setList(copy);
  }
};

export const removeFromListById = (setList: any, id: string | number) =>
  setList((prev: Array<any>) => {
    return prev.filter((x) => x["id"] !== id);
  });
