import { Box, Grid, IconButton } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { SavedBrandContext } from "contexts/SavedBrand";
import { useContext } from "react";
import { BrandRecommendation } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { HEART_COLOR } from "styles/oneOffColor";

import CardTitle from "./CardTitle";
import MenuOptions from "./MenuOptions";
import styles from "./styles";

interface Props {
  recommendation: BrandRecommendation;
  showActions: boolean;
}

export default function CardActions({ recommendation, showActions }: Props) {
  // Contexts
  const { setAlert } = useContext(AlertContext);
  const { isBrandInList, handleAddDebounced, handleRemoveDebounced } =
    useContext(SavedBrandContext);

  const handleAddToOutreach = async (e: CustomEvent) => {
    e.stopPropagation();
    await handleAddDebounced(recommendation.bentoBrand);
  };

  return (
    <Grid container sx={{ py: 1.5, pl: 1.5, pr: 0.5 }} alignItems="center">
      <Grid item xs>
        <CardTitle
          showActions={showActions}
          bentoBrand={recommendation.bentoBrand}
        />
      </Grid>
      {showActions && (
        <>
          <Grid item xs="auto">
            {isBrandInList(recommendation.bentoBrand.id) ? (
              <IconButton
                sx={styles.heart}
                onClick={(e: CustomEvent) => {
                  e.stopPropagation();
                  handleRemoveDebounced(recommendation.bentoBrand);
                }}
              >
                <Box
                  component="i"
                  className="fa-solid fa-heart"
                  sx={{ color: HEART_COLOR }}
                />
              </IconButton>
            ) : (
              <IconButton sx={styles.heart} onClick={handleAddToOutreach}>
                <Box component="i" className="fa-regular fa-heart" />
              </IconButton>
            )}
          </Grid>

          <MenuOptions
            setAlert={setAlert}
            brandRecommendation={recommendation}
          />
        </>
      )}
    </Grid>
  );
}
