import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { TopRecommendationsContext } from "contexts/TopRecommendations";
import { useContext, useState } from "react";
import {
  BrandRecommendation,
  BrandRecommendationArchiveReason,
} from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { UNSPECIFIED } from "constants/influencer";
import { fetcherAuth } from "utils/api";
import { removeFromList } from "utils/updateLocalState";

interface Props {
  brandRecommendation: BrandRecommendation;
  setAlert: SetAlertType;
}

export default function MenuOptions({ brandRecommendation, setAlert }: Props) {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { topRecommendations, setTopRecommendations } = useContext(
    TopRecommendationsContext,
  );

  const { setErrorAlert } = useContext(AlertContext);

  const bentoBrandId = brandRecommendation?.bentoBrandId;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event?: CustomEvent) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleArchive = async (e: CustomEvent, archiveReason: string) => {
    e.stopPropagation();
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/brand-recommendations/${bentoBrandId}/archive`,
        "POST",
        {},
        {
          archiveReason,
        },
      );
      setAlert("Removed this brand from your list", "success");
      updateLocalState();
      handleClose();
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const reportBrokenLink = async () => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${bentoBrandId}/report-link`,
        "POST",
        {},
        { link: UNSPECIFIED },
      );
      setAlert("Report submitted.", "success");
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const updateLocalState = () => {
    removeFromList(
      topRecommendations,
      setTopRecommendations,
      brandRecommendation,
      "bentoBrandId",
    );
  };

  return (
    <>
      <IconButton
        sx={{
          fontSize: 12,
          color: "green.main",
          ml: 0.5,
          width: 20,
          height: 20,
        }}
        onClick={handleClick}
      >
        <Box component="i" className="fa-regular fa-ellipsis-vertical" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem
            onClick={(e: CustomEvent) =>
              handleArchive(
                e,
                BrandRecommendationArchiveReason.DO_NOT_RECOMMEND,
              )
            }
          >
            <ListItemIcon>
              <Box component="i" className="fa-regular fa-ban" />
            </ListItemIcon>
            <Typography variant="body2" color="text.secondary">
              Do not recommend
            </Typography>
          </MenuItem>
          <MenuItem onClick={reportBrokenLink}>
            <ListItemIcon>
              <Box component="i" className="fa-regular fa-flag" />
            </ListItemIcon>
            <Typography variant="body2" color="text.secondary">
              Report broken link
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
