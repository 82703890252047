import { Box, Grid, Typography } from "@mui/material";
import { BentoBrand } from "schemas/dashboard";

import { BrandDemographicFields } from "./helpers";
import styles from "./styles";

interface Props {
  bentoBrand: BentoBrand;
}

export default function BrandDemographics({ bentoBrand }: Props) {
  const renderField = (key: string) => {
    let value = bentoBrand[key as keyof BentoBrand];

    return value ? (
      <Grid item xs={12} key={key}>
        <Typography gutterBottom>
          <Box component="span" sx={styles.key}>
            {BrandDemographicFields[key]}:{" "}
          </Box>{" "}
          <Box component="span">{value?.toString()}</Box>
        </Typography>
      </Grid>
    ) : (
      <></>
    );
  };

  const hasDemographics =
    bentoBrand?.targetReferenceLinks &&
    bentoBrand?.targetReferenceLinks?.length > 0;

  return hasDemographics ? (
    <Box>
      <Typography variant="h6">Demographics</Typography>
      {Object.keys(BrandDemographicFields).map((key) => renderField(key))}
    </Box>
  ) : (
    <></>
  );
}
