import { useAuth } from "@clerk/clerk-react";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PortfolioSite } from "schemas/portfolioSite";

import SiteFavicon from "components/PortfolioSite/SiteFavicon";
import { fetcherAuth } from "utils/api";

import styles from "./styles";

export default function PortfolioSitesList() {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [portfolioSites, setPortfolioSites] = useState<PortfolioSite[]>([]);

  const fetchPortfolioSites = async () => {
    if (!currentOrg?.id) {
      return;
    }
    try {
      setLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/portfolio-sites`,
      );
      setPortfolioSites(res.sites);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const createNewSite = async () => {
    if (!currentOrg?.id) {
      return;
    }
    try {
      setLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/portfolio-sites`,
        "POST",
        {},
      );
      setPortfolioSites((prev) => [...prev, res.portfolioSite]);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPortfolioSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <Box sx={styles.root}>
      <Backdrop sx={styles.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Typography variant="h5" paragraph>
        Your Portfolio Sites
      </Typography>
      <Grid container gap={2}>
        {portfolioSites.map((site) => (
          <Grid item xs={12} key={site.id}>
            <Card>
              <CardContent>
                <Grid container columnSpacing={1} alignItems="center">
                  <Grid item xs="auto">
                    <SiteFavicon site={site} />
                  </Grid>
                  <Grid item xs="auto">
                    <Typography variant="h5">{site.title}</Typography>
                  </Grid>
                </Grid>

                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  {site.description}
                </Typography>
                <Typography paragraph color="textSecondary">
                  {site.subdomain}.onbento.com
                </Typography>
                <Button
                  onClick={() => {
                    navigate(`${site.id}/edit/settings`);
                  }}
                  variant="outlined"
                >
                  Edit
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            onClick={() => createNewSite()}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            Create New{" "}
            <Box component="i" className="fa-solid fa-plus" sx={{ ml: 1 }} />{" "}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
