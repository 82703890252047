import { grey } from "@mui/material/colors";

const styles = {
  header: {
    position: "sticky",
    zIndex: 2,
    backgroundColor: "primary.main",
    py: 0,
    px: 2,
  },
  desktopHeader: {
    borderBottom: `1px solid ${grey[200]}`,
    py: 1.5,
    px: 2,
  },
  headerButton: {
    color: "white",
    fontSize: 16,
  },
  mobileContainer: {
    maxHeight: "100vh",
    overflow: "hidden",
  },
  container: {
    maxHeight: "calc(100vh - 100px)",
    overflow: "auto",
    pb: { xs: 0, md: 20 },
  },
  active: {
    color: "primary.main",
  },
  close: {
    fontSize: 14,
  },
  tab: {
    width: "50%",
    textTransform: "none",
    fontSize: 12,
    color: grey[400],
  },
};

export default styles;
