import { Box, Divider, Menu, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ContactViewContext } from "contexts/ContactView";
import { TrackingColumnsContext } from "contexts/TrackingColumns";
import { TrackingColumnsFiltersContext } from "contexts/TrackingColumnsFilters";
import { TrackingColumnsSortContext } from "contexts/TrackingColumnsSort";
import { Dispatch, SetStateAction, useCallback, useContext } from "react";

import {
  COLUMN_NAME,
  HEADER_COLUMN_MENU_ITEM_SELECTED,
} from "features/Influencer/Tracking/events";
import {
  ContactViewProperty,
  PropertySortDirection,
} from "features/Influencer/Tracking/schema";

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  prop: ContactViewProperty;
  setOpenEditColumn: Dispatch<SetStateAction<boolean>>;
}

export default function HeaderMenu({
  anchorEl,
  open,
  handleClose,
  prop,
  setOpenEditColumn,
}: Props) {
  const { trackTable } = useContext(ContactViewContext);
  const { handleHideColumn } = useContext(TrackingColumnsContext);
  const { handleAddSort, sortableColumns } = useContext(
    TrackingColumnsSortContext,
  );
  const { setHighlightColumn, filterButtonRef, filterableColumns } = useContext(
    TrackingColumnsFiltersContext,
  );

  const id = prop?.id;
  const name = prop?.contactProperty?.name || prop?.contactPropertyName;

  const handleDelete = async () => {
    if (!id) return;
    trackTable(HEADER_COLUMN_MENU_ITEM_SELECTED, {
      [COLUMN_NAME]: name,
      "Menu Item": "Hide Column",
    });
    await handleHideColumn(id);
  };

  const handleEdit = () => {
    handleClose();
    setOpenEditColumn(true);
    trackTable(HEADER_COLUMN_MENU_ITEM_SELECTED, {
      [COLUMN_NAME]: name,
      "Menu Item": "Rename Column",
    });
  };

  const isNotSortable = !sortableColumns?.find((x) => x.id === prop.id);
  const clickFilterButton = useCallback(
    () => filterButtonRef.current?.click(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isNotFilterable = !filterableColumns?.find((x) => x.id === prop.id);

  const addFilter = () => {
    trackTable(HEADER_COLUMN_MENU_ITEM_SELECTED, {
      [COLUMN_NAME]: name,
      "Menu Item": "Filter",
    });
    setHighlightColumn(prop);
    clickFilterButton();
  };

  const sortAsc = () => {
    trackTable(HEADER_COLUMN_MENU_ITEM_SELECTED, {
      [COLUMN_NAME]: name,
      "Menu Item": "Sort A -> Z",
    });
    handleAddSort(PropertySortDirection.asc, prop);
  };

  const sortDesc = () => {
    trackTable(HEADER_COLUMN_MENU_ITEM_SELECTED, {
      [COLUMN_NAME]: name,
      "Menu Item": "Sort Z -> A",
    });
    handleAddSort(PropertySortDirection.desc, prop);
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuList dense sx={{ outline: "none" }}>
        <MenuItem
          onClick={handleDelete}
          disabled={prop?.contactPropertyName === "email"}
        >
          <Box sx={{ mr: 1 }} component="i" className="fa-thin fa-eye-slash" />
          Hide Column
        </MenuItem>
        <Tooltip
          title={
            prop?.contactPropertyId
              ? ""
              : "You cannot edit name for this column because it is an automated column by Bento"
          }
        >
          <div>
            <MenuItem onClick={handleEdit} disabled={!prop?.contactPropertyId}>
              <Box sx={{ mr: 1 }} component="i" className="fa-thin fa-edit" />
              Rename column
            </MenuItem>
          </div>
        </Tooltip>
        <Divider />
        <MenuItem disabled={isNotSortable} onClick={sortAsc}>
          <Box
            sx={{ mr: 1 }}
            component="i"
            className="fa-thin fa-arrow-up-short-wide"
          />{" "}
          Sort A - Z
        </MenuItem>
        <MenuItem disabled={isNotSortable} onClick={sortDesc}>
          <Box
            sx={{ mr: 1 }}
            component="i"
            className="fa-thin fa-arrow-down-short-wide"
          />{" "}
          Sort Z - A
        </MenuItem>
        <Divider />
        <Tooltip
          title={
            isNotFilterable
              ? "You cannot filter by this column. Columns must be either select or checkbox, and must have at least 1 option."
              : ""
          }
        >
          <div>
            <MenuItem onClick={addFilter} disabled={isNotFilterable}>
              <Box sx={{ mr: 1 }} component="i" className="fa-thin fa-filter" />{" "}
              Filter
            </MenuItem>
          </div>
        </Tooltip>
      </MenuList>
    </Menu>
  );
}
