import { DAYS_TO_DELAY_FOLLOWUP } from "constants/templates";
import { addBusinessDays } from "utils/time";

export const ReplyScoreLabel = (score: number) => {
  if (score < 60) {
    return "This email has a high chance of bouncing";
  } else if (score < 70) {
    return "This email has a low chance of bouncing";
  } else if (score < 88) {
    return "This contact has responded to creators within the last 90 days";
  } else if (score < 99) {
    return "This contact is used for partnerships/collaborations with creators";
  } else {
    return "This contact has responded with a positive intent within the last 90 days";
  }
};

export const getFollowUpEmailScheduledTime = (index: number) => {
  const x = addBusinessDays(DAYS_TO_DELAY_FOLLOWUP * index);
  return x.format("MMM DD");
};
