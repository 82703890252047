import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { UserNotifications } from "schemas/dashboard";

export const BOTH = "both";
export const OPT_IN_EMAIL = "optInEmail";
export const OPT_IN_PHONE = "optInPhone";

export const getRadioOption = (userNotifications: UserNotifications | null) => {
  if (!userNotifications) {
    return OPT_IN_EMAIL;
  }
  if (userNotifications?.optInEmail && userNotifications?.optInPhone) {
    return BOTH;
  } else if (userNotifications?.optInPhone) {
    return OPT_IN_PHONE;
  } else {
    return OPT_IN_EMAIL;
  }
};

export const setNotificationsFromRadioOption = (
  value: string,
  setUserNotifications: Dispatch<SetStateAction<UserNotifications | null>>,
) => {
  if (value === "both") {
    setUserNotifications({
      optInEmail: true,
      optInPhone: true,
    });
  } else if (value === "optInEmail") {
    setUserNotifications({
      optInEmail: true,
      optInPhone: false,
    });
  } else {
    setUserNotifications({
      optInEmail: false,
      optInPhone: true,
    });
  }
};

interface Props {
  option: string;
  handleChange: any;
}

export const RadioOptionForm = ({ option, handleChange }: Props) => {
  return (
    <FormControl fullWidth>
      <RadioGroup value={option} onChange={handleChange}>
        <FormControlLabel
          value={OPT_IN_EMAIL}
          control={<Radio />}
          label="Email"
        />
        <FormControlLabel
          value={OPT_IN_PHONE}
          control={<Radio />}
          label="Text Message"
        />
        <FormControlLabel
          value={BOTH}
          control={<Radio />}
          label="Email and Text Message"
        />
      </RadioGroup>
    </FormControl>
  );
};
