import { Box, ClickAwayListener, Paper, Popper } from "@mui/material";
import React, { ForwardedRef, forwardRef } from "react";

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: (e: Event | React.SyntheticEvent) => void;
  open: boolean;
  message?: string;
}

const NotEditableMenu = forwardRef(
  (
    { anchorEl, handleClose, open, message }: Props,
    ref: ForwardedRef<HTMLDivElement> | null | undefined,
  ) => {
    if (!open) return <></>;
    return (
      <Popper
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        sx={{ zIndex: 2 }}
        placement="left-end"
      >
        <Paper sx={{ fontSize: 12, p: 1, background: "white" }} elevation={2}>
          <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClose}>
            <Box>
              {message ||
                "You cannot edit this field. It is automated by Bento."}
            </Box>
          </ClickAwayListener>
        </Paper>
      </Popper>
    );
  },
);
export default NotEditableMenu;
