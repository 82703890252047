import { useMediaQuery, useTheme } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";

interface DiscoverViewContextInterface {
  tabWidth: number;
  discoverTab: DiscoverTabView | null;
  setDiscoverTab: (tab: DiscoverTabView | null) => void;
  lowerWidth: boolean;
  displayedBrandInfo: boolean;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  tabWidth: 350,
  discoverTab: null,
  setDiscoverTab: defaultContextMissingFunction,
  lowerWidth: false,
  displayedBrandInfo: true,
};

export enum DiscoverTabView {
  CHAT = "Chat",
  SAVED_BRANDS = "Saved Brands",
  TEMPLATES = "Templates",
}

const DiscoverViewContext =
  createContext<DiscoverViewContextInterface>(defaultInterface);

interface DiscoverViewProviderProps {
  children: React.ReactNode;
}

const DiscoverViewProvider = ({ children }: DiscoverViewProviderProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const tabWidth = 350;

  const [discoverTab, setDiscoverTabView] = useState<DiscoverTabView | null>(
    null,
  );

  const isMobileScreenWithTab = useMediaQuery("(max-width:1400px)");
  const lowerWidth = discoverTab !== null && isMobileScreenWithTab;
  const displayedBrandInfo = useMediaQuery(
    discoverTab !== null ? "(min-width:1200px)" : "(min-width:900px)",
  );

  // Discover tab will act like a toggle, except when opening AI chat
  const setDiscoverTab = (tab: DiscoverTabView | null) => {
    if (tab === null || (discoverTab === tab && tab !== DiscoverTabView.CHAT)) {
      setDiscoverTabView(null);
    } else {
      setDiscoverTabView(tab);
    }
  };

  useEffect(() => {
    if (isMobileScreen) {
      setDiscoverTabView(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileScreen]);

  return (
    <DiscoverViewContext.Provider
      value={{
        discoverTab,
        setDiscoverTab,
        tabWidth,
        lowerWidth,
        displayedBrandInfo,
      }}
    >
      {children}
    </DiscoverViewContext.Provider>
  );
};

export { DiscoverViewProvider, DiscoverViewContext };
