import { grey } from "@mui/material/colors";

import { drawerStyles } from "components/Drawer/reusableDrawerStyles";

const styles = {
  ...drawerStyles,
  progressContainer: {
    width: "100%",
    pt: 4,
  },
  dialogTitleText: {
    backgroundColor: "yellow.light",
    borderBottom: `1px solid ${grey[300]}`,
  },
  dialogActions: {
    borderTop: `1px solid ${grey[300]}`,
  },
  brandName: {
    width: { xs: 100, md: 300 },
    textAlign: "center",
  },
  dialogContent: {
    my: 0,
    mx: 0,
    px: 0,
    py: 0,
  },
  scheduledAtContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "primary.main",
    pl: 2,
    pb: 1,
    pt: 1,
    alignItems: "center",
    fontSize: { xs: "11px", sm: "14px" },
    color: "white",
  },
  summary: {
    width: "100%",
    "& .MuiAccordionSummary-content": { width: "100%" },
  },
  summaryText: {
    width: "calc(100% - 50px)",
  },
  snippet: {
    textOverflow: "ellipsis",
    fontSize: "12px",
    mt: 0.5,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  subjectWrapper: {
    background: "white",
    maxWidth: "100%",
    borderBottom: `1px solid ${grey[300]}`,
  },
  subject: { pb: 2, pt: 1, px: 1, fontWeight: 700 },
};

export default styles;
