import { useAuth } from "@clerk/clerk-react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DiscoverTabView, DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SubscriptionContext } from "contexts/Subscription";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { routes } from "schemas/routes";
import { barWidth } from "styles/theme";

import Alert from "components/Alert";
import Loading from "components/Loading";
import PermanentDrawer from "components/PermanentDrawer";
import HomeSideBar from "features/Home/Sidebar";
import ChatHelper from "features/Home/Sidebar/ChatHelper";
import TemplateList from "features/Home/Sidebar/TemplateList";
import QuickSendIndividualDrawer from "features/Influencer/ContactList/QuickSendIndividualDrawer";
import BrandBar from "features/Influencer/Discover/BrandBar";
import DetailedBrandView from "features/Influencer/Discover/DetailedBrandView";
import QuickSendEmailBar from "features/Influencer/Discover/EmailBar";
import CreateTemplate from "features/Influencer/Templates/TemplatesTable/CreateTemplate";
import { fetcherAuth } from "utils/api";
import { FEEDBACK_GIVEN } from "utils/localStorage";
import { identifyUser, trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useAlert } from "utils/useAlert";
import { usePageSize } from "utils/usePageSize";

import FavoritesList from "./Sidebar/FavoritesList";
import TopNavigation from "./TopNavigation";
import { UpgradeDialog } from "./UpgradeDialog";
import { NotInterested } from "./UpgradeDialog/NotInterested";
import styles from "./styles";

export default function Home() {
  const [message, severity, setAlert, closeAlert] = useAlert();
  const { getToken, isSignedIn } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const { isLargeRecommendedPage } = usePageSize();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  // Contexts
  const {
    currentUser,
    currentOrg,
    isInfluencer,
    setCurrentOrg,
    setCurrentUser,
    setUserIntegrations,
    setUserNotifications,
    setProfile,
    setEmailSettings,
    setHideCityDialog,
  } = useContext(OrganizationUserContext);
  const { brandDetailsId } = useContext(QuickSendDrawerContext);
  const { createTemplateDialogOpen } = useContext(OutreachTemplatesContext);
  const {
    subscription,
    setSubscription,
    upgradeDialogSource,
    setUpgradeDialogSource,
  } = useContext(SubscriptionContext);
  const {
    emailHealth,
    fetchIntegrationLoading,
    fetchIntegrationHealth,
    setOpenIntegrationDialog,
  } = useContext(UserIntegrationsContext);
  const { discoverTab, tabWidth, setDiscoverTab } =
    useContext(DiscoverViewContext);
  const hasAlreadyGivenFeedback =
    localStorage.getItem(`${FEEDBACK_GIVEN}-${currentOrg?.id}`) === "true";

  const [fetchUserLoading, setFetchUserLoading] = useState<boolean>(true);
  const [notInterestedOpen, setNotInterestedOpen] = useState(false);

  const fetchUser = async () => {
    setFetchUserLoading(true);

    if (currentUser && currentOrg?.id) {
      const subscribeQuery = searchParams.get("subscribe");
      if (subscribeQuery) {
        trackEvent("Visited Page with Subscribe Dialog", {
          params: subscribeQuery,
        });
        setUpgradeDialogSource("Link Query");
      }

      await fetchIntegrationHealth(currentOrg.id);
      setFetchUserLoading(false);
      return;
    }

    try {
      const res = await fetcherAuth(getToken, `/api/organization/auth/user`);
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setSubscription(res.subscription);
      setUserIntegrations(res.userIntegrations);
      setUserNotifications(res.userNotifications);
      setEmailSettings(res.emailSetting);

      const hasOnboarded = Boolean(res?.organizationProfile?.hasOnboarded);

      if (!hasOnboarded) {
        navigate(`/${routes.onboardingInfluencer}${location.search}`);
        setHideCityDialog(true);
        return;
      } else if (res.organizationProfile) {
        const copy = makeDeepCopy(res.organizationProfile);
        setProfile(copy);
      }

      const subscribeQuery = searchParams.get("subscribe");
      if (subscribeQuery) {
        trackEvent("Visited Page with Subscribe Dialog", {
          params: subscribeQuery,
        });
        setUpgradeDialogSource("Link Query");
      }

      await fetchIntegrationHealth(res.organization.id);
    } catch (error) {
      // the user might not be created, go to onboarding
      navigate(`/${routes.onboardingInfluencer}${location.search}`);
    } finally {
      setFetchUserLoading(false);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchUser();
    } else {
      setFetchUserLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  useEffect(() => {
    const checkoutInfo = searchParams.get("checkout");
    if (checkoutInfo === "success") {
      setAlert(
        <>
          Thank you for subscribing to Bento. You can visit our settings page to
          manage your subscription!
        </>,
        "success",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (!fetchIntegrationLoading && !emailHealth) {
      checkIfUserHasSentEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailHealth, fetchIntegrationLoading, currentOrg?.id]);

  const header = !isMobileScreen && !isInfluencer && (
    <Grid container sx={styles.pageHeader}>
      <IconButton
        onClick={() => navigate(`${routes.opportunities}`)}
        sx={styles.edit}
      >
        <Box component="i" className="fa-regular fa-gear" />
      </IconButton>
      {currentUser && currentUser?.profileImageUrl && (
        <Avatar src={currentUser?.profileImageUrl} sx={styles.userImage} />
      )}

      <Grid item sx={{ ml: 2 }}>
        <Typography>{currentUser?.name}</Typography>
        {!isInfluencer && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5)", fontSize: 12 }}>
            {currentOrg?.name}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  const handleCloseUpgradeDialog = () => {
    if (
      !hasAlreadyGivenFeedback &&
      subscription &&
      subscription?.remainingMessages <= 0
    ) {
      setNotInterestedOpen(true);
    }
    setUpgradeDialogSource("");
  };

  const handleCloseNotInterestedDialog = () => {
    setUpgradeDialogSource("");
    setNotInterestedOpen(false);
    searchParams.delete("checkout");
    setSearchParams(searchParams);
  };

  const checkIfUserHasSentEmail = async () => {
    if (!currentOrg?.id) {
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/has-sent-emails`,
      );
      if (res.hasSentEmails) {
        setOpenIntegrationDialog(true);
      }
    } catch (error) {
      setAlert(
        error?.message || "Something went wrong. Please reload and try again",
        "error",
      );
    }
  };

  const getContent = () => {
    if (discoverTab === DiscoverTabView.SAVED_BRANDS) {
      return <FavoritesList closeDialog={() => setDiscoverTab(null)} />;
    } else if (discoverTab === DiscoverTabView.TEMPLATES) {
      return <TemplateList />;
    } else if (discoverTab === DiscoverTabView.CHAT) {
      return <ChatHelper />;
    } else {
      return <></>;
    }
  };

  return fetchUserLoading ? (
    <Loading />
  ) : (
    <Grid>
      {location.pathname.includes("portfolio-sites") &&
      location.pathname.includes("edit/design") ? (
        <Outlet />
      ) : (
        <>
          <TopNavigation />
          <Grid sx={styles.body}>
            {header}
            <Box
              sx={[
                styles.wrapper,
                {
                  mr: {
                    xs: 1,
                    md:
                      discoverTab !== null
                        ? `${tabWidth + barWidth}px`
                        : `${barWidth}px`,
                  },
                },
              ]}
            >
              <Outlet />
            </Box>
            {!isMobileScreen && <HomeSideBar />}
          </Grid>
          {isMobileScreen && (
            <FavoritesList closeDialog={() => setDiscoverTab(null)} />
          )}

          {/* Recommendation page has their own display of QuickSend drawer and brand details view */}
          {!isLargeRecommendedPage && (
            <>
              {!isMobileScreen && <BrandBar />}
              {!isMobileScreen && <QuickSendEmailBar />}
              <QuickSendIndividualDrawer />
              {brandDetailsId > -1 && <DetailedBrandView />}
            </>
          )}

          {!isMobileScreen && (
            <PermanentDrawer
              open={discoverTab !== null}
              drawerWidth={tabWidth}
              content={getContent()}
            />
          )}
          {createTemplateDialogOpen && <CreateTemplate />}
        </>
      )}

      <UpgradeDialog
        open={upgradeDialogSource !== ""}
        onClose={handleCloseUpgradeDialog}
        setAlert={setAlert}
      />

      <NotInterested
        open={notInterestedOpen || searchParams.get("checkout") === "failed"}
        close={handleCloseNotInterestedDialog}
        setAlert={setAlert}
      />

      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </Grid>
  );
}
