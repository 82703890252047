import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmailAddress, EmailDraft } from "schemas/email";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";

import Drawer from "components/Drawer";
import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";
import RecipientAutocomplete from "components/EmailThread/EmailDraft/RecipientAutocomplete";
import { fetcherAuth } from "utils/api";
import { isInvalidEmail } from "utils/string";
import { trackEvent } from "utils/tracking";

import AttachmentManager from "../AttachmentManager";
import { TemplateContentType } from "../schema";
import styles from "./styles";

const ComposeEmailDrawer = () => {
  const { setAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const { currentSidebarOption, setFetchContactsParams } = useContext(
    OutreachContactsContext,
  );

  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [emailDraft, setEmailDraft] = useState<EmailDraft>({
    toContacts: [],
    ccContacts: [],
    body: "",
    subject: "",
  });
  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);
  const [bodyError, setBodyError] = useState<boolean>(false);

  // Contexts
  const { currentOrg } = useContext(OrganizationUserContext);
  const { emailHealth, setOpenIntegrationDialog, fetchIntegrationHealth } =
    useContext(UserIntegrationsContext);

  const handleClose = () => {
    navigate(`/${routes.inbox}`);
  };

  const handleCcContactsChange = (newContacts: EmailAddress[]) => {
    trackEvent(`Compose Email ccContacts Input Changed`, {
      emailDraft,
    });
    setEmailDraft((prev) => {
      return { ...prev, ccContacts: newContacts };
    });
  };

  const handleToContactsChange = (newContacts: EmailAddress[]) => {
    trackEvent(`Compose Email toContacts Input Changed`, {
      emailDraft,
    });
    setEmailDraft((prev) => {
      return { ...prev, toContacts: newContacts };
    });
  };

  const handleEditSubject = (event: CustomEvent) => {
    const { value } = event.target;
    setEmailDraft((prev) => {
      return { ...prev, subject: value };
    });
  };

  const isBodyEmpty = (body: string) => {
    return body.trim() === "" || body.replace(/<p>\s*<\/p>/g, "") === "";
  };

  const editor = useEditor(
    {
      extensions: extensions({
        placeholderText: "Write the email body here...",
      }),
      content: emailDraft.body,
      onUpdate({ editor }) {
        const body = editor.isEmpty ? "" : editor.getHTML();
        setEmailDraft((prev) => {
          return { ...prev, body };
        });
        if (!isBodyEmpty(body)) {
          setBodyError(false);
        }
      },
    },
    [],
  ) as TipTapEditor;

  const validateSend = () => {
    if (isBodyEmpty(emailDraft.body)) {
      setBodyError(true);
      return true;
    }

    for (const contact of emailDraft.ccContacts) {
      if (isInvalidEmail(contact.email)) {
        setAlert(
          `"${contact.email}" in your "cc" is not a properly formatted email. Please make sure it is properly formatted before sending`,
          "error",
        );
        return true;
      }
    }
    for (const contact of emailDraft.toContacts) {
      if (isInvalidEmail(contact.email)) {
        setAlert(
          `"${contact.email}" in your "to" is not a properly formatted email. Please make sure it is properly formatted before sending`,
          "error",
        );
        return true;
      }
    }
    return false;
  };

  const handleSendEmail = async () => {
    if (!currentOrg?.id) {
      return;
    }

    if (validateSend()) {
      return;
    }

    if (!emailHealth) {
      setOpenIntegrationDialog(true);
      return;
    }

    try {
      setSendLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails`,
        "POST",
        {},
        {
          toContacts: emailDraft.toContacts,
          ccContacts: emailDraft.ccContacts,
          emails: [
            {
              subject: emailDraft.subject,
              body: emailDraft.body,
              contentType: TemplateContentType.INBOX,
              attachment: emailDraft.attachment,
            },
          ],
        },
      );
      setAlert(
        "Successfully scheduled email! It will be sent shortly and can be viewed in your sent folder",
        "success",
      );
      handleClose();
      if (
        currentSidebarOption?.key === "sent" ||
        currentSidebarOption?.key === "scheduled"
      ) {
        setFetchContactsParams((prev) => {
          return {
            ...prev,
            page: 1,
          };
        });
      }
    } catch (error) {
      if (!emailHealth) {
        setOpenIntegrationDialog(true);
      } else {
        setAlert(
          error?.message ||
            "Something went wrong. Unable to send outreach email. Please reload and retry again.",
          "error",
        );
      }
    } finally {
      setSendLoading(false);
    }
  };

  useEffect(() => {
    if (emailHealth) {
      setOpenIntegrationDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailHealth]);

  useEffect(() => {
    if (currentOrg?.id) {
      fetchIntegrationHealth(currentOrg.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <Drawer open={true} onClose={handleClose}>
      <DialogTitle sx={styles.dialogTitleText}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Button
            color="secondary"
            onClick={handleClose}
            sx={styles.actionButton}
            size="small"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={sendLoading}
            variant="contained"
            onClick={handleSendEmail}
            disableElevation
            disabled={emailDraft.toContacts.length === 0}
            size="small"
          >
            Send
            <Box
              component="i"
              className="fa-regular fa-paper-plane"
              sx={{ ml: 1 }}
            />
          </LoadingButton>
        </Grid>
      </DialogTitle>

      <DialogContent sx={styles.dialogContent}>
        <Grid
          item
          xs={12}
          container
          sx={{ py: 0.5 }}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs="auto">
            <Typography
              component="div"
              sx={{ width: 25 }}
              variant="caption"
              color="textSecondary"
            >
              to:
            </Typography>
          </Grid>
          <Grid item xs>
            <RecipientAutocomplete
              value={emailDraft.toContacts}
              updateValue={handleToContactsChange}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ py: 0.5 }}
          container
          spacing={1}
          alignItems="center"
        >
          <Grid item xs="auto">
            <Typography
              component="div"
              sx={{ width: 25 }}
              variant="caption"
              color="textSecondary"
            >
              cc:
            </Typography>
          </Grid>
          <Grid item xs>
            <RecipientAutocomplete
              value={emailDraft.ccContacts}
              updateValue={handleCcContactsChange}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ py: 0.5 }}
          container
          spacing={1}
          alignItems="center"
        >
          <Grid item xs="auto">
            <Typography
              component="div"
              sx={{ width: 50 }}
              variant="caption"
              color="textSecondary"
            >
              Subject:
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              size="small"
              value={emailDraft.subject}
              onChange={handleEditSubject}
              fullWidth
              variant="outlined"
              placeholder="Subject"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }}>
          {bodyError && (
            <Typography variant="body2" sx={styles.errorText}>
              Please fill in the body of this email
            </Typography>
          )}
          <Box
            sx={[
              { position: "relative " },
              bodyError ? styles.errorContent : {},
            ]}
          >
            <Editor
              editor={editor}
              allowAttachment={true}
              openAttachmentManager={() => {
                setAttachmentDialogOpen(true);
              }}
              attachment={emailDraft?.attachment}
              removeAttachment={() => {
                setEmailDraft((prev) => ({
                  ...prev,
                  attachment: "",
                }));
              }}
              allowImage={true}
            />
          </Box>
        </Grid>
        <AttachmentManager
          enteredSelection={(attachment: string) => {
            setEmailDraft((prev) => ({
              ...prev,
              attachment,
            }));
          }}
          open={attachmentDialogOpen}
          setOpen={setAttachmentDialogOpen}
        />
      </DialogContent>
    </Drawer>
  );
};

export default ComposeEmailDrawer;
