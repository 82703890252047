import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";

import { fetcherAuth } from "utils/api";

import styles from "./styles";

export default function MergeExisting() {
  const navigate = useNavigate();

  const { contactUploadId } = useParams();

  const { setErrorAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [updateExisting, setUpdateExisting] = useState<boolean>(true);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { selectedView } = useContext(ContactViewContext);

  const handleBack = () => {
    navigate(
      `/${routes.trackingView}/${selectedView?.id}/${routes.pitchTrackerImport}/csv/${contactUploadId}/match`,
    );
  };

  const updateUpload = async () => {
    if (!currentOrg?.id) {
      return;
    }

    setNextLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-uploads/${contactUploadId}`,
        "PATCH",
        {},
        { updateExisting },
      );
      navigate(
        `/${routes.trackingView}/${selectedView?.id}/${routes.pitchTrackerImport}/csv/${res.contactUpload.id}/summary`,
      );
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setNextLoading(false);
    }
  };

  const fetchContactUpload = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-uploads/${contactUploadId}`,
        "GET",
        {},
      );
      setUpdateExisting(res.contactUpload.updateExisting);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: CustomEvent) => {
    const { value } = event.target;

    setUpdateExisting(value);
  };

  useEffect(() => {
    if (currentOrg?.id) {
      fetchContactUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, contactUploadId]);

  return (
    <>
      <DialogTitle>Merging existing contacts</DialogTitle>
      <Backdrop sx={styles.backdrop} open={loading} appear={false}>
        <CircularProgress />
      </Backdrop>
      <DialogContent>
        <Box sx={{ my: 3 }}>
          <Typography component="div" gutterBottom>
            For contacts already in Bento, would you like to combine their
            information with what's currently in Bento, or should we skip these
            contacts during the upload?
          </Typography>
          <FormControl>
            <FormLabel id="update-existing"></FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="emailTrackingEnabled"
              row
              aria-labelledby="update-existing"
              value={updateExisting}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Merge"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Skip"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleBack}>
          Back
        </Button>
        <LoadingButton
          onClick={updateUpload}
          loading={nextLoading}
          variant="contained"
          disableElevation
        >
          Next
        </LoadingButton>
      </DialogActions>
    </>
  );
}
