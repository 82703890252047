import { SubscriptionType } from "./dashboard";

export enum Plans {
  FREE = "free",
  STARTER = "starter",
  PRO = "pro",
}

export const SubscriptionMessages: { [key: string]: string } = {
  [SubscriptionType.CANCELED]:
    "Your subscription has been cancelled. Please update your subscription to continue being able to use Bento.",
  [SubscriptionType.PAUSED]:
    "Your subscription has been paused. Please update your subscription to continue being able to use Bento.",
  [SubscriptionType.INCOMPLETE_EXPIRED]:
    "Your payment was incomplete when subscribing to Bento. Please subscribe to continue using Bento.",
  [SubscriptionType.INCOMPLETE]:
    "Your payment was incomplete when subscribing to Bento. Please subscribe to continue using Bento.",
  [SubscriptionType.PAST_DUE]:
    "Your latest payment for the subscription failed. Please update your subscription to continue being able to use Bento.",
  [SubscriptionType.UNPAID]:
    "Your latest payment for the subscription failed. Please update your subscription to continue being able to use Bento.",
};
