import { grey } from "@mui/material/colors";

const styles = {
  root: {
    marginTop: 2,
    justifyContent: "center",
    display: "flex",
    gap: 2,
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: "80px",
  },
  pdfIcon: {
    padding: "5px",
    color: "#F86F67",
  },
  videoIcon: {
    padding: "5px",
    color: grey[700],
  },
  text: {
    textTransform: "uppercase",
    color: grey[600],
    fontSize: 12,
  },
  placeholder: {
    background: grey[200],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: grey[600],
    height: "80px",
  },
  caption: {
    width: "70px",
    color: "text.secondary",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export default styles;
