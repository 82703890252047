import { LoadingButton } from "@mui/lab";
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Accordion as MuiAccordion,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { GmailThread, OutreachContact } from "schemas/dashboard";
import { EmailAddress, EmailDraft, EmailThreadSetting } from "schemas/email";
import { CustomEvent } from "schemas/functions";

import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";
import AttachmentManager from "features/Influencer/ContactList/AttachmentManager";
import { generateColorFromString } from "utils/color";
import { sanitize } from "utils/sanitize";
import { trackEvent } from "utils/tracking";

import RecipientAutocomplete from "./RecipientAutocomplete";
import styles from "./styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

interface EmailDraftComponentProps {
  from: EmailAddress;
  gmailThread: GmailThread;
  emailDraft: EmailDraft;
  setEmailDraft: React.Dispatch<SetStateAction<EmailDraft>>;
  handleSendDraftEmail: () => void;
  cancelDraft: () => void;
  sendLoading: boolean;
  emailThreadSetting: EmailThreadSetting;
  setEmailThreadSetting: React.Dispatch<SetStateAction<EmailThreadSetting>>;
  displaySubjectEditor?: boolean;
  currentContact: OutreachContact;
  displayFollowUp?: boolean;
}

export default function EmailDraftComponent({
  emailDraft,
  from,
  gmailThread,
  setEmailDraft,
  handleSendDraftEmail,
  cancelDraft,
  sendLoading,
  emailThreadSetting,
  setEmailThreadSetting,
  displaySubjectEditor,
  currentContact,
  displayFollowUp,
}: EmailDraftComponentProps) {
  const { getLatestFollowUpEmail } = useContext(OutreachTemplatesContext);
  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);
  const body = sanitize(emailDraft?.body || "");

  const handleGetBody = async () => {
    if (emailDraft?.body && emailDraft?.body?.length > 0) return;
    const followUpBody = await getLatestFollowUpEmail(currentContact);
    setEmailDraft((prev) => {
      return { ...prev, body: followUpBody };
    });
  };

  const editor = useEditor(
    {
      extensions: extensions(),
      content: body,
      onUpdate({ editor }) {
        setEmailDraft((prev) => {
          return { ...prev, isChanged: true, body: editor.getHTML() };
        });
      },
    },
    [!!emailDraft.body],
  ) as TipTapEditor;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackEvent("Disable Follow Up Tasks Checkbox Checked", { emailDraft });
    setEmailThreadSetting({
      ...emailThreadSetting,
      disableFollowUpTasks: event.target.checked,
    });
  };

  const handleSubjectChange = (e: CustomEvent) => {
    trackEvent(`Inbox Subject Changed`, {
      "Gmail Thread ID": gmailThread?.id,
      emailDraft,
    });
    setEmailDraft((prev) => {
      return { ...prev, subject: e.target.value };
    });
  };

  const handleCcContactsChange = (newContacts: EmailAddress[]) => {
    trackEvent(`Outreach Contact Thread ccContacts Input Changed`, {
      "Gmail Thread ID": gmailThread?.id,
      emailDraft,
    });
    setEmailDraft((prev) => {
      return { ...prev, ccContacts: newContacts };
    });
  };

  const handleToContactsChange = (newContacts: EmailAddress[]) => {
    trackEvent(`Outreach Contact Thread toContacts Input Changed`, {
      "Gmail Thread ID": gmailThread?.id,
      emailDraft,
    });
    setEmailDraft((prev) => {
      return { ...prev, toContacts: newContacts };
    });
  };

  const allParticipants = [
    ...gmailThread.messages[gmailThread.messages.length - 1]?.cc,
    gmailThread.messages[gmailThread.messages.length - 1]?.from_,
    ...gmailThread.messages[gmailThread.messages.length - 1]?.bcc,
    ...gmailThread.messages[gmailThread.messages.length - 1]?.to,
  ];

  useEffect(() => {
    if (displayFollowUp) {
      handleGetBody();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayFollowUp]);

  return (
    <Accordion
      disableGutters
      square
      expanded={true}
      elevation={0}
      sx={{
        width: "100%",
        borderTop: "none",
        borderBottom: "none",
      }}
    >
      <AccordionSummary sx={styles.summary}>
        <Grid container item xs={12}>
          {from.email && (
            <Grid item xs="auto">
              <Avatar
                sx={{
                  background: generateColorFromString(from.email),
                  textTransform: "uppercase",
                  mr: 1,
                }}
              >
                {from.name?.charAt(0) || from.email.charAt(0)}
              </Avatar>
            </Grid>
          )}
          <Grid item container xs sx={styles.summaryText}>
            <Grid item xs={12}>
              {(from?.name || from?.email) && (
                <Typography>
                  {from.name || from.email}{" "}
                  <Typography
                    component="span"
                    color="textSecondary"
                    variant="caption"
                    sx={{ ml: 1 }}
                  >
                    now
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ py: 0.5 }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs="auto">
                <Typography
                  component="div"
                  sx={{ width: 25 }}
                  variant="caption"
                  color="textSecondary"
                >
                  to:
                </Typography>
              </Grid>
              <Grid item xs>
                <RecipientAutocomplete
                  options={allParticipants}
                  value={emailDraft.toContacts}
                  updateValue={handleToContactsChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ py: 0.5 }}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item xs="auto">
                <Typography
                  component="div"
                  sx={{ width: 25 }}
                  variant="caption"
                  color="textSecondary"
                >
                  cc:
                </Typography>
              </Grid>
              <Grid item xs>
                <RecipientAutocomplete
                  options={allParticipants}
                  value={emailDraft.ccContacts}
                  updateValue={handleCcContactsChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {displaySubjectEditor && (
          <TextField
            label="Subject"
            fullWidth
            size="small"
            placeholder="Type your email subject here..."
            sx={{ my: 2 }}
            value={emailDraft?.subject}
            onChange={handleSubjectChange}
          />
        )}
        <Editor
          editor={editor}
          allowAttachment={true}
          openAttachmentManager={() => {
            setAttachmentDialogOpen(true);
          }}
          attachment={emailDraft.attachment}
          removeAttachment={() =>
            setEmailDraft((prev) => {
              return { ...prev, attachment: undefined };
            })
          }
          allowImage={true}
        />
        <Grid container sx={{ mt: 1, ml: 1 }} alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  edge="start"
                  onChange={handleCheckboxChange}
                  checked={emailThreadSetting.disableFollowUpTasks}
                  disableRipple
                  icon={
                    <Box
                      component="i"
                      className="fa-regular fa-square"
                      sx={{ color: "green.main" }}
                    />
                  }
                  checkedIcon={
                    <Box
                      component="i"
                      className="fa-regular fa-square-check"
                      sx={{ color: "green.main" }}
                    />
                  }
                />
              }
              sx={styles.checkbox}
              label="Don't Remind Me About This Thread"
            />
          </Grid>
          <Grid container justifyContent="flex-end" item xs={12}>
            <Button
              color="secondary"
              sx={{ mr: 1, my: { xs: 1, md: 0 } }}
              disableElevation
              variant="outlined"
              onClick={cancelDraft}
            >
              Cancel
            </Button>
            <LoadingButton
              disableElevation
              sx={{
                width: 75,
                my: { xs: 1, md: 0 },
              }}
              variant="contained"
              onClick={handleSendDraftEmail}
              loading={sendLoading}
              disabled={
                emailDraft.body?.length < 10 ||
                (displaySubjectEditor && emailDraft.subject?.trim()?.length < 5)
              }
            >
              Send{" "}
              <Box
                component="i"
                className="fa-regular fa-paper-plane"
                sx={{ ml: 1 }}
              />
            </LoadingButton>
          </Grid>
        </Grid>
        <AttachmentManager
          enteredSelection={(attachment: string) =>
            setEmailDraft((prev) => {
              return { ...prev, attachment };
            })
          }
          open={attachmentDialogOpen}
          setOpen={setAttachmentDialogOpen}
        />
      </AccordionDetails>
    </Accordion>
  );
}
