import { Backdrop, CircularProgress } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { PAGE_VISITED } from "constants/trackingProps";
import OutreachContactDrawer from "features/Influencer/ContactList/OutreachContactDrawer";
import ImportDialog from "features/Influencer/Tracking/ImportDialog";
import { trackEvent } from "utils/tracking";

import DraggableTable from "./DraggableTable";

const ContactView = () => {
  const { selectedView } = useContext(ContactViewContext);
  const { viewLoading, setContactViewParams } = useContext(
    ContactViewSearchContext,
  );

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!viewLoading) {
      setContactViewParams((prev) => ({ ...prev, page: 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {viewLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={viewLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      {selectedView?.name && <DraggableTable />}
      <Routes>
        <Route
          path=":outreachContactId/*"
          element={<OutreachContactDrawer />}
        />
        <Route path="pitch-tracker/import/*" element={<ImportDialog />} />
      </Routes>
    </>
  );
};

export default ContactView;
