import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";
import { PaletteColor, PaletteType } from "schemas/portfolioSite";

import { convertToColor } from "components/PortfolioSite/helpers";

export type ColorOptions = {
  types: string[];
  palette?: { [key in PaletteType]: PaletteColor };
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    color: {
      setColor: (color: string) => ReturnType;
      unsetColor: () => ReturnType;
    };
  }
}

/**
 * This is a customization of the following extension:
 * @see https://tiptap.dev/api/extensions/color
 */
export const Color = Extension.create<ColorOptions>({
  name: "color",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          colorId: {
            default: null,
            parseHTML: (element) => element.getAttribute("color-id"),
            renderHTML: (attributes) => {
              if (!attributes.colorId) {
                return {};
              }

              return {
                "color-id": attributes.colorId,
                style: `color: ${convertToColor(
                  attributes.colorId,
                  this.options.palette,
                )}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setColor:
        (color: string | PaletteType) =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", {
              colorId: color,
            })
            .run();
        },
      unsetColor:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { colorId: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
