import { Box, Button, Chip, Divider, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendOutreachTabsContext } from "contexts/QuickSendOutreachTabs";
import { useContext } from "react";
import { CustomEvent } from "schemas/functions";

import { MAXIMUM_FOLLOWUP_ALLOWED } from "constants/templates";
import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

export default function SelectOutreach() {
  const { outreachTab, setOutreachTab } = useContext(
    QuickSendOutreachTabsContext,
  );
  const { outreachMessages, previewLoading } = useContext(QuickSendContext);
  const {
    setTemplateForQuickSend,
    bentoBrand,
    handleFetchPreviews,
    bentoContact,
  } = useContext(QuickSendContext);

  const followups = outreachMessages.slice(1, outreachMessages?.length);
  const { followupTemplates } = useContext(OutreachTemplatesContext);

  const handleAddMoreFollowup = () => {
    if (previewLoading !== -1) return;
    if (followups?.length === MAXIMUM_FOLLOWUP_ALLOWED) return;
    trackEvent("Add Another Follow Up Button Clicked");

    setTemplateForQuickSend((prev) => {
      const copy = makeDeepCopy(prev);
      if (followupTemplates?.length === 0) {
        copy.push({
          name: "Followup Default",
          body: "",
          subject: "Followup Default",
          contentType: TemplateContentType.FOLLOWUP,
        });
      } else {
        copy.push(followupTemplates[0]);
      }
      if (bentoBrand) {
        handleFetchPreviews(bentoBrand.id, bentoContact, copy);
      }
      return copy;
    });
  };

  const handleRemoveFollowUp = (index: number) => {
    if (previewLoading !== -1) return;
    trackEvent("Remove Follow Up Button Clicked", {
      "Follow Up Position": index,
    });
    setTemplateForQuickSend((prev: Template[]) => {
      const copy = makeDeepCopy(prev);
      copy.splice(index, 1);
      if (bentoBrand) {
        handleFetchPreviews(bentoBrand.id, bentoContact, copy);
      }
      return copy;
    });
  };

  const switchToOutreach = (e: CustomEvent) => {
    if (previewLoading !== -1) return;
    e.preventDefault();
    setOutreachTab(0);
  };

  const switchToFollowUp = (e: CustomEvent, index: number) => {
    if (previewLoading !== -1) return;
    e.preventDefault();
    setOutreachTab(index);
  };

  return (
    <>
      <Grid container alignItems="end">
        <Chip
          label="Outreach"
          size="small"
          sx={outreachTab === 0 ? styles.active : styles.inactive}
          onClick={switchToOutreach}
        />

        {followups?.map((x, index) => (
          <Chip
            key={index}
            label={`Follow-Up ${index + 1}`}
            size="small"
            sx={outreachTab === index + 1 ? styles.active : styles.inactive}
            onClick={(e) => switchToFollowUp(e, index + 1)}
            onDelete={() => handleRemoveFollowUp(index + 1)}
            deleteIcon={<Box component="i" className="fa-thin fa-xmark" />}
          />
        ))}

        {followups?.length < MAXIMUM_FOLLOWUP_ALLOWED && (
          <Button
            size="small"
            sx={styles.addButton}
            onClick={handleAddMoreFollowup}
          >
            {followups?.length > 0 ? "Add + " : "Add Follow-Up +"}
          </Button>
        )}
      </Grid>
      <Divider sx={{ mt: -0.1, backgroundColor: grey[200] }} />
    </>
  );
}
