import {
  Box,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { UserIntegrationsProvider } from "contexts/UserIntegrations";
import { useContext, useEffect } from "react";

import StyledPage from "components/Styled/Page";
import { PAGE_VISITED } from "constants/trackingProps";
import Subscription from "features/Home/UpgradeDialog/Subscription";
import { trackEvent } from "utils/tracking";

import EmailTracking from "./EmailTracking";
import GoogleConnect from "./GoogleConnect";
import Referral from "./Referral";
import UserNotificationsSettings from "./UserNotifications";
import styles from "./styles";

export default function Settings() {
  const { setAlert } = useContext(AlertContext);
  const theme = useTheme();
  const { discoverTab } = useContext(DiscoverViewContext);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen =
    isMediumScreen || (isLargeScreen && discoverTab !== null);

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage
      content={
        <Grid>
          {!isMobileScreen && (
            <Typography variant="h3" gutterBottom sx={styles.header}>
              <strong>Settings</strong>
            </Typography>
          )}

          <Referral setAlert={setAlert} />

          <Divider sx={{ my: 4 }} />

          <Typography variant="h4" paragraph>
            Subscription
          </Typography>
          <Subscription setAlert={setAlert} />

          <Divider sx={{ my: 4 }} />

          <Typography variant="h4" gutterBottom>
            Integrations
          </Typography>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Google
              </Typography>
              <Typography variant="body2">
                Connect your Gmail to Bento
              </Typography>
              <Typography variant="body2">
                You can revoke your access at any time.{" "}
                <Link
                  href="https://support.google.com/accounts/answer/3466521?hl=en"
                  target="_blank"
                >
                  Manage your Google access.
                </Link>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <UserIntegrationsProvider>
                <GoogleConnect setAlert={setAlert} />
              </UserIntegrationsProvider>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>

          <Grid item xs={12}>
            <UserNotificationsSettings />
          </Grid>

          <Divider sx={{ my: 4 }} />

          <Typography variant="h4" gutterBottom>
            Email Tracking
          </Typography>
          <Grid item xs={12}>
            <EmailTracking />
          </Grid>
          <Box sx={{ height: 50 }} />
        </Grid>
      }
    />
  );
}
