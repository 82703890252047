import { grey } from "@mui/material/colors";

const styles = {
  root: {
    background: "white",
    maxWidth: "100%",
  },
  expandWrapper: {
    position: "relative",
    width: "100%",
    zIndex: 10,
    height: 40,
    background: "white",
  },
  expandDividerOne: {
    pt: "20px",
  },
  expandDividerTwo: {
    pt: "4px",
  },
  expandIcon: {
    position: "absolute",
    left: 16,
    p: 1.5,
    width: "42px",
    height: "42px",
    fontSize: "14px",
    border: `1px solid ${grey[500]}`,
    background: "white",
    color: grey[700],
    "&:hover, &:focus": {
      backgroundColor: grey[100],
    },
  },
};

export default styles;
