const column = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: { xs: "100%", md: "100svh" },
  minHeight: { lg: 800 },
};

const styles = {
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "yellow.main",
    overflowY: "auto",
  },
  image: {
    width: "min(80vh, 40vw)",
    minWidth: 400,
    maxWidth: 600,
  },
  maxWidthContainer: {
    display: "block",
    margin: "auto",
  },
  header: {
    fontSize: { xs: 20, sm: 30, md: 32 },
  },
  subtitle: {
    fontWeight: 300,
    fontSize: { xs: 12, sm: 14, md: 18 },
    mb: 3,
  },
  collageContainer: {
    ...column,
    pl: 22,
  },
  signUpContainer: {
    ...column,
  },
  footer: {
    position: { xs: "relative", md: "absolute" },
    pt: { xs: 2, md: 0 },
    pb: 4,
    pr: { xs: 0, md: 4 },
    bottom: 0,
    right: 0,
    textAlign: "right",
    m: { xs: "0 auto", md: "auto" },
  },
  poweredByContainer: {
    display: "flex",
    gap: 0.5,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  poweredByText: {
    fontSize: 12,
    color: "green.main",
  },
  topBarLogo: {
    width: 60,
    position: "absolute",
    top: "24px",
    cursor: "pointer",
    left: "24px",
  },
  poweredByLogo: {
    width: 60,
    margin: "auto",
    cursor: "pointer",
  },
};

export default styles;
