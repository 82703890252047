import { MatchColumn, PropertyType } from "schemas/dashboard";

const getColumnFromPreview = (columnIndex: number, preview: string[][]) => {
  const res = [];
  for (const row of preview) {
    res.push(row[columnIndex]); // first index is the column header
  }
  return res;
};

const isUrl = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

export const guessPropertyType = (
  matchColumn: MatchColumn,
  preview: string[][],
): PropertyType => {
  const column = getColumnFromPreview(matchColumn.column_index, preview);

  if (column.length === 0) {
    return PropertyType.shortText;
  }

  const header = column[0].toLowerCase();
  const values = column
    .slice(1)
    .filter(Boolean)
    .map((v) => v.toLowerCase().trim());

  if (header.includes("attachment")) {
    return PropertyType.attachment;
  }

  const isCheckbox = values.every((value) =>
    ["true", "false", "yes", "no", "0", "1"].includes(value.toLowerCase()),
  );
  if (isCheckbox) {
    return PropertyType.checkbox;
  }

  const allNumbers = values.every((value) => !isNaN(Number(value)));
  if (allNumbers) {
    return PropertyType.number;
  }

  const isAttachment = values.every((value) => isUrl(value));
  if (isAttachment) {
    return PropertyType.attachment;
  }

  if (values.some((value) => value.includes(","))) {
    const multipleSelectValues = values.reduce(
      (acc: string[], value: string) => {
        return acc.concat(value.split(","));
      },
      [],
    );
    const hasDuplicate =
      new Set(multipleSelectValues).size !== multipleSelectValues.length;

    if (hasDuplicate) {
      return PropertyType.multipleSelect;
    }
  }

  const uniqueValues = new Set(values);
  const numberOfRows = values.length;
  if (uniqueValues.size < numberOfRows) {
    return PropertyType.singleSelect;
  }

  const maxLength = Math.max(...values.map((value) => value.length));
  if (maxLength > 80) {
    return PropertyType.longText;
  }

  return PropertyType.shortText;
};
