import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BuiltInContactPropertyName,
  ContactProperty,
  MatchColumn,
  PropertyNameLabel,
} from "schemas/dashboard";
import { routes } from "schemas/routes";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import EditColumnDialog from "./EditColumnDialog";
import styles from "./styles";

export default function MatchColumns() {
  const navigate = useNavigate();
  const { contactUploadId } = useParams();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const { setErrorAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [matchColumns, setMatchColumns] = useState<MatchColumn[]>([]);
  const [preview, setPreview] = useState<string[][]>([]);
  const [contactProperties, setContactProperties] = useState<ContactProperty[]>(
    [],
  );
  const [selectedMatchColumnIndex, setSelectedMatchColumnIndex] = useState<
    number | null
  >(null);
  const [total, setTotal] = useState<number>();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { selectedView } = useContext(ContactViewContext);

  const handleBack = () => {
    if (!selectedView) return;
    navigate(
      `/${routes.trackingView}/${selectedView.id}/${routes.pitchTrackerImport}/csv`,
    );
  };

  const fetchInitialData = async () => {
    await Promise.all([fetchPreview(), fetchProperties()]);
    setLoading(false);
  };

  const fetchPreview = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-uploads/${contactUploadId}/preview`,
        "GET",
        {},
      );
      setMatchColumns(res.matchColumns);
      setPreview(res.preview);
      setTotal(res.total);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const fetchProperties = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-properties`,
        "GET",
        {},
      );
      setContactProperties(res.contactProperties);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  useEffect(() => {
    if (currentOrg?.id) {
      fetchInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, contactUploadId]);

  const checkScrollable = () => {
    if (tableContainerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } =
        tableContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    const tableContainerCurrentElement = tableContainerRef.current;
    if (tableContainerCurrentElement) {
      tableContainerCurrentElement.addEventListener("scroll", checkScrollable);
    }
    return () => {
      window.removeEventListener("resize", checkScrollable);
      if (tableContainerCurrentElement) {
        tableContainerCurrentElement.removeEventListener(
          "scroll",
          checkScrollable,
        );
      }
    };
  }, []);

  const handleScroll = (direction: string) => {
    if (tableContainerRef.current) {
      const { scrollLeft, clientWidth } = tableContainerRef.current;
      const newScrollPosition =
        direction === "left"
          ? scrollLeft - clientWidth
          : scrollLeft + clientWidth;
      tableContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
    setTimeout(() => {
      checkScrollable();
    }, 750);
  };

  const handleMatchColumns = async () => {
    if (!currentOrg?.id) {
      return;
    }

    setNextLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-uploads/${contactUploadId}/match`,
        "POST",
        {},
        { matchColumns },
      );
      navigate(
        `/${routes.trackingView}/${selectedView?.id}/${routes.pitchTrackerImport}/csv/${res.contactUpload.id}/existing`,
      );
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setNextLoading(false);
    }
  };

  const getName = (matchColumn: MatchColumn) => {
    if (matchColumn.contact_property_id) {
      const contactProperty = contactProperties.find(
        (property) => property.id === matchColumn.contact_property_id,
      );
      if (contactProperty && contactProperty.name) {
        return contactProperty.name;
      }
    }
    if (matchColumn.contact_property_name) {
      return PropertyNameLabel[matchColumn.contact_property_name];
    }
    return preview[0]?.[matchColumn.column_index] || "";
  };

  const editSkipColumn = (matchColumn: MatchColumn, checked: boolean) => {
    trackEvent("Upload Pitch Tracker CSV Skip Column Checkbox Clicked", {
      columnIndex: matchColumn.column_index,
      colName: getName(matchColumn),
      matchColumn,
      checked: !checked,
    });
    setMatchColumns((prev) => {
      return prev.map((match) => {
        if (match.column_index === matchColumn.column_index) {
          match.skip_column = !checked;
        }
        return match;
      });
    });
  };

  const openEditColumnDialog = (matchColumn: MatchColumn) => {
    setSelectedMatchColumnIndex(matchColumn.column_index);
  };

  const numberOfMatches = matchColumns.reduce(
    (acc: number, curr: MatchColumn) =>
      !curr.skip_column &&
      (curr.contact_property_id || curr.contact_property_name)
        ? acc + 1
        : acc,
    0,
  );

  const numberOfErrors = matchColumns.reduce(
    (acc: number, curr: MatchColumn) =>
      !curr.skip_column &&
      !curr.contact_property_id &&
      !curr.contact_property_name
        ? acc + 1
        : acc,
    0,
  );

  const emailColumnMatched = matchColumns.find(
    (matchColumn) =>
      matchColumn.contact_property_name === BuiltInContactPropertyName.email &&
      !matchColumn.skip_column,
  );

  return (
    <>
      <DialogTitle>Match Column Labels</DialogTitle>
      <Backdrop sx={styles.backdrop} open={loading} appear={false}>
        <CircularProgress />
      </Backdrop>
      <DialogContent>
        <Typography sx={{ pl: 5 }} color="textSecondary" paragraph>
          <strong>{total}</strong> contacts were recognized.
        </Typography>
        <Grid container sx={{ mb: 2 }}>
          <Grid container item xs={12}>
            <Grid
              item
              xs="auto"
              onClick={() => handleScroll("left")}
              sx={[styles.arrowGrid, canScrollLeft && styles.arrowVisible]}
            >
              <IconButton sx={[!canScrollLeft && styles.hideIcon]}>
                <Box
                  component="i"
                  sx={styles.arrow}
                  className="fa-solid fa-arrow-left"
                />
              </IconButton>
            </Grid>
            <Grid item xs sx={styles.tableGridContainer}>
              <TableContainer
                ref={tableContainerRef}
                sx={styles.tableContainer}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={styles.row}>
                      {matchColumns.map((matchColumn) => {
                        const errorColumn =
                          !matchColumn.skip_column &&
                          !matchColumn.contact_property_id &&
                          !matchColumn.contact_property_name;
                        return (
                          <Tooltip
                            key={matchColumn.column_index}
                            placement="top"
                            arrow
                            title={
                              errorColumn &&
                              "Edit the column name to resolve this error"
                            }
                          >
                            <TableCell
                              sx={[
                                styles.headerCell,
                                errorColumn && styles.errorHeaderCell,
                                matchColumn.skip_column && styles.skippedCell,
                              ]}
                            >
                              <Grid
                                container
                                columnSpacing={1}
                                alignItems="center"
                              >
                                <Grid item xs>
                                  <Button
                                    sx={styles.headerButton}
                                    onClick={() =>
                                      openEditColumnDialog(matchColumn)
                                    }
                                    fullWidth
                                  >
                                    <Box
                                      sx={[
                                        styles.headerIcon,
                                        errorColumn
                                          ? styles.errorColor
                                          : styles.successColor,
                                      ]}
                                      component="i"
                                      className="fa-solid fa-edit"
                                    />
                                    <Typography
                                      component="span"
                                      sx={[
                                        errorColumn
                                          ? styles.errorColor
                                          : styles.successColor,
                                      ]}
                                    >
                                      {getName(matchColumn)}
                                    </Typography>
                                  </Button>
                                </Grid>
                                <Grid item xs="auto">
                                  {!errorColumn && (
                                    <Checkbox
                                      edge="start"
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                        checked,
                                      ) => {
                                        editSkipColumn(matchColumn, checked);
                                      }}
                                      checked={!matchColumn.skip_column}
                                      disableRipple
                                      icon={
                                        <Box
                                          component="i"
                                          className="fa-regular fa-square"
                                          sx={styles.successColor}
                                        />
                                      }
                                      checkedIcon={
                                        <Box
                                          component="i"
                                          className="fa-solid fa-square-check"
                                          sx={styles.successColor}
                                        />
                                      }
                                    />
                                  )}
                                  {errorColumn && (
                                    <Box
                                      sx={[
                                        styles.headerIcon,
                                        errorColumn
                                          ? styles.errorColor
                                          : styles.successColor,
                                      ]}
                                      component="i"
                                      className="fa-regular fa-triangle-exclamation"
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </Tooltip>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preview.map((row, rowIdx) => {
                      if (rowIdx === 0) {
                        return null;
                      }

                      return (
                        <TableRow sx={styles.row} key={rowIdx}>
                          {row.map((value, columnIdx) => {
                            const matchColumn = matchColumns.find(
                              (matchColumn) =>
                                matchColumn.column_index === columnIdx,
                            );
                            const errorColumn =
                              !matchColumn ||
                              (!matchColumn.contact_property_id &&
                                !matchColumn.contact_property_name);
                            return (
                              <TableCell
                                sx={[
                                  styles.cell,
                                  errorColumn && styles.errorCell,
                                  !!matchColumn?.skip_column &&
                                    styles.skippedCell,
                                ]}
                                key={columnIdx}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              item
              xs="auto"
              onClick={() => handleScroll("right")}
              sx={[styles.arrowGrid, canScrollRight && styles.arrowVisible]}
            >
              <IconButton sx={[!canScrollRight && styles.hideIcon]}>
                <Box
                  component="i"
                  sx={styles.arrow}
                  className="fa-solid fa-arrow-right"
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ pl: 5 }}>
          {!emailColumnMatched && (
            <Typography sx={styles.mustMatchEmail} paragraph>
              <strong>You must match an email address column to import.</strong>
            </Typography>
          )}
          {emailColumnMatched && (
            <Typography color="textSecondary" paragraph>
              <strong>{numberOfMatches}</strong> column
              {numberOfMatches !== 1 ? "s" : ""} will be imported.{" "}
              {numberOfErrors > 0 && (
                <>
                  <strong>
                    {numberOfErrors} column{numberOfErrors !== 1 ? "s" : ""}{" "}
                    still need to be matched.
                  </strong>{" "}
                  Please match them before continuing.
                </>
              )}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleBack}>
          Back
        </Button>
        <LoadingButton
          onClick={handleMatchColumns}
          loading={nextLoading}
          variant="contained"
          disableElevation
          disabled={numberOfErrors > 0 || !emailColumnMatched}
        >
          Next
        </LoadingButton>
      </DialogActions>

      <EditColumnDialog
        selectedMatchColumnIndex={selectedMatchColumnIndex}
        setSelectedMatchColumnIndex={setSelectedMatchColumnIndex}
        matchColumns={matchColumns}
        setMatchColumns={setMatchColumns}
        preview={preview}
        setContactProperties={setContactProperties}
        contactProperties={contactProperties}
      />
    </>
  );
}
