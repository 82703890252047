import { Box, Grid, Typography } from "@mui/material";
import { forwardRef, useImperativeHandle } from "react";
import { CustomEvent } from "schemas/functions";

import { emailThreadStyles as styles } from "./styles";

interface Props {
  onClick: (e?: CustomEvent) => void;
  lastGmailThreadLength?: number;
  lastGmailThreadParticipants?: string;
}

const TableEmailThread = forwardRef(
  (
    { onClick, lastGmailThreadParticipants, lastGmailThreadLength }: Props,
    ref,
  ) => {
    useImperativeHandle(ref, () => {
      return {
        childRefKeyPressed(e: KeyboardEvent) {
          if (e.key === "Enter") {
            onClick(e);
          }
        },
      };
    });

    const renderEmailThreadParticipant = () => {
      if (lastGmailThreadParticipants && lastGmailThreadLength) {
        return (
          <Typography noWrap sx={styles.linkText}>
            {lastGmailThreadParticipants}, {lastGmailThreadLength}
          </Typography>
        );
      } else {
        return <Typography sx={styles.linkText}>Open</Typography>;
      }
    };

    return (
      <Grid
        wrap="nowrap"
        container
        alignItems="center"
        onClick={onClick}
        sx={styles.container}
      >
        {renderEmailThreadParticipant()}{" "}
        <Box
          sx={{ ml: 1 }}
          component="i"
          className="fa-regular fa-arrow-up-right-from-square"
        />
      </Grid>
    );
  },
);

export default TableEmailThread;
