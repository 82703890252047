import { useEffect } from "react";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.open(`https://www.onbento.com/privacy-policy`, "_self");
  }, []);
  return <></>;
};

export default PrivacyPolicyPage;
