import { useAuth } from "@clerk/clerk-react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext, useMemo } from "react";
import { OutreachContact, OutreachContactStatus } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";
import { OutreachStatusLabel, OutreachStatusOptions } from "schemas/outreach";

import OutreachStatusChip from "components/OutreachStatusChip";
import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface Props {
  contact: OutreachContact;
  setAlert: SetAlertType;
}

const SelectStatus = ({ contact, setAlert }: Props) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const {
    contacts,
    setContacts,
    currentContact,
    setCurrentContact,
    fetchContact,
  } = useContext(OutreachContactsContext);

  const index = useMemo(() => {
    return contacts.findIndex((c: OutreachContact) => c.id === contact.id);
  }, [contact.id, contacts]);

  const handleChange = (e: CustomEvent) => {
    e.stopPropagation();
    const newStatus = e.target.value;
    handleChangeStatus(newStatus);
    // update state optimistically
    if (index >= 0) {
      setContacts((prev) => {
        const copy = makeDeepCopy(prev);
        copy[index].status = newStatus;
        return copy;
      });
    }
    if (currentContact?.id === contact.id) {
      setCurrentContact((prev) => {
        const copy = makeDeepCopy(prev);
        copy.status = newStatus;
        return copy;
      });
    }
  };

  const handleChangeStatus = async (newStatus: OutreachContactStatus) => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${contact?.id}`,
        "PATCH",
        {},
        {
          status: newStatus,
        },
      );
    } catch (error) {
      setAlert(
        "Unable to update status. Please reload and retry again",
        "error",
      );
      resetState();
    }
  };

  const resetState = () => {
    if (contact.id) {
      fetchContact(contact.id);
    }
  };

  return (
    <FormControl sx={{ width: "max-content" }} size="small">
      <Select
        value={contact?.status}
        onClick={(event: CustomEvent) => event.stopPropagation()}
        onChange={handleChange}
        label=""
        sx={styles.noBorder}
        renderValue={(selected: OutreachContactStatus) => (
          <Box key={selected}>
            <OutreachStatusChip status={selected} />
          </Box>
        )}
      >
        {OutreachStatusOptions.map((option: OutreachContactStatus) => (
          <MenuItem key={option} value={option}>
            {OutreachStatusLabel[option]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default SelectStatus;
