import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { TasksContext } from "contexts/Tasks";
import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Task, TaskSort, TaskStatus } from "schemas/dashboard";

import { PAGE_VISITED } from "constants/trackingProps";
import OutreachContactDrawer from "features/Influencer/ContactList/OutreachContactDrawer";
import { fetcherAuth } from "utils/api";
import { convertToIsoTime } from "utils/time";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy, updateList } from "utils/updateLocalState";

import SingleTask from "../SingleTask";
import { AddReminderDialog } from "./AddReminderDialog";
import styles from "./styles";

export default function AllTasks() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { setAlert, setErrorAlert } = useContext(AlertContext);

  // Contexts
  const { currentOrg } = useContext(OrganizationUserContext);
  const { tasks, setTasks, taskLoading, fetchTasks } = useContext(TasksContext);
  const { getToken } = useAuth();

  // State variables
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);

  const [taskCreateLoading, setCreateTaskLoading] = useState(false);

  const handleClose = () => {
    setOpenTaskDialog(false);
  };

  const handleSave = async () => {
    const body = makeDeepCopy(taskToEdit);
    if (taskToEdit?.dueAt) {
      const isoTime = convertToIsoTime(taskToEdit?.dueAt, setAlert);
      if (!isoTime) {
        return;
      }
      body["dueAt"] = isoTime;
    } else {
      delete body["dueAt"];
    }

    try {
      setCreateTaskLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/tasks`,
        "POST",
        {},
        body,
      );
      setAlert("Task saved successfully", "success");
      updateList(tasks, setTasks, res.task, "id");
      handleClose();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCreateTaskLoading(false);
    }
  };

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    fetchTasks(
      TaskStatus.PENDING,
      TaskSort.DUE_DATE,
      "brand,project,outreach_contact",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const hasNoTasks =
    !taskLoading &&
    tasks.length === 0 &&
    "You do not have any tasks at this time.";

  const pendingTasks = tasks?.filter((x) => x.status === TaskStatus.PENDING);

  return (
    <Grid>
      <Grid
        container
        sx={styles.boardHeaderContainer}
        justifyContent={isMobileScreen ? "flex-left" : "space-between"}
        alignItems="center"
      >
        {isMobileScreen ? (
          <Typography>{hasNoTasks}</Typography>
        ) : (
          <Grid item xs={12}>
            <Typography sx={styles.pageHeader} component="div">
              <strong>Tasks Manager</strong>
            </Typography>

            <Typography sx={{ mb: 2 }}>
              On this page, you can track all tasks related to your
              partnerships. {hasNoTasks}
            </Typography>
          </Grid>
        )}
      </Grid>

      {taskLoading && <CircularProgress />}

      {!taskLoading && tasks?.length > 0 && (
        <Grid sx={isMobileScreen ? styles.mobileContainer : {}}>
          {pendingTasks?.map((task) => (
            <Box key={task?.id}>
              <SingleTask task={task} />
            </Box>
          ))}
        </Grid>
      )}

      {taskToEdit && (
        <AddReminderDialog
          open={openTaskDialog}
          close={handleClose}
          task={taskToEdit}
          setTask={setTaskToEdit}
          handleSave={handleSave}
          taskCreateLoading={taskCreateLoading}
          setAlert={setAlert}
        />
      )}

      <Routes>
        <Route
          path="contact/:outreachContactId/*"
          element={<OutreachContactDrawer />}
        />
      </Routes>
    </Grid>
  );
}
