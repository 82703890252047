import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { EditorView } from "@tiptap/pm/view";

import useTippy from "components/Tippy";

export const useMergeTags = () => {
  const { toggleTooltip: toggleMergeTapTippy } = useTippy();
  const { toggleTooltip: toggleVariableTippy } = useTippy();

  const toggleMergeTagTooltip = (
    view?: EditorView | null,
    pos?: number,
    node?: ProseMirrorNode,
  ) => {
    toggleMergeTapTippy(getMergeTagTooltipContent(node), view, pos);
  };

  const toggleVariableTooltip = (
    view?: EditorView | null,
    pos?: number,
    node?: ProseMirrorNode,
  ) => {
    toggleVariableTippy(getVariableTooltipContent(node), view, pos);
  };

  const getVariableTooltipContent = (node?: ProseMirrorNode) => {
    if (!node) return "";
    return `Fill out "${node.attrs?.label}" when you send an outreach email`;
  };

  const getMergeTagTooltipContent = (node?: ProseMirrorNode) => {
    if (!node) return "";
    return `We will fill out "${node.attrs?.label}" for you when you send an outreach email`;
  };

  return [toggleMergeTagTooltip, toggleVariableTooltip] as const;
};
