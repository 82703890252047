import HardBreak from "@tiptap/extension-hard-break";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { EditorView } from "@tiptap/pm/view";
import { AnyExtension } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { PaletteColor, PaletteType } from "schemas/portfolioSite";

import { Color } from "./Color";
import CustomImage from "./Image";
import { LanguageTool } from "./LanguageTool";
import { MergeTag } from "./MergeTag";
import suggestion from "./MergeTag/suggestion";
import OneLiner from "./OneLiner";
import Table from "./Table";
import { Variable } from "./Variable";

interface ExtensionProps {
  toggleVariableTooltip?: (
    view?: EditorView | null,
    pos?: number,
    node?: ProseMirrorNode,
  ) => void;
  toggleMergeTagTooltip?: (
    view?: EditorView | null,
    pos?: number,
    node?: ProseMirrorNode,
  ) => void;
  suggestionOptions?: string[];
  hardBreaksOnly?: boolean;
  isPlain?: boolean;
  placeholderText?: string;
  palette?: { [key in PaletteType]: PaletteColor };
  displayMergeTagWarning?: (mergeTag: string) => void;
}

export const extensions = ({
  toggleVariableTooltip,
  toggleMergeTagTooltip,
  suggestionOptions,
  hardBreaksOnly,
  isPlain,
  placeholderText,
  palette,
  displayMergeTagWarning,
}: ExtensionProps = {}): AnyExtension[] => {
  const variableConfigurations = {
    toggleTooltip: toggleVariableTooltip,
  };

  let res: AnyExtension[];
  if (isPlain) {
    res = [
      OneLiner,
      Variable.configure(variableConfigurations),
      Text,
      Paragraph,
    ];
  } else {
    res = [
      Link.configure({
        openOnClick: false,
      }),
      Color.configure({ types: [TextStyle.name, ListItem.name], palette }),
      // @ts-ignore
      TextStyle.configure({ types: [ListItem.name] }),
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
        hardBreak: false,
      }),
      Variable.configure(variableConfigurations),
      CustomImage.configure({
        HTMLAttributes: {
          class: "custom-image",
        },
        inline: true,
      }),
      LanguageTool.configure({
        language: "auto",
        apiUrl: "https://api.languagetoolplus.com/v2/check",
      }),
      Table.configure({ resizable: true, allowTableNodeSelection: true }),
      TableRow,
      TableHeader,
      TableCell,
    ];
    if (hardBreaksOnly) {
      res.push(
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              Enter: () => this.editor.commands.setHardBreak(),
            };
          },
        }),
      );
    } else {
      res.push(HardBreak);
    }
  }
  if (suggestionOptions) {
    res.push(
      MergeTag.configure({
        HTMLAttributes: {
          class: "merge-tag",
        },
        suggestion: suggestion(suggestionOptions),
        suggestionOptions,
        toggleTooltip: toggleMergeTagTooltip,
        displayMergeTagWarning,
      }),
    );
  }
  if (placeholderText) {
    res.push(
      Placeholder.configure({
        placeholder: placeholderText,
      }),
    );
  }
  return res;
};
