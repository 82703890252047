import {
  Box,
  Chip,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import { SavedBrandContext } from "contexts/SavedBrand";
import { useContext, useEffect } from "react";
import {
  BentoBrand,
  BentoBrandMetadataTags,
  MetadataType,
  SearchParams,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { useAutoCompletePrediction } from "components/LocationAutocomplete/useAutoCompletePrediction";
import { DiscoverSearchChipColor } from "utils/color";
import { DISCOVER_METADATA_TAGS } from "utils/localStorage";
import { formatNumber } from "utils/string";
import { trackEvent } from "utils/tracking";
import { useSearchBrand } from "utils/useSearchBrand";

import BrandIcons from "./BrandIcons";

const styles = {
  brandName: {
    fontSize: 14,
    fontWeight: 700,
  },
  followerCount: {
    fontSize: 12,
  },
  instagramUsername: {
    width: "max-content",
    fontSize: 12,
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  draft: {
    color: "error.main",
  },
  metadata: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "capitalize",
  },
  about: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
  },
};

interface Props {
  bentoBrand: BentoBrand;
}

export default function BrandTitle({ bentoBrand }: Props) {
  const { handleAddGeopoint, handleClickOnTag } = useSearchBrand();
  const { inputValue, setInputValue, geopoint } = useAutoCompletePrediction();

  const { setSelectedMetadataTags } = useContext(BrandsContext);
  const { draftBentoBrands } = useContext(SavedBrandContext);

  const isDrafted = draftBentoBrands?.find(
    (x) => Number(x.id) === Number(bentoBrand?.id),
  );

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (geopoint?.country_code && geopoint?.longitude && geopoint?.latitude) {
      handleAddGeopoint(
        geopoint?.country_code,
        geopoint?.latitude,
        geopoint?.longitude,
        inputValue,
      );
      setSelectedMetadataTags((prev) => {
        const exists = prev?.find((x) => x.value === inputValue);
        if (!exists) {
          prev.push({
            ...geopoint,
            type: MetadataType.location,
            value: inputValue,
          });
        }
        sessionStorage.setItem(DISCOVER_METADATA_TAGS, JSON.stringify(prev));
        return prev;
      });
      setInputValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geopoint]);

  const getColor = (type: MetadataType) => {
    if (type === MetadataType.location)
      return DiscoverSearchChipColor[SearchParams.LOCATION_DISCOVER];
    if (type === MetadataType.size)
      return DiscoverSearchChipColor[SearchParams.SIZE_DISCOVER];
    return DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER];
  };

  return (
    <Grid item container xs>
      <Grid
        item
        xs={12}
        container
        gap={1}
        sx={{ mb: { xs: 0.5, sm: 0 } }}
        alignItems="center"
      >
        <Box sx={styles.brandName}>
          {bentoBrand?.brandName}
          {isDrafted && (
            <>
              ,{" "}
              <Box component="span" sx={styles.draft}>
                Draft
              </Box>
            </>
          )}
        </Box>
        <BrandIcons bentoBrand={bentoBrand} />
      </Grid>
      <Grid container item xs={12} gap={1}>
        {bentoBrand?.metadata?.map(
          (tag: BentoBrandMetadataTags, index: number) => (
            <Chip
              onClick={(e) => handleClickOnTag(e, tag, setInputValue)}
              size="small"
              sx={{
                backgroundColor: getColor(tag.type),
                textTransform: "capitalize",
              }}
              label={tag.value}
              key={index}
            />
          ),
        )}
      </Grid>

      <Grid item xs={12}>
        {bentoBrand?.instagramUsername && (
          <Typography sx={styles.instagramUsername} component="div">
            {bentoBrand.instagramFollowerCount && (
              <Typography
                component="span"
                color="textSecondary"
                sx={styles.followerCount}
              >
                {formatNumber(bentoBrand.instagramFollowerCount)} followers{" "}
              </Typography>
            )}
            <Link
              target="_blank"
              href={`https://instagram.com/${bentoBrand?.instagramUsername}`}
              sx={{ width: "max-content" }}
              onClick={(e: CustomEvent) => {
                e.stopPropagation();
                trackEvent("Instagram Link Brand Card Clicked", {
                  "Instagram Username": bentoBrand.instagramUsername,
                  "Bento Brand ID": bentoBrand.id,
                });
              }}
            >
              @{bentoBrand?.instagramUsername}
            </Link>
          </Typography>
        )}
      </Grid>
      {!isMobileScreen && bentoBrand.aboutSummary && (
        <Grid item xs={12} sx={{ mt: 0.5 }}>
          <Typography color="textSecondary" variant="caption" sx={styles.about}>
            {bentoBrand.aboutSummary}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
