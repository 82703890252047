import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import { TrackingColumnsContext } from "contexts/TrackingColumns";
import { TrackingColumnsSortContext } from "contexts/TrackingColumnsSort";
import { useContext, useEffect, useRef } from "react";
import { BuiltInContactPropertyName } from "schemas/dashboard";
import { NONE } from "schemas/forms";
import { CustomEvent } from "schemas/functions";

import { PropertySortDirection } from "features/Influencer/Tracking/schema";
import { makeDeepCopy } from "utils/updateLocalState";

export default function TableSortPopupMenu() {
  const parentRef = useRef();
  const { getTitleForHeader } = useContext(TrackingColumnsContext);
  const {
    open,
    anchorEl,
    handleCloseSortMenu,
    selectedSort,
    setSelectedSort,
    handleAddSort,
    sortableColumns,
  } = useContext(TrackingColumnsSortContext);

  const addSort = (e: CustomEvent) => {
    const { value } = e.target;
    if (!selectedSort) return;
    if (value === NONE) {
      handleAddSort(null, selectedSort);
    } else {
      handleAddSort(value, selectedSort);
    }
  };

  const handleChange = (e: CustomEvent) => {
    const column = sortableColumns?.find((x) => x.id === e.target.value);
    if (!!selectedSort?.sortDirection && column) {
      handleAddSort(selectedSort?.sortDirection, column);
    } else if (column) {
      setSelectedSort(column);
    }
  };

  useEffect(() => {
    const sortedColumn = sortableColumns?.find((x) => x.sortDirection !== null);
    if (sortedColumn) {
      setSelectedSort(sortedColumn);
    } else {
      const createdOn = sortableColumns?.find(
        (x) => x.contactPropertyName === BuiltInContactPropertyName.createdAt,
      );
      if (createdOn) {
        const copy = makeDeepCopy(createdOn);
        copy.sortDirection = PropertySortDirection.desc;
        setSelectedSort(copy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortableColumns?.length]);

  return (
    <Box ref={parentRef}>
      <Popover
        id={"sort-popup-menu"}
        open={open}
        container={parentRef.current} // used so that the loading backdrop appears above this popover
        anchorEl={anchorEl}
        onClose={handleCloseSortMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 1 }}>In this view, sort records by </Typography>

          <Grid container gap={1} alignItems="center">
            <FormControl sx={{ width: 200 }}>
              <FormHelperText sx={{ ml: 0 }}>Column Name</FormHelperText>
              <Select
                size="small"
                onChange={handleChange}
                value={selectedSort?.id || ""}
                MenuProps={{ container: parentRef.current }} // used so that the loading backdrop appears above this popover
              >
                {sortableColumns?.map((x) => (
                  <MenuItem value={x.id} key={x.id}>
                    {getTitleForHeader(
                      x.contactPropertyName || x?.contactProperty?.name,
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: 200 }}>
              <FormHelperText sx={{ ml: 0 }}>Direction</FormHelperText>
              <Select
                size="small"
                onChange={addSort}
                value={selectedSort?.sortDirection || NONE}
              >
                <MenuItem value={NONE}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={PropertySortDirection.asc}>
                  <Box
                    sx={{ mr: 1 }}
                    component="i"
                    className="fa-thin fa-arrow-up-short-wide"
                  />{" "}
                  Ascending
                </MenuItem>
                <MenuItem value={PropertySortDirection.desc}>
                  <Box
                    sx={{ mr: 1 }}
                    component="i"
                    className="fa-thin fa-arrow-down-short-wide"
                  />{" "}
                  Descending
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
}
