import { AlertColor } from "@mui/material";
import { useState } from "react";

export const useAlert = () => {
  const [message, setMessage] = useState<string | JSX.Element>();
  const [severity, setSeverity] = useState<AlertColor>("success");

  const setAlert = (
    customMessage: string | JSX.Element,
    customType: AlertColor
  ) => {
    setMessage(customMessage);
    setSeverity(customType);
  };

  const closeAlert = () => {
    setMessage("");
  };
  return [message, severity, setAlert, closeAlert] as const;
};
