import { grey } from "@mui/material/colors";

const styles = {
  message: {
    px: 2,
    py: 1,
    border: `1px solid`,
    borderColor: grey[300],
    borderRadius: 2,
    m: 1,
  },
  assistantMessage: {
    backgroundColor: "white",
  },
  userMessage: {
    backgroundColor: "primary.light",
  },
  iconButton: {
    fontSize: 12,
    textTransform: "none",
  },
  text: {
    overflow: "hidden",
    maxWidth: "300px",
    textOverflow: "ellipsis",
  },
};

export default styles;
