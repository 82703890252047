import { Box, Grid, IconButton, Popover, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { EmailActivity } from "schemas/dashboard";

import { formatDate } from "components/EmailThread/helpers";

import styles from "./styles";

interface IProps {
  id: string;
  type: string;
  emailActivities: EmailActivity[];
  isTracked: boolean;
}

export default function EmailActivityIcon({
  id,
  type,
  emailActivities,
  isTracked,
}: IProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={styles.root}
      aria-owns={open ? `mouse-over-popover-open-details-${id}` : undefined}
      aria-haspopup="true"
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {isTracked && (
        <IconButton
          size="small"
          sx={[
            styles.icon,
            emailActivities.length === 0 ? styles.noOpens : styles.opens,
          ]}
        >
          <Box component="i" className="fa-solid fa-check-double fa-sm" />
        </IconButton>
      )}
      {!isTracked && (
        <IconButton size="small" sx={[styles.icon, styles.notTracked]}>
          <Box component="i" className="fa-solid fa-eye-slash fa-sm" />
        </IconButton>
      )}
      <Popover
        id={`mouse-over-popover-open-details-${id}`}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container sx={{ maxWidth: "400px", p: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              sx={{ fontWeight: 700 }}
              component="div"
              gutterBottom
            >
              {isTracked && (
                <>
                  {emailActivities.length === 0 &&
                    `This ${type} has not been opened.`}
                  {emailActivities.length !== 0 &&
                    `This ${type} has been opened ${
                      emailActivities.length
                    } time${emailActivities.length !== 1 ? "s" : ""}.`}
                </>
              )}
              {!isTracked &&
                `No tracking data available for this ${type}. Only emails sent through the Bento app are tracked.`}
            </Typography>
            {isTracked && (
              <Typography variant="caption" component="div" gutterBottom>
                Bento tracks all email opens, including when you open them in
                Gmail. To avoid tracking your own opens, view the thread
                directly in Bento instead.
              </Typography>
            )}
          </Grid>
          {emailActivities.map((activity: EmailActivity) => (
            <Grid item xs={12} key={activity.id}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                Opened {formatDate(moment.unix(activity.createdAt))}
                {activity.formattedAddress &&
                activity.formattedAddress !== "Unknown"
                  ? `, ${activity.formattedAddress}`
                  : ""}
                {activity.userDevice ? `, ${activity.userDevice}` : ""}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Box>
  );
}
