import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  Grid,
  Typography,
  capitalize,
  useMediaQuery,
} from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { SubscriptionType } from "schemas/dashboard";
import { Plans, SubscriptionMessages } from "schemas/payments";
import theme from "styles/theme";

import { SubscriptionColor } from "utils/color";
import { epochToLocalDate } from "utils/time";

import {
  disabledCurrentPlan,
  getCurrentPlanButton,
  getOtherPlanButton,
  getStatusChipLabel,
  getSubscriptionInformation,
} from "./helpers";
import styles from "./styles";

interface IProps {
  plan: Plans;
  loading: boolean;
  onClick: () => void;
}

export function Plan({ loading, onClick, plan }: IProps) {
  const { subscription, plans, isProPlan } = useContext(SubscriptionContext);
  const { discoverTab } = useContext(DiscoverViewContext);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen =
    isMediumScreen || (isLargeScreen && discoverTab !== null);

  const {
    stripePlan,
    isCurrentPlan,
    price,
    description,
    emailSent,
    resubscribe,
    downGradeToFreeTier,
    leftOverOnDowngrade,
  } = getSubscriptionInformation(plan, plans, subscription);

  const backgroundColor = stripePlan?.status
    ? SubscriptionColor[stripePlan?.status]
    : "";

  const emailSentText = downGradeToFreeTier ? (
    <Typography color="textSecondary" component="div">
      You have sent{" "}
      <Box component="span" sx={{ color: "info.main" }}>
        <strong>{emailSent}</strong>
      </Box>{" "}
      emails this month
      {leftOverOnDowngrade > 0 && (
        <> and you can still send {leftOverOnDowngrade} emails more</>
      )}
      .{" "}
      {subscription?.overrideSubscription && (
        <>To try out the platform, we have gifted you the Pro plan.</>
      )}{" "}
      {subscription && subscription.referralsBoost > 0 && (
        <>
          {" "}
          You can send{" "}
          <Box component="span" sx={{ color: "success.main" }}>
            <strong>{subscription?.referralsBoost}</strong>
          </Box>{" "}
          additional emails due to referrals.
        </>
      )}
    </Typography>
  ) : (
    <>
      <Typography color="textSecondary" component="div">
        You have sent{" "}
        <Box component="span" sx={{ color: "info.main" }}>
          <strong>{emailSent}</strong>
        </Box>{" "}
        emails this month
        {subscription && subscription.remainingMessages > 0 && (
          <>
            {" "}
            and you can still send{" "}
            <Box component="span" sx={{ color: "success.main" }}>
              {subscription?.remainingMessages}
            </Box>{" "}
            more on this plan
          </>
        )}
        .{" "}
        {subscription?.overrideSubscription && (
          <>To try out the platform, we have gifted you the Pro plan.</>
        )}{" "}
        {subscription && subscription.referralsBoost > 0 && (
          <>
            You can send{" "}
            <Box component="span" sx={{ color: "success.main" }}>
              <strong>{subscription?.referralsBoost}</strong>
            </Box>{" "}
            additional emails due to referrals.
          </>
        )}
      </Typography>
    </>
  );

  return (
    <Grid
      item
      xs={12}
      md={isMobileScreen ? 12 : 4}
      container
      direction="column"
    >
      <Grid item container xs="auto" sx={{ mb: 3 }}>
        <Grid item xs="auto" sx={{ mr: 1 }}>
          <Typography variant="h5">
            {capitalize(plan)}
            {isCurrentPlan && (
              <Chip
                size="small"
                sx={[styles.chip, { backgroundColor }]}
                label={getStatusChipLabel(subscription)}
              />
            )}
            <br />
            {price === 0 ? "$0" : `$${price}/month`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs sx={styles.textContainer}>
        <Typography color="textSecondary" sx={styles.planDescription}>
          {description}
        </Typography>
        {isCurrentPlan ? (
          <>
            {stripePlan?.status !== SubscriptionType.CANCELED && emailSentText}
            {subscription && subscription?.remainingMessages <= 0 ? (
              <Typography sx={styles.warningText}>
                Please upgrade to send more this month
              </Typography>
            ) : (
              <Typography sx={styles.warningText}>
                <i>
                  {stripePlan?.status &&
                    stripePlan?.status in SubscriptionMessages &&
                    SubscriptionMessages[stripePlan?.status]}
                  {stripePlan?.cancelAt &&
                    ` Your subscription is set to cancel on ${epochToLocalDate(
                      stripePlan.cancelAt,
                    )}`}
                </i>
              </Typography>
            )}
          </>
        ) : (
          <>
            {stripePlan?.status === SubscriptionType.CANCELED &&
              plan === Plans.FREE &&
              emailSentText}
          </>
        )}
      </Grid>
      <Grid item xs="auto">
        {isCurrentPlan ? (
          <LoadingButton
            loading={loading}
            color={resubscribe ? "primary" : "secondary"}
            variant={resubscribe ? "contained" : "outlined"}
            fullWidth
            disabled={disabledCurrentPlan(plan, subscription)}
            onClick={onClick}
            disableElevation
          >
            {getCurrentPlanButton(plan, subscription)}
          </LoadingButton>
        ) : (
          <>
            {plan !== Plans.FREE && (
              <LoadingButton
                loading={loading}
                onClick={onClick}
                variant="contained"
                fullWidth
                disableElevation
              >
                {getOtherPlanButton(plan, isProPlan)}
              </LoadingButton>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
