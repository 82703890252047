const styles = {
  startFromScratch: {
    textTransform: "none",
    fontSize: 14,
  },
  dialogContent: {
    pb: { xs: 0, md: 10 },
    overflowX: "hidden",
  },
};

export default styles;
