export const getStyles = (isSavedBrandOpen: boolean) => {
  return {
    root: {
      position: "fixed",
      bottom: 0,
      zIndex: 3,
      right: {
        md: isSavedBrandOpen ? 878 : 528,
        xl: isSavedBrandOpen ? 978 : 628,
      },
      boxShadow: 3,
      backgroundColor: "primary.main",
      py: 1,
      px: 2,
      width: { md: 325, lg: isSavedBrandOpen ? 325 : 400, xl: 500 },
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      cursor: "pointer",
    },
    recommendationPage: {},
    icon: {
      color: "white",
      fontSize: 12,
    },
    text: {
      color: "white",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: { xs: 80, md: 250 },
    },
  };
};
