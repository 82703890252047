import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { OrganizationUserContext } from "contexts/Organization";
import { Dispatch, SetStateAction, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";
import { routes } from "schemas/routes";

import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";
import {
  TemplateGoal,
  TemplateHighlight,
  TemplateTone,
} from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface CustomizePartOneEmailFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
  isProfilePage?: boolean;
}

const CustomizePartOneEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
  isProfilePage = false,
}: CustomizePartOneEmailFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(OrganizationUserContext);

  const handleBack = () => {
    navigate(`/${routes.pitchOnboarding}${location.search}`);
  };

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value;
    setOnboardingForm(copy);
  };

  const handleButtonChange = (field: string, value: string) => {
    setOnboardingForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const editor = useEditor(
    {
      extensions: extensions({ hardBreaksOnly: true }),
      content: onboardingForm?.signature,
      onUpdate({ editor }) {
        setOnboardingForm((prev) => {
          return { ...prev, signature: editor.getHTML() };
        });
      },
    },
    [!!onboardingForm?.signature],
  ) as TipTapEditor;

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    saveOnboardingForm(onboardingForm, "emailPartOne");
  };

  return (
    <>
      <Typography variant="h3" sx={{ mb: 3 }}>
        <Box
          component="i"
          color="secondary.dark"
          sx={{ pr: 2 }}
          className="fa-solid fa-envelope"
        />
        Let us help you write customized emails
      </Typography>

      {isProfilePage && (
        <Typography>
          The following questions help Bento's AI generate personalized pitch
          emails for you, which will appear on your recommendations page. If
          you'd prefer to craft your own pitch email templates, you can do so on{" "}
          <Link to={`/${routes.templates}`}>your templates page</Link>.
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <FormHelperText sx={styles.helperText}>
          Write a 2-3 sentence "About me" (first sentences in your pitch email)
          *
        </FormHelperText>
        <TextField
          sx={styles.textField}
          fullWidth
          name="introduction"
          placeholder={`My name is ${
            currentUser?.name?.split(" ")?.[0] || `{name}`
          } ✨ and I am a fashion, beauty, and lifestyle content creator from...`}
          onChange={handleChange}
          multiline
          minRows={4}
          required
          value={onboardingForm?.introduction || ""}
        />

        {!isProfilePage && (
          <>
            <Grid sx={styles.fieldContainer}>
              <FormHelperText sx={styles.helperText}>
                What is your current main pitching goal? *
              </FormHelperText>
              <Grid container gap={1}>
                {[
                  {
                    key: TemplateGoal.PR,
                    value:
                      "Being added to their PR list or seeking gifted opportunities (great if you're just starting out!)",
                  },
                  {
                    key: TemplateGoal.UGC,
                    value:
                      "Creating UGC content (great if you don't have a wide social presence!)",
                  },
                  {
                    key: TemplateGoal.PAID,
                    value:
                      "Asking for paid collaborations (great if you've had paid opportunities before!)",
                  },
                ].map((goal) => (
                  <Button
                    key={goal.key}
                    onClick={() => {
                      handleButtonChange("defaultGoal", goal.key);
                    }}
                    size="small"
                    variant="outlined"
                    sx={[
                      styles.button,
                      onboardingForm.defaultGoal === goal.key &&
                        styles.selected,
                    ]}
                  >
                    {goal.value}
                  </Button>
                ))}
              </Grid>
            </Grid>

            <Grid sx={styles.fieldContainer}>
              <FormHelperText sx={styles.helperText}>
                Preferred tone of voice *
              </FormHelperText>
              <Grid container gap={1}>
                {[
                  {
                    key: TemplateTone.UPBEAT,
                    value:
                      "Upbeat and sweet (you love emojis and exclamation marks!)",
                  },
                  {
                    key: TemplateTone.CASUAL,
                    value:
                      "Casual and friendly (you keep it short and concise)",
                  },
                  {
                    key: TemplateTone.PROFESSIONAL,
                    value:
                      "Professional and thorough (you show you've done your research)",
                  },
                ].map((tone) => (
                  <Button
                    onClick={() => {
                      handleButtonChange("defaultTemplateTone", tone.key);
                    }}
                    key={tone.key}
                    variant="outlined"
                    size="small"
                    sx={[
                      styles.button,
                      onboardingForm.defaultTemplateTone === tone.key &&
                        styles.selected,
                    ]}
                  >
                    {tone.value}
                  </Button>
                ))}
              </Grid>
            </Grid>

            <Grid sx={styles.fieldContainer}>
              <FormHelperText sx={styles.helperText}>
                What do you typically highlight in pitch emails? *
              </FormHelperText>
              <Grid container gap={1}>
                {[
                  {
                    key: TemplateHighlight.CONTENT_IDEA,
                    value: "Unique content ideas",
                  },
                  {
                    key: TemplateHighlight.ENGAGEMENT,
                    value: "Engagement numbers/follower count",
                  },
                  {
                    key: TemplateHighlight.PAST_BRANDS,
                    value: "Past partnerships",
                  },
                  {
                    key: TemplateHighlight.BRAND_ALIGNMENT,
                    value:
                      "Specific reasons for wanting to work with the brand",
                  },
                ].map((highlight) => (
                  <Button
                    onClick={() => {
                      handleButtonChange(
                        "defaultTemplateHighlight",
                        highlight.key,
                      );
                    }}
                    key={highlight.key}
                    variant="outlined"
                    size="small"
                    sx={[
                      styles.button,
                      onboardingForm.defaultTemplateHighlight ===
                        highlight.key && styles.selected,
                    ]}
                  >
                    {highlight.value}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </>
        )}

        <Grid sx={styles.fieldContainer}>
          <FormHelperText sx={styles.helperText}>
            Enter your email signature (image or text)
          </FormHelperText>
          <Editor editor={editor} allowAttachment={false} allowImage={true} />
        </Grid>

        {!isProfilePage && (
          <Grid item xs="auto" container columnGap={1}>
            <Grid item xs="auto">
              <Button onClick={handleBack} disableElevation>
                Back
              </Button>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <LoadingButton
                disableElevation
                variant="contained"
                type="submit"
                loading={loading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default CustomizePartOneEmailForm;
