import { lighten } from "@mui/material";
import { grey } from "@mui/material/colors";

const styles = {
  button: {
    borderRadius: 1,
    textTransform: "none",
    backgroundColor: "green.dark",
    color: "secondary.light",
    "&:hover": {
      backgroundColor: `${lighten("#151e16", 0.1)}`,
    },
    "&:disabled": {
      backgroundColor: grey[300],
    },
  },
  secondary: {
    borderRadius: 1,
    textTransform: "none",
    color: "green.dark",
    border: "1px solid",
    borderColor: "green.dark",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.02)",
      border: "1px solid",
    },
  },
};
export default styles;
