import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BentoBrand,
  BentoContact,
  BentoContactResponse,
} from "schemas/dashboard";
import { Message, MessageAttachment } from "schemas/email";
import { CustomEvent } from "schemas/functions";

import Drawer from "components/Drawer";
import EmailThread from "components/EmailThread";
import BrandSearchAutoComplete from "features/Admin/AdminSharedComponents/BrandSearchAutoComplete";
import { fetcherAuth } from "utils/api";
import { openBase64DataInTab } from "utils/files";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface EditContactProps {
  setAlert: (
    customMessage: string | JSX.Element,
    customType: AlertColor,
  ) => void;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  refetchContacts: () => void;
}

const EditContact = ({
  setAlert,
  refetchContacts,
  setLoading,
}: EditContactProps) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  const contactId = Number(id);

  const [contact, setContact] = useState<BentoContact | undefined>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [bentoContactResponse, setBentoContactResponse] = useState<
    BentoContactResponse | undefined
  >();

  const fetchContact = async () => {
    setLoading(true);
    try {
      const { contact } = await fetcherAuth(
        getToken,
        `/api/admin/bento-contacts/${contactId}`,
        "GET",
      );
      setContact(contact);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  const handleClose = () => {
    navigate(`/admin/contacts`, { replace: true });
  };

  const fetchResponse = async (responseId: number) => {
    setLoading(true);
    try {
      let url = `/api/admin/bento-contacts/responses/${responseId}`;
      const { bentoContactResponse, messages } = await fetcherAuth(
        getToken,
        url,
      );
      setMessages(messages);
      setBentoContactResponse(bentoContactResponse);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    } finally {
      setLoading(false);
    }
  };

  const openAttachment = async (
    messageId: string,
    attachment: MessageAttachment,
  ) => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/user-integrations/${bentoContactResponse?.userIntegrationId}/messages/${messageId}/attachments/${attachment.attachment_id}`,
        "GET",
      );
      openBase64DataInTab(res.attachment.data, attachment.mime_type);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    }
  };

  const onBrandChange = async (
    event: CustomEvent,
    newValue: BentoBrand | null,
  ) => {
    setContact((prev) => {
      if (prev) {
        const copy: BentoContact = makeDeepCopy(prev);
        copy.bentoBrand = newValue || undefined;
        copy.bentoBrandId = newValue?.id;
        return copy;
      }
    });
  };

  const onChange = (event: CustomEvent) => {
    const { name, value } = event.target;
    setContact((prev) => {
      if (prev) {
        const copy = makeDeepCopy(prev);
        copy[name] = value;
        return copy;
      }
    });
  };

  const saveChanges = async () => {
    if (!contact) {
      return;
    }
    setSaveLoading(true);
    try {
      let url = `/api/admin/bento-contacts/${contact?.id}`;
      await fetcherAuth(
        getToken,
        url,
        "PUT",
        {},
        {
          ...contact,
        },
      );
      refetchContacts();
      setAlert("Saved", "success");
      handleClose();
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Drawer
      open={contactId > 0}
      width={{ xs: "auto", md: 800, lg: 1000 }}
      onClose={handleClose}
    >
      <DialogTitle sx={styles.dialogTitleText}>
        <Grid container alignItems="center" justifyContent={"space-between"}>
          <Button
            color="secondary"
            onClick={handleClose}
            sx={styles.actionButton}
            size="small"
          >
            Close
          </Button>

          <Box>
            <Typography component="span" noWrap sx={styles.brandName}>
              {contact?.name}
            </Typography>
          </Box>
          <LoadingButton
            variant="contained"
            sx={styles.actionButton}
            onClick={saveChanges}
            loading={saveLoading}
            disableElevation
            size="small"
          >
            Save
          </LoadingButton>
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <BrandSearchAutoComplete
              setAlert={setAlert}
              value={contact?.bentoBrand || null}
              onBrandChange={onBrandChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              onChange={onChange}
              value={contact?.name || ""}
              name="name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              value={contact?.email || ""}
              name="email"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Title"
              onChange={onChange}
              value={contact?.title || ""}
              name="title"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email Score"
              type="number"
              onChange={onChange}
              value={contact?.score || ""}
              name="score"
            />
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ mt: 3 }}>
          Responses for this contact
        </Typography>
        <Grid container sx={styles.emailRoot}>
          <Grid item container xs={4} sx={styles.wrapper}>
            {contact?.bentoContactResponses?.length === 0 && (
              <Typography variant="caption">No responses found</Typography>
            )}
            {contact?.bentoContactResponses?.map((res) => (
              <Grid key={res.id} item sx={{ mb: 2, mr: 2, p: 1 }} xs={12}>
                <Card>
                  <CardContent>
                    <Typography>{res.userIntegration?.email}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button onClick={() => fetchResponse(res.id)}>View</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={8} sx={styles.wrapper}>
            <EmailThread messages={messages} openAttachment={openAttachment} />
          </Grid>
        </Grid>
      </DialogContent>
    </Drawer>
  );
};

export default EditContact;
