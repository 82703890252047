import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Task, TaskStatus } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { epochToLocalDate } from "utils/time";

const styles = {
  textField: {
    mb: 2,
  },
};

interface IProps {
  open: boolean;
  close: () => void;
  handleSave: () => void;
  task: Task;
  setTask: Dispatch<SetStateAction<Task | null>>;
  taskCreateLoading: boolean;
  handleDelete?: () => void;
  setAlert: SetAlertType;
}

export function AddReminderDialog({
  open,
  close,
  handleSave,
  task,
  setTask,
  taskCreateLoading,
  handleDelete,
  setAlert,
}: IProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    setTask((prev: Task | null) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getDueAtDate = (dueAtDate?: number | string) => {
    if (typeof dueAtDate === "number") {
      return epochToLocalDate(dueAtDate);
    } else {
      return dueAtDate;
    }
  };

  return (
    <Dialog open={open} onClose={close} fullScreen={isMobileScreen}>
      <DialogTitle>Add Reminder</DialogTitle>

      <DialogContent>
        <TextField
          onChange={handleChange}
          sx={styles.textField}
          fullWidth
          name="dueAt"
          value={getDueAtDate(task?.dueAt)}
          variant="standard"
          label="Due At"
          type="date"
        />

        <FormHelperText>Task Status</FormHelperText>
        <Select
          value={task.status}
          label="Task Status"
          fullWidth
          variant="standard"
          sx={styles.textField}
          onChange={handleChange}
          name="status"
        >
          <MenuItem value={TaskStatus.PENDING}>Pending</MenuItem>
          <MenuItem value={TaskStatus.COMPLETED}>Completed</MenuItem>
        </Select>
        <TextField
          onChange={handleChange}
          sx={styles.textField}
          fullWidth
          name="description"
          value={task?.description}
          variant="standard"
          label="Description"
          placeholder="Send outreach email"
          minRows={4}
          multiline
        />
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            {task?.id && task?.id > 0 && handleDelete && (
              <LoadingButton
                loading={taskCreateLoading}
                onClick={handleDelete}
                sx={{ color: "error.main" }}
              >
                Delete
              </LoadingButton>
            )}
          </Grid>

          <Grid xs={6} container item justifyContent="flex-end">
            <Button onClick={close} color="secondary">
              Cancel
            </Button>
            <LoadingButton loading={taskCreateLoading} onClick={handleSave}>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
