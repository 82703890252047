import { SetAlertType } from "schemas/functions";

export const copyToClipboard = (
  text?: string,
  setAlert?: SetAlertType,
  successMessage?: string,
) => {
  if (!text) {
    return;
  }
  navigator.clipboard.writeText(text);
  if (setAlert) setAlert(successMessage || "Copied to clipboard!", "success");
};

export const isPage = (pageName: string) => {
  return window.location.pathname.includes(pageName);
};
