import { SignIn, SignUp, useAuth } from "@clerk/clerk-react";
import { Navigate, useLocation } from "react-router-dom";
import { routes } from "schemas/routes";

import Loading from "components/Loading";
import { LANDING_PAGE_LINK } from "constants/influencer";

import InfluencerLayout from "./Layout/Influencer";
import { AuthAction } from "./schema";

interface AuthPageProps {
  action: string;
  prevLink: string;
}

const AuthPage = ({ action, prevLink }: AuthPageProps) => {
  const { isLoaded, isSignedIn } = useAuth();
  const location = useLocation();

  const redirectUrl = prevLink?.includes(routes.dashboard)
    ? prevLink
    : `/${routes.onboardingInfluencer}${location.search}`;

  if (window.location.hostname === "crm.hatchways.io") {
    const newPath = "https://app.onbento.com" + window.location.pathname;
    window.location.href = newPath;
    return null;
  }

  if (isSignedIn) {
    return <Navigate to={redirectUrl} replace />;
  }

  if (isLoaded) {
    return (
      <InfluencerLayout>
        {action === AuthAction.SIGN_UP && (
          <SignUp
            signInUrl={`/${routes.signIn}`}
            redirectUrl={redirectUrl}
            appearance={{
              layout: {
                logoLinkUrl: LANDING_PAGE_LINK,
              },
            }}
          />
        )}
        {action === AuthAction.SIGN_IN && (
          <SignIn
            signUpUrl={`/${routes.signUp}`}
            redirectUrl={redirectUrl}
            appearance={{
              layout: {
                logoLinkUrl: LANDING_PAGE_LINK,
              },
            }}
          />
        )}
      </InfluencerLayout>
    );
  } else {
    return <Loading />;
  }
};

export default AuthPage;
