import { grey } from "@mui/material/colors";

const styles = {
  roundImage: {
    objectFit: "contain",
    width: 100,
    height: 100,
    borderRadius: 50,
    border: "1px solid",
    borderColor: grey[200],
  },
  headerImage: {
    objectFit: "cover",
    width: 75,
    height: 100,
    borderRadius: 0,
  },
};

export default styles;
