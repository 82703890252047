// Event names
export const ATTACHMENT_VIEWED = "Attachment Viewed";
export const ADD_FILTER_BUTTON_CLICKED = "Add Filter Button Clicked";

export const ADD_NEW_COLUMN = "Add New Column Button Clicked";
export const NEW_COLUMN_TYPE_SELECTED = "New Column Type Selected";

export const ADD_OPTION_CLICKED =
  "Add More Option For Select Column Button Clicked";
export const REMOVE_OPTION_CLICKED =
  "Remove Option For Select Column Button Clicked";

export const COLOR_VIEWED = "Color Palette Opened";
export const COLOR_SELECTED = "Color Palette Selected";

export const CSV_FILE_DROPPED = "Import Contacts CSV File Dropped";
export const CONSENT_READING_EMAIL_OPTION_SELECTED =
  "Consent Reading Email Option Selected";

export const GET_START_GUIDE_CLICKED = "Get Started Guide Clicked";

export const HEADER_COLUMN_MENU_OPENED = "Header Column Menu Opened";
export const HEADER_COLUMN_MENU_ITEM_SELECTED =
  "Header Column Menu Item Selected";

export const IMPORT_CONTACTS_CLICKED =
  "Import Contacts From CSV Button Clicked";

export const ADD_NEW_ROW = "Add New Row Button Clicked";
export const ROW_MENU_OPENED = "Row Menu Opened";

export const TABLE_CELL_CLICKED = "Table Cell Clicked";
export const TABLE_VIEWED = "Table Viewed";
export const TABLE_HIDE_COLUMN = "Hide Column Button Clicked";
export const TABLE_FILTER = "Filter Table Button Clicked";
export const TABLE_SORT = "Sort Table Button Clicked";
export const TABLE_SEARCH_CLEARED = "Table Search Cleared";

export const IMPORT_CSV_FLOW_EXITED_EARLY = "Import CSV Flow Exited Early";
export const IMPORT_FLOW_EXITED_EARLY = "Import Flow Exited Early";
export const IMPORT_FLOW_SELECTED_TYPE = "Import Flow Selected Type";

// Props names
export const COLUMN_NAME = "Column Name";
export const COLUMN_TYPE = "Column Type";
export const COLUMN_ID = "Column ID";
