import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BuildInContactPropertyNamesList,
  BuiltInContactPropertyName,
  ContactProperty,
  MatchColumn,
  PropertyNameLabel,
  PropertyType,
  PropertyTypeLabel,
  PropertyTypesList,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import { guessPropertyType } from "./helpers";

interface EditColumnDialogProps {
  selectedMatchColumnIndex: number | null;
  setSelectedMatchColumnIndex: Dispatch<SetStateAction<number | null>>;
  matchColumns: MatchColumn[];
  setMatchColumns: Dispatch<SetStateAction<MatchColumn[]>>;
  preview: string[][];
  contactProperties: ContactProperty[];
  setContactProperties: Dispatch<SetStateAction<ContactProperty[]>>;
}

export default function EditColumnDialog({
  selectedMatchColumnIndex,
  setSelectedMatchColumnIndex,
  matchColumns,
  setMatchColumns,
  preview,
  contactProperties,
  setContactProperties,
}: EditColumnDialogProps) {
  const { selectedView } = useContext(ContactViewContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const [editMatchColumn, setEditMatchColumn] = useState<MatchColumn>();
  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [newContactProperty, setNewContactProperty] = useState<ContactProperty>(
    {
      name: "",
    },
  );

  const currentMatchColumn = matchColumns.find(
    (column) => column.column_index === selectedMatchColumnIndex,
  );

  useEffect(() => {
    if (currentMatchColumn) {
      setNewContactProperty({
        name:
          selectedMatchColumnIndex !== null
            ? preview[0]?.[selectedMatchColumnIndex]
            : "",
        type: guessPropertyType(currentMatchColumn, preview),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatchColumnIndex, preview]);

  useEffect(() => {
    if (selectedMatchColumnIndex !== null) {
      if (currentMatchColumn?.column_index !== undefined) {
        setEditMatchColumn({ ...currentMatchColumn });
        setIsCreateNew(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatchColumnIndex]);

  const changeSkip = () => {
    setEditMatchColumn((prev) => {
      if (prev) {
        return {
          ...prev,
          skip_column: !prev.skip_column,
        };
      }
    });
  };

  const changeSelect = (event: CustomEvent) => {
    let { value } = event.target;
    if (value === "create-new") {
      setIsCreateNew(true);
      setEditMatchColumn((prev) => {
        if (prev) {
          return {
            ...prev,
            contact_property_id: null,
            contact_property_name: null,
          };
        }
      });
    } else if (typeof value === "string") {
      setIsCreateNew(false);
      setEditMatchColumn((prev) => {
        if (prev) {
          return {
            ...prev,
            contact_property_id: null,
            contact_property_name: value as BuiltInContactPropertyName,
          };
        }
      });
    } else {
      setIsCreateNew(false);
      setEditMatchColumn((prev) => {
        if (prev) {
          return {
            ...prev,
            contact_property_id: value,
            contact_property_name: null,
          };
        }
      });
    }
  };

  const changeNewContactProperty = (event: CustomEvent) => {
    const { name, value } = event.target;
    setNewContactProperty((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fieldValue = isCreateNew
    ? "create-new"
    : editMatchColumn?.contact_property_id ||
      editMatchColumn?.contact_property_name ||
      "";

  const onClose = () => {
    setSelectedMatchColumnIndex(null);
  };

  const handleConfirm = async () => {
    trackEvent("Upload Pitch Tracker CSV Skip Column Matching Confirmed", {
      isCreateNew,
      matchColumn: editMatchColumn,
      newContactProperty,
    });

    setConfirmLoading(true);
    if (isCreateNew) {
      if (!currentOrg?.id) {
        setConfirmLoading(false);
        setAlert("Something went wrong, please refresh page", "error");
        return;
      }
      try {
        const res = await fetcherAuth(
          getToken,
          `/api/organization/${currentOrg?.id}/contact-properties`,
          "POST",
          {},
          {
            ...newContactProperty,
            contactViewId: selectedView?.id,
          },
        );
        if (res.contactProperty) {
          setContactProperties((prev) => [...prev, res.contactProperty]);
          setMatchColumns((prev) => {
            return prev.map((column) => {
              if (
                editMatchColumn &&
                column.column_index === selectedMatchColumnIndex
              ) {
                return {
                  ...column,
                  contact_property_id: res.contactProperty.id,
                };
              } else {
                return column;
              }
            });
          });
          setSelectedMatchColumnIndex(null);
        }
      } catch (error) {
        setErrorAlert(error);
      } finally {
        setConfirmLoading(false);
      }
    } else {
      setMatchColumns((prev) => {
        return prev.map((column) => {
          if (
            editMatchColumn &&
            column.column_index === selectedMatchColumnIndex
          ) {
            return editMatchColumn;
          } else {
            return column;
          }
        });
      });
      setConfirmLoading(false);
      setSelectedMatchColumnIndex(null);
    }
  };

  return (
    <Dialog
      open={selectedMatchColumnIndex !== null}
      fullWidth
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle>
        Edit column label for `
        {editMatchColumn?.column_index !== undefined &&
          preview[0]?.[editMatchColumn?.column_index]}
        `
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <FormHelperText>Do you want to import this column?</FormHelperText>
            <FormControl>
              <FormLabel id="skip-column"></FormLabel>
              <RadioGroup
                onChange={changeSkip}
                name="skip_column"
                row
                aria-labelledby="skip-column"
                value={!editMatchColumn?.skip_column}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {!editMatchColumn?.skip_column && (
            <>
              <Grid item xs={12}>
                <FormHelperText>Match to a column in Bento</FormHelperText>
                <Select onChange={changeSelect} fullWidth value={fieldValue}>
                  {BuildInContactPropertyNamesList.map((propertyName) => (
                    <MenuItem
                      disabled={
                        !!matchColumns.find(
                          (column) =>
                            column.contact_property_name === propertyName &&
                            column.contact_property_name !==
                              currentMatchColumn?.contact_property_name,
                        )
                      }
                      key={propertyName}
                      value={propertyName}
                    >
                      {PropertyNameLabel[propertyName]}
                    </MenuItem>
                  ))}
                  {contactProperties.map((property) => (
                    <MenuItem
                      disabled={
                        !!matchColumns.find(
                          (column) =>
                            column.contact_property_id === property.id &&
                            column.contact_property_id !==
                              currentMatchColumn?.contact_property_id,
                        )
                      }
                      key={property.id}
                      value={property.id}
                    >
                      {property.name}
                    </MenuItem>
                  ))}
                  <MenuItem value={"create-new"}>
                    Create a new column...
                  </MenuItem>
                </Select>
              </Grid>
              {isCreateNew && (
                <>
                  <Grid item xs={12}>
                    <FormHelperText>New column title *</FormHelperText>
                    <TextField
                      fullWidth
                      name="name"
                      onChange={changeNewContactProperty}
                      placeholder="Column Title"
                      required
                      value={newContactProperty.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormHelperText>New column type *</FormHelperText>
                    <Select
                      onChange={changeNewContactProperty}
                      fullWidth
                      name="type"
                      value={newContactProperty.type || ""}
                    >
                      {PropertyTypesList.map((propertyType: PropertyType) => (
                        <MenuItem key={propertyType} value={propertyType}>
                          {PropertyTypeLabel[propertyType]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton loading={confirmLoading} onClick={handleConfirm}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
