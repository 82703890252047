import { Button, CircularProgress } from "@mui/material";
import grey from "@mui/material/colors/grey";
import { SavedBrandStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

interface IProps {
  tab: SavedBrandStatus;
  status: SavedBrandStatus;
  label: string;
  total?: number;
  handleChange: (e: CustomEvent, status: SavedBrandStatus) => void;
}

const styles = {
  mobileButton: {
    color: grey[500],
    fontSize: 12,
    textTransform: "none",
    borderRadius: 0,
    height: 50,
    minWidth: "100px",
  },
  mobileActive: {
    color: "white",
    borderBottom: "2px solid",
    borderColor: "secondary.light",
  },
};

const FavoriteListTabMobile = ({
  tab,
  status,
  label,
  total,
  handleChange,
}: IProps) => {
  return (
    <Button
      onClick={(e: CustomEvent) => handleChange(e, status)}
      sx={[styles.mobileButton, tab === status ? styles.mobileActive : {}]}
    >
      {label}{" "}
      {total === undefined ? (
        <CircularProgress size={10} color="secondary" sx={{ ml: 1 }} />
      ) : (
        `(${total})`
      )}
    </Button>
  );
};

export default FavoriteListTabMobile;
