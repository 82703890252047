import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { NewBentoContact } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import styles from "./styles";

interface AddNewContactDialogProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (newContact: NewBentoContact) => void;
  contact: NewBentoContact;
  setContact: Dispatch<SetStateAction<NewBentoContact>>;
}

export default function AddNewContactDialog({
  open,
  handleClose,
  handleConfirm,
  contact,
  setContact,
}: AddNewContactDialogProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    setContact((prev: NewBentoContact) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={isMobileScreen}>
      <DialogTitle>Change Outreach Contact ✉️</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Please fill out the information below for the contact you want to send
          outreach to.
        </Typography>

        <TextField
          sx={styles.textField}
          fullWidth
          value={contact?.email}
          name="email"
          label="Contact Email *"
          onChange={handleChange}
        />

        <TextField
          sx={styles.textField}
          fullWidth
          value={contact?.name}
          name="name"
          label="Contact Name"
          onChange={handleChange}
        />

        <TextField
          sx={styles.textField}
          fullWidth
          value={contact?.title}
          name="title"
          label="Contact Title"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => handleConfirm(contact)}
          disabled={!contact?.email}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
