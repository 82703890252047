import { grey } from "@mui/material/colors";

const styles = {
  brandCard: {
    width: "95%",
    cursor: "pointer",
    borderRadius: 2,
    border: `1px solid ${grey[300]}`,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
};

export default styles;
