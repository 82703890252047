import {
  BrandContact,
  OpportunityStage,
  OrganizationProfile,
} from "schemas/dashboard";
import { StringMap } from "schemas/functions";

import { titleCase } from "./string";

export const getAllStages = (isInfluencer: boolean) => {
  if (isInfluencer) {
    return [
      OpportunityStage.OPPORTUNITIES,
      OpportunityStage.FULFILLING,
      OpportunityStage.COMPLETED,
    ];
  } else {
    return [
      OpportunityStage.NEW,
      OpportunityStage.DISCOVERY,
      OpportunityStage.SCHEDULING_DEMO,
      OpportunityStage.DEMO,
      OpportunityStage.NEGOTIATION,
      OpportunityStage.WON,
      OpportunityStage.LOST,
    ];
  }
};

export const getStageName = (stage: OpportunityStage) => {
  return titleCase(stage);
};

export const getBrandContactName = (contact: BrandContact) => {
  return contact?.name || contact?.title || contact?.email || "";
};

export const hasSocialMediaProfile = (
  organizationProfile: OrganizationProfile | StringMap | null,
) => {
  if (!organizationProfile) {
    return false;
  }
  return (
    organizationProfile?.instagramUrl ||
    organizationProfile?.tiktokUrl ||
    organizationProfile?.youtubeUrl ||
    organizationProfile?.twitchUrl ||
    organizationProfile?.portfolioUrl
  );
};

export const getProfilePlatform = (
  organizationProfile: OrganizationProfile | null,
) => {
  if (organizationProfile?.instagramUrl) {
    return { platform: "Instagram " };
  } else if (organizationProfile?.tiktokUrl) {
    return { platform: "Tiktok " };
  } else if (organizationProfile?.youtubeUrl) {
    return { platform: "YouTube " };
  } else if (organizationProfile?.twitchUrl) {
    return { platform: "Twitch " };
  } else {
    return { platform: "" };
  }
};

export const VIRTUAL_ASSISTANT_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLSdebMXfxZgSoJ-l4gnORBQUn5AY9kros0nTb3D-cxneTxpDSw/viewform";
