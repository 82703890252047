import { grey } from "@mui/material/colors";

const styles = {
  brandName: {
    maxWidth: {
      xs: "calc(100vw - 100px)",
      md: "350px",
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dialogTitleText: {
    borderBottom: `1px solid ${grey[300]}`,
    zIndex: 10,
    py: 0,
    px: 0,
    backgroundColor: "white",
  },
  dialogGrid: {
    height: 38,
    cursor: "pointer",
    backgroundColor: "yellow.light",
    px: 2,
  },
  dialogWrapper: {
    position: "sticky",
    top: 0,
    right: 0,
    left: 0,
  },
  container: {
    display: "grid",
    gridAutoFlow: "column",
    overflowX: "auto",
  },
  logo: {
    width: 40,
    height: 40,
    borderRadius: 4,
    border: "1px solid",
    borderColor: grey[200],
    objectFit: "contain",
  },
  key: {
    color: grey[600],
    fontSize: 14,
  },
  value: {
    textTransform: "capitalize",
  },
  iconButton: {
    color: "dark.green",
    fontSize: 12,
  },
  section: {
    mt: { xs: 2, md: 4 },
  },
  brandLink: {
    maxWidth: { xs: 300, md: 200 },
    textOverflow: "ellipsis",
    color: "green.main",
  },
  flag: { fontSize: 12, color: grey[500] },
  icon: {
    color: "secondary.dark",
    fontSize: 10,
  },
};

export default styles;
