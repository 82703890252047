const styles = {
  root: {
    background: "#fff",
    borderRadius: "0.5rem",
    color: "rgba(0, 0, 0, 0.8)",
    fontSize: "0.9rem",
    overflow: "hidden",
    padding: "0.2rem",
    position: "relative",
  },
  title: {
    mb: 1,
  },
  chip: {
    mr: 0.5,
    mb: 0.5,
  },
};

export default styles;
