export const pageStyles = {
  pageBody: {
    px: { xs: 2, md: 5 },
    pt: 0,
  },
  pageBodyWithRightNavBar: {
    p: { xs: 2, md: 6 },
    width: { xs: "100vw", lg: "calc(100% - 550px)" },
    pt: { xs: 12, md: 6 }, // to avoid menu bar
  },
  rightNavBar: {
    position: "fixed",
    top: 100, // avoid navigation
    right: 0,
    bottom: 0,
    width: 550,
  },
  pageHeader: {
    fontSize: { xs: 20, md: 35 },
  },
  boardHeaderContainer: {
    mb: 2,
    mt: 2,
  },
};
