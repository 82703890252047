import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Theme,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { BentoBrand, BrandRecommendation } from "schemas/dashboard";

import BrandIcons from "features/Influencer/Discover/Shared/BrandIcons";
import { generateColorFromPalette } from "utils/color";

import CardActions from "./CardActions";

const styles = {
  postImage: {
    height: 157.5,
    objectFit: "cover",
    display: "block",
    margin: "auto",
  },
  card: (fullWidth: boolean) => ({
    width: fullWidth ? "100%" : 280,
    backgroundColor: "white",
    border: `1px solid ${grey[200]}`,
    borderRadius: 4,
  }),
  emptyImageBox: {
    height: 157.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    flexDirection: "column",
    p: 2,
  },
  icons: {
    position: "absolute",
    bottom: 64,
    right: 4,
  },
  selected: (theme: Theme) => ({
    border: `1px solid ${theme.palette.secondary.main}`,
  }),
};

interface IProps {
  recommendation: BrandRecommendation;
  showActions: boolean;
  index: number;
  handleClickCard: (bentoBrand: BentoBrand) => void;
  fullWidth?: boolean;
  selected?: boolean;
}

export default function BrandRecommendationCard({
  recommendation,
  showActions,
  index,
  handleClickCard,
  fullWidth = false,
  selected = false,
}: IProps) {
  const renderDescription = (description: string) => {
    const shortenedDescription =
      description?.length > 100
        ? `${description?.substring(0, 100)}...`
        : description;
    const container = document.createElement("div");
    container.innerHTML = shortenedDescription;

    const clean = container.textContent || container.innerText;

    return clean;
  };

  return (
    <Card
      sx={[styles.card(fullWidth), selected && styles.selected]}
      elevation={0}
    >
      <CardActionArea
        onClick={() => handleClickCard(recommendation.bentoBrand)}
      >
        {recommendation?.bentoBrand?.mainImageUrl ||
        recommendation?.socialMediaPost?.postAssetUrl ? (
          <CardMedia
            component="img"
            sx={styles.postImage}
            alt={`example post for ${recommendation?.bentoBrand?.brandName}`}
            image={
              recommendation?.bentoBrand?.mainImageUrl ||
              recommendation?.socialMediaPost?.postAssetUrl
            }
          />
        ) : (
          <Box
            sx={[
              styles.emptyImageBox,
              {
                backgroundColor: generateColorFromPalette(
                  index,
                  "lightColorPalette",
                ),
              },
            ]}
          >
            <Box
              component="i"
              className={
                recommendation?.socialMediaPost?.postDescription
                  ? "fa-solid fa-video"
                  : "fa-brands fa-instagram"
              }
              sx={{ fontSize: 16, mb: 2 }}
            />
            <Typography sx={{ fontSize: 13 }} textAlign="center">
              {renderDescription(
                recommendation?.socialMediaPost?.postDescription || "",
              )}
            </Typography>
          </Box>
        )}
        <Box sx={styles.icons}>
          <BrandIcons bentoBrand={recommendation.bentoBrand} isCardView />
        </Box>
        {
          <CardActions
            recommendation={recommendation}
            showActions={showActions}
          />
        }
      </CardActionArea>
    </Card>
  );
}
