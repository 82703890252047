import { Typography } from "@mui/material";
import { forwardRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface MarkdownProps {
  content: string;
  size?: "small" | "normal";
}

export default forwardRef<HTMLDivElement, MarkdownProps>(
  ({ content, size = "normal" }, ref) => {
    return (
      <Typography
        component="div"
        fontSize={size === "small" ? 12 : "inherit"}
        ref={ref}
      >
        <ReactMarkdown
          children={content}
          remarkPlugins={[remarkGfm]}
          components={{
            a: ({ node, children, ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
          }}
        />
      </Typography>
    );
  },
);
