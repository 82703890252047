import { grey } from "@mui/material/colors";

const styles = {
  button: {
    backgroundColor: "white",
    color: "primary.dark",
    border: `1px solid`,
    borderColor: "primary.main",
    borderRadius: 2,
    mr: 2,
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
};

export default styles;
