import { Box, Grid, Typography } from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import { getStyles } from "./styles";

function BrandBar() {
  const { selectedBrand } = useContext(BrandsContext);
  const { brandView, handleOpenBrandDetails } = useContext(
    QuickSendDrawerContext,
  );

  const { discoverTab, displayedBrandInfo } = useContext(DiscoverViewContext);

  const styles = getStyles(discoverTab !== null);

  if (
    !selectedBrand ||
    brandView !== QuickSendTabView.COLLAPSE ||
    !displayedBrandInfo
  ) {
    return <></>;
  }

  const handleToggleExpand = (e: CustomEvent) => {
    e.stopPropagation();
    handleOpenBrandDetails(selectedBrand);
    trackEvent("Brand Bar Clicked", {
      button: "Expand View",
    });
  };
  return (
    <Box sx={styles.root}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        onClick={handleToggleExpand}
      >
        <Typography sx={styles.text} noWrap>
          {selectedBrand?.brandName} (Info)
        </Typography>
      </Grid>
    </Box>
  );
}

export default BrandBar;
