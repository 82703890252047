const styles = {
  button: {
    textTransform: "capitalize",
    color: "secondary.dark",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  active: {
    color: "black",
    textTransform: "capitalize",
  },
  separator: {
    px: 0.5,
  },
};

export default styles;
