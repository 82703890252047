import { Extension } from "@tiptap/core";
import { Range, ReactRenderer } from "@tiptap/react";
import { debounce } from "lodash";
import { Node as PMNode } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import tippy, { Instance } from "tippy.js";

import { trackEvent } from "utils/tracking";

import Suggestions, { SuggestionsProps } from "./Suggestions";

export interface Replacement {
  value: string;
}

export interface Context {
  text: string;
  offset: number;
  length: number;
}

export interface Type {
  typeName: string;
}

export interface Category {
  id: string;
  name: string;
}

export interface Rule {
  id: string;
  description: string;
  issueType: string;
  category: Category;
}

export interface Match {
  message: string;
  shortMessage: string;
  replacements: Replacement[];
  offset: number;
  length: number;
  context: Context;
  sentence: string;
  type: Type;
  rule: Rule;
  ignoreForIncompleteSentence: boolean;
  contextForSureMatch: number;
}

export interface LanguageToolResponse {
  matches: Match[];
}

function transformTextForChecking(doc: PMNode) {
  let text = "";
  let previousNodeType: string | undefined = undefined;
  doc.descendants((node, pos) => {
    if (node.isText) {
      text += node.text;
    } else if (
      node.type.name === "merge-tag" ||
      node.type.name === "variable"
    ) {
      text += "X";
    } else if (node.type.name === "paragraph") {
      if (!previousNodeType) {
        text += "\n";
      } else {
        text += "\n\n";
      }
    } else {
      text += "\n";
    }

    previousNodeType = node.type.name;
  });
  return text;
}

async function proofreadDocument(
  text: string,
  apiUrl: string,
  language: string,
): Promise<Decoration[]> {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: `text=${encodeURIComponent(
        text,
      )}&language=${language}&enabledOnly=false`,
    });

    const data: LanguageToolResponse = await response.json();

    if (data.matches.length > 0) {
      trackEvent("Email Template Fetched Language Tool Suggestion", {
        numberOfSuggestions: data.matches.length,
      });
    }

    return data.matches.map((match) =>
      Decoration.inline(
        match.offset,
        match.offset + match.length,
        {
          class: `lt lt-${match.rule.issueType}`,
          "data-match": JSON.stringify(match),
        },
        { title: match.message },
      ),
    );
  } catch (error) {
    // API is unreliable when using the free version
    return [];
  }
}

export const LanguageTool = Extension.create<{
  apiUrl: string;
  language: string;
}>({
  name: "languagetool",

  addOptions() {
    return {
      language: "auto",
      apiUrl: "https://api.languagetool.org/v2/check",
    };
  },

  addProseMirrorPlugins() {
    let component: ReactRenderer<typeof Suggestions, SuggestionsProps>;
    let popup: Instance[];
    let extension = this;

    const onScroll = () => {
      if (popup) {
        popup[0].hide();
      }
    };

    const plugin = new Plugin({
      key: new PluginKey("languagetoolPlugin"),
      props: {
        decorations(state) {
          return this.getState(state);
        },
        handleDOMEvents: {
          click(view, event) {
            const { target } = event;
            if (!(target instanceof HTMLElement)) return false;

            const decorationElement = target.closest(".lt");
            if (!decorationElement) {
              if (popup) {
                popup[0].hide();
              }
              return false;
            }

            const matchData = JSON.parse(
              decorationElement.getAttribute("data-match") || "{}",
            );

            const acceptSuggestion = (matchRange: Range, value: string) => {
              trackEvent("Email Template Selected Language Tool Suggestion", {
                replacement: value,
                matchRange,
              });

              extension.editor.commands.insertContentAt(matchRange, value);
              if (popup) {
                popup[0].hide();
              }

              const decos: DecorationSet = this.getState(
                extension.editor.state,
              );

              const filteredDecos = decos
                .find()
                .filter(
                  (deco) =>
                    deco.from !== matchRange.from && deco.to !== matchRange.to,
                );

              const newDecorationSet = DecorationSet.create(
                extension.editor.state.doc,
                filteredDecos,
              );
              view.dispatch(
                view.state.tr.setMeta(
                  "languagetoolDecorations",
                  newDecorationSet,
                ),
              );
            };

            if (!component) {
              component = new ReactRenderer<
                typeof Suggestions,
                SuggestionsProps
              >(Suggestions, {
                props: {
                  match: matchData,
                  start: view.posAtDOM(decorationElement, 0),
                  command: acceptSuggestion,
                },
                editor: extension.editor,
              });
            } else {
              component.updateProps({
                match: matchData,
                start: view.posAtDOM(decorationElement, 0),
              });
            }
            const pos = view.posAtDOM(target, 0);
            const coordsStart = view.coordsAtPos(pos);

            const clientRect = new DOMRect(
              coordsStart.left,
              coordsStart.top,
              0,
              coordsStart.bottom - coordsStart.top,
            );

            if (!popup) {
              popup = tippy("body", {
                getReferenceClientRect: () => clientRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
              });
              window.addEventListener("wheel", onScroll, { passive: true });
            } else {
              popup[0].setProps({
                getReferenceClientRect: () => clientRect,
              });
              popup[0].show();
            }

            trackEvent("Email Template Clicked Language Tool Decoration", {
              message: matchData?.message,
              shortMessage: matchData?.shortMessage,
              context: matchData?.context,
            });

            return false;
          },
        },
      },
      state: {
        init: (_, { doc }) => DecorationSet.empty,
        apply: (tr, value) => {
          const decos = tr.getMeta("languagetoolDecorations");
          if (decos) {
            return decos;
          }
          return value.map(tr.mapping, tr.doc);
        },
      },
      view: (view) => {
        const proofread = () => {
          const text = transformTextForChecking(view.state.doc);
          const apiUrl = this.options.apiUrl;
          const language = this.options.language;

          proofreadDocument(text, apiUrl, language).then((decorations) => {
            const filteredDecorations = decorations.filter((deco) => {
              let includeDecoration = true;

              if (
                deco.from >= view.state.doc.content.size ||
                deco.to > view.state.doc.content.size
              ) {
                return false;
              }

              view.state.doc.nodesBetween(deco.from, deco.to, (node) => {
                if (
                  node.type.name === "merge-tag" ||
                  node.type.name === "variable"
                ) {
                  includeDecoration = false;
                  return false;
                }
              });

              return includeDecoration;
            });
            const decos = DecorationSet.create(
              view.state.doc,
              filteredDecorations,
            );
            view.dispatch(
              view.state.tr.setMeta("languagetoolDecorations", decos),
            );
          });
        };

        const debouncedProofread = debounce(() => {
          proofread();
        }, 500);

        return {
          update: (view, prevState) => {
            if (view.state.doc !== prevState.doc) {
              debouncedProofread();
            }
          },
          destroy: () => {
            if (popup) {
              popup[0].destroy();
            }
            if (component) {
              component.destroy();
            }
            window.removeEventListener("wheel", onScroll);
          },
        };
      },
    });
    return [plugin];
  },
});
