import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { CustomEvent } from "schemas/functions";
import theme from "styles/theme";

import LocationAutocomplete from "components/LocationAutocomplete";
import { UserLocation } from "components/LocationAutocomplete/schema";
import { fetcherAuth } from "utils/api";

interface CityDialogProps {
  open: boolean;
  handleClose: (city?: string) => void;
}

export default function CityDialog({ open, handleClose }: CityDialogProps) {
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { getToken } = useAuth();
  const { currentOrg, profile, setProfile } = useContext(
    OrganizationUserContext,
  );
  const { setErrorAlert } = useContext(AlertContext);

  const [saveLoading, setSaveLoading] = useState(false);

  const handleSave = async () => {
    if (!profile) {
      return;
    }
    setSaveLoading(true);
    try {
      const { organizationProfile } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/settings`,
        "PUT",
        {},
        profile,
      );
      handleClose(organizationProfile?.city);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const handleLocationChange = (userLocation: UserLocation) => {
    setProfile((profile) => {
      return {
        ...profile,
        city: userLocation.address,
        location: userLocation.country_code,
        addressComponents: userLocation.address_components,
        longitude: userLocation.longitude,
        latitude: userLocation.latitude,
      };
    });
  };

  return (
    <Dialog
      onClick={(e: CustomEvent) => e.stopPropagation()}
      open={open}
      onClose={() => handleClose()}
      fullScreen={isMobileScreen}
    >
      <DialogTitle>
        <Grid container justifyContent="center">
          Get Better Recommendations Based On Your Location ✨
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ mb: 2, zIndex: 50 }}>
          Fill out a <strong>location (zip, city, neighborhood, etc.)</strong>{" "}
          below to find brands near you.
        </Box>
        <LocationAutocomplete handleLocationChange={handleLocationChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          Close
        </Button>
        <LoadingButton onClick={handleSave} loading={saveLoading}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
