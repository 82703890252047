import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function OpenSendDialog() {
  const { bentoBrandId } = useParams();
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);

  useEffect(() => {
    const id = Number(bentoBrandId);
    if (!isNaN(id)) {
      handleOpenQuickSendIndividual(id, "Promotional Email", undefined, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bentoBrandId]);

  return <></>;
}
