import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { useContext, useEffect, useState } from "react";
import { BentoBrand, UserRequest, UserRequestSource } from "schemas/dashboard";

import BrandAutoComplete from "features/Admin/AdminSharedComponents/BrandSearchAutoComplete";
import { fetcherAuth } from "utils/api";

interface FulfillDialogProps {
  open: boolean;
  handleClose: (userRequestToDelete?: UserRequest) => void;
  userRequest: UserRequest | null;
}

export default function FulfillDialog({
  open,
  handleClose,
  userRequest,
}: FulfillDialogProps) {
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<BentoBrand | null>(null);
  const { setAlert, setErrorAlert } = useContext(AlertContext);

  const onBrandsChange = async (
    event: CustomEvent,
    newValue: BentoBrand | null,
  ) => {
    setValue(newValue);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/admin/user-requests/${userRequest?.id}/fulfill`,
        "POST",
        {},
        {
          bentoBrandId: value?.id,
        },
      );
      setAlert("Successfully fulfilled request", "success");
      if (userRequest) handleClose(userRequest);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRequest?.id]);

  if (!userRequest) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>
        Fulfill User Request For `{userRequest?.description}`
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          {userRequest?.source === UserRequestSource.SOURCE_REQUEST_BRAND
            ? " Please type the name of the BentoBrand that we found for the user below."
            : "Once you press Send Email below, the user will receive an email that more brands are added to their search results."}
        </Typography>

        {userRequest?.source === UserRequestSource.SOURCE_REQUEST_BRAND && (
          <>
            <Box sx={{ my: 2 }}>
              <BrandAutoComplete
                setAlert={setAlert}
                value={value}
                onBrandChange={onBrandsChange}
              />
            </Box>
            <FormHelperText>
              Please make sure the BentoBrand you select has BentoContacts. An
              email will be sent to alert the user that we have found this
              BentoBrand with contacts for them.
            </FormHelperText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          disabled={
            userRequest?.source === UserRequestSource.SOURCE_REQUEST_BRAND &&
            !value
          }
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
