import { Map } from "schemas/functions";

import { ON_BEHALF_OF_TOKEN } from "./localStorage";

const INTERNAL_SERVER_ERROR = "doctype html";

const handleThrowError = (result: string) => {
  if (result?.includes(INTERNAL_SERVER_ERROR)) {
    throw new Error("Something went wrong. Please reload and try again.");
  } else {
    throw new Error(result);
  }
};

export const fetcher = async (
  url: string,
  method = "GET",
  headers = {},
  body = {},
  token = "",
  customizations: Map = {},
) => {
  const {
    returnResult = false,
    isForm = false,
    throwError = true,
    signal = null,
  } = customizations;

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  if (!isForm) {
    headers = { ...headers, "Content-Type": "application/json" };
  }

  if (signal) {
    headers = { ...headers };
  }

  let content = {
    method,
    signal,
    headers,
  };

  if (method !== "GET") {
    content = {
      ...content,
      ...{ body: !isForm ? JSON.stringify(body) : body },
    };
  }

  const result = await fetch(url, content);

  if (returnResult) {
    return result;
  }

  const contentType = result.headers.get("content-type");
  if (contentType !== "application/json") {
    const resultText = await result.text();
    if (result.ok) return resultText;
    if (throwError) handleThrowError(resultText);
  }

  const json = await result.json();

  if (result.ok) {
    return json;
  }
  if (throwError) {
    handleThrowError(json.message);
  } else {
    return json;
  }
};

export const fetcherAuth = async (
  getToken: any,
  url: string,
  method = "GET",
  headers = {},
  body = {},
  returnResult = false,
  isForm = false,
  throwError = true,
  signal: AbortSignal | null = null,
) => {
  try {
    const adminToken = sessionStorage.getItem(ON_BEHALF_OF_TOKEN);

    let token = "";
    if (adminToken) {
      token = adminToken;
    } else {
      token = await getToken({ template: "crm" });
    }
    if (token) {
      return fetcher(url, method, headers, body, token, {
        returnResult,
        isForm,
        throwError,
        signal,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const isNotFoundError = (error: Error) => {
  return error?.message?.includes("Not Found");
};

export const arrayQuery = (arr: any, key: string) => {
  return arr.map((i: string) => i && `&${key}=` + i)?.join("");
};
