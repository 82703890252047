import { Box, Button, Menu } from "@mui/material";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import styles from "./styles";

interface IProps {
  id: string;
  label: string;
  children: ReactNode;
  close?: boolean;
  setClose?: Dispatch<SetStateAction<boolean>>;
}

export default function DropdownSelect({
  id,
  label,
  children,
  close,
  setClose,
}: IProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (setClose) setClose(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idName = open ? id : undefined;

  useEffect(() => {
    if (close) {
      handleClose();
    }
  }, [close]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color={open ? "success" : "inherit"}
        sx={{ textTransform: "none" }}
        aria-describedby={idName}
        onClick={handleClick}
      >
        {label}{" "}
        <Box
          component="i"
          className={`fa-regular fa-chevron-${open ? "up" : "down"}`}
          sx={styles.arrow}
        />
      </Button>
      <Menu
        id={idName}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
}
