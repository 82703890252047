import { useAuth } from "@clerk/clerk-react";
import { AdminUserContext } from "contexts/Admin";
import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Alert from "components/Alert";
import Loading from "components/Loading";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

import LogoutPage from "./Logout";
import NavigationBar from "./NavigationBar";

const adminRoutes = [
  "organizations",
  "brands",
  "contacts",
  "elasticsearch",
  "migrations",
  "emails",
  "scraping",
  "referrals",
  "user-requests",
  "csv",
];

function AdminHome() {
  const { getToken } = useAuth();

  const [fetchUserLoading, setFetchUserLoading] = useState<boolean>(false);
  const [message, severity, setAlert, closeAlert] = useAlert();

  const context = useContext(AdminUserContext);
  const { currentAdminUser, setCurrentAdminUser } = {
    ...context,
  };

  const fetchAdminUser = async () => {
    setFetchUserLoading(true);
    try {
      const { adminUser } = await fetcherAuth(
        getToken,
        "/api/admin/users/current",
        "GET",
      );
      if (setCurrentAdminUser) {
        setCurrentAdminUser(adminUser);
      }
    } catch (error) {
      setAlert(
        "An error occurred, you do not have access to this page",
        "warning",
      );
    }
    setFetchUserLoading(false);
  };

  useEffect(() => {
    if (!currentAdminUser) {
      fetchAdminUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  return fetchUserLoading ? (
    <Loading />
  ) : (
    <>
      {!fetchUserLoading ? (
        currentAdminUser ? (
          <>
            <NavigationBar routes={adminRoutes} />
            <Outlet />
          </>
        ) : (
          <LogoutPage />
        )
      ) : null}
      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </>
  );
}

export { AdminHome, adminRoutes };
