import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Message, MessageAttachment } from "schemas/email";

import Email from "./Email";
import styles from "./styles";

interface EmailThreadProps {
  messages: Message[];
  openAttachment: (
    messageId: string,
    attachment: MessageAttachment,
  ) => Promise<void>;
  showSubject?: boolean;
}

export default function EmailThread({
  messages,
  showSubject = true,
  openAttachment,
}: EmailThreadProps) {
  const [expanded, setExpanded] = useState<number[]>([]);
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const handleChange = (panel: number) => {
    const isExpanded = expanded.includes(panel);
    if (isExpanded) {
      setExpanded(expanded.filter((e) => e !== panel));
    } else {
      setExpanded((expanded) => [...expanded, panel]);
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      setExpanded([messages.length - 1]);
    }
    setExpandAll(false);
  }, [messages.length]);

  return (
    <>
      {messages.length > 0 && (
        <Grid container item xs sx={styles.root}>
          {showSubject && (
            <Typography
              variant="h6"
              sx={{ p: 1, mt: 0, mb: 1, fontWeight: 700 }}
            >
              {messages[0]?.subject}
            </Typography>
          )}

          <Email
            showTopBorder={showSubject}
            isExpanded={expanded.includes(0)}
            handleChange={handleChange}
            idx={0}
            message={messages[0]}
            openAttachment={openAttachment}
          />
          {messages.length - 2 > 0 && (
            <>
              {expandAll && (
                <>
                  {messages.slice(1, -1)?.map((message, idx) => {
                    return (
                      <Email
                        isExpanded={expanded.includes(idx + 1)}
                        handleChange={handleChange}
                        idx={idx + 1}
                        key={idx}
                        message={message}
                        openAttachment={openAttachment}
                      />
                    );
                  })}
                </>
              )}
              {!expandAll && (
                <Box
                  sx={styles.expandWrapper}
                  onClick={() => setExpandAll(!expandAll)}
                >
                  <IconButton
                    size="small"
                    onClick={() => setExpandAll(!expandAll)}
                    sx={styles.expandIcon}
                  >
                    {messages.length - 2}
                  </IconButton>
                  <Divider sx={styles.expandDividerOne} />
                  <Divider sx={styles.expandDividerTwo} />
                </Box>
              )}
            </>
          )}
          {messages.length > 1 && (
            <Email
              showTopBorder={messages.length <= 2 || expandAll}
              isExpanded={expanded.includes(messages.length - 1)}
              handleChange={handleChange}
              idx={messages.length - 1}
              message={messages[messages.length - 1]}
              openAttachment={openAttachment}
            />
          )}
        </Grid>
      )}
    </>
  );
}
