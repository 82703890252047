import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ConfirmDialogProps {
  header: string | JSX.Element;
  subheader?: string | JSX.Element;
  open: boolean;
  handleClose?: () => void;
  handleCancel?: () => void;
  handleConfirm: () => void;
  loading?: boolean;
  confirmText?: string;
  cancelText?: string;
}

export default function ConfirmDialog({
  header,
  subheader,
  open,
  handleClose,
  handleConfirm,
  handleCancel,
  loading,
  confirmText,
  cancelText,
}: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>{subheader}</DialogContent>
      <DialogActions>
        {(handleCancel || handleClose) && (
          <Button
            onClick={handleCancel ? handleCancel : handleClose}
            color="secondary"
          >
            {cancelText ? cancelText : "Cancel"}
          </Button>
        )}
        {loading ? (
          <LoadingButton loading={loading}>
            {confirmText ? confirmText : "Confirm"}
          </LoadingButton>
        ) : (
          <Button onClick={handleConfirm}>
            {confirmText ? confirmText : "Confirm"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
