import grey from "@mui/material/colors/grey";

import { drawerStyles } from "components/Drawer/reusableDrawerStyles";

const styles = {
  ...drawerStyles,
  dialogContent: {
    my: 0,
    mx: 0,
    px: { xs: 0.5, sm: 1, md: 2 },
    pt: `10px !important`,
  },
  errorContent: { border: "1px solid", borderColor: "red", borderRadius: 2 },
  errorText: { color: "red" },
  instagramText: {
    width: { xs: "100%", md: 500 },
  },
  dialogTitleText: {
    backgroundColor: "yellow.light",
    borderBottom: `1px solid ${grey[300]}`,
  },
};

export default styles;
