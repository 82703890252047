import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Email, OutreachContact } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";
import { routes } from "schemas/routes";

import { fetcherAuth } from "utils/api";
import { removeFromList } from "utils/updateLocalState";

interface Props {
  contact: OutreachContact;
  scheduledEmail: Email;
  setAlert: SetAlertType;
}

const CancelMessageButton = ({ contact, scheduledEmail, setAlert }: Props) => {
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const {
    contacts,
    setContacts,
    currentContactScheduledEmails,
    setCurrentContactScheduledEmails,
    fetchContact,
  } = useContext(OutreachContactsContext);

  const [loading, setLoading] = useState(false);

  const cancelMessage = async (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!currentOrg?.id) {
      return;
    }

    setLoading(true);
    try {
      const result = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/outreach-contacts/${contact?.id}/cancel-message`,
        "POST",
        {},
        {},
        true,
        false,
        false,
      );
      if (result.ok) {
        removeFromList(contacts, setContacts, contact, "id");
        removeFromList(
          currentContactScheduledEmails,
          setCurrentContactScheduledEmails,
          scheduledEmail,
          "id",
        );
        // navigate back to the inbox tab as all scheduled emails are canceled
        navigate(`/${routes.inbox}`);
        setAlert("Successfully cancelled message", "success");
      } else {
        const contentType = result.headers.get("content-type");
        if (contentType !== "application/json") {
          throw new Error(await result.text());
        }
        const body = await result.json();
        // refetch the current contact, which will also update the contacts
        if (contact?.id) {
          fetchContact(contact.id);
        }
        throw new Error(body.message);
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      loading={loading}
      size="small"
      variant="outlined"
      color="secondary"
      sx={{ textTransform: "none" }}
      onClick={cancelMessage}
    >
      Delete <Box component="i" sx={{ ml: 1 }} className="fa-solid fa-trash" />
    </LoadingButton>
  );
};
export default CancelMessageButton;
