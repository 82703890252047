import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { OrganizationType } from "schemas/dashboard";

import styles from "./styles";

interface LayoutProps {
  body: React.ReactNode;
  type?: OrganizationType;
}

const Layout = ({ body, type }: LayoutProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid>
      {!isMobileScreen && (
        <Grid sx={styles.signInImage}>
          <Typography sx={styles.quote} component="div">
            {!type && "Welcome"}
            {type === OrganizationType.FOUNDER && (
              <>
                <Box component="span" sx={styles.quote}>
                  &#8220;
                </Box>
                The CRM tool to close deals{" "}
                <strong>
                  <i>faster</i>
                </strong>
                .
                <Box component="span" sx={styles.quote}>
                  &#8221;
                </Box>
              </>
            )}
          </Typography>
        </Grid>
      )}
      <Grid item sx={styles.signInContent}>
        {body}
      </Grid>
    </Grid>
  );
};

export default Layout;
