import { grey } from "@mui/material/colors";

const styles = {
  th: {
    borderTop: `1px solid ${grey[300]}`,
    px: 1,
    minWidth: 120,
    whiteSpace: "nowrap",
    opacity: 1,
    position: "sticky",
    cursor: "pointer",
    transition: "width transform 0.2s ease-in-out",
  },
  normalBg: {
    backgroundColor: "yellow.light",
    "&:hover": {
      backgroundColor: "yellow.main",
    },
  },
  filterColor: {
    backgroundColor: "#ebdef0",
    color: "black",
    "&:hover": {
      backgroundColor: "#f5eef8",
    },
  },
  sortColor: {
    backgroundColor: "#d6eaf8",
    color: "black",
    "&:hover": {
      backgroundColor: "#ebf5fb",
    },
  },

  icon: { fontSize: 12, color: "black", width: 20, height: 20 },
};

export default styles;
