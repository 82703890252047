import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { UserNotifications } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";
import theme from "styles/theme";

import {
  BOTH,
  OPT_IN_PHONE,
  RadioOptionForm,
  getRadioOption,
  setNotificationsFromRadioOption,
} from "features/Settings/UserNotifications/helpers";
import { fetcherAuth } from "utils/api";
import { normalizePhone } from "utils/form";
import { makeDeepCopy } from "utils/updateLocalState";

interface NotifyDialogProps {
  setAlert: SetAlertType;
  open: boolean;
  handleClose: () => void;
  handleCreateBrandNotification: () => void;
  notifications: UserNotifications | null;
  setNotifications: Dispatch<SetStateAction<UserNotifications | null>>;
}

export default function NotifyDialog({
  setAlert,
  open,
  handleClose,
  handleCreateBrandNotification,
  notifications,
  setNotifications,
}: NotifyDialogProps) {
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { getToken } = useAuth();
  const { currentOrg, setUserNotifications, profile, setProfile } = useContext(
    OrganizationUserContext,
  );

  const [option, setOption] = useState<string>(getRadioOption(notifications));
  const [phone, setPhone] = useState<string | undefined>("");
  const [saveLoading, setSaveLoading] = useState(false);

  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    setOption(value);
    setNotificationsFromRadioOption(value, setNotifications);
  };

  const handlePhoneNumberChange = (e: CustomEvent) => {
    const formatted = normalizePhone(e.target.value);
    setPhone(formatted);
  };

  const handleSave = async () => {
    const data = {
      ...profile,
      ...notifications,
    };
    if (phone) {
      data["phoneNumber"] = phone;
    }
    try {
      setSaveLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/settings`,
        "PUT",
        {},
        data,
      );
      if (res.organizationProfile) {
        const copy = makeDeepCopy(res.organizationProfile);
        setProfile(copy);
        setUserNotifications(notifications);
        if (handleCreateBrandNotification) {
          handleCreateBrandNotification();
        }
        handleClose();
      }
    } catch (error) {
      setSaveLoading(false);
      setAlert(
        error?.message || "Something went wrong please reload and retry again",
        "error",
      );
    }
  };

  const noPhoneNumberProvided =
    (option === BOTH || option === OPT_IN_PHONE) &&
    !phone &&
    !profile?.phoneNumber;

  return (
    <Dialog
      onClick={(e: CustomEvent) => e.stopPropagation()}
      open={open}
      onClose={handleClose}
      fullScreen={isMobileScreen}
    >
      <DialogTitle>Notifications Settings</DialogTitle>
      <DialogContent>
        <Typography>
          Select how you want to receive your notifications
        </Typography>

        <RadioOptionForm option={option} handleChange={handleChange} />

        {(option === BOTH || option === OPT_IN_PHONE) &&
          !profile?.phoneNumber && (
            <>
              <Typography gutterBottom>
                We do not have your phone number on file. Please input your
                phone number below.
              </Typography>
              <TextField
                label=""
                fullWidth
                placeholder="(750) 932-3498"
                onChange={handlePhoneNumberChange}
                value={phone}
              />
            </>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
        <LoadingButton
          onClick={handleSave}
          disabled={noPhoneNumberProvided}
          loading={saveLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
