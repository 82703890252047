import { red } from "@mui/material/colors";

const styles = {
  backdrop: {
    backgroundColor: "#fff",
    zIndex: 9999,
    position: "absolute",
    borderRadius: 2,
  },
  link: {
    cursor: "pointer",
  },
  formControl: {
    ml: 2,
  },
  checkbox: {
    color: "green.main",
  },
  error: {
    color: red[500],
    "& .MuiFormControlLabel-label": {
      color: red[500],
    },
  },
  checkboxError: {
    color: red[500],
  },
};
export default styles;
