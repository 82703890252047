import { Divider, Grid, Typography } from "@mui/material";

import Alert from "components/Alert";
import StyledPage from "components/Styled/Page";
import { useAlert } from "utils/useAlert";

import AdminDomainSearch from "./DomainSearch";
import AdminEmailVerification from "./EmailVerification";

const AdminMigrations = () => {
  const [message, severity, setAlert, closeAlert] = useAlert();

  return (
    <StyledPage
      content={
        <Grid sx={{ mt: 2 }}>
          <Typography variant="h3" gutterBottom>
            <strong>Migrations</strong>
          </Typography>

          <AdminEmailVerification setAlert={setAlert} />
          <Divider sx={{ my: 3 }} />

          <AdminDomainSearch setAlert={setAlert} />

          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
        </Grid>
      }
    />
  );
};

export default AdminMigrations;
