import {
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import StyledDialogTitle from "./DialogTitle";
import styles from "./styles";

interface StyledDialogProps {
  title: string;
  leftAction?: React.ReactElement;
  rightAction?: React.ReactElement;
  actionContainer?: React.ReactElement | false;
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
}

export default function StyledDialog({
  title,
  leftAction,
  rightAction,
  actionContainer,
  open,
  handleClose,
  children,
}: StyledDialogProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobileScreen}
    >
      <StyledDialogTitle
        title={title}
        leftAction={leftAction}
        rightAction={rightAction}
      />
      <DialogContent>{children}</DialogContent>
      {actionContainer && (
        <DialogActions sx={styles.footer}>{actionContainer}</DialogActions>
      )}
    </Dialog>
  );
}
