import { Drawer } from "@mui/material";
import { grey } from "@mui/material/colors";

interface PermanentDrawerProps {
  drawerWidth: number;
  open: boolean;
  content: JSX.Element;
}

const PermanentDrawer = ({
  drawerWidth,
  open,
  content,
}: PermanentDrawerProps) => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        top: 0,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          border: "none",
          borderLeft: `1px solid ${grey[200]}`,
          top: 0,
          width: drawerWidth,
          overflowY: "hidden",
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      {content}
    </Drawer>
  );
};

export default PermanentDrawer;
