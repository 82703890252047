import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { BrandsContext } from "contexts/Brands";
import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchParams } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";

import { isPage } from "utils/general";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

export default function DiscoverSearchBar() {
  const theme = useTheme();
  const { abortController } = useContext(BrandsContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const brandQuery = searchParams.get(SearchParams.QUERY_DISCOVER);

  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (e: CustomEvent, value: string | undefined) => {
    setSearchQuery(value || "");
  };

  const handleAutocompleteChange = (e: CustomEvent, value: any) => {
    handleSearch(value?.trim() || "");
  };

  const handleKeyDown = (e: CustomEvent) => {
    const value = e.target.value;
    if (e.keyCode === 13) {
      handleSearch(value?.trim() || "");
    }
  };

  const handleSearch = async (value: string) => {
    if (abortController?.current) {
      abortController.current?.abort();
    }
    if (!isPage(routes.brands)) {
      navigate(`/${routes.brands}/all?${SearchParams.QUERY_DISCOVER}=${value}`);
    } else {
      searchParams.delete(SearchParams.SORT_DISCOVER);
      searchParams.set(SearchParams.QUERY_DISCOVER, value);
      setSearchParams(searchParams);
    }

    trackEvent("Search Keyword Entered", { keyword: value });
  };

  const isDiscoverPage = isPage(routes.brands);
  const hideBar = !isDiscoverPage && isMobileScreen;

  return (
    <Grid container alignItems="center">
      {!hideBar && (
        <Grid item xs>
          <Autocomplete
            sx={{ width: "100%" }}
            value={brandQuery || ""}
            freeSolo
            loadingText="Searching..."
            options={[]}
            onInputChange={handleInputChange}
            onChange={handleAutocompleteChange}
            disablePortal
            renderInput={(params) => (
              <TextField
                sx={styles.root}
                {...params}
                placeholder="Search Brand, City, Category, Aesthetic..."
                InputProps={{
                  ...params.InputProps,
                  startAdornment: !isMobileScreen && (
                    <Box
                      component="i"
                      className="fa-regular fa-search"
                      sx={{ px: 2 }}
                    />
                  ),
                }}
                onKeyDown={handleKeyDown}
              />
            )}
          />
        </Grid>
      )}
      {isMobileScreen && !hideBar && (
        <Grid item xs="auto">
          <Button
            variant="contained"
            disableElevation
            sx={styles.searchIcon}
            onClick={() => handleSearch(searchQuery)}
          >
            <Box component="i" className="fa-regular fa-search" />
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
