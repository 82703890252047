import { grey } from "@mui/material/colors";
import { ARROW_COLOR } from "styles/oneOffColor";

import { drawerStyles } from "components/Drawer/reusableDrawerStyles";

const styles = {
  ...drawerStyles,
  dialogTitleText: {
    backgroundColor: "yellow.light",
    borderBottom: `1px solid ${grey[300]}`,
    px: 2,
  },
  upDownButton: {
    mx: 1,
    width: 20,
    height: 20,
    fontSize: 16,
    color: ARROW_COLOR,
  },
  text: {
    fontSize: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "10vw",
  },
  longText: {
    fontSize: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: { xs: 80, sm: 300 },
  },
  icon: {
    color: "secondary.dark",
    fontSize: 10,
  },
  border: {
    borderRight: `1px solid ${grey[300]}`,
    mr: 2,
  },
};

export default styles;
