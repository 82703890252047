import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { BentoContact } from "schemas/dashboard";
import { CustomEvent, Map, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";

interface AdminDomainSearchProps {
  setAlert: SetAlertType;
}

const AdminDomainSearch = ({ setAlert }: AdminDomainSearchProps) => {
  const { getToken } = useAuth();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("all");

  const [domainSearchOption, setDomainSearchOption] = useState<Map>({
    days: 90,
    score: 70,
    minContacts: 3,
    limit: 1,
    bentoBrandId: 1,
    department: [],
  });
  const [contactsFound, setContactsFound] = useState<BentoContact[]>([]);

  const handleDomainSearchChange = (e: CustomEvent) => {
    let { name, value } = e.target;
    if (name === "department") {
      value = typeof value === "string" ? value.split(",") : value;
    }
    setDomainSearchOption((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDomainSearchMigration = async () => {
    if (
      domainSearchOption.days < 0 ||
      domainSearchOption.score < 0 ||
      domainSearchOption.score >= 100 ||
      domainSearchOption.minContacts < 0
    ) {
      setAlert(
        "Days must be larger than 0. Confidence score must be between 0 and 100. Minimum number of contact must be larger than 0",
        "error",
      );
      return;
    }

    const data: Map = {
      days: domainSearchOption.days,
      score: domainSearchOption.score,
      min_contacts: domainSearchOption.minContacts,
    };

    if (domainSearchOption?.limit > 0) {
      data["limit"] = domainSearchOption.limit;
    }
    if (domainSearchOption?.department?.length > 0) {
      data["department"] = domainSearchOption?.department?.join(",");
    }

    try {
      setLoading(true);
      await fetcherAuth(
        getToken,
        `/api/admin/bento-brands/domain-search-migrations`,
        "POST",
        {},
        data,
      );
      setAlert(
        "Successfully starting migration to search domains for BentoBrand",
        "success",
      );
    } catch (error) {
      setAlert(error?.message || "Something went wrong.", "error");
    } finally {
      setLoading(false);
    }
  };

  const findForOneBrand = async () => {
    try {
      setLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/admin/bento-brands/${domainSearchOption?.bentoBrandId}/find-hunter-contacts-for-brand`,
        "POST",
        {},
        {
          score: domainSearchOption?.score,
          department: domainSearchOption?.department?.join(","),
        },
      );
      if (res.bentoContacts?.length > 0) {
        setContactsFound(res.bentoContacts);
      } else {
        setAlert(
          "Hunter could not find any new contacts with this brand",
          "success",
        );
      }
    } catch (error) {
      setAlert(error?.message || "Something went wrong.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = () => {
    if (tab === "all") {
      setTab("specific");
    } else {
      setTab("all");
    }
  };

  return (
    <>
      <Typography variant="h4" sx={{ my: 2 }}>
        <strong>Hunter Email Search / Improve Contact Quality</strong>
      </Typography>

      <Tabs value={tab} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab
          value="all"
          label="Find emails on All BentoBrands"
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="specific"
          label="Find emails on a specific BentoBrand"
          sx={{ textTransform: "none" }}
        />
      </Tabs>
      <Grid item sx={{ mb: 2 }}>
        {tab === "all" && (
          <>
            <Typography>
              Run a migration on all BentoBrands that met the following
              criteria:
              <li>
                has less than <strong>{domainSearchOption?.minContacts}</strong>{" "}
                contacts
              </li>
              <li>
                the brand has not been searched within the last{" "}
                <strong>{domainSearchOption?.days}</strong> days.
              </li>
              <li>
                When creating a new BentoContact, their email address must have
                a "Hunter email confidence score" higher or equal to{" "}
                <strong>{domainSearchOption?.score}</strong>.
              </li>
            </Typography>

            <Grid container gap={2} sx={{ my: 1 }}>
              <TextField
                onChange={handleDomainSearchChange}
                name="minContacts"
                type="number"
                value={domainSearchOption.minContacts}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">contacts</InputAdornment>
                  ),
                }}
              />
              <TextField
                onChange={handleDomainSearchChange}
                name="days"
                type="number"
                value={domainSearchOption.days}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">days</InputAdornment>
                  ),
                }}
              />
              <TextField
                onChange={handleDomainSearchChange}
                name="score"
                type="number"
                value={domainSearchOption.score}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">score</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}

        {tab === "specific" && (
          <>
            <Typography>
              Please input the BentoBrand id you want to find contacts for
            </Typography>
            <TextField
              onChange={handleDomainSearchChange}
              name="bentoBrandId"
              type="number"
              value={domainSearchOption.bentoBrandId}
            />
          </>
        )}
      </Grid>

      <Grid item sx={{ mb: 2 }}>
        <Typography>
          Optionally, find contacts specific to these departments:{" "}
        </Typography>
        <FormControl sx={{ my: 2, width: 500 }}>
          <InputLabel>Department</InputLabel>
          <Select
            value={domainSearchOption?.department}
            multiple
            label="Department"
            name="department"
            onChange={handleDomainSearchChange}
          >
            <MenuItem value={"marketing"}>Marketing</MenuItem>
            <MenuItem value={"communication"}>Communication</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {tab === "all" && (
        <Grid item sx={{ mb: 2 }}>
          <Typography>
            {domainSearchOption.limit > 0 ? (
              <>
                Run this migration on the first found{" "}
                <strong>{domainSearchOption.limit}</strong> BentoBrands that met
                the criteria above.
              </>
            ) : (
              "Run this migration on all BentoBrands"
            )}
          </Typography>
          <TextField
            onChange={handleDomainSearchChange}
            name="limit"
            type="number"
            value={domainSearchOption.limit}
          />
          <FormHelperText>
            Put 0 if you want to run on all BentoBrands.
          </FormHelperText>
        </Grid>
      )}

      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={tab === "all" ? handleDomainSearchMigration : findForOneBrand}
      >
        {tab === "all" ? "Run Migration" : "Find"}
      </LoadingButton>

      {tab === "specific" && (
        <Grid sx={{ my: 3 }}>
          {contactsFound?.map((contact) => (
            <Typography gutterBottom key={contact.id}>
              Contact <strong>{contact?.name}</strong> with email{" "}
              <strong>{contact?.email}</strong> found.
            </Typography>
          ))}
        </Grid>
      )}
    </>
  );
};

export default AdminDomainSearch;
