import { CircularProgress, Grid } from "@mui/material";

import getStyles from "./styles";

interface LoadingProps {
  backgroundColor?: string;
}

const Loading = ({ backgroundColor }: LoadingProps) => {
  const styles = getStyles(backgroundColor);

  return (
    <Grid sx={styles.background}>
      <CircularProgress />
    </Grid>
  );
};

export default Loading;
