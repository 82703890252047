import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext } from "react";
import { BentoBrand } from "schemas/dashboard";

import { getIconsCondition } from "../DetailedBrandView/helpers";

const styles = {
  icon: {
    fontSize: { xs: 14, sm: 16 },
    mx: 0.5,
  },
  paidPartnershipBadge: (isCardView?: boolean) => ({
    color: isCardView ? "#45A37A" : "#32885F",
  }),
  worksWithUgcBadge: (isCardView?: boolean) => ({
    color: isCardView ? "#73ADF2" : "#388BED",
  }),
  bolt: (isCardView?: boolean) => ({
    color: isCardView ? "#786EEB" : "#786EEB",
  }),
  star: (isCardView?: boolean) => ({
    color: isCardView ? "#EDDB38" : "#EDDB38",
  }),
  locationPin: (isCardView?: boolean) => ({
    color: isCardView ? "#B45353" : "#CE6868",
  }),
  tiktok: (isCardView?: boolean) => ({
    color: isCardView ? "#00f2ea" : "#010101",
  }),
  directContact: (isCardView?: boolean) => ({
    color: isCardView ? "#FFD199" : "#F6BB75",
  }),
  tag: {
    fontSize: 12,
    color: grey[600],
    textTransform: "capitalize",
  },
};

interface Props {
  bentoBrand: BentoBrand;
  isBrandView?: boolean;
  isCardView?: boolean;
}

export default function BrandIcons({
  bentoBrand,
  isBrandView,
  isCardView,
}: Props) {
  const { profile } = useContext(OrganizationUserContext);

  const {
    addedWithinLastWeek,
    hasPaidPartnership,
    worksWithUgc,
    noRecentContacts,
    hasTiktokAds,
    hasNonGenericEmails,
  } = getIconsCondition(bentoBrand);

  const isUserCity = () => {
    if (!profile?.city || !(profile?.city?.length > 0)) {
      return;
    }
    return bentoBrand?.locationNames?.includes(profile?.city?.toLowerCase());
  };

  const getContainerStyles = () => {
    const styles = {
      ml: -0.5,
      p: 0,
      mb: 0,
    };
    return styles;
  };

  const renderIcons = () => {
    return (
      <Grid
        item
        container
        xs="auto"
        alignItems="center"
        sx={getContainerStyles()}
      >
        {isUserCity() && (
          <Tooltip enterTouchDelay={0} title="Close to your city">
            <Box
              component="i"
              className="fa-solid fa-location-pin"
              sx={[styles.icon, styles.locationPin(isCardView)]}
            />
          </Tooltip>
        )}
        {hasPaidPartnership && (
          <Tooltip enterTouchDelay={0} title="Verified Paid Partnership">
            <Box
              component="i"
              className="fa-solid fa-suitcase"
              sx={[styles.icon, styles.paidPartnershipBadge(isCardView)]}
            />
          </Tooltip>
        )}
        {worksWithUgc && (
          <Tooltip enterTouchDelay={0} title="Works with UGC creators">
            <Box
              component="i"
              className="fa-solid fa-tag"
              sx={[styles.icon, styles.worksWithUgcBadge(isCardView)]}
            />
          </Tooltip>
        )}
        {addedWithinLastWeek && (
          <Tooltip enterTouchDelay={0} title="Added within last week">
            <Box
              sx={[styles.icon, styles.bolt(isCardView)]}
              component="i"
              className="fa-solid fa-bolt"
            />
          </Tooltip>
        )}
        {hasTiktokAds && (
          <Tooltip enterTouchDelay={0} title="Runs TikTok Ads">
            <Box
              component="i"
              sx={[styles.icon, styles.tiktok(isCardView)]}
              className="fa-brands fa-tiktok"
            />
          </Tooltip>
        )}
        {noRecentContacts && (
          <Tooltip enterTouchDelay={0} title="Not contacted this week">
            <Box
              component="i"
              sx={[styles.icon, styles.star(isCardView)]}
              className="fa-solid fa-star"
            />
          </Tooltip>
        )}
        {hasNonGenericEmails && (
          <Tooltip enterTouchDelay={0} title="Has a direct contact">
            <Box
              component="i"
              sx={[styles.icon, styles.directContact(isCardView)]}
              className="fa-solid fa-user"
            />
          </Tooltip>
        )}
      </Grid>
    );
  };

  const renderForBrandsView = () => {
    return (
      <>
        {isUserCity() && (
          <Grid container alignItems="center" item xs={12}>
            <Box
              sx={[styles.icon, styles.locationPin(isCardView)]}
              component="i"
              className="fa-solid fa-location-pin fa-fw"
            />
            <Typography gutterBottom>Close to your city</Typography>
          </Grid>
        )}
        {hasPaidPartnership && (
          <Grid container alignItems="center" item xs={12}>
            <Box
              component="i"
              className="fa-solid fa-briefcase fa-fw"
              sx={[styles.icon, styles.paidPartnershipBadge(isCardView)]}
            />
            <Typography gutterBottom>Verified Paid Partnership</Typography>
          </Grid>
        )}
        {worksWithUgc && (
          <Grid container alignItems="center" item xs={12}>
            <Box
              component="i"
              className="fa-solid fa-tag fa-fw"
              sx={[styles.icon, styles.worksWithUgcBadge(isCardView)]}
            />
            <Typography gutterBottom>Works with UGC creators</Typography>
          </Grid>
        )}

        {addedWithinLastWeek && (
          <Grid container alignItems="center" item xs={12}>
            <Box
              sx={[styles.icon, styles.bolt(isCardView)]}
              component="i"
              className="fa-solid fa-bolt fa-fw"
            />
            <Typography gutterBottom>Added within last week</Typography>
          </Grid>
        )}

        {hasTiktokAds && (
          <Grid container alignItems="center" item xs={12}>
            <Box
              sx={[styles.icon, styles.tiktok(isCardView)]}
              component="i"
              className="fa-brands fa-tiktok fa-fw"
            />
            <Typography gutterBottom>Runs TikTok Ads</Typography>
          </Grid>
        )}

        {noRecentContacts && (
          <Grid container alignItems="center" item xs={12}>
            <Box
              component="i"
              sx={[styles.icon, styles.star(isCardView)]}
              className="fa-solid fa-star fa-fw"
            />
            <Typography gutterBottom>Not contacted this week</Typography>
          </Grid>
        )}

        {hasNonGenericEmails && (
          <Grid container alignItems="center" item xs={12}>
            <Box
              component="i"
              sx={[styles.icon, styles.directContact(isCardView)]}
              className="fa-solid fa-user fa-fw"
            />
            <Typography gutterBottom>Has a direct contact</Typography>
          </Grid>
        )}
      </>
    );
  };

  if (isBrandView) {
    return renderForBrandsView();
  } else {
    return renderIcons();
  }
}
