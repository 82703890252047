import DOMPurify from "dompurify";

export function sanitize(html: string) {
  // Remove tracking pixels
  DOMPurify.addHook("uponSanitizeElement", (node, data) => {
    if (data.tagName === "img") {
      const hasTrackingAttributes =
        node.getAttribute("width") === "0" &&
        node.getAttribute("height") === "0";

      if (hasTrackingAttributes) {
        return node.parentNode?.removeChild(node);
      }
    }
  });
  const sanitizedHtml = DOMPurify.sanitize(html, { ADD_ATTR: ["size-id"] });

  DOMPurify.removeHook("uponSanitizeElement");

  return sanitizedHtml;
}
