import { Box, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendContactsContext } from "contexts/QuickSendContacts";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import moment from "moment";
import { useContext } from "react";
import { NewBentoContact } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { HandleChangePreviewFunction } from "schemas/outreach";

import { makeDeepCopy } from "utils/updateLocalState";

import SelectContact from "./SelectContact";
import styles from "./styles";

interface Props {
  handleAddNewContact?: (
    newContact: NewBentoContact,
    handleClose: () => void,
  ) => void;
  changePreview: HandleChangePreviewFunction;
}

const SharedEmailParts = ({ handleAddNewContact, changePreview }: Props) => {
  const { errors, setErrors } = useContext(QuickSendDrawerContext);
  const { bentoBrand, bentoContact } = useContext(QuickSendContext);
  const { outreachMessages, setTemplateForQuickSend } =
    useContext(QuickSendContext);
  const { getLastContacted } = useContext(QuickSendContactsContext);

  const handleEditSubject = (e: CustomEvent) => {
    const { value } = e.target;
    changePreview("subject", value, 0);
    setTemplateForQuickSend((prev) => {
      const copy = makeDeepCopy(prev);
      if (copy && copy[0]) {
        copy[0]["subject"] = value;
      }
      return copy;
    });
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        outreachSubject: false,
      };
    });
  };

  const lastContacted = bentoContact?.email
    ? getLastContacted(bentoContact?.email)
    : "";

  return (
    <Box>
      {bentoBrand && (
        <SelectContact
          handleAddNewContact={handleAddNewContact}
          brand={bentoBrand}
        />
      )}

      {lastContacted && (
        <Box sx={[styles.subjectWrapper, styles.border]}>
          <Typography component="span" sx={styles.annotation}>
            Last Contacted On:
          </Typography>
          <Typography>
            {moment(lastContacted)?.format("MMM DD, YYYY")}
            <Box
              component="i"
              className="fa-solid fa-circle-check"
              sx={{ ml: 1, color: "green.light" }}
            />
          </Typography>
        </Box>
      )}

      <Box sx={[styles.subjectWrapper, { height: 40 }]}>
        <Typography component="span" sx={styles.annotation}>
          Subject:
        </Typography>
        <TextField
          size="small"
          value={outreachMessages[0]?.subject || ""}
          onChange={handleEditSubject}
          fullWidth
          variant="standard"
          placeholder={
            errors?.outreachSubject ? "Please fill in the subject" : "Subject"
          }
          error={errors.outreachSubject}
          InputProps={{
            disableUnderline: !errors.outreachSubject,
          }}
          inputProps={{
            sx: {
              "&::placeholder": {
                color: errors?.outreachSubject ? "red" : `${grey[500]}`,
                opacity: 1,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default SharedEmailParts;
