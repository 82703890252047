const styles = {
  content: {
    overflowY: "visible",
  },
  timezonePicker: {
    mb: 2,
  },
  tipContainer: {
    p: 2,
    mt: 2,
    backgroundColor: "yellow.light",
  },
  bottomDialog: {
    position: "absolute",
    bottom: 0,
  },
};

export default styles;
