import { Box, Typography, capitalize } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import { Template } from "features/Influencer/ContactList/schema";
import TemplateBody from "features/Influencer/Templates/SharedComponents/TemplateBody";

interface FinalizeFollowupStepProps {
  template: Template;
  setTemplate: React.Dispatch<SetStateAction<Template>>;
  displayError: boolean;
  isDirty?: boolean;
  setIsDirty?: Dispatch<SetStateAction<boolean>>;
}

export default function FinalizeFollowupStep({
  template,
  setTemplate,
  displayError,
  isDirty,
  setIsDirty,
}: FinalizeFollowupStepProps) {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Edit and Save {capitalize(template?.contentType)} Template
      </Typography>

      <TemplateBody
        editTemplate={template}
        setEditTemplate={setTemplate}
        displayError={displayError}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
      />
      <p>Make any edits to this template!</p>
    </Box>
  );
}
