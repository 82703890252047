import { Box, Grid, Link, Typography } from "@mui/material";
import { BentoBrand, BentoBrandProduct } from "schemas/dashboard";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface Props {
  bentoBrand: BentoBrand;
  products: BentoBrandProduct[];
}

export default function BrandProducts({ products, bentoBrand }: Props) {
  const onClick = (item: BentoBrandProduct) => {
    trackEvent("Brand Product Clicked", {
      product: item?.name,
      "Bento Brand ID": bentoBrand?.id,
    });
    if (item?.onlineStoreUrl) {
      window.open(item.onlineStoreUrl, "_blank");
    } else {
      window.open(bentoBrand?.website, "_blank");
    }
  };

  return (
    <>
      <Typography variant="h6" paragraph component="div">
        Products
      </Typography>
      <Grid container justifyContent="flex-start" gap={2} sx={styles.container}>
        {products?.map((item, index) => (
          <Box key={index}>
            <Link onClick={() => onClick(item)}>
              <Box
                component="img"
                src={item.imageUrl}
                loading="lazy"
                sx={styles.image}
              />
            </Link>

            <Box sx={styles.postBy}>
              <Link onClick={() => onClick(item)}>{item.name}</Link>
            </Box>
          </Box>
        ))}
      </Grid>
    </>
  );
}
