import {
  BentoBrand,
  BentoContact,
  NewBentoContact,
  PreviewMessage,
} from "schemas/dashboard";
import { Conditions } from "schemas/outreach";

export type Column = {
  key: string;
  name: string;
};

export type Template = {
  id?: number;
  createdAt?: number;
  updatedAt?: number;
  name: string;
  subject: string;
  type?: string;
  contentType: TemplateContentType;
  body?: string;
  attachment?: string;
  // Use for QuickSend only
  emailTemplateId?: number;
  // Use for Template Creation only
  bentoTemplateId?: number;
  // Use for OutreachDraft only
  isAutogenerated?: boolean;
};

export enum MergeTags {
  first_name = '<span data-type="merge-tag" class="merge-tag" data-label="First Name">{First Name}</span>',
  brand_name = '<span data-type="merge-tag" class="merge-tag" data-label="Brand Name">{Brand Name}</span>',
}

export enum MergeTagLabel {
  first_name = `{First Name}`,
  brand_name = `{Brand Name}`,
}

export type EmailPreview = {
  bentoBrand: BentoBrand;
  bentoContact: BentoContact;
  bentoContactId?: number;
  outreachMessages: PreviewMessage[];
  contactMethod: string;
  newContact?: NewBentoContact;
  to: string;
};

export enum TemplateContentType {
  OUTREACH = "outreach",
  FOLLOWUP = "followup",
  INBOX = "inbox",
}

export enum CreateTemplateStatus {
  SCRATCH = "scratch",
  MAGIC = "magic",
}

export type InstagramSession = {
  username: string;
  reconnectRequired: boolean;
};

export type ConfirmMessage = {
  bentoContactId?: number;
  bentoBrandId?: number;
  subject?: string;
  body?: string;
  contentType: TemplateContentType;
  attachment?: string;
  message?: string;
};

export type TabCountMap = { [key: string]: number };

export type HandleSendEmail = (
  sendAt?: null | string,
  conditions?: Conditions,
) => void;
