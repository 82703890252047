import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { SetAlertType } from "schemas/functions";

import Referral from "features/Settings/Referral";
import { trackEvent } from "utils/tracking";

import Subscription from "./Subscription";

interface IProps {
  open: boolean;
  onClose: () => void;
  setAlert: SetAlertType;
}

export function UpgradeDialog({ open, onClose, setAlert }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { upgradeDialogSource } = useContext(SubscriptionContext);

  const close = () => {
    onClose();
    setTimeout(() => {
      searchParams.delete("checkout");
      setSearchParams(searchParams);
    }, 500);
  };

  useEffect(() => {
    if (open) {
      trackEvent("Shown Paywall Dialog", {
        Reason: upgradeDialogSource,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="lg"
      fullScreen={isMobileScreen}
    >
      {searchParams.get("checkout") !== "failed" && (
        <>
          <DialogTitle variant="h4">Subscription</DialogTitle>
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
            <Subscription setAlert={setAlert} />

            <Divider sx={{ my: 4 }} />

            <Referral setAlert={setAlert} />

            <Box sx={{ my: 3 }} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
