import { green, grey, red } from "@mui/material/colors";

const styles = {
  tableContainer: {
    border: "1px solid",
    maxHeight: { xs: "auto", md: "calc(100vh - 350px)" },
    borderColor: grey[200],
  },
  tableGridContainer: {
    width: "calc(100% - 100px)",
  },
  arrowGrid: {
    cursor: "pointer",
    width: "50px",
    height: "100%",
    alignItems: "center",
    display: "flex",
    background: "white",
  },
  hideIcon: {
    visibility: "hidden",
  },
  arrowVisible: {
    background: grey[600],
  },
  arrow: {
    color: "white",
  },
  row: {
    "&:last-child td": {
      borderBottom: 0,
    },
  },
  headerCell: {
    minWidth: "250px",
    borderRight: `1px solid ${grey[200]}`,
    px: 1,
    py: 1,
    "&:last-child": {
      borderRight: 0,
    },
  },
  successColor: {
    color: green[700],
  },
  errorColor: {
    color: "white",
  },
  headerIcon: {
    mr: 1,
  },
  headerButton: {
    textTransform: "none",
    justifyContent: "flex-start",
  },
  cell: {
    borderRight: `1px solid ${grey[200]}`,
    "&:last-child": {
      borderRight: 0,
    },
    color: grey[700],
  },
  errorHeaderCell: {
    backgroundColor: red[900],
  },
  errorCell: {
    backgroundColor: red[50],
  },
  skippedCell: {
    backgroundColor: grey[50],
  },
  backdrop: {
    backgroundColor: "#fff",
    zIndex: 9999,
    position: "absolute",
    borderRadius: 2,
  },
  mustMatchEmail: {
    color: red[900],
  },
};
export default styles;
