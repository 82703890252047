import { Box, Drawer, Grid, IconButton } from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { forwardRef, useContext, useState } from "react";
import { BentoBrand, SavedBrandStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import BrandList from "./BrandList";
import FavoriteListTabMobile from "./MobileTab";
import ProgressBar from "./ProgressBar";
import styles from "./styles";

interface Props {
  displayedBrands: BentoBrand[];
  loading: boolean;
}

const FavoritesListMobile = forwardRef(
  ({ displayedBrands, loading }: Props, ref) => {
    const { tab, setTab, draftTotal, savedBrandTotal } =
      useContext(SavedBrandContext);

    const [expanded, setExpanded] = useState<boolean>(false);

    const onClose = () => {
      setExpanded((prevExpand) => !prevExpand);
    };

    const handleChange = (event: CustomEvent, newValue: SavedBrandStatus) => {
      event.stopPropagation();
      trackEvent("Saved Brand Tab Clicked", { tab: newValue });
      setTab(newValue);
      setExpanded(true);
    };

    const getTotal = (status: SavedBrandStatus) => {
      if (status === SavedBrandStatus.DRAFTS) {
        return draftTotal;
      } else {
        return savedBrandTotal;
      }
    };

    return (
      <Drawer
        open={expanded}
        onClose={onClose}
        variant="permanent"
        anchor={"bottom"}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={styles.header}
        >
          <Grid container justifyContent="flex-start" item xs={10}>
            <FavoriteListTabMobile
              status={SavedBrandStatus.SAVED}
              label="Saved"
              total={getTotal(SavedBrandStatus.SAVED)}
              tab={tab}
              handleChange={handleChange}
            />
            <FavoriteListTabMobile
              status={SavedBrandStatus.DRAFTS}
              label="Drafts"
              total={getTotal(SavedBrandStatus.DRAFTS)}
              tab={tab}
              handleChange={handleChange}
            />
          </Grid>
          <IconButton sx={styles.headerButton} onClick={onClose}>
            <Box
              component="i"
              className={`fa-solid fa-chevron-${expanded ? "down" : "up"}`}
            />
          </IconButton>
        </Grid>

        {expanded && (
          <Grid container sx={styles.mobileContainer} gap={1}>
            <BrandList
              displayedBrands={displayedBrands}
              loading={loading}
              ref={ref}
            />
            <ProgressBar />
          </Grid>
        )}
      </Drawer>
    );
  },
);

export default FavoritesListMobile;
