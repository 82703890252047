import { arrayMove } from "@dnd-kit/sortable";
import { PortfolioSitesEditContext } from "contexts/PortfolioSitesEdit";
import { useContext } from "react";
import {
  ButtonType,
  ElementType,
  FooterSection,
  GallerySection,
  GeneralSection,
  MediaBorderType,
  MediaFit,
  NavigationSection,
  PaletteType,
  PortfolioSiteSection,
  SectionType,
  SiteElement,
  SiteFont,
  SiteLink,
  SiteMedia,
  TypographyType,
} from "schemas/portfolioSite";
import { v4 as uuidv4 } from "uuid";

export const usePortfolioSite = () => {
  const { addRevision, latestRevision } = useContext(PortfolioSitesEditContext);

  const rearrangeLinks = (
    activeId: string,
    overId: string,
    section: FooterSection,
    type: "socials" | "links",
  ) => {
    if (!latestRevision) return;
    const newSection = { ...section };
    const oldIndex = newSection[type].findIndex((l) => l.id === activeId);
    const newIndex = newSection[type].findIndex((l) => l.id === overId);
    newSection[type] = arrayMove(newSection[type], oldIndex, newIndex);
    const newRevision = {
      ...latestRevision,
      sections: latestRevision.sections.map((sec) =>
        sec.id === newSection.id ? newSection : sec,
      ),
    };
    addRevision(newRevision);
  };

  const replaceSection = (newSection: PortfolioSiteSection) => {
    if (!latestRevision) return;
    const newRevision = {
      ...latestRevision,
      sections: latestRevision.sections.map((sec) =>
        sec.id === newSection.id ? newSection : sec,
      ),
    };
    addRevision(newRevision);
  };

  const addNewMedia = (section: GallerySection) => {
    if (!latestRevision) return;
    let newMedia: SiteMedia;
    if (section.media[0]) {
      newMedia = {
        element_type: ElementType.media,
        id: uuidv4(),
        aspect_ratio: section.media[0].aspect_ratio,
        fit: section.media[0].fit,
        border_type: section.media[0].border_type,
        alt_text: "",
        href: null,
        asset: null,
      };
    } else {
      newMedia = {
        element_type: ElementType.media,
        id: uuidv4(),
        aspect_ratio: 1,
        fit: MediaFit.cover,
        border_type: MediaBorderType.rounded,
        alt_text: "",
        href: null,
        asset: null,
      };
    }
    const newSection = { ...section, media: [...section.media, newMedia] };
    replaceSection(newSection);
  };

  const addNewLink = (section: FooterSection, type: "socials" | "links") => {
    if (!latestRevision) return;
    let newLink: SiteLink | undefined;
    if (type === "socials") {
      newLink = {
        id: uuidv4(),
        element_type: ElementType.link,
        href: {
          to: "https://instagram.com",
          anchor: null,
          open_new_tab: true,
        },
        text: null,
        icon: "fa-solid fa-question",
        color: section[type][0]?.color || null,
      };
    } else {
      newLink = {
        id: uuidv4(),
        element_type: ElementType.link,
        href: {
          to: null,
          anchor: latestRevision.sections[1].id || "",
          open_new_tab: false,
        },
        text: "new link",
        icon: null,
        color: section[type][0]?.color || null,
      };
    }
    const newSection = { ...section, [type]: [...section[type], newLink] };
    replaceSection(newSection);
  };

  const _processSection = (
    elementId: string,
    newElement: SiteElement | null,
    section: PortfolioSiteSection,
  ) => {
    if (section.section_type === SectionType.gallery) {
      const gallerySection = section as GallerySection;
      if (gallerySection.title?.id === elementId) {
        return { ...gallerySection, title: newElement };
      }
      if (gallerySection.media.findIndex((m) => m.id === elementId) > -1) {
        if (newElement === null) {
          return {
            ...gallerySection,
            media: gallerySection.media.filter((m) => m.id !== elementId),
          };
        } else {
          return {
            ...gallerySection,
            media: gallerySection.media.map((m) =>
              m.id === elementId ? (newElement as SiteMedia) : m,
            ),
          };
        }
      }
    } else if (section.section_type === SectionType.general) {
      const generalSection = section as GeneralSection;
      if (generalSection.title?.id === elementId) {
        return { ...generalSection, title: newElement };
      }
      for (let colIdx = 0; colIdx < generalSection.columns.length; colIdx++) {
        if (
          generalSection.columns[colIdx].elements.findIndex(
            (e) => e.id === elementId,
          ) > -1
        ) {
          if (newElement === null) {
            return {
              ...generalSection,
              columns: generalSection.columns.map((col, idx) =>
                idx === colIdx
                  ? {
                      ...generalSection.columns[colIdx],
                      elements: generalSection.columns[colIdx].elements.filter(
                        (e) => e.id !== elementId,
                      ),
                    }
                  : col,
              ),
            };
          } else {
            return {
              ...generalSection,
              columns: generalSection.columns.map((col, idx) =>
                idx === colIdx
                  ? {
                      ...generalSection.columns[colIdx],
                      elements: generalSection.columns[colIdx].elements.map(
                        (e) => (e.id === elementId ? newElement : e),
                      ),
                    }
                  : col,
              ),
            };
          }
        }
      }
    } else if (section.section_type === SectionType.navigation) {
      const navigationSection = section as NavigationSection;
      if (navigationSection.links.findIndex((l) => l.id === elementId) > -1) {
        if (newElement === null) {
          return {
            ...navigationSection,
            links: navigationSection.links.filter((e) => e.id !== elementId),
          };
        } else {
          return {
            ...navigationSection,
            links: navigationSection.links.map((e) =>
              e.id === elementId ? newElement : e,
            ),
          };
        }
      } else if (
        navigationSection.buttons.findIndex((b) => b.id === elementId) > -1
      ) {
        if (newElement === null) {
          return {
            ...navigationSection,
            buttons: navigationSection.buttons.filter(
              (e) => e.id !== elementId,
            ),
          };
        } else {
          return {
            ...navigationSection,
            buttons: navigationSection.buttons.map((e) =>
              e.id === elementId ? newElement : e,
            ),
          };
        }
      }
    } else if (section.section_type === SectionType.footer) {
      const footerSection = section as FooterSection;
      if (footerSection.title?.id === elementId) {
        return { ...footerSection, title: newElement };
      }
      let attribute: "links" | "socials" | undefined;
      if (footerSection.links.findIndex((l) => l.id === elementId) > -1) {
        attribute = "links";
      } else if (
        footerSection.socials.findIndex((l) => l.id === elementId) > -1
      ) {
        attribute = "socials";
      }
      if (attribute) {
        if (newElement === null) {
          return {
            ...footerSection,
            [attribute]: footerSection[attribute].filter(
              (l) => l.id !== elementId,
            ),
          };
        } else {
          return {
            ...footerSection,
            [attribute]: footerSection[attribute].map((l) =>
              l.id === elementId ? newElement : l,
            ),
          };
        }
      }
    }

    return section;
  };

  const replaceElement = (newElement: SiteElement, sectionId?: string) => {
    if (!latestRevision) return;
    const newRevision = {
      ...latestRevision,
      sections: latestRevision.sections.map((section) => {
        if (!sectionId || section.id === sectionId) {
          return _processSection(newElement.id, newElement, section);
        }
        return section;
      }),
    };
    addRevision(newRevision);
  };

  const deleteElement = (elementId: string, sectionId?: string) => {
    if (!latestRevision) return;
    const newRevision = {
      ...latestRevision,
      sections: latestRevision.sections.map((section) => {
        if (!sectionId || section.id === sectionId) {
          return _processSection(elementId, null, section);
        }
        return section;
      }),
    };
    addRevision(newRevision);
  };

  const deleteSection = (sectionId: string) => {
    if (!latestRevision) return;
    const newRevision = {
      ...latestRevision,
      sections: latestRevision.sections.filter((s) => s.id !== sectionId),
    };
    addRevision(newRevision);
  };

  const updateButton = (buttonType: ButtonType, key: string, value: any) => {
    if (!latestRevision) return;
    const newRevision = {
      ...latestRevision,
      buttonStyles: {
        ...latestRevision.buttonStyles,
        [buttonType]: {
          ...latestRevision.buttonStyles[buttonType],
          [key]: value,
        },
      },
    };
    addRevision(newRevision);
  };

  const updatePalette = (paletteType: PaletteType, newColor: string | null) => {
    if (!latestRevision) return;
    const newRevision = {
      ...latestRevision,
      palette: {
        ...latestRevision.palette,
        [paletteType]: {
          color: newColor,
        },
      },
    };
    addRevision(newRevision);
  };

  const updateFonts = (
    typographies: (ButtonType | TypographyType)[],
    key: string,
    value: any,
  ) => {
    if (!latestRevision) return;

    const newFonts = typographies.reduce<{ [key in string]: SiteFont }>(
      (acc, typography) => {
        acc[typography] = {
          ...latestRevision.siteFonts.fonts[typography],
          [key]: value,
        };
        return acc;
      },
      {},
    );

    const newRevision = {
      ...latestRevision,
      siteFonts: {
        ...latestRevision.siteFonts,
        fonts: {
          ...latestRevision.siteFonts.fonts,
          ...newFonts,
        },
      },
    };

    addRevision(newRevision);
  };

  return {
    deleteElement,
    replaceElement,
    replaceSection,
    addNewMedia,
    rearrangeLinks,
    addNewLink,
    deleteSection,
    updateButton,
    updateFonts,
    updatePalette,
  } as const;
};
