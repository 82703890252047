import { Box, ButtonBase } from "@mui/material";

import styles from "./styles";

interface Props {
  hideView: boolean;
  clickArrow: () => void;
}

const CollapsibleButton = ({ hideView, clickArrow }: Props) => {
  return (
    <ButtonBase sx={styles.arrowContainer} onClick={clickArrow}>
      <Box
        sx={styles.arrowIcon}
        component="i"
        className={
          hideView
            ? "fa-thin fa-arrow-left-from-line"
            : "fa-thin fa-arrow-right-from-line"
        }
      />
    </ButtonBase>
  );
};

export default CollapsibleButton;
