import { LoadingButton } from "@mui/lab";
import {
  Box,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import lodash from "lodash";
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useContext,
  useState,
} from "react";
import { ContactProperty, PropertyType } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import ColorPicker from "components/ColorPicker";
import {
  ADD_OPTION_CLICKED,
  COLOR_SELECTED,
  COLOR_VIEWED,
  COLUMN_ID,
  COLUMN_NAME,
  COLUMN_TYPE,
  NEW_COLUMN_TYPE_SELECTED,
  REMOVE_OPTION_CLICKED,
} from "features/Influencer/Tracking/events";
import {
  ColumnIcons,
  SELECT_COLOR_OPTIONS,
  SelectOption,
} from "features/Influencer/Tracking/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedType?: PropertyType | null;
  setSelectedType?: Dispatch<SetStateAction<PropertyType | null>>;
  closeDrawer?: () => void;
  editedProperty?: ContactProperty;
}

export default function CustomColumnDialog({
  open,
  handleClose,
  selectedType,
  setSelectedType,
  closeDrawer,
  editedProperty,
}: Props) {
  const { trackTable } = useContext(ContactViewContext);
  const defaultProperty = {
    type: selectedType || PropertyType.longText,
    name: "",
    id: -1,
  };

  const rc1 =
    SELECT_COLOR_OPTIONS[
      Math.floor(Math.random() * SELECT_COLOR_OPTIONS.length)
    ];
  const rc2 =
    SELECT_COLOR_OPTIONS[
      Math.floor(Math.random() * SELECT_COLOR_OPTIONS.length)
    ];
  const defaultOptions = [
    {
      name: "My Option 1",
      color: rc1,
    },
    {
      name: "My Option 2",
      color: rc2,
    },
  ];
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { updateProperty } = useContext(ContactViewContext);
  const [displayColorPickerIndex, setDisplayColorPickerIndex] = useState(-1);
  const [property, setProperty] = useState<ContactProperty>(
    editedProperty?.id ? editedProperty : defaultProperty,
  );
  const isEdited = editedProperty?.id!!;
  const [options, setOptions] = useState<SelectOption[]>(defaultOptions);
  const trackingData = {
    [COLUMN_TYPE]: property?.type,
    [COLUMN_NAME]: property?.name,
    [COLUMN_ID]: property?.id,
  };

  const handleChange = (e: CustomEvent) => {
    trackTable(NEW_COLUMN_TYPE_SELECTED, trackingData);
    if (setSelectedType) setSelectedType(e.target.value);
  };

  const handleChangeOption = (e: CustomEvent, name: string, index: number) => {
    const { value } = e.target;
    const copy = makeDeepCopy(options);
    copy[index][name] = value;
    setOptions(copy);
  };

  const setColor = (color: string) => {
    trackTable(COLOR_SELECTED, { ...trackingData, Color: color });
    const copy = makeDeepCopy(options);
    copy[displayColorPickerIndex]["color"] = color;
    setOptions(copy);
  };

  const toggleColor = (index: number) => {
    if (index === displayColorPickerIndex) {
      setDisplayColorPickerIndex(-1);
    } else {
      trackTable(COLOR_VIEWED, trackingData);
      setDisplayColorPickerIndex(index);
    }
  };

  const removeOption = (index: number) => {
    trackTable(REMOVE_OPTION_CLICKED, trackingData);
    const copy = makeDeepCopy(options);
    copy.splice(index, 1);
    setOptions(copy);
  };

  const handleAddOption = () => {
    trackTable(ADD_OPTION_CLICKED, trackingData);
    const copy = makeDeepCopy(options);
    copy.push({
      name: "New Option",
      color: SELECT_COLOR_OPTIONS[0],
    });
    setOptions(copy);
  };

  const handleClickAway = () => {
    setDisplayColorPickerIndex(-1);
  };

  const handleCreate = async () => {
    const isSelecteType =
      selectedType === PropertyType.singleSelect ||
      selectedType === PropertyType.multipleSelect;
    if (isSelecteType && options?.length === 0) {
      setAlert("Need at least one select option", "warning");
    }
    if (!selectedType) {
      return;
    }
    setLoading(true);
    const newProperty = { ...property, type: selectedType };
    await updateProperty(newProperty, options, "create");
    if (closeDrawer) closeDrawer();
    setLoading(false);

    reset();
  };

  const reset = () => {
    if (setSelectedType) setSelectedType(null);
    setProperty(defaultProperty);
    setOptions(defaultOptions);
  };

  const handleUpdateColumnName = (e: CustomEvent) => {
    setProperty((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  const handleSave = () => {
    if (isEdited) {
      updateProperty(property, property?.selectOptions);
      handleClose();
    } else {
      handleCreate();
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>
        {isEdited ? "Edit Column Name" : "Create Your Own Column"}
      </DialogTitle>
      <DialogContent sx={{ minWidth: 500 }}>
        <FormHelperText sx={{ color: "primary.main" }}>
          Column Name
        </FormHelperText>
        <TextField
          fullWidth
          variant="standard"
          placeholder="Type column name here"
          onChange={handleUpdateColumnName}
          value={property?.name}
          required
        />

        {!isEdited && (
          <FormControl fullWidth sx={{ my: 2 }} variant="standard">
            <FormHelperText sx={{ color: "primary.main" }}>
              Column Type
            </FormHelperText>
            <Select value={selectedType || ""} onChange={handleChange}>
              {Object.values(PropertyType)?.map((type) => (
                <MenuItem key={type} value={type}>
                  <Box
                    component="i"
                    className={`fa-thin ${ColumnIcons[type]}`}
                    sx={{ mr: 1 }}
                  />
                  {lodash.startCase(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {(selectedType === PropertyType.singleSelect ||
          selectedType === PropertyType.multipleSelect) && (
          <Box>
            <FormHelperText sx={{ color: "primary.main" }}>
              Column Options
            </FormHelperText>
            {options?.map((o, index) => (
              <Box key={index} sx={{ my: 1 }}>
                <Grid container alignItems="center" wrap="nowrap">
                  <IconButton
                    sx={styles.remove}
                    onClick={() => removeOption(index)}
                  >
                    <Box component="i" className="fa-regular fa-circle-xmark" />
                  </IconButton>
                  <Box
                    sx={[styles.color, { backgroundColor: o.color }]}
                    onClick={() => toggleColor(index)}
                  />
                  <TextField
                    value={o.name}
                    onChange={(e: CustomEvent) =>
                      handleChangeOption(e, "name", index)
                    }
                    size="small"
                    variant="standard"
                  />
                </Grid>
              </Box>
            ))}

            {displayColorPickerIndex > -1 && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ position: "relative" }}>
                  <ColorPicker
                    color={options[displayColorPickerIndex].color}
                    setColor={setColor}
                    colors={SELECT_COLOR_OPTIONS}
                  />
                </Box>
              </ClickAwayListener>
            )}

            <Button size="small" onClick={handleAddOption}>
              + Add Option{" "}
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleSave}
          disabled={property?.name?.length === 0}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
