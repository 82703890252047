import { grey } from "@mui/material/colors";

const styles = {
  chip: {
    fontSize: 12,
    borderRadius: 2,
    color: grey[800],
  },
};

export default styles;
