import { mergeAttributes } from "@tiptap/core";
import Image from "@tiptap/extension-image";
import { NodeSelection } from "prosemirror-state";

interface ImageOptions {
  src?: string;
  alt?: string;
  title?: string;
  size?: "small" | "medium" | "large" | "original";
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    customImage: {
      setImage: (options: ImageOptions) => ReturnType;
    };
  }
}

export default Image.extend({
  name: "custom-image",

  addAttributes() {
    return {
      // @ts-ignore
      ...Image.config.addAttributes(),
      size: {
        default: "original",
        parseHTML: (element) => element.getAttribute("size-id"),
        renderHTML: (attributes) => {
          if (!attributes.size) {
            return {};
          }

          return {
            "size-id": attributes.size,
          };
        },
      },
      style: {
        parseHTML: (element) => element.style?.cssText,
      },
    };
  },

  addCommands() {
    return {
      setImage:
        (options: ImageOptions) =>
        ({ tr, commands }) => {
          if (
            tr.selection instanceof NodeSelection &&
            tr.selection?.node?.type?.name === "custom-image"
          ) {
            return commands.updateAttributes("custom-image", options);
          }
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    const size = node.attrs.size as "small" | "medium" | "large" | "original";

    if (size === "small") {
      HTMLAttributes.width = "25px";
    } else if (size === "medium") {
      HTMLAttributes.width = "120px";
    } else if (size === "large") {
      HTMLAttributes.width = "250px";
    }

    if (size !== "original") {
      HTMLAttributes.style = "";
    }

    return [
      "img",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
});
