import { useAuth } from "@clerk/clerk-react";
import lodash from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Email,
  EmailActivity,
  GmailThread,
  OutreachContact,
  OutreachContactStatus,
} from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

export const PER_PAGE = 25;

export type Suggestion = {
  id: number;
  type: "outreach_contact" | "brand";
  suggestion: string;
};

export enum Order {
  asc = "asc",
  desc = "desc",
}

export enum OrderBy {
  brand_name = "brand_name",
  contact = "contact",
  status = "status",
  is_important = "is_important",
  last_gmail_message_date = "last_gmail_message_date",
}

export type InboxSidebarOption = {
  key: string;
  label: string;
  icon: string;
  statuses: OutreachContactStatus[];
};

export const InboxSidebarStatusMap: { [key: string]: OutreachContactStatus[] } =
  {
    inbox: [
      OutreachContactStatus.bounced,
      OutreachContactStatus.received_response,
      OutreachContactStatus.interested,
      OutreachContactStatus.not_interested,
    ],
    sent: [
      OutreachContactStatus.no_response,
      OutreachContactStatus.bounced,
      OutreachContactStatus.received_response,
      OutreachContactStatus.interested,
      OutreachContactStatus.not_interested,
    ],
    scheduled: [OutreachContactStatus.pending],
  };

export const InboxSidebarOptions: InboxSidebarOption[] = [
  {
    key: "inbox",
    label: "Inbox",
    icon: "fa-solid fa-inbox",
    statuses: InboxSidebarStatusMap.inbox,
  },
  {
    key: "sent",
    label: "Sent",
    icon: "fa-solid fa-paper-plane",
    statuses: InboxSidebarStatusMap.sent,
  },
  {
    key: "scheduled",
    label: "Scheduled",
    icon: "fa-regular fa-clock",
    statuses: InboxSidebarStatusMap.scheduled,
  },
];

export const defaultFetchContactsParams = {
  page: 1,
  statuses: InboxSidebarStatusMap.inbox,
  selectedSuggestion: null,
  order: Order.desc,
  orderBy: OrderBy.last_gmail_message_date,
};

type FetchContactsParams = {
  page: number;
  selectedSuggestion: Suggestion | null;
  statuses: OutreachContactStatus[];
  importantOnly?: boolean;
  order?: Order;
  orderBy?: OrderBy;
};

interface OutreachContactsContextInterface {
  fetchLoading: boolean;
  setFetchLoading: Dispatch<SetStateAction<boolean>>;
  contacts: OutreachContact[];
  total: number | null;
  setContacts: Dispatch<SetStateAction<OutreachContact[]>>;
  currentContact: OutreachContact | null;
  setCurrentContact: Dispatch<SetStateAction<OutreachContact | null>>;
  currentContactEmailThreads: GmailThread[];
  setCurrentContactEmailThreads: Dispatch<SetStateAction<GmailThread[]>>;
  currentContactScheduledEmails: Email[];
  setCurrentContactScheduledEmails: Dispatch<SetStateAction<Email[]>>;
  currentContactEmailActivities: EmailActivity[];
  currentContactThreadsLoading: boolean;
  currentContactLoading: boolean;
  currentContactScheduledEmailsLoading: boolean;
  currentContactEmailActivitiesLoading: boolean;
  setCurrentContactLoading: Dispatch<SetStateAction<boolean>>;
  currentContactPageToken: string | null;
  fetchContact: (outreachContactId: number) => void;
  fetchEmailThreads: (outreachContactId: number, reset?: boolean) => void;
  fetchScheduledEmails: (outreachContactId: number) => void;
  fetchEmailActivities: (outreachContactId: number) => void;
  abortController: React.MutableRefObject<AbortController | undefined>;
  fetchContactsParams: FetchContactsParams;
  setFetchContactsParams: Dispatch<SetStateAction<FetchContactsParams>>;
  currentSidebarOption: InboxSidebarOption | undefined;
  hitsLimit: boolean;
  fetchMoreLoading: boolean;
  missingIntegration: boolean;
  isFiltering: boolean;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  fetchLoading: false,
  setFetchLoading: defaultContextMissingFunction,
  contacts: [],
  total: null,
  setContacts: defaultContextMissingFunction,
  currentContact: null,
  setCurrentContact: defaultContextMissingFunction,
  currentContactEmailThreads: [],
  setCurrentContactEmailThreads: defaultContextMissingFunction,
  currentContactScheduledEmails: [],
  currentContactEmailActivities: [],
  setCurrentContactScheduledEmails: defaultContextMissingFunction,
  currentContactThreadsLoading: true,
  currentContactLoading: true,
  currentContactScheduledEmailsLoading: true,
  currentContactEmailActivitiesLoading: true,
  setCurrentContactLoading: defaultContextMissingFunction,
  currentContactPageToken: null,
  fetchContact: defaultContextMissingFunction,
  fetchEmailThreads: defaultContextMissingFunction,
  fetchScheduledEmails: defaultContextMissingFunction,
  fetchEmailActivities: defaultContextMissingFunction,
  abortController: undefined,
  fetchContactsParams: defaultFetchContactsParams,
  setFetchContactsParams: defaultContextMissingFunction,
  currentSidebarOption: undefined,
  hitsLimit: false,
  fetchMoreLoading: false,
  missingIntegration: false,
  isFiltering: false,
};

const OutreachContactsContext =
  // @ts-ignore: We cannot do `useRef` to initialize `abortController`
  createContext<OutreachContactsContextInterface>(defaultInterface);

interface OutreachContactsProviderProps {
  children: React.ReactNode;
}

const OutreachContactsProvider = ({
  children,
}: OutreachContactsProviderProps) => {
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();

  // Outreach Page State
  const [contacts, setContacts] = useState<OutreachContact[]>([]);
  const [fetchContactsParams, setFetchContactsParams] =
    useState<FetchContactsParams>(defaultFetchContactsParams);
  const [total, setTotal] = useState<number | null>(null);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [missingIntegration, setMissingIntegration] = useState(false);

  // Individual Outreach Contact State
  const [currentContact, setCurrentContact] = useState<OutreachContact | null>(
    null,
  );
  const [currentContactEmailThreads, setCurrentContactEmailThreads] = useState<
    GmailThread[]
  >([]);
  const [currentContactPageToken, setCurrentContactPageToken] = useState<
    string | null
  >(null);
  const [currentContactThreadsLoading, setCurrentContactThreadsLoading] =
    useState<boolean>(true);
  const [currentContactLoading, setCurrentContactLoading] =
    useState<boolean>(true);
  const [currentContactScheduledEmails, setCurrentContactScheduledEmails] =
    useState<Email[]>([]);
  const [currentContactEmailActivities, setCurrentContactEmailActivities] =
    useState<EmailActivity[]>([]);
  const [
    currentContactEmailActivitiesLoading,
    setCurrentContactEmailActivitiesLoading,
  ] = useState<boolean>(true);
  const [
    currentContactScheduledEmailsLoading,
    setCurrentContactScheduledEmailsLoading,
  ] = useState<boolean>(true);

  const abortController = useRef<AbortController | undefined>(undefined);

  const fetchContacts = async () => {
    if (fetchContactsParams.page === 1) {
      setFetchLoading(true);
    } else {
      setFetchMoreLoading(true);
    }
    if (!currentOrg?.id) {
      return;
    }
    let url = `/api/organization/${currentOrg?.id}/outreach-contacts?include=brand,bento_brand&per_page=${PER_PAGE}&page=${fetchContactsParams.page}`;
    if (fetchContactsParams.selectedSuggestion) {
      if (fetchContactsParams.selectedSuggestion.type === "brand") {
        url += `&brand_id=${fetchContactsParams.selectedSuggestion.id}`;
      } else {
        url += `&outreach_contact_id=${fetchContactsParams.selectedSuggestion.id}`;
      }
    }
    if (fetchContactsParams.statuses.length > 0) {
      url +=
        "&" +
        fetchContactsParams.statuses
          .map((status) => `statuses=${status}`)
          .join("&");
    }
    if (fetchContactsParams.importantOnly) {
      url += `&is_important=${fetchContactsParams.importantOnly}`;
    }
    if (fetchContactsParams.orderBy) {
      url += `&sort=${fetchContactsParams.orderBy}:${fetchContactsParams.order}`;
    }
    try {
      abortController.current = new AbortController();
      const res = await fetcherAuth(
        getToken,
        url,
        "GET",
        {},
        {},
        false,
        false,
        true,
        abortController.current.signal,
      );
      if (fetchContactsParams.page === 1) {
        setContacts(res.outreachContacts);
      } else {
        setContacts((prev) => [...prev, ...res.outreachContacts]);
      }
      setTotal(res.total);
    } catch (error) {
      if (error?.message?.includes("aborted")) {
        return;
      }
      setAlert(
        error?.message ||
          "Unable to fetch contacts. Please reload and retry again",
        "error",
      );
    } finally {
      setFetchLoading(false);
      setFetchMoreLoading(false);
    }
  };

  const fetchEmailThreads = async (
    outreachContactId: number,
    reset: boolean = false,
  ) => {
    if (!currentOrg) {
      return;
    }
    setCurrentContactThreadsLoading(true);

    let pageToken = currentContactPageToken;

    if (reset) {
      setCurrentContactPageToken(null);
      setCurrentContactEmailThreads([]);
      pageToken = null;
    }

    let url = `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/threads`;
    if (pageToken) {
      url += `?pageToken=${pageToken}`;
    }
    try {
      const { gmailThreads, nextPageToken } = await fetcherAuth(
        getToken,
        url,
        "GET",
      );
      setCurrentContactEmailThreads((prev) => [...prev, ...gmailThreads]);
      setCurrentContactPageToken(nextPageToken);
      setMissingIntegration(false);
    } catch (error) {
      if (error?.message?.includes("Google integration")) {
        setMissingIntegration(true);
      } else {
        setErrorAlert(error);
      }
    } finally {
      setCurrentContactThreadsLoading(false);
    }
  };

  const fetchContact = async (outreachContactId: number) => {
    if (!currentOrg) {
      return;
    }
    setCurrentContactLoading(true);

    try {
      const { outreachContact } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}`,
        "GET",
      );
      setCurrentContact(outreachContact);
      // update the contact itself if it is in the list
      setContacts((prev) => {
        const copy = makeDeepCopy(prev);
        for (const idx in copy) {
          if (copy[idx].id === outreachContact.id) {
            copy[idx] = outreachContact;
          }
        }
        return copy;
      });
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCurrentContactLoading(false);
    }
  };

  const fetchScheduledEmails = async (outreachContactId: number) => {
    if (!currentOrg) {
      return;
    }
    setCurrentContactScheduledEmailsLoading(true);

    try {
      const { scheduledEmails } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/scheduled-emails`,
        "GET",
      );
      setCurrentContactScheduledEmails(scheduledEmails);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCurrentContactScheduledEmailsLoading(false);
    }
  };

  const fetchEmailActivities = async (outreachContactId: number) => {
    if (!currentOrg) {
      return;
    }
    setCurrentContactEmailActivitiesLoading(true);

    try {
      const { lastEmailActivities } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/last-email-activity`,
        "GET",
      );
      setCurrentContactEmailActivities(lastEmailActivities);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCurrentContactEmailActivitiesLoading(false);
    }
  };

  useEffect(() => {
    if (fetchContactsParams) {
      fetchContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, fetchContactsParams]);

  const currentSidebarOption = useMemo(() => {
    return InboxSidebarOptions.find((option) =>
      lodash.isEqual(option.statuses, fetchContactsParams.statuses),
    );
  }, [fetchContactsParams.statuses]);

  const hitsLimit = total === null ? false : total <= contacts.length;

  const isFiltering =
    fetchContactsParams.importantOnly ||
    !!fetchContactsParams.selectedSuggestion;

  return (
    <OutreachContactsContext.Provider
      value={{
        fetchLoading,
        setFetchLoading,
        contacts,
        total,
        setContacts,
        currentContact,
        setCurrentContact,
        currentContactEmailThreads,
        setCurrentContactEmailThreads,
        currentContactEmailActivities,
        currentContactScheduledEmails,
        setCurrentContactScheduledEmails,
        currentContactLoading,
        setCurrentContactLoading,
        currentContactThreadsLoading,
        currentContactScheduledEmailsLoading,
        currentContactEmailActivitiesLoading,
        currentContactPageToken,
        fetchContact,
        fetchEmailThreads,
        fetchScheduledEmails,
        fetchEmailActivities,
        abortController,
        fetchContactsParams,
        setFetchContactsParams,
        currentSidebarOption,
        hitsLimit,
        fetchMoreLoading,
        missingIntegration,
        isFiltering,
      }}
    >
      {children}
    </OutreachContactsContext.Provider>
  );
};

export { OutreachContactsContext, OutreachContactsProvider };
