import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ALL, NONE } from "schemas/forms";
import { MenuOption } from "schemas/forms";
import { CustomEvent } from "schemas/functions";

interface FormSelectProps {
  label: JSX.Element | string;
  handleChange: (e: CustomEvent, queryMapKey: string) => void;
  formKey: string;
  value: any;
  options: MenuOption[];
  hasNone?: boolean;
}

const FormSelect = ({
  label,
  handleChange,
  formKey,
  value,
  options,
  hasNone,
}: FormSelectProps) => {
  return (
    <FormControl variant="standard" sx={{ width: 150 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        onChange={(e: CustomEvent) => handleChange(e, formKey)}
        label={label}
        value={value}
      >
        <MenuItem value={ALL}>
          <em>All</em>
        </MenuItem>
        {hasNone && (
          <MenuItem value={NONE}>
            <em>None</em>
          </MenuItem>
        )}
        {options?.map((option: MenuOption, index: number) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormSelect;
