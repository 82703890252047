import { useAuth } from "@clerk/clerk-react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";

import Alert from "components/Alert";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

const AdminScraping = () => {
  const { getToken } = useAuth();
  const [message, severity, setAlert, closeAlert] = useAlert();

  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setFile(selectedFile || null);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = new FormData();
      if (!file) {
        throw new Error("You must upload a CSV file");
      }

      const fileExt = file.name.split(".").pop()?.toLowerCase();
      if (fileExt !== "csv") {
        throw new Error("You must select a CSV file");
      }
      data.append("file", file);

      await fetcherAuth(
        getToken,
        `/api/admin/scraping/social-media-accounts-csv`,
        "POST",
        {},
        data,
        false,
        true,
      );
      setAlert("Uploaded accounts", "success");
    } catch (error) {
      setAlert(error?.message, "warning");
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledPage
      content={
        <Grid sx={{ mt: 2 }}>
          <Typography variant="h3" gutterBottom>
            <strong>Upload Accounts to Scrape</strong>
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormHelperText sx={{ pb: 2 }}>
              This will add all these accounts (remove duplicates) to our
              database and enqueue them to scrape on a daily basis.
            </FormHelperText>
            <FormControl>
              <Input type="file" sx={{ mr: 1 }} onChange={handleFileChange} />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Submit
            </Button>
          </form>
          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
        </Grid>
      }
    />
  );
};

export default AdminScraping;
