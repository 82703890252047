import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { SetStateAction, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import Drawer from "components/Drawer";
import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface AdminNewContact {
  name?: string;
  title?: string;
  email?: string;
  bentoBrandId?: number;
  bentoBrand?: BentoBrand;
  score?: number;
}

interface NewContactProps {
  setAlert: (
    customMessage: string | JSX.Element,
    customType: AlertColor,
  ) => void;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  refetchContacts: () => void;
}

const NewContact = ({
  setAlert,
  refetchContacts,
  setLoading,
}: NewContactProps) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const [contact, setContact] = useState<AdminNewContact>({
    score: 60,
  });
  const [searchBrandResults, setSearchBrandResults] = useState<BentoBrand[]>(
    [],
  );
  const [searchBrandLoading, setSearchBrandLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const handleClose = () => {
    navigate(`/admin/contacts`, { replace: true });
  };

  const handleOnInputChange = (event: CustomEvent, newInputValue: string) => {
    setSearchBrandLoading(true);
    changeInput(newInputValue);
  };

  const changeInput = useMemo(
    () =>
      debounce(async (newSearchQuery: string) => {
        await searchBrands(newSearchQuery);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const searchBrands = async (queryString: string) => {
    try {
      let url = `/api/admin/bento-brands?query=${queryString}`;
      const { bentoBrands } = await fetcherAuth(getToken, url);
      setSearchBrandResults(bentoBrands);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    } finally {
      setSearchBrandLoading(false);
    }
  };

  const onBrandChange = async (
    event: CustomEvent,
    newValue: BentoBrand | null,
  ) => {
    setContact((prev) => {
      if (prev) {
        const copy = makeDeepCopy(prev);
        copy.bentoBrand = newValue || undefined;
        copy.bentoBrandId = newValue?.id;
        return copy;
      }
    });
  };

  const onChange = (event: CustomEvent) => {
    const { name, value } = event.target;
    setContact((prev) => {
      if (prev) {
        const copy = makeDeepCopy(prev);
        copy[name] = value;
        return copy;
      }
    });
  };

  const saveChanges = async () => {
    if (!contact) {
      return;
    }
    if (!contact.email) {
      setAlert("Email is required", "warning");
      return;
    }
    if (!contact.bentoBrandId) {
      setAlert("Brand is required", "warning");
      return;
    }
    setSaveLoading(true);
    try {
      let url = `/api/admin/bento-contacts`;
      const res = await fetcherAuth(
        getToken,
        url,
        "POST",
        {},
        {
          ...contact,
        },
        true,
        false,
        false,
      );
      if (res.ok) {
        refetchContacts();
        setAlert("Created contact", "success");
        handleClose();
      } else {
        const jsonResp = await res.json();
        if (res.status === 400) {
          setAlert("Email and Brand are required field", "warning");
        } else {
          setAlert(`An error occurred ${jsonResp}`, "warning");
        }
      }
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Drawer
      open={true}
      width={{ xs: "auto", md: 800, lg: 1000 }}
      onClose={handleClose}
    >
      <DialogTitle sx={styles.dialogTitleText}>
        <Grid container alignItems="center" justifyContent={"space-between"}>
          <Button
            color="secondary"
            onClick={handleClose}
            sx={styles.actionButton}
            size="small"
          >
            Close
          </Button>

          <Box>
            <Typography component="span" noWrap sx={styles.brandName}>
              {contact?.name}
            </Typography>
          </Box>
          <LoadingButton
            variant="contained"
            sx={styles.actionButton}
            onClick={saveChanges}
            loading={saveLoading}
            disableElevation
            size="small"
          >
            Save
          </LoadingButton>
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Autocomplete
              id="brands"
              options={searchBrandResults}
              disableCloseOnSelect
              value={contact?.bentoBrand || null}
              onChange={onBrandChange}
              loading={searchBrandLoading}
              getOptionLabel={(option: BentoBrand) =>
                `${option.brandName} (${option.instagramUsername}, ${option.id})`
              }
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                >{`${option.brandName} (${option.instagramUsername}, ${option.id})`}</li>
              )}
              onInputChange={handleOnInputChange}
              // Override Autocomplete Default Filter Function with BE searching.
              filterOptions={(x) => x}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Brand *"}
                  placeholder="Type brand name or website to search..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {searchBrandLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              onChange={onChange}
              value={contact?.name || ""}
              name="name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email *"
              onChange={onChange}
              value={contact?.email || ""}
              name="email"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Title"
              onChange={onChange}
              value={contact?.title || ""}
              name="title"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              label="Score"
              onChange={onChange}
              value={contact?.score || ""}
              name="score"
            />
            <FormHelperText>
              To make sure contact is displayed to user, their score must be
              above 70.
            </FormHelperText>
          </Grid>
        </Grid>
      </DialogContent>
    </Drawer>
  );
};

export default NewContact;
