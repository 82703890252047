import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";
import { routes } from "schemas/routes";

import { TemplateHighlight } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface CustomizePartTwoEmailFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
  isProfilePage?: boolean;
}

const CustomizePartTwoEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
  isProfilePage,
}: CustomizePartTwoEmailFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value;
    setOnboardingForm(copy);
  };

  const handleBack = () => {
    navigate(`/${routes.customizePartOneOnboarding}${location.search}`);
  };

  const handleSkip = () => {
    trackEvent("Onboarding Form - Skip Button Pressed", {
      page: "emailPartTwo",
    });
    navigate(`/${routes.customizeCategoryOnboarding}${location.search}`);
  };

  const addIdea = () => {
    trackEvent("Onboarding Form - Add Idea Button Pressed");
    setOnboardingForm((prev) => {
      const copy = {
        ...prev,
        uniqueContentIdeas: [...(prev.uniqueContentIdeas || ["", "", ""]), ""],
      };
      return copy;
    });
  };

  const removeIdea = (index: number) => {
    trackEvent("Onboarding Form - Remove Idea Button Pressed");
    setOnboardingForm((prev) => {
      const copy = {
        ...prev,
        uniqueContentIdeas: (prev.uniqueContentIdeas || ["", "", ""]).filter(
          (_: string, i: number) => i !== index,
        ),
      };
      return copy;
    });
  };

  const handleIdeaChange = (newIdea: string, index: number) => {
    setOnboardingForm((prev) => {
      const copy = { ...prev };
      if (!copy.uniqueContentIdeas) {
        copy.uniqueContentIdeas = ["", "", ""];
      }
      copy.uniqueContentIdeas = copy.uniqueContentIdeas.map(
        (idea: string, idx: number) => {
          if (idx === index) {
            return newIdea;
          }
          return idea;
        },
      );
      return copy;
    });
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    saveOnboardingForm(onboardingForm, "emailPartTwo");
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {!isProfilePage && (
          <Typography variant="h3" sx={{ mb: 3 }}>
            <Box
              component="i"
              color="secondary.dark"
              sx={{ pr: 2 }}
              className="fa-solid fa-envelope"
            />
            Let us help you write customized emails
          </Typography>
        )}

        {(isProfilePage ||
          onboardingForm.defaultTemplateHighlight ===
            TemplateHighlight.CONTENT_IDEA) && (
          <Grid sx={styles.fieldContainer}>
            <FormHelperText sx={styles.helperText}>
              Share 3-4 recent ideas you've pitched to brands
            </FormHelperText>

            <Grid container rowSpacing={0.5} sx={{ mb: 0.5 }}>
              {(onboardingForm.uniqueContentIdeas || ["", "", ""]).map(
                (idea: string, index: number) => (
                  <Grid
                    item
                    alignItems="center"
                    container
                    columnGap={0.5}
                    xs={12}
                    key={index}
                  >
                    <Grid item xs>
                      <TextField
                        sx={[styles.textField, { mb: 0 }]}
                        fullWidth
                        placeholder={`Idea #${index + 1}`}
                        onChange={(e: CustomEvent) => {
                          handleIdeaChange(e.target.value, index);
                        }}
                        value={idea || ""}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <IconButton
                        onClick={() => {
                          removeIdea(index);
                        }}
                        sx={styles.removeButton}
                      >
                        <Box
                          component="i"
                          className="fa-regular fa-circle-xmark"
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                ),
              )}
            </Grid>

            <Button size="small" onClick={addIdea} variant="outlined">
              Add Idea
              <Box component="i" className="fa-solid fa-add" sx={{ ml: 1 }} />
            </Button>
          </Grid>
        )}

        {(isProfilePage ||
          onboardingForm.defaultTemplateHighlight ===
            TemplateHighlight.ENGAGEMENT) && (
          <>
            <FormHelperText sx={styles.helperText}>
              Number of followers
            </FormHelperText>
            <TextField
              sx={styles.textField}
              fullWidth
              name="numOfFollowers"
              placeholder="105K"
              onChange={handleChange}
              value={onboardingForm?.numOfFollowers || ""}
            />
            <FormHelperText sx={styles.helperText}>
              Engagement numbers typically shared with brands
            </FormHelperText>
            <TextField
              sx={styles.textField}
              fullWidth
              placeholder="Your engagement numbers for a particular partnership"
              name="engagement"
              onChange={handleChange}
              value={onboardingForm?.engagement || ""}
            />
          </>
        )}

        {(isProfilePage ||
          onboardingForm.defaultTemplateHighlight ===
            TemplateHighlight.PAST_BRANDS) && (
          <>
            <FormHelperText sx={styles.helperText}>
              Share your most impressive past partnerships
            </FormHelperText>
            <TextField
              sx={styles.textField}
              fullWidth
              name="mostImpressivePartnerships"
              placeholder="Share details of your most impressive partnerships"
              onChange={handleChange}
              multiline
              minRows={4}
              value={onboardingForm?.mostImpressivePartnerships || ""}
            />
          </>
        )}

        {(isProfilePage ||
          onboardingForm.defaultTemplateHighlight ===
            TemplateHighlight.BRAND_ALIGNMENT) && (
          <>
            <FormHelperText sx={styles.helperText}>
              Share what you often say to brands about why you want to work with
              them
            </FormHelperText>
            <TextField
              sx={styles.textField}
              fullWidth
              name="brandPitch"
              placeholder="I absolutely love ..."
              onChange={handleChange}
              multiline
              minRows={4}
              value={onboardingForm?.brandPitch || ""}
            />
          </>
        )}

        {!isProfilePage && (
          <Grid item xs="auto" container columnGap={1}>
            <Grid item xs="auto">
              <Button onClick={handleBack} disableElevation>
                Back
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button onClick={handleSkip} disableElevation color="secondary">
                Skip
              </Button>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <LoadingButton
                disableElevation
                variant="contained"
                type="submit"
                loading={loading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default CustomizePartTwoEmailForm;
