import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SetAlertType } from "schemas/functions";

import FileUpload from "../FileUpload";
import styles from "./styles";

interface AttachmentUploadProps {
  file: File | null;
  setFile: Dispatch<SetStateAction<any>>;
  setAlert: SetAlertType;
  buttonText: string;
  imageOnly?: boolean;
  anyMedia?: boolean;
}

function AttachmentUpload({
  file,
  setFile,
  setAlert,
  buttonText,
  imageOnly = false,
  anyMedia = false,
}: AttachmentUploadProps) {
  const [preview, setPreview] = useState<string>("");

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    } else {
      setPreview("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Box sx={styles.root}>
      {!preview ? (
        <Box sx={[styles.image, styles.placeholder]}>PREVIEW</Box>
      ) : (
        <Box sx={styles.imageContainer}>
          {file?.type.startsWith("image") || file?.type.endsWith(".svg") ? (
            <Box
              component="img"
              alt="img preview"
              sx={styles.image}
              src={preview}
            />
          ) : file?.type.startsWith("video") ? (
            <Box
              component="i"
              sx={styles.videoIcon}
              className="fa-regular fa-file-video fa-5x"
            />
          ) : (
            <Box
              component="i"
              sx={styles.pdfIcon}
              className="fa-regular fa-file-pdf fa-5x"
            />
          )}
          <Typography variant="caption" sx={styles.caption}>
            {file && file.name}
          </Typography>
        </Box>
      )}
      <Box>
        <FileUpload
          id="attachmentPicker"
          name="attachment"
          setFile={setFile}
          setAlert={setAlert}
          limit={anyMedia ? 500000000 : 25000000}
          buttonText={buttonText}
          acceptType={
            imageOnly
              ? "image/*,.svg"
              : anyMedia
                ? "image/*,.svg,.mp4,.mov"
                : "image/*,.pdf"
          }
        />

        <Typography sx={styles.text} component="div">
          {imageOnly
            ? "JPG, SVG, or PNG. Max size of 25MB"
            : anyMedia
              ? "JPG, SVG, PNG, MP4 or MOV. Max size of 500MB"
              : "JPG, SVG, PNG or PDF. Max size of 25MB"}
        </Typography>
      </Box>{" "}
    </Box>
  );
}

export default AttachmentUpload;
