import { Grid, useMediaQuery, useTheme } from "@mui/material";

import { pageStyles } from "./styles";

interface Props {
  content?: JSX.Element;
  rightNavBar?: JSX.Element;
}

export default function StyledPage({ content, rightNavBar }: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      {rightNavBar ? (
        <Grid container>
          <Grid item sx={pageStyles.pageBodyWithRightNavBar}>
            {content}
          </Grid>
          <Grid item sx={isMobileScreen ? {} : pageStyles.rightNavBar}>
            {rightNavBar}
          </Grid>
        </Grid>
      ) : (
        <Grid sx={pageStyles.pageBody}>{content}</Grid>
      )}
    </>
  );
}
