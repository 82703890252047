import { green, grey, yellow } from "@mui/material/colors";

const styles = {
  base: {
    p: 4,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    border: `1px solid ${grey[400]}`,
    borderRadius: 5,
  },
  click: {
    cursor: "pointer",
    "&:hover": {
      borderColor: grey[700],
    },
  },
  icon: {
    mb: 1,
    color: grey[600],
  },
  acceptBorder: {
    borderColor: green[700],
  },
  acceptColor: {
    color: green[700],
  },
  rejectBorder: {
    borderColor: yellow[700],
  },
  rejectColor: {
    color: yellow[700],
  },
};

export default styles;
