import { BrandsContext, TagLabel } from "contexts/Brands";
import lodash from "lodash";
import { Dispatch, SetStateAction, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BentoBrandMetadataTags,
  BentoBrandSizeToSearchKey,
  CATEGORIES,
  DISCOVER_FILTER,
  LOCATIONS,
  MetadataLocationType,
  MetadataType,
  SearchParams,
} from "schemas/dashboard";
import { ALL } from "schemas/forms";
import { CustomEvent, Map } from "schemas/functions";

import { DiscoverSearchGeopoint } from "components/LocationAutocomplete/schema";

import { DISCOVER_METADATA_TAGS, DISCOVER_SEARCH_PARAMS } from "./localStorage";
import { trackEvent } from "./tracking";

export const MetadataTypeToParams: Map = {
  [MetadataType.tags]: SearchParams.QUERY_DISCOVER,
  [MetadataType.size]: SearchParams.SIZE_DISCOVER,
};

export const useSearchBrand = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setSelectedMetadataTags } = useContext(BrandsContext);

  const handleSelectOption = (value: string, currentOptions: string[]) => {
    if (value === ALL) {
      return ALL;
    }
    const indexOfSelectedOption = currentOptions?.findIndex((x) => x === value);
    const isOptionSelected = indexOfSelectedOption > -1;

    // If option is already selected, remove option. Else add it to the list.
    if (isOptionSelected) {
      currentOptions.splice(indexOfSelectedOption, 1);
    } else {
      currentOptions.push(value);
    }
    return currentOptions
      ?.filter((x: string) => x !== ALL && x?.length > 0)
      ?.join(",");
  };

  const deleteChip = (value: string, paramsValue: string) => {
    const arr = paramsValue.split(",");
    const index = arr?.findIndex((x) => x === value?.toLowerCase());
    if (index > -1) {
      arr.splice(index, 1);
    }
    const strArr = arr?.join(",");
    return strArr;
  };

  const addCategoryQueryToSearch = (name: string, value: string) => {
    // When user select option from CategoryFilters or when they click on a Tag.
    if (
      name === SearchParams.SORT_DISCOVER ||
      name === SearchParams.CITY_DISCOVER
    ) {
      searchParams.set(name, value);
    } else {
      const paramsValue = searchParams.get(name) || "";
      const newParamsValue = handleSelectOption(value, paramsValue?.split(","));
      if (newParamsValue === "") {
        searchParams.delete(name);
      } else {
        searchParams.set(name, newParamsValue);
      }
    }
    setSearchParams(searchParams);
    storeUserQuery();
  };

  const deleteCategoryFromSearch = (chip: TagLabel) => {
    // Delete a tag from Category dropdown.
    if (chip.params === SearchParams.GEOPOINTS_DISCOVER && chip.key) {
      const parsed = JSON.parse(chip.key);
      if (parsed?.longitude && parsed?.latitude) {
        handleDeleteGeopoint(parsed.latitude, parsed.longitude);
        return;
      }
    }
    const paramsValue = searchParams.get(chip.params) || "";
    const strArr = deleteChip(chip.key, paramsValue);
    if (strArr === "") {
      searchParams.delete(chip.params);
    } else {
      searchParams.set(chip.params, strArr);
    }
    setSearchParams(searchParams);
    storeUserQuery();
  };

  const deleteMetadataFromSearch = (tag: BentoBrandMetadataTags) => {
    // Delete a tag from Metadata.
    setSelectedMetadataTags((prev) => {
      const newArray = prev?.filter((x) => x.value !== tag.value);
      sessionStorage.setItem(DISCOVER_METADATA_TAGS, JSON.stringify(newArray));
      return newArray;
    });
    if (tag.type === MetadataType.location && tag.latitude && tag.longitude) {
      handleDeleteGeopoint(tag.latitude, tag.longitude);
    } else {
      const params = MetadataTypeToParams[tag.type];
      const strArr = deleteChip(tag.value, searchParams.get(params) || "");
      if (strArr) {
        searchParams.set(params, strArr);
      } else {
        searchParams.delete(params);
      }
      setSearchParams(searchParams);
      storeUserQuery();
    }
  };

  const handleAddGeopoint = (
    countryCode: string,
    latitude: number,
    longitude: number,
    label?: string,
  ) => {
    // Search location using latitude / longitude.
    const value = {
      country_code: countryCode,
      latitude,
      longitude,
      label,
    };
    let arrayOfGeopoints = [];
    const paramsValue = searchParams.get(SearchParams.GEOPOINTS_DISCOVER) || "";
    if (paramsValue) {
      arrayOfGeopoints = JSON.parse(paramsValue);
    }
    const exists = arrayOfGeopoints?.find(
      (x: DiscoverSearchGeopoint) =>
        (x.latitude === latitude && x.longitude === longitude) ||
        x.label === label,
    );
    if (exists) {
      return;
    }
    arrayOfGeopoints.push(value);
    searchParams.set(
      SearchParams.GEOPOINTS_DISCOVER,
      JSON.stringify(arrayOfGeopoints),
    );
    setSearchParams(searchParams);
    storeUserQuery();
  };

  const handleDeleteGeopoint = (latitude: number, longitude: number) => {
    // Remove latitude / longitude from search.
    const paramsValue = searchParams.get(SearchParams.GEOPOINTS_DISCOVER);
    if (!paramsValue) return;
    const arrayOfGeopoints = JSON.parse(paramsValue);
    const index = arrayOfGeopoints?.findIndex(
      (x: DiscoverSearchGeopoint) =>
        x.latitude === latitude && x.longitude === longitude,
    );
    if (index > -1) {
      arrayOfGeopoints.splice(index, 1);
    }
    const strArr = JSON.stringify(arrayOfGeopoints);
    searchParams.set(SearchParams.GEOPOINTS_DISCOVER, strArr);
    setSearchParams(searchParams);
    storeUserQuery();
  };

  const handleClickOnTag = (
    e: CustomEvent,
    tag: BentoBrandMetadataTags,
    setInputValue?: Dispatch<SetStateAction<string>>,
  ) => {
    trackEvent("Brand Card - Tag Clicked", { Tag: tag.value });
    e.stopPropagation();
    if (tag?.type === MetadataType.location && tag?.value) {
      if (
        tag?.locationType === MetadataLocationType.continent ||
        tag?.locationType === MetadataLocationType?.country
      ) {
        const categoryLocation = LOCATIONS?.find(
          (x) => x.name === lodash.startCase(tag?.value),
        );
        if (categoryLocation) {
          addCategoryQueryToSearch(
            SearchParams.LOCATION_DISCOVER,
            categoryLocation.key,
          );
          return;
        }
      }
      if (setInputValue) setInputValue(tag?.value);
    } else {
      const params = MetadataTypeToParams[tag.type];
      const searchQuery = searchParams.get(params);
      let arr = searchQuery?.split(",");
      if (!arr) {
        arr = [];
      }
      let value: any = tag?.value;
      if (tag?.type === MetadataType.size) {
        if (tag.value?.toLowerCase() in BentoBrandSizeToSearchKey) {
          // @ts-ignore
          value = BentoBrandSizeToSearchKey[tag.value?.toLowerCase()];
        }
      } else {
        const existingCategory = CATEGORIES?.find(
          (x) => x.name === lodash.startCase(tag?.value),
        );
        if (existingCategory) {
          addCategoryQueryToSearch(
            SearchParams.CATEGORY_DISCOVER,
            existingCategory.key,
          );
          return;
        }
      }
      const exists = arr?.find((x) => x === value.toLowerCase());
      if (!exists) {
        arr.push(value?.toLowerCase());
        if (tag?.type !== MetadataType.size) {
          setSelectedMetadataTags((prev) => {
            const exists = prev?.find((x) => x.value?.toLowerCase() === value);
            if (!exists) {
              prev.push(tag);
            }
            sessionStorage.setItem(
              DISCOVER_METADATA_TAGS,
              JSON.stringify(prev),
            );
            return prev;
          });
        }
      }
      const newStr = arr?.join(",");
      searchParams.set(params, newStr);
      setSearchParams(searchParams);
      storeUserQuery();
    }
  };

  const storeUserQuery = () => {
    // Store user's search query.
    const urlWithQuery = window.location.href?.split("?");

    let relevantKey = false;
    if (urlWithQuery?.length >= 2) {
      searchParams.forEach((value, key) => {
        if (key in DISCOVER_FILTER || key === "queryBrand") {
          relevantKey = true;
        }
      });
      if (relevantKey) {
        const query = urlWithQuery[1];
        sessionStorage.setItem(DISCOVER_SEARCH_PARAMS, query);
      } else {
        sessionStorage.removeItem(DISCOVER_SEARCH_PARAMS);
      }
    } else {
      sessionStorage.removeItem(DISCOVER_SEARCH_PARAMS);
    }
  };

  return {
    storeUserQuery,
    handleAddGeopoint,
    handleDeleteGeopoint,
    addCategoryQueryToSearch,
    deleteCategoryFromSearch,
    deleteMetadataFromSearch,
    handleClickOnTag,
  };
};
