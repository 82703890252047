import { grey } from "@mui/material/colors";

export const getStyles = (isSavedBrandOpen: boolean) => {
  return {
    root: {
      position: "fixed",
      bottom: 0,
      right: isSavedBrandOpen ? 420 : 70,
      width: { md: 450, xl: 550 },
      boxShadow: 3,
      backgroundColor: "white",
      border: `1px solid ${grey[300]}`,
      zIndex: 250,
      cursor: "pointer",
      overflowY: "auto",
      minHeight: "60svh",
      maxHeight: "100svh",
      display: "flex",
      flexDirection: "column",
    },
    recommendationRoot: {
      height: "100%",
    },
  };
};
