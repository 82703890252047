const styles = {
  iconButton: {
    fontSize: 14,
    color: "secondary.main",
    width: 20,
    height: 20,
    mt: 2,
  },
};

export default styles;
