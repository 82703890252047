import { useAuth } from "@clerk/clerk-react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import React, { useContext, useEffect, useState } from "react";
import { UserRequest } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { titleCase } from "utils/string";
import { formatEpochTimestamp } from "utils/time";
import { removeFromList } from "utils/updateLocalState";

import FullFillDialog from "./FullFillDialog";
import styles from "./styles";

const UserRequests: React.FC = () => {
  const { getToken } = useAuth();
  const { setAlert } = useContext(AlertContext);

  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<UserRequest[]>([]);
  const [totalCount, setTotalCount] = useState<number | undefined>();

  const [userRequest, setUserRequest] = useState<UserRequest | null>(null);

  const fetchUserRequests = async (pageToFetch: number) => {
    setLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/user-requests?page=${pageToFetch}&per_page=${20}`,
        "GET",
      );
      setResults(res.userRequests);
      setTotalCount(res.total);
    } catch (error) {
      setAlert(
        "An error occurred, you do not have access to this page",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (e: CustomEvent, newPage: number) => {
    setPage(newPage + 1);
    fetchUserRequests(newPage + 1);
  };

  useEffect(() => {
    fetchUserRequests(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDialog = (userRequestToDelete?: UserRequest) => {
    removeFromList(results, setResults, userRequestToDelete, "id");
    setUserRequest(null);
  };

  return (
    <StyledPage
      content={
        <Grid sx={{ mt: 2 }}>
          <Backdrop sx={styles.backdrop} open={loading}>
            <CircularProgress />
          </Backdrop>
          <Typography variant="h3" gutterBottom>
            <strong>User Requests</strong>
          </Typography>

          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader sx={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Request Submitted On</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((res) => (
                  <TableRow key={res.id}>
                    <TableCell>{res?.userId}</TableCell>
                    <TableCell>{res.description}</TableCell>
                    <TableCell>{titleCase(res.source)}</TableCell>
                    <TableCell>
                      {formatEpochTimestamp(res?.createdAt)}
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => setUserRequest(res)}
                      >
                        Fulfil User Request
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount || 0}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            page={page - 1}
            onPageChange={handleChangePage}
          />

          <FullFillDialog
            open={userRequest !== null}
            handleClose={closeDialog}
            userRequest={userRequest}
          />
        </Grid>
      }
    />
  );
};

export default UserRequests;
