import { useAuth } from "@clerk/clerk-react";
import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { UserIntegration } from "schemas/dashboard";
import { Message, MessageAttachment } from "schemas/email";
import { CustomEvent } from "schemas/functions";

import Alert from "components/Alert";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { openBase64DataInTab } from "utils/files";
import { useAlert } from "utils/useAlert";

import EmailThread from "../../../components/EmailThread";

export default function ReadEmail() {
  const [message, severity, setAlert, closeAlert] = useAlert();

  const { getToken } = useAuth();
  const [emailId, setEmailId] = useState();
  const [messages, setMessages] = useState<Message[]>([]);
  const [userIntegration, setUserIntegration] = useState<
    UserIntegration | undefined
  >();

  const handleReadEmail = async () => {
    if (!emailId) {
      setAlert("Please enter an email id", "error");
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/emails/${emailId}/read`,
        "GET",
      );
      setMessages(res.messages);
      setUserIntegration(res.userIntegration);
    } catch (error) {
      setAlert(`${error}`, "error");
    }
  };

  const openAttachment = async (
    messageId: string,
    attachment: MessageAttachment,
  ) => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/user-integrations/${userIntegration?.id}/messages/${messageId}/attachments/${attachment.attachment_id}`,
        "GET",
      );
      openBase64DataInTab(res.attachment.data, attachment.mime_type);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    }
  };

  const onChange = (e: CustomEvent) => {
    const { value } = e.target;
    setEmailId(value);
  };

  return (
    <StyledPage
      content={
        <>
          <Grid sx={{ mt: 2 }} container spacing={1}>
            <Grid item xs={12}>
              <TextField
                placeholder="#"
                label="Email Id"
                value={emailId || ""}
                onChange={onChange}
              />
              <Button
                variant="contained"
                sx={{ ml: 1, mb: 2, height: "54px" }}
                onClick={handleReadEmail}
              >
                Enter
              </Button>
            </Grid>
          </Grid>
          <EmailThread messages={messages} openAttachment={openAttachment} />
          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
        </>
      }
    />
  );
}
