import EditTemplateDialog from "../../EditTemplate";

interface ImportFlowProps {
  onSuccess: () => void;
  setIsImport: (isImport: boolean) => void;
}

export default function ImportFlow({
  onSuccess,
  setIsImport,
}: ImportFlowProps) {
  return (
    <EditTemplateDialog isNew setIsImport={setIsImport} onSuccess={onSuccess} />
  );
}
