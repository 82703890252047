import { grey } from "@mui/material/colors";

const styles = {
  video: {
    width: "100%",
    borderRadius: "24px",
    border: `1px solid ${grey[200]}`,
    "&:hover": {
      border: `1px solid ${grey[300]}`,
    },
  },
  link: { color: "primary.main", cursor: "pointer" },
};

export default styles;
