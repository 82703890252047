import { Box, Grid, Skeleton } from "@mui/material";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendOutreachTabsContext } from "contexts/QuickSendOutreachTabs";
import { inRange } from "lodash";
import { Dispatch, SetStateAction, useContext } from "react";
import { PreviewMessage } from "schemas/dashboard";
import { HandleChangePreviewFunction } from "schemas/outreach";

import { HandleSendEmail } from "../../schema";
import PreviewIndividual from "./PreviewIndividual";
import styles from "./styles";

interface PreviewPerContactProps {
  handleSendEmail: HandleSendEmail;
  sendLoading: boolean;
  changePreview: HandleChangePreviewFunction;
  setUpdateOrgProfile?: Dispatch<SetStateAction<boolean>>;
}

export default function PreviewPerContact({
  handleSendEmail,
  sendLoading,
  changePreview,
  setUpdateOrgProfile,
}: PreviewPerContactProps) {
  const { outreachMessages, bentoBrand } = useContext(QuickSendContext);
  const { outreachTab } = useContext(QuickSendOutreachTabsContext);

  const outreach = outreachMessages?.[0];
  let followups: PreviewMessage[];

  if (outreachMessages?.length > 1) {
    followups = outreachMessages.slice(1, outreachMessages?.length);
  }

  const skeleton = (
    <Grid container sx={styles.skeletonGroup} gap={2}>
      <Skeleton animation="wave" variant="rounded" height={30} width="80%" />
      <Skeleton animation="wave" variant="rounded" height={30} width="100%" />
      <Skeleton animation="wave" variant="rounded" height={200} width="100%" />
    </Grid>
  );

  const renderPage = () => {
    if (!bentoBrand) return skeleton;
    if (outreachTab > 0 && inRange(outreachTab - 1, 0, followups?.length)) {
      const followup = followups[outreachTab - 1];
      return (
        <PreviewIndividual
          preview={followup}
          changePreview={changePreview}
          index={outreachTab}
          handleSendEmail={handleSendEmail}
          sendLoading={sendLoading}
        />
      );
    } else if (outreach) {
      return (
        <PreviewIndividual
          preview={outreach}
          changePreview={changePreview}
          index={0}
          handleSendEmail={handleSendEmail}
          sendLoading={sendLoading}
          setUpdateOrgProfile={setUpdateOrgProfile}
        />
      );
    } else {
      return skeleton;
    }
  };

  return <Box>{renderPage()}</Box>;
}
