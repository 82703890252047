import React, { SetStateAction, createContext, useState } from "react";
import {
  Organization,
  OrganizationProfile,
  OrganizationType,
  OrganizationUser,
  UserIntegration,
  UserNotifications,
} from "schemas/dashboard";
import { EmailSettings } from "schemas/email";

/*
 * This is the Context for all Organization related values
 */

interface OrganizationUserContextInterface {
  currentUser: OrganizationUser | null;
  setCurrentUser: React.Dispatch<SetStateAction<OrganizationUser | null>>;
  currentOrg: Organization | null;
  setCurrentOrg: React.Dispatch<SetStateAction<Organization | null>>;
  clearOrganizationContext: () => void;
  isInfluencer: boolean;
  userIntegrations: UserIntegration[];
  setUserIntegrations: React.Dispatch<SetStateAction<UserIntegration[]>>;
  profile: OrganizationProfile | null;
  setProfile: React.Dispatch<SetStateAction<OrganizationProfile>>;
  userNotifications: UserNotifications | null;
  setUserNotifications: React.Dispatch<
    SetStateAction<UserNotifications | null>
  >;
  emailSettings: EmailSettings | null;
  setEmailSettings: React.Dispatch<SetStateAction<EmailSettings | null>>;
  hideCityDialog: boolean;
  setHideCityDialog: React.Dispatch<SetStateAction<boolean>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  currentUser: null,
  setCurrentUser: defaultContextMissingFunction,
  currentOrg: null,
  setCurrentOrg: defaultContextMissingFunction,
  clearOrganizationContext: defaultContextMissingFunction,
  isInfluencer: false,
  userIntegrations: [],
  setUserIntegrations: defaultContextMissingFunction,
  profile: null,
  setProfile: defaultContextMissingFunction,
  userNotifications: null,
  setUserNotifications: defaultContextMissingFunction,
  emailSettings: null,
  setEmailSettings: defaultContextMissingFunction,
  hideCityDialog: false,
  setHideCityDialog: defaultContextMissingFunction,
};

const OrganizationUserContext =
  createContext<OrganizationUserContextInterface>(defaultInterface);

interface OrganizationUserProviderProps {
  children: React.ReactNode;
}

const OrganizationUserProvider = ({
  children,
}: OrganizationUserProviderProps) => {
  const [currentUser, setCurrentUser] = useState<OrganizationUser | null>(null);
  const [currentOrg, setCurrentOrg] = useState<Organization | null>(null);
  const [profile, setProfile] = useState<OrganizationProfile>({
    id: 0,
  });
  const [userNotifications, setUserNotifications] =
    useState<UserNotifications | null>(null);

  const isInfluencer = currentOrg?.type === OrganizationType.INFLUENCER;

  const [userIntegrations, setUserIntegrations] = useState<UserIntegration[]>(
    [],
  );
  const [emailSettings, setEmailSettings] = useState<EmailSettings | null>(
    null,
  );
  const [hideCityDialog, setHideCityDialog] = useState<boolean>(false);

  const clearOrganizationContext = () => {
    setCurrentUser(null);
    setCurrentOrg(null);
    setHideCityDialog(false);
  };

  return (
    <OrganizationUserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentOrg,
        setCurrentOrg,
        clearOrganizationContext,
        isInfluencer,
        userIntegrations,
        setUserIntegrations,
        profile,
        setProfile,
        userNotifications,
        setUserNotifications,
        emailSettings,
        setEmailSettings,
        hideCityDialog,
        setHideCityDialog,
      }}
    >
      {children}
    </OrganizationUserContext.Provider>
  );
};

export { OrganizationUserProvider, OrganizationUserContext };
