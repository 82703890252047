import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { useContext } from "react";
import { Map } from "schemas/functions";

import { ATTACHMENT_VIEWED } from "features/Influencer/Tracking/events";

type Props = {
  attachment: string;
  openAttachmentManager?: () => void;
  removeAttachment?: () => void;
  styles: Map;
};

const AttachmentDisplay = ({
  attachment,
  openAttachmentManager,
  removeAttachment,
  styles,
}: Props) => {
  const { trackTable } = useContext(ContactViewContext);
  const viewAttachment = () => {
    trackTable(ATTACHMENT_VIEWED, { attachment });
    window.open(attachment, "_blank");
  };

  return (
    <Grid container alignItems="center" sx={styles.attachmentBox}>
      <Box className="fa-regular fa-paperclip" sx={{ mr: 1 }} />{" "}
      <Tooltip title="View Attachment">
        <Link onClick={viewAttachment}>
          <Typography sx={styles.attachmentName}>
            {attachment.split("/")[attachment.split("/").length - 1]}
          </Typography>
        </Link>
      </Tooltip>
      {openAttachmentManager && (
        <IconButton
          size="small"
          color="primary"
          onClick={openAttachmentManager}
        >
          <Box
            component="i"
            className="fa-thin fa-edit"
            sx={{ fontSize: 12 }}
          />
        </IconButton>
      )}
      {removeAttachment && (
        <IconButton size="small" color="secondary" onClick={removeAttachment}>
          <Box
            component="i"
            className="fa-thin fa-trash"
            sx={{ fontSize: 12 }}
          />
        </IconButton>
      )}
    </Grid>
  );
};

export default AttachmentDisplay;
