import { useAuth } from "@clerk/clerk-react";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Organization, OrganizationUser } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";

interface Props {
  organizationId: number;
  setAlert: SetAlertType;
}

export default function DoNotEmail({ organizationId, setAlert }: Props) {
  const { getToken } = useAuth();

  const [loading, setLoading] = useState(false);

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [organizationUser, setOrganizationUser] =
    useState<OrganizationUser | null>(null);

  const fetchProfile = async (organizationId: number) => {
    setLoading(true);
    if (!organizationId) return;
    try {
      const { organization, organizationUser } = await fetcherAuth(
        getToken,
        `/api/admin/organization-profiles/${organizationId}`,
      );
      setOrganization(organization);
      setOrganizationUser(organizationUser);
    } catch (error) {
      setAlert(
        error?.message ||
          "An error occurred, you do not have access to this page",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDoNotEmail = async (event: CustomEvent) => {
    const { checked } = event.target;

    setOrganizationUser((prevOrganizationUser: OrganizationUser | null) => {
      if (prevOrganizationUser) {
        return {
          ...prevOrganizationUser,
          doNotEmail: checked,
        };
      } else return prevOrganizationUser;
    });

    try {
      setLoading(true);
      let data = {
        name: organization?.name,
        doNotEmail: checked,
      };

      const response = await fetcherAuth(
        getToken,
        `/api/admin/organization-profiles/${organizationId}`,
        "PUT",
        {},
        data,
      );
      setOrganization(response.organization);
      setAlert("Profile saved", "success");
    } catch (error) {
      setAlert(
        error.message || "An error occured when updating profile",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile(organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <Box sx={{ py: 2 }}>
      <Typography>Type: {organization?.type}</Typography>
      {organizationUser && (
        <Grid container>
          <Grid item xs={3}>
            Email:
          </Grid>
          <Grid item xs={9}>
            {organizationUser.email}
          </Grid>
          <Grid item xs={3}>
            Do Not Email
          </Grid>
          <Grid item xs={9}>
            <Switch
              checked={organizationUser.doNotEmail}
              onClick={handleDoNotEmail}
              disabled={loading}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
