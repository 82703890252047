import { Box, Typography } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { Dispatch, SetStateAction, useCallback, useContext } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

import { CSV_FILE_DROPPED } from "features/Influencer/Tracking/events";

import styles from "./styles";

interface DropzoneUploadProps {
  setFile: Dispatch<SetStateAction<File | null>>;
  dragEnabled: boolean;
  onError: (fileRejections: FileRejection[]) => void;
}

export default function DropzoneUpload({
  setFile,
  dragEnabled,
  onError,
}: DropzoneUploadProps) {
  const { trackTable } = useContext(ContactViewContext);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        onError(fileRejections);
      }
      trackTable(CSV_FILE_DROPPED);
      setFile(acceptedFiles[0]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    multiple: false,
    noClick: dragEnabled ? true : false,
    noDrag: dragEnabled ? false : true,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Box
          sx={[
            styles.base,
            isDragAccept && styles.acceptBorder,
            isDragReject && styles.rejectBorder,
          ]}
        >
          <Box
            component="i"
            sx={[
              styles.icon,
              isDragAccept && styles.acceptColor,
              isDragReject && styles.rejectColor,
            ]}
            className={
              dragEnabled
                ? "fa-solid fa-arrows-up-down-left-right"
                : "fa-solid fa-arrow-up-from-bracket"
            }
          />
          <Typography
            sx={[
              isDragAccept && styles.acceptColor,
              isDragReject && styles.rejectColor,
            ]}
          >
            {isDragAccept
              ? "Drop your CSV file here"
              : "Only a single CSV file is accepted"}
          </Typography>
        </Box>
      ) : (
        <Box sx={[styles.base, !dragEnabled && styles.click]}>
          <Box
            component="i"
            sx={styles.icon}
            className={
              dragEnabled
                ? "fa-solid fa-arrows-up-down-left-right"
                : "fa-solid fa-arrow-up-from-bracket"
            }
          />
          <Typography color="textSecondary">
            {dragEnabled ? "Drag and drop a CSV file" : "Browse for a CSV file"}
          </Typography>
        </Box>
      )}
    </div>
  );
}
