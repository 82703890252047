import { useEffect } from "react";
import { routes } from "schemas/routes";

const PageNotFound = () => {
  useEffect(() => {
    window.open(`/${routes.dashboard}`, "_self");
  }, []);
  return <></>;
};

export default PageNotFound;
