import { grey } from "@mui/material/colors";

const styles = {
  pdfIcon: {
    width: "30px",
    color: "#F86F67",
    padding: "1px",
  },
  videoIcon: {
    width: "30px",
    color: grey[700],
    padding: "1px",
  },
  tab: {
    textTransform: "none",
    fontSize: { xs: 12, md: "auto" },
  },
};

export default styles;
