const styles = {
  summary: {
    width: "100%",
    "& .MuiAccordionSummary-content": { width: "100%" },
  },
  summaryText: {
    width: "calc(100% - 50px)",
  },
  snippet: {
    textOverflow: "ellipsis",
    fontSize: "12px",
    mt: 0.5,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  scheduledAtContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "primary.main",
    pl: 2,
    pb: 1,
    pt: 1,
    alignItems: "center",
    fontSize: { xs: "11px", sm: "14px" },
    color: "white",
  },
};

export default styles;
