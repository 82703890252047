import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CustomEvent } from "schemas/functions";

import { ChangedFieldMap } from "features/Influencer/Tracking/schema";

interface Props {
  open: boolean;
  handleClose: (e: CustomEvent) => void;
  handleConfirm: (e: CustomEvent) => void;
  changedFields: ChangedFieldMap;
  loading: boolean;
}

export default function OverWriteColumnDialog({
  open,
  handleClose,
  handleConfirm,
  changedFields,
  loading,
}: Props) {
  const renderChangedField = (key: string) => {
    const values = changedFields[key];
    return (
      <Box sx={{ mb: 0.5 }} component="li" key={key}>
        {key}:{" "}
        {values?.map((value, index) => (
          <Chip key={index} sx={{ mr: 0.5 }} label={value} size="small" />
        ))}
      </Box>
    );
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Existing Automated Values</DialogTitle>
      <DialogContent>
        We noticed that you have these automated values already filled out on
        this row. Entering your email may overwrite these values.
        <Box sx={{ mt: 1 }}>
          {Object.keys(changedFields)?.map((x) => renderChangedField(x))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton loading={loading} onClick={handleConfirm}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
