const styles = {
  header: {
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "inherit",
    textDecoration: "none",
  },
  page: {
    my: 2,
    color: "white",
  },
  pageBox: {
    direction: "row",
    display: "flex",
    justifyContent: "flex-end",
  },
};

export default styles;
