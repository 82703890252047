import { ColorResult, TwitterPicker } from "react-color";
import { CustomEvent } from "schemas/functions";

interface Props {
  color: string;
  setColor: (color: string) => void;
  colors?: string[];
}

export const ColorPicker = ({ color, setColor, colors }: Props) => {
  const handleColorChange = ({ hex }: { hex: string }, event: CustomEvent) => {
    event.stopPropagation();
    setColor(hex);
  };
  return (
    <div>
      <TwitterPicker
        colors={colors}
        color={color}
        onChange={(_: ColorResult, e: CustomEvent) => e.stopPropagation()}
        onChangeComplete={handleColorChange}
      />
    </div>
  );
};

export default ColorPicker;
