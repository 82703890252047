import { Avatar } from "@mui/material";
import { PortfolioSite } from "schemas/portfolioSite";

import styles from "./styles";

interface IProps {
  site: PortfolioSite;
}

export default function SiteFavicon({ site }: IProps) {
  return (
    <>
      {site.favicon ? (
        <Avatar sx={styles.avatar} src={site.favicon} alt="favicon" />
      ) : (
        <Avatar sx={styles.avatar}>{site.title?.[0] || "S"}</Avatar>
      )}
    </>
  );
}
