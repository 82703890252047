import { Moment } from "moment";

export function formatDate(
  dateStr: string | Moment,
  isShortened: boolean = false,
): string {
  let date;
  if (typeof dateStr === "string") {
    date = new Date(dateStr);
  } else {
    date = dateStr.toDate();
  }
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const diffDays = diff / (1000 * 60 * 60 * 24);
  const diffHours = diff / (1000 * 60 * 60);
  const diffMinutes = diff / (1000 * 60);
  if (diffDays < 1) {
    const numberOfMinutes = Math.floor(diffMinutes);
    const numberOfHours = Math.floor(diffHours);
    if (diffHours < 1) {
      return `${numberOfMinutes} minute${numberOfMinutes !== 1 ? "s" : ""} ago`;
    }
    return `${numberOfHours} hour${numberOfHours !== 1 ? "s" : ""} ago`;
  } else if (diffDays < 7) {
    const numberOfDays = Math.floor(diffDays);
    return `${numberOfDays} day${numberOfDays !== 1 ? "s" : ""} ago`;
  } else {
    if (isShortened) {
      return date.toLocaleString("default", {
        month: "short",
        day: "numeric",
      });
    } else {
      return date.toLocaleString("default", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    }
  }
}
