import { useAuth } from "@clerk/clerk-react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext, useMemo } from "react";
import { OutreachContact } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

interface Props {
  contact: OutreachContact;
  fieldName: "isPinned" | "isImportant";
  setAlert: SetAlertType;
}

const IconAction = ({ contact, fieldName, setAlert }: Props) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const {
    contacts,
    setContacts,
    currentContact,
    setCurrentContact,
    fetchContact,
  } = useContext(OutreachContactsContext);

  const index = useMemo(() => {
    return contacts.findIndex((c: OutreachContact) => c.id === contact.id);
  }, [contact.id, contacts]);

  const toggleValue = (e: CustomEvent) => {
    e.stopPropagation();

    const previousValue = contact[fieldName];
    handleChangeStatus(!previousValue);

    // update data optimistically
    if (currentContact?.id === contact.id) {
      setCurrentContact((prev) => {
        const copy = makeDeepCopy(prev);
        copy[fieldName] = !previousValue;
        return copy;
      });
    }
    if (index < 0) return;
    setContacts((prev) => {
      const copy = makeDeepCopy(prev);
      if (fieldName === "isPinned") {
        let lastPinnedIndex = -1;
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].isPinned) {
            lastPinnedIndex = i;
          }
        }
        copy[index][fieldName] = !previousValue;
        if (index === lastPinnedIndex) {
          return copy;
        } else if (index < lastPinnedIndex) {
          const [item] = copy.splice(index, 1);
          copy.splice(lastPinnedIndex, 0, item);
        } else {
          const [item] = copy.splice(index, 1);
          copy.splice(lastPinnedIndex + 1, 0, item);
        }
      } else {
        copy[index][fieldName] = !previousValue;
      }
      return copy;
    });
  };

  const handleChangeStatus = async (newValue: boolean) => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${contact?.id}`,
        "PATCH",
        {},
        {
          [fieldName]: newValue,
        },
      );
    } catch (error) {
      setAlert(
        "Unable to update status. Please reload and retry again",
        "error",
      );
      if (contact.id) {
        fetchContact(contact.id);
      }
    }
  };

  return (
    <Tooltip
      title={
        fieldName === "isPinned"
          ? "Pin this contact to the top of your inbox table"
          : "Star this contact to mark it as important. Contacts are automatically starred when the contact has emailed you and you have not responded yet"
      }
      placement="top"
    >
      <IconButton size="small" color="primary" onClick={toggleValue}>
        <Box
          component="i"
          className={`fa-${contact[fieldName] ? "solid" : "regular"} fa-${
            fieldName === "isPinned" ? "thumbtack" : "star"
          }`}
        />
      </IconButton>
    </Tooltip>
  );
};
export default IconAction;
