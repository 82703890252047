import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { fetcherAuth } from "utils/api";
import { navigateBackPath } from "utils/navigation";

import { CONSENT_READING_EMAIL_OPTION_SELECTED } from "../../events";

const styles = {
  backdrop: {
    backgroundColor: "#fff",
    zIndex: 9999,
    position: "absolute",
    borderRadius: 2,
  },
  link: {
    cursor: "pointer",
  },
  formControl: {
    ml: 2,
  },
  checkbox: {
    color: "green.main",
  },
};

export default function UploadGmail() {
  const navigate = useNavigate();
  const { emailHealth, setOpenIntegrationDialog, setIntegrationDialogText } =
    useContext(UserIntegrationsContext);
  const [consent, setConsent] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const { selectedView, trackTable } = useContext(ContactViewContext);
  const location = useLocation();
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);

  const onBack = () => {
    navigate(navigateBackPath(location.pathname, 1));
  };

  const handleClose = () => {
    navigate(navigateBackPath(location.pathname, 3));
  };

  const confirmUpload = async () => {
    if (!emailHealth) {
      setIntegrationDialogText(
        "In order to import contacts into Bento, we need read access to your Google account to automatically set their status and create follow-up tasks for you ✨",
      );
      setOpenIntegrationDialog(true);
      return;
    }

    setConfirmLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-uploads/gmail`,
        "POST",
        {},
        { contactViewId: selectedView?.id },
      );
      setConfirmed(res.success);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <DialogTitle>Upload Contacts From Gmail</DialogTitle>
      <DialogContent>
        {confirmed ? (
          <>
            <Typography gutterBottom>
              <strong>Connected Successfully!</strong> We have everything we
              need to import your contacts from Gmail. We will now be scanning
              your emails and updating your pitch tracker with existing contacts
              from Gmail. We will notify you over email when this process is
              completed.
            </Typography>
          </>
        ) : (
          <>
            <Typography gutterBottom>
              To upload your contacts from Gmail, Bento will scan your emails
              for specific keywords to identify past companies you've partnered
              with. Using AI, Bento will categorize these partnerships and
              create follow-up tasks for you.
              <Box component="strong" sx={{ ml: 0.5 }}>
                Do you agree to allow Bento to connect to your Gmail and upload
                your contacts?
              </Box>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  edge="start"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConsent(event.target.checked);
                    trackTable(CONSENT_READING_EMAIL_OPTION_SELECTED, {
                      "Clicked From": "Importing Contacts From Gmail",
                      "User Consented": event.target.checked,
                    });
                  }}
                  checked={consent}
                  disableRipple
                  required
                  icon={
                    <Box
                      sx={styles.checkbox}
                      component="i"
                      className="fa-regular fa-square"
                    />
                  }
                  checkedIcon={
                    <Box
                      component="i"
                      className="fa-solid fa-square-check"
                      sx={styles.checkbox}
                    />
                  }
                />
              }
              sx={styles.formControl}
              label="Yes, I agree"
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        {confirmed ? (
          <Button color="secondary" onClick={handleClose}>
            Close
          </Button>
        ) : (
          <>
            <Button color="secondary" onClick={onBack}>
              Back
            </Button>
            <LoadingButton
              variant="contained"
              onClick={confirmUpload}
              loading={confirmLoading}
              disabled={!consent}
              disableElevation
            >
              Confirm
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </>
  );
}
