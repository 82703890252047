import { Box, Grid, IconButton, Typography } from "@mui/material";
import { routes } from "schemas/routes";

import { getPageName } from "constants/trackingProps";
import DiscoverSearchBar from "features/Influencer/Discover/Search";
import { isPage } from "utils/general";

import styles from "./styles";

interface TopNavigationProps {
  width: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  renderBellIcon: () => JSX.Element;
}

function TopNavigationMobile({
  width,
  handleClick,
  renderBellIcon,
}: TopNavigationProps) {
  const isDiscoverPage = isPage(routes.brands);

  const menuBarsMobileIcon = (
    <IconButton onClick={handleClick} sx={styles.iconButton}>
      <Box component="i" className="fa-regular fa-bars" />
    </IconButton>
  );

  return (
    <>
      <Grid
        sx={[styles.mobileNavigation, { width }]}
        gap={1}
        justifyContent="space-between"
      >
        <>
          {isDiscoverPage ? (
            menuBarsMobileIcon
          ) : (
            <>
              <Grid container justifyContent="space-between">
                <Grid container item xs={9} alignItems="center">
                  {menuBarsMobileIcon}
                  <Typography variant="h6">
                    <strong>{getPageName()}</strong>
                  </Typography>
                </Grid>

                {renderBellIcon()}
              </Grid>
            </>
          )}
        </>

        <Grid item xs>
          {isPage(routes.brands) && <DiscoverSearchBar />}
        </Grid>
      </Grid>
    </>
  );
}

export default TopNavigationMobile;
