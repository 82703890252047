import { Box, Typography } from "@mui/material";

import styles from "./styles";

const TypingLoader = () => {
  return (
    <Box display="flex" alignItems="center">
      <Typography sx={styles.text} component="span">
        Typing
      </Typography>
      <Box sx={styles.dots}>
        <Box sx={[styles.dot, styles.dot1]}></Box>
        <Box sx={[styles.dot, styles.dot2]}></Box>
        <Box sx={[styles.dot, styles.dot3]}></Box>
      </Box>
    </Box>
  );
};

export default TypingLoader;
