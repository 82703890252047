import { useAuth } from "@clerk/clerk-react";
import { debounce } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BentoBrand,
  OutreachDraft,
  SavedBrand,
  SavedBrandStatus,
} from "schemas/dashboard";

import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";
import { PaginationSource, PaginationType } from "./QuickSendPagination";
import { TopRecommendationsContext } from "./TopRecommendations";

export const PER_PAGE = 20;

interface SavedBrandContextInterface {
  savedBrandsLoading: boolean;
  draftBrandsLoading: boolean;
  savedBrandTotal?: number;
  draftTotal?: number;
  setSavedBrandTotal: React.Dispatch<SetStateAction<number | undefined>>;
  setDraftTotal: React.Dispatch<SetStateAction<number | undefined>>;
  setSavedBrandPage: React.Dispatch<SetStateAction<number>>;
  setDraftPage: React.Dispatch<SetStateAction<number>>;
  handleAddDebounced: (brand: BentoBrand) => void;
  handleRemoveDebounced: (brand: BentoBrand) => void;
  removeLoading: number;
  tab: SavedBrandStatus;
  setTab: React.Dispatch<SetStateAction<SavedBrandStatus>>;
  savedBrands: SavedBrand[];
  setSavedBrands: Dispatch<SetStateAction<SavedBrand[]>>;
  draftBentoBrands: BentoBrand[];
  setDraftBentoBrands: React.Dispatch<SetStateAction<BentoBrand[]>>;
  handleDeleteDraft: (brand: BentoBrand) => void;
  getFavoritesList: (
    paginationSource: PaginationSource | FavoritesListSource,
  ) => (BentoBrand | SavedBrand)[];
  isBrandInList: (
    brandId: number,
    source?: PaginationSource | FavoritesListSource,
  ) => boolean;
  getId: (brand: BentoBrand | SavedBrand) => number;
  handleAddDraft: (brand: BentoBrand) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  savedBrandsLoading: false,
  draftBrandsLoading: false,
  savedBrandTotal: undefined,
  draftTotal: undefined,
  setSavedBrandTotal: defaultContextMissingFunction,
  setDraftTotal: defaultContextMissingFunction,
  setSavedBrandPage: defaultContextMissingFunction,
  setDraftPage: defaultContextMissingFunction,
  handleAddDebounced: defaultContextMissingFunction,
  handleRemoveDebounced: defaultContextMissingFunction,
  removeLoading: -1,
  tab: SavedBrandStatus.SAVED,
  setTab: defaultContextMissingFunction,
  savedBrands: [],
  setSavedBrands: defaultContextMissingFunction,
  draftBentoBrands: [],
  setDraftBentoBrands: defaultContextMissingFunction,
  handleDeleteDraft: defaultContextMissingFunction,
  getFavoritesList: defaultContextMissingFunction,
  isBrandInList: defaultContextMissingFunction,
  getId: defaultContextMissingFunction,
  handleAddDraft: defaultContextMissingFunction,
};

const SavedBrandContext =
  createContext<SavedBrandContextInterface>(defaultInterface);

interface SavedBrandProviderProps {
  children: React.ReactNode;
}

export enum FavoritesListSource {
  ALL_BRANDS = "All Brands",
}

const SavedBrandProvider = ({ children }: SavedBrandProviderProps) => {
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { topRecommendations } = useContext(TopRecommendationsContext);

  const [savedBrandsLoading, setSavedBrandsLoading] = useState(false);
  const [draftBrandsLoading, setDraftBrandsLoading] = useState(false);
  const [savedBrandPage, setSavedBrandPage] = useState<number>(1);
  const [draftPage, setDraftPage] = useState<number>(1);
  const [savedBrandTotal, setSavedBrandTotal] = useState<number | undefined>(
    undefined,
  );
  const [draftTotal, setDraftTotal] = useState<number | undefined>(undefined);

  const [tab, setTab] = useState<SavedBrandStatus>(SavedBrandStatus.SAVED);

  const [saveLoading, setSaveLoading] = useState(-1);
  const [removeLoading, setRemoveLoading] = useState(-1);

  const [draftBentoBrands, setDraftBentoBrands] = useState<BentoBrand[]>([]);
  const [savedBrands, setSavedBrands] = useState<SavedBrand[]>([]);

  const allBrands = [...savedBrands, ...draftBentoBrands];

  const getId = (brand: PaginationType) => {
    return "bentoBrandId" in brand
      ? Number(brand.bentoBrandId)
      : Number(brand.id);
  };

  const getFavoritesList = (
    paginationSource: PaginationSource | FavoritesListSource,
  ) => {
    const list = {
      [PaginationSource.DRAFTED_BRANDS]: draftBentoBrands,
      [PaginationSource.SAVED_BRANDS]: savedBrands,
      [FavoritesListSource.ALL_BRANDS]: allBrands,
      [PaginationSource.TOP_RECOMMENDATION]: topRecommendations,
    };
    return paginationSource in list ? list[paginationSource] : [];
  };

  const isBrandInList = (
    bentoBrandId: number,
    source:
      | PaginationSource
      | FavoritesListSource = FavoritesListSource.ALL_BRANDS,
  ) => {
    const brands = getFavoritesList(source);
    return brands?.findIndex((brand) => getId(brand) === bentoBrandId) > -1;
  };

  const fetchSavedBrands = async () => {
    if (!currentOrg?.id) return;

    setSavedBrandsLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/saved-brands?page=${savedBrandPage}&per_page=${PER_PAGE}`,
        "GET",
      );
      if (savedBrandPage === 1) {
        setSavedBrands(res.brands);
      } else {
        setSavedBrands((prev) => [...prev, ...res.brands]);
      }
      setSavedBrandTotal(res.total);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setSavedBrandsLoading(false);
    }
  };

  const fetchDraftBrands = async () => {
    if (!currentOrg?.id) return;

    setDraftBrandsLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-drafts?page=${draftPage}&per_page=${PER_PAGE}&is_autogenerated=${false}`,
        "GET",
        {},
      );
      const draftBentoBrands = res.outreachDrafts?.map(
        (x: OutreachDraft) => x.bentoBrand,
      );
      if (draftPage === 1) {
        setDraftBentoBrands(draftBentoBrands);
      } else {
        setDraftBentoBrands((prev) => [...prev, ...draftBentoBrands]);
      }
      setDraftTotal(res.total);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setDraftBrandsLoading(false);
    }
  };

  const modifyDraftBrands = (brand: BentoBrand, action: "add" | "remove") => {
    setDraftBentoBrands((prev: BentoBrand[]) => {
      const exists =
        prev.findIndex((b) => Number(b.id) === Number(brand.id)) > -1;
      if (!exists && action === "add") {
        setDraftTotal((prev) => (prev || 0) + 1);
        return [brand, ...prev];
      } else if (exists && action === "remove") {
        setDraftTotal((prev) => (prev || 0) - 1);
        return prev.filter((b) => Number(b.id) !== Number(brand.id));
      }
      return prev;
    });
  };

  const modifySavedBrands = (brand: SavedBrand, action: "add" | "remove") => {
    setSavedBrands((prev: SavedBrand[]) => {
      const exists =
        prev.findIndex(
          (b) => Number(b.bentoBrandId) === Number(brand.bentoBrandId),
        ) > -1;
      if (!exists && action === "add") {
        setSavedBrandTotal((prev) => (prev || 0) + 1);
        return [brand, ...prev];
      } else if (exists && action === "remove") {
        setSavedBrandTotal((prev) => (prev || 0) - 1);
        return prev.filter(
          (b) => Number(b.bentoBrandId) !== Number(brand.bentoBrandId),
        );
      }
      return prev;
    });
  };

  const handleAdd = (brand: BentoBrand) => {
    if (isBrandInList(brand.id, PaginationSource.DRAFTED_BRANDS)) {
      return;
    }

    modifySavedBrands(
      {
        bentoBrand: brand,
        bentoBrandId: brand.id,
      },
      "add",
    );
  };

  const handleRemove = (brand: BentoBrand) => {
    modifySavedBrands(
      {
        bentoBrand: brand,
        bentoBrandId: brand.id,
      },
      "remove",
    );
  };

  const handleAddDebounced = useMemo(
    () =>
      debounce(async (brand: BentoBrand) => {
        handleAdd(brand);
        try {
          setSaveLoading(brand?.id);
          await fetcherAuth(
            getToken,
            `/api/organization/${currentOrg?.id}/saved-brands`,
            "PUT",
            {},
            {
              bentoBrandId: brand.id,
            },
          );
        } catch (error) {
          setErrorAlert(error);
        } finally {
          setSaveLoading(-1);
        }
      }, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentOrg?.id,
      saveLoading,
      draftBentoBrands?.length,
      savedBrands?.length,
    ],
  );

  const handleRemoveDebounced = useMemo(
    () =>
      debounce(async (brand: BentoBrand) => {
        if (!currentOrg?.id) return;
        handleRemove(brand);
        try {
          setRemoveLoading(brand?.id);
          await fetcherAuth(
            getToken,
            `/api/organization/${currentOrg?.id}/saved-brands`,
            "DELETE",
            {},
            {
              bentoBrandId: brand.id,
            },
            true,
            false,
            true,
          );
          handleDeleteDraft(brand);
        } catch (error) {
          setErrorAlert(error);
        } finally {
          setRemoveLoading(-1);
        }
      }, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, removeLoading, savedBrands?.length],
  );

  const handleDeleteDraft = useMemo(
    () =>
      debounce(async (brand: BentoBrand) => {
        if (!currentOrg?.id) return;
        modifyDraftBrands(brand, "remove");
        try {
          setRemoveLoading(brand?.id);
          await fetcherAuth(
            getToken,
            `/api/organization/${currentOrg?.id}/outreach-drafts/bento-brands/${brand.id}`,
            "DELETE",
          );
        } catch (error) {
          modifyDraftBrands(brand, "add");
          setErrorAlert(error);
        } finally {
          setRemoveLoading(-1);
        }
      }, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, removeLoading],
  );

  const handleAddDraft = (bentoBrand: BentoBrand) => {
    modifyDraftBrands(bentoBrand, "add");
    // Remove brands from saved brands list if they become a draft.
    modifySavedBrands({ bentoBrand, bentoBrandId: bentoBrand.id }, "remove");
  };

  useEffect(() => {
    fetchSavedBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedBrandPage, currentOrg?.id]);

  useEffect(() => {
    fetchDraftBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftPage, currentOrg?.id]);

  return (
    <SavedBrandContext.Provider
      value={{
        getId,
        isBrandInList,
        getFavoritesList,
        setSavedBrandPage,
        setDraftPage,
        savedBrandsLoading,
        draftBrandsLoading,
        savedBrandTotal,
        draftTotal,
        setSavedBrandTotal,
        setDraftTotal,
        handleAddDebounced,
        handleRemoveDebounced,
        handleDeleteDraft,
        removeLoading,
        tab,
        setTab,
        savedBrands,
        setSavedBrands,
        draftBentoBrands,
        setDraftBentoBrands,
        handleAddDraft,
      }}
    >
      {children}
    </SavedBrandContext.Provider>
  );
};

export { SavedBrandProvider, SavedBrandContext };
