import { grey } from "@mui/material/colors";

import { tableStyles } from "components/Styled/Table/sharedStyles";

const styles = {
  ...tableStyles,
  header: {
    "& .MuiTableCell-head": {
      backgroundColor: "secondary.light",
    },
  },
  noContactBox: {
    backgroundColor: "white",
    p: 2,
    boxShadow: "none",
    border: "1px solid",
    borderColor: grey[200],
  },
  noContacts: {
    pt: 5,
    maxWidth: 1000,
    margin: "auto",
    textAlign: "center",
  },
  contacts: {
    overflow: "scroll",
    paddingBottom: "50px",
  },
};
export default styles;
