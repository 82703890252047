import { useAuth } from "@clerk/clerk-react";
import { Box, Link, Typography } from "@mui/material";

import defaultStyles from "./styles";

function LogoutPage() {
  const { isSignedIn, signOut } = useAuth();
  return (
    <Box sx={defaultStyles.root}>
      <Typography variant="h1">Admin Dashboard</Typography>
      <Typography paragraph>
        <>
          {isSignedIn && (
            <>
              You are currently logged in but do not have access to the
              dashboard. Please log out first{" "}
              <Link
                component="button"
                variant="body1"
                onClick={() => signOut()}
              >
                here
              </Link>{" "}
              and then sign in with an email that has access.
            </>
          )}
        </>
      </Typography>
    </Box>
  );
}

export default LogoutPage;
