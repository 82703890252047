import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";
import { routes } from "schemas/routes";

import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface PitchEmailFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
}

const PitchEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
}: PitchEmailFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasPitchEmailValidation, setHasPitchEmailValidation] = useState<
    string | undefined
  >();
  const [pitchEmailValidation, setPitchEmailValidation] = useState<
    string | undefined
  >();

  const handleBack = () => {
    navigate(`/${routes.recommendationOnboarding}${location.search}`);
  };

  const handleRadioChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value === "true";
    setOnboardingForm(copy);
    setHasPitchEmailValidation(undefined);
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    if (
      onboardingForm.hasPitchEmail === null ||
      onboardingForm.hasPitchEmail === undefined
    ) {
      setHasPitchEmailValidation("Please fill out this field.");
      return;
    }

    if (
      onboardingForm.hasPitchEmail &&
      (!onboardingForm.pitchEmail || onboardingForm.pitchEmail === "<p></p>")
    ) {
      setPitchEmailValidation("Please fill out this field.");
      return;
    }

    saveOnboardingForm(onboardingForm, "pitch");
  };

  const editor = useEditor(
    {
      extensions: extensions(),
      content: onboardingForm?.pitchEmail,
      onUpdate({ editor }) {
        setOnboardingForm((prev) => {
          return { ...prev, pitchEmail: editor.getHTML() };
        });
        setPitchEmailValidation(undefined);
      },
    },
    [],
  ) as TipTapEditor;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-dollar-sign"
          />
          Let's get you deals!
        </Typography>

        <Grid sx={styles.fieldContainer}>
          <FormHelperText
            error={!!hasPitchEmailValidation}
            sx={styles.helperText}
            id="has-pitch-email"
          >
            Do you have a pitch email for reaching out to brands? *
          </FormHelperText>
          <FormControl required>
            <RadioGroup
              onChange={handleRadioChange}
              name="hasPitchEmail"
              row
              aria-labelledby="has-pitch-email"
              value={onboardingForm?.hasPitchEmail}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
            {hasPitchEmailValidation && (
              <FormHelperText error={!!hasPitchEmailValidation}>
                {hasPitchEmailValidation}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {onboardingForm.hasPitchEmail && (
          <Grid sx={styles.fieldContainer}>
            <FormHelperText
              error={!!pitchEmailValidation}
              sx={styles.helperText}
            >
              Paste your pitch email (can be edited later) *
            </FormHelperText>
            <Editor editor={editor} allowAttachment={false} allowImage={true} />
            {pitchEmailValidation && (
              <FormHelperText error={!!pitchEmailValidation}>
                {pitchEmailValidation}
              </FormHelperText>
            )}
          </Grid>
        )}

        <Grid item xs="auto" container columnGap={1}>
          <Grid item xs="auto">
            <Button onClick={handleBack} disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <LoadingButton
              disableElevation
              variant="contained"
              type="submit"
              loading={loading}
            >
              Next
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PitchEmailForm;
