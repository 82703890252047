import { Button, ButtonProps, Tooltip } from "@mui/material";

import defaultStyles from "./styles";

export interface StyledButtonProps extends Omit<ButtonProps, "color"> {
  tooltipDescription?: string;
  color?: string;
  styles?: any;
  [rest: string]: any;
}

// Component definition
function StyledButton({
  children,
  styles,
  color,
  tooltipDescription,
  ...rest
}: StyledButtonProps) {
  return (
    <Tooltip title={tooltipDescription}>
      <Button
        sx={[
          color === "secondary"
            ? defaultStyles.secondary
            : defaultStyles.button,
          styles,
        ]}
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
}

export default StyledButton;
