import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { TrackingColumnsContext } from "contexts/TrackingColumns";
import { TrackingColumnsFiltersContext } from "contexts/TrackingColumnsFilters";
import lodash from "lodash";
import { useContext, useRef } from "react";
import { PropertyType } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import {
  BuiltInFilters,
  ContactViewProperty,
  SelectOption,
} from "features/Influencer/Tracking/schema";

import { getFollowUpChip } from "../DraggbleCell/helpers";
import styles from "./styles";

interface Props {
  index: number;
  prop: ContactViewProperty;
  handleChooseColumn: (select: ContactViewProperty, index: number) => void;
  handleChooseFilter: (
    value: string[],
    index: number,
    removeFromUi?: boolean,
  ) => void;
  selectedFilters: any;
}

export default function SingleFilter({
  handleChooseColumn,
  prop,
  index,
  handleChooseFilter,
  selectedFilters,
}: Props) {
  const parentRef = useRef(null);

  const { getTitleForHeader } = useContext(TrackingColumnsContext);
  const { filterableColumns, highlightColumn } = useContext(
    TrackingColumnsFiltersContext,
  );
  const { selectedView } = useContext(ContactViewContext);

  const props = selectedView?.contactViewProperties || [];

  const generateForCustomOptions = (selectOptions: SelectOption[]) => {
    const displayedMenuItems = [];
    for (const option of selectOptions) {
      displayedMenuItems.push({
        key: option.id,
        name: lodash.startCase(option.name),
      });
    }
    return displayedMenuItems;
  };

  const generateForBuiltIn = (options: (string | boolean)[]) => {
    const displayedMenuItems = [];
    for (const option of options) {
      let name = lodash.startCase(option?.toString());
      if (option && option?.toString()?.includes("sent_follow_up")) {
        name = getFollowUpChip(option?.toString())?.name;
      }
      displayedMenuItems.push({
        key: option,
        name,
      });
    }
    return displayedMenuItems;
  };

  const getOptions = () => {
    const opts = prop?.contactProperty?.selectOptions;
    const name = prop?.contactPropertyName;
    if (opts && opts.length > 0) {
      return generateForCustomOptions(opts);
    } else if (prop?.contactProperty?.type === PropertyType.checkbox) {
      return generateForBuiltIn([true, false]);
    } else if (name) {
      return generateForBuiltIn(BuiltInFilters[name]);
    } else {
      return [{ key: "", name: "" }];
    }
  };

  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    const newProp = props?.find((x) => x.id === Number(value));
    if (newProp) handleChooseColumn(newProp, index);
  };

  const handleAddFilter = (e: CustomEvent) => {
    const { value } = e.target;
    handleChooseFilter(value, index);
  };

  const selectedKeys = prop?.filters?.[0]?.values || [];
  const displayedOptions = getOptions();

  const getColumns = () => {
    if (!filterableColumns) return [];
    const leftovers = [];
    const ids = selectedFilters?.map((x: any) => x.id);
    for (const column of filterableColumns) {
      if (!ids?.includes(column?.id) || column.id === prop.id) {
        leftovers?.push(column);
      }
    }
    return leftovers;
  };

  const highlight = {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#59C0A4",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "59C0A4",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "59C0A4",
    },
  };

  const addHighlight = prop.id === highlightColumn?.id;

  return (
    <Grid container gap={1} alignItems="center" ref={parentRef}>
      <FormControl sx={{ width: 200 }}>
        <FormHelperText sx={{ ml: 0 }}>Column Name</FormHelperText>
        <Select
          sx={addHighlight ? highlight : {}}
          size="small"
          onChange={handleChange}
          value={prop?.id || ""}
        >
          {getColumns()?.map((x) => (
            <MenuItem value={x.id} key={x.id}>
              {getTitleForHeader(
                x.contactPropertyName || x?.contactProperty?.name,
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ width: 300 }}>
        <FormHelperText sx={{ ml: 0 }}>Filtered By</FormHelperText>
        <Select
          size="small"
          onChange={handleAddFilter}
          value={selectedKeys}
          multiple
          displayEmpty
          MenuProps={{
            container: parentRef.current, // used so that the loading backdrop appears above this popover
          }}
          sx={addHighlight ? highlight : {}}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Select Filter</em>;
            }
            const values: string[] = [];
            for (const key of selected) {
              //   @ts-ignore: boolean
              const name = displayedOptions?.find((x) => x.key === key)?.name;
              if (name) values.push(name);
            }
            return values?.join(", ");
          }}
        >
          <MenuItem disabled value="">
            <em>Select Filter</em>
          </MenuItem>
          {displayedOptions?.map((x, index) => (
            //   @ts-ignore: boolean
            <MenuItem value={x.key} key={index}>
              {/* @ts-ignore: False value */}
              <Checkbox checked={selectedKeys?.includes(x.key)} />
              {x?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip title="Remove filter">
        <IconButton
          sx={styles.iconButton}
          onClick={() => handleChooseFilter([], index, true)}
        >
          <Box component="i" className="fa-regular fa-xmark" />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
