import { grey } from "@mui/material/colors";

import { drawerStyles } from "components/Drawer/reusableDrawerStyles";

const styles = {
  ...drawerStyles,
  progressContainer: {
    width: "100%",
    pt: 4,
  },
  dialogTitleText: {
    backgroundColor: "yellow.light",
    borderBottom: `1px solid ${grey[300]}`,
  },
  dialogActions: {
    borderTop: `1px solid ${grey[300]}`,
  },
  brandName: {
    width: { xs: 100, md: 300 },
    textAlign: "center",
  },
  dialogContent: {
    my: 0,
    mx: 0,
    px: 0,
    py: 0,
    pb: { xs: "24px", md: 0 },
  },
};

export default styles;
