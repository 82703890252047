/**
 * Open base64url-encoded file data in a new tab.
 * @param data A base6url-encoded string.
 * @param mimeType The MIME type of the file.
 */
export const openBase64DataInTab = (data: string, mimeType: string) => {
  const dataFixed = data.replace(/-/g, "+").replace(/_/g, "/");
  const cleanedBase64 = dataFixed.replace(/\s/g, "");
  const byteCharacters = atob(cleanedBase64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl, "_blank");
};
