import { grey } from "@mui/material/colors";

const styles = {
  rowPinned: {
    background: "white",
  },
  subject: {
    fontSize: 12,
    color: grey[700],
  },
  email: {
    fontSize: 11,
    color: grey[500],
  },
  snippet: {
    fontSize: 11,
    color: grey[500],
  },
  noBorder: {
    ml: -2,
    fontSize: 12,
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  actionButton: {
    textTransform: "none",
    border: "1px solid",
    borderColor: "primary.main",
    width: 150,
    backgroundColor: "#0B5345",
    "&:hover": {
      backgroundColor: "primary.dark",
    },
    color: "white",
  },
  none: {
    color: grey[500],
    fontSize: 12,
  },
  scheduledFor: {
    color: grey[500],
    fontSize: 12,
    ml: 0.5,
    mr: 0.5,
  },
  accordion: {
    cursor: "pointer",
    position: "relative",
    background: grey[50],
    p: 1.5,
    borderTop: "1px solid",
    borderColor: grey[200],
    transition: "box-shadow 0.2s", // smooth transition for the boxShadow
    "&:last-child": { borderBottom: `1px solid ${grey[200]}` },

    "&:hover": {
      boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.1)", // subtle boxShadow on hover
      zIndex: 2,
    },
  },
  date: {
    color: "#117864",
  },
  statusWrapper: {
    textAlign: { xs: "left", sm: "right" },
    p: 0.5,
  },
  nameWrapper: {
    width: { md: "180px", lg: "200px" },
    pr: 2,
    overflow: "hidden",
    whiteSpace: "nowrap",
    " > p, div": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  formattedDate: {
    width: { md: "80px", lg: "90px" },
    color: grey[500],
    textAlign: "center",
  },
  openedColumnMobile: {
    pt: 2,
    pb: 1,
    px: 1.5,
  },
  openedColumn: {
    width: { xs: "inherit", md: "100px", lg: "150px" },
    px: 0.5,
    textAlign: { xs: "right", md: "center" },
  },
  openedColumnIcon: { color: grey[500], ml: 0.5 },
  openedColumnGrey: { color: grey[500], fontSize: 11 },
  disabledText: { color: grey[400], fontSize: 11 },
  participants: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: { xs: "200px", lg: "250px" },
    display: "inline-block",
  },
  mobilePinned: {
    position: "absolute",
    top: "10px",
    right: "4px",
  },
  mobileTitle: {
    maxWidth: "90%",
  },
  lastThreadLength: {
    fontSize: 12,
    ml: 0.75,
    position: "relative",
  },
};

export default styles;
