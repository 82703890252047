import { grey } from "@mui/material/colors";

const styles = {
  formHelperText: {
    color: grey[500],
  },
  delete: { color: "error.main", fontSize: 14, textTransform: "none" },
  divider: { width: "100%", my: 1 },
};

export default styles;
