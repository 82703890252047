import { grey } from "@mui/material/colors";

const styles = {
  root: {
    backgroundColor: "primary.dark",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    position: "fixed",
    overflowY: "auto",
    px: { xs: 0, sm: 3, md: 5, lg: 20 },
    py: { xs: 0, sm: 2, md: 5 },
    opacity: 1,
    backgroundImage:
      "radial-gradient(#F7F6F0 0.55px, transparent 0.55px), radial-gradient(#F7F6F0 0.55px, #151F16 0.55px)",
    backgroundSize: "22px 22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    borderRadius: { xs: 0, sm: 4 },
    backgroundColor: "yellow.main",
    maxWidth: { lg: "1024px" },
    p: { xs: 3, sm: 5, md: 8 },
  },
  fieldContainer: {
    mb: 2,
  },
  helperText: {
    mb: 0.5,
  },
  textField: {
    mb: 2,
    "& .MuiInputBase-root": { backgroundColor: "white" },
    "& .MuiFormHelperText-root": { color: "error.main" },
  },
  linkAdornment: {
    "& .MuiTypography-root": {
      color: grey[900],
      fontSize: { xs: 12, md: 14 },
    },
  },
  selectSocial: {
    backgroundColor: "white",
    width: "120px",
  },
  autocomplete: { backgroundColor: "white", mb: 2 },
  removeButton: {
    color: "secondary.main",
    fontSize: 12,
    width: 20,
    height: 20,
  },
  button: {
    textTransform: "none",
    color: grey[500],
    borderRadius: 0,
    borderColor: grey[500],
    "&:hover": {
      color: "black",
    },
  },
  selected: {
    color: "black",
    borderColor: "black",
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[50],
    },
  },
  loadingSkeleton: {
    width: "100%",
    border: `1px solid ${grey[200]}`,
    height: 217.5,
    borderRadius: 4,
  },
  quickSendSkeleton: {
    my: 1,
    mx: "2.5%",
    width: "95%",
  },
  quickSendWrapper: {
    position: "relative",
    overflowY: "auto",
    height: { xs: "calc(100vh - 200px)", md: "calc(100vh - 350px)" },
    minHeight: "500px",
    border: `1px solid ${grey[300]}`,
    backgroundColor: "white",
  },
};

export default styles;
