import { grey } from "@mui/material/colors";

const styles = {
  actionButton: {
    width: 100,
    fontSize: 12,
  },
  title: { fontSize: 16, lineHeight: 1.5, fontWeight: 600 },
  footer: {
    backgroundColor: "yellow.light",
    borderTop: `1px solid ${grey[300]}`,
    textAlign: { xs: "center", md: "auto" },
    p: { xs: 2 },
    position: "sticky",
    zIndex: { xs: "2", md: "auto" },
    bottom: 0,
    width: "100%",
  },
};
export default styles;
