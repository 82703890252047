import { Box } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { ColumnDialog, TrackingColumnsContext } from "contexts/TrackingColumns";
import { useContext } from "react";

import { ADD_NEW_COLUMN } from "features/Influencer/Tracking/events";

import AddColumnMenu from "./Menu";
import styles from "./styles";

const AddColumn = () => {
  const { trackTable } = useContext(ContactViewContext);
  const { dialogType, setDialogType } = useContext(TrackingColumnsContext);

  const handleClick = () => {
    trackTable(ADD_NEW_COLUMN);
    setDialogType(ColumnDialog.add);
  };

  const handleClose = () => {
    setDialogType(null);
  };

  return (
    <Box sx={styles.addCellContainer}>
      <Box sx={styles.addCell} onClick={handleClick}>
        <Box component="i" className="fa-solid fa-plus" />
      </Box>
      <AddColumnMenu open={dialogType !== null} handleClose={handleClose} />
    </Box>
  );
};

export default AddColumn;
