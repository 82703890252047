import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { FormHelperText, Grid, TextField } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { normalizePhone } from "utils/form";
import { makeDeepCopy } from "utils/updateLocalState";

import {
  BOTH,
  OPT_IN_PHONE,
  RadioOptionForm,
  getRadioOption,
  setNotificationsFromRadioOption,
} from "./helpers";

export default function UserNotificationsSettings() {
  const { getToken } = useAuth();
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const {
    currentOrg,
    setUserNotifications,
    userNotifications,
    profile,
    setProfile,
  } = useContext(OrganizationUserContext);
  const [option, setOption] = useState<string>(
    getRadioOption(userNotifications),
  );

  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    setOption(value);
    setNotificationsFromRadioOption(value, setUserNotifications);
  };

  const handleChangePhone = (e: CustomEvent) => {
    const { value } = e.target;
    const copy = makeDeepCopy(profile);
    copy.phoneNumber = value;
    setProfile(copy);
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      let data = {
        ...userNotifications,
        ...profile,
      };

      const response = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/settings`,
        "PUT",
        {},
        data,
      );
      setProfile(response.organizationProfile);
      setAlert("Profile saved", "success");
    } catch (error) {
      setAlert(
        error.message || "An error occured when updating profile",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        Set how you want to receive notifications when a brand's contact is
        available from Bento.
      </Grid>

      <RadioOptionForm option={option} handleChange={handleChange} />

      {(option === BOTH || option === OPT_IN_PHONE) &&
        !profile?.phoneNumber && (
          <FormHelperText sx={{ color: "error.main", my: 1 }}>
            <i>
              We need a <strong>phone number</strong> to send notifications by
              text messaging.
            </i>
          </FormHelperText>
        )}

      {(option === BOTH || option === OPT_IN_PHONE) && (
        <Grid item xs={12} md={6}>
          <FormHelperText>Phone Number </FormHelperText>
          <TextField
            sx={{ backgroundColor: "white", maxWidth: 300 }}
            fullWidth
            name="phoneNumber"
            onChange={handleChangePhone}
            value={normalizePhone(profile?.phoneNumber || "")}
            placeholder="(750) 932-3498"
          />
        </Grid>
      )}

      <LoadingButton
        loading={loading}
        variant="contained"
        sx={{ mt: 2 }}
        disableElevation
        onClick={handleSave}
      >
        Save
      </LoadingButton>
    </>
  );
}
