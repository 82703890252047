import { Box, Grid, Link, Typography } from "@mui/material";
import { BentoBrand, TikTokAd } from "schemas/dashboard";

import { formatNumber } from "utils/string";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface Props {
  item: TikTokAd;
  bentoBrand: BentoBrand | null;
}

const TikTokAdContent = ({ item, bentoBrand }: Props) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={6} sm={4} md={3}>
        <video controls style={styles.video}>
          <source src={item.uploadedVideoUrl} type="video/mp4" />
        </video>
      </Grid>
      <Grid item xs md={6}>
        <Grid container spacing={1}>
          {item.ctrPercentile && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs="auto">
                Top {Math.round(item.ctrPercentile * 100)}%
              </Grid>
              <Grid item xs>
                <Typography color="textSecondary">CTR</Typography>
              </Grid>
            </Grid>
          )}
          {item.conversionCountPercentile && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs="auto">
                Top {Math.round(item.conversionCountPercentile * 100)}%
              </Grid>
              <Grid item xs>
                <Typography color="textSecondary">Conversion</Typography>
              </Grid>
            </Grid>
          )}
          {item.shareCount && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs="auto">
                {formatNumber(item.shareCount)}
              </Grid>
              <Grid item xs>
                <Typography color="textSecondary">Shares</Typography>
              </Grid>
            </Grid>
          )}
          {item.likeCount && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs="auto">
                {formatNumber(item.likeCount)}
              </Grid>
              <Grid item xs>
                <Typography color="textSecondary">Likes</Typography>
              </Grid>
            </Grid>
          )}
          {item.title && (
            <Grid item xs={12}>
              <Typography component="span">Ad Caption: </Typography>
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
              >
                {item.title}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Link
              href={`https://ads.tiktok.com/business/creativecenter/topads/${item.id}/pc/en`}
              onClick={() =>
                trackEvent("Related TikTok Ad Clicked", {
                  "Bento Brand ID": bentoBrand?.id,
                  tikTokAd: item.id,
                  view: "Detailed Brand View",
                })
              }
              sx={styles.link}
              target="_blank"
            >
              More Info
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fa-regular fa-arrow-up-right-from-square"
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TikTokAdContent;
