import { Box } from "@mui/material";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface Props {
  hideMenu?: boolean;
  mergeTags?: string[];
  editor: any;
}

const MergeTagMenuBar = ({ hideMenu, mergeTags, editor }: Props) => {
  const displayMergeTagMenuBar = Boolean(
    !hideMenu && mergeTags && mergeTags.length > 0,
  );

  return displayMergeTagMenuBar ? (
    <Box sx={styles.mergeTagContainer}>
      Personalization Tags:
      {mergeTags &&
        mergeTags.map((tag) => (
          <Box
            onClick={() => {
              trackEvent("Email Template Clicked Insert Merge Tag", {
                label: tag,
              });
              editor
                .chain()
                .focus()
                .insertContentAt(editor.state.selection.anchor, [
                  {
                    type: "merge-tag",
                    attrs: {
                      label: tag,
                    },
                  },
                ])
                .run();
            }}
            key={tag}
            sx={styles.mergeTag}
          >
            {`{${tag}}`}
          </Box>
        ))}
    </Box>
  ) : (
    <></>
  );
};

export default MergeTagMenuBar;
