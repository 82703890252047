import { grey } from "@mui/material/colors";

import { pageStyles } from "components/Styled/Page/styles";

const styles = {
  ...pageStyles,
  messageBox: {
    my: 3,
    mx: { xs: 2, md: 0 },
  },
  cardContainer: {
    pb: { xs: 10, md: 4 }, // padding for saved brands
  },
  emailsLeft: {
    fontSize: 12,
    color: grey[500],
  },
  number: {
    color: "primary.main",
    cursor: "pointer",
  },
  manage: {
    textTransform: "none",
  },
  searchingText: {
    px: 3,
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export default styles;
