import { Box, Button, Grid, Typography } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { QuickSendEditorContext } from "contexts/QuickSendEditor";
import { MutableRefObject, forwardRef, useContext, useRef } from "react";
import { ChatMessage } from "schemas/dashboard";

import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import useTemplate from "utils/useTemplate";

import styles from "./styles";

interface ChatMessageProps {
  chatMessage: ChatMessage;
}

export default forwardRef<HTMLDivElement, ChatMessageProps>(
  ({ chatMessage }, ref) => {
    const messageRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const { updateTemplateBody } = useTemplate();
    const { setAlert } = useContext(AlertContext);
    const {
      bentoBrand,
      outreachMessages,
      setOutreachMessages,
      setTemplateForQuickSend,
      contactName,
    } = useContext(QuickSendContext);
    const { setIsEdited } = useContext(QuickSendDrawerContext);
    const { editor } = useContext(QuickSendEditorContext);

    const handleInsertMessage = () => {
      if (!outreachMessages || outreachMessages?.length < 1) {
        return;
      }

      trackEvent("Insert Message Button Clicked", {
        "Bento Brand ID": bentoBrand?.id,
      });
      setIsEdited(true);
      setOutreachMessages((prev) => {
        const copy = makeDeepCopy(prev);
        copy[0].body = chatMessage.message;
        return copy;
      });
      if (editor) {
        editor.commands.setContent(chatMessage.message);
      }
      updateTemplateBody(
        setTemplateForQuickSend,
        chatMessage.message,
        0,
        contactName,
      );
      setAlert("Updated your outreach email", "success");
    };

    return (
      <Grid
        item
        container
        ref={ref}
        xs={12}
        justifyContent={
          chatMessage.role === "assistant" ? "flex-start" : "flex-end"
        }
        alignItems="flex-end"
      >
        <Grid
          item
          xs={chatMessage.role === "assistant" ? 12 : 9}
          sx={[
            styles.message,
            chatMessage.role === "assistant"
              ? styles.assistantMessage
              : styles.userMessage,
          ]}
        >
          <Typography component="div" sx={styles.text}>
            <Box
              ref={messageRef}
              dangerouslySetInnerHTML={{ __html: chatMessage.message }}
            ></Box>
          </Typography>
          {chatMessage.role === "assistant" &&
            chatMessage?.countsTowardQuota && (
              <Button
                size="small"
                disableElevation
                variant="contained"
                sx={styles.iconButton}
                onClick={handleInsertMessage}
              >
                Insert{" "}
              </Button>
            )}
        </Grid>
      </Grid>
    );
  },
);
