import { Box, DialogTitle, Grid, Typography } from "@mui/material";
import React from "react";

import styles from "../styles";

interface StyledDialogTitleProps {
  title: string;
  leftAction?: React.ReactElement;
  rightAction?: React.ReactElement;
}

export default function StyledDialogTitle({
  title,
  leftAction,
  rightAction,
}: StyledDialogTitleProps) {
  return (
    <DialogTitle>
      <Grid container alignItems="center" justifyContent="space-between">
        <Box sx={styles.actionButton}>{leftAction}</Box>
        <Typography variant="h2" sx={styles.title}>
          {title}
        </Typography>
        <Box
          sx={[
            styles.actionButton,
            { display: "flex", justifyContent: "flex-end" },
          ]}
        >
          {rightAction}
        </Box>
      </Grid>
    </DialogTitle>
  );
}
