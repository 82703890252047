const styles = {
  root: {
    pt: 5,
    maxWidth: "700px",
    margin: "auto",
    mb: 10,
    px: 5,
  },
  backdrop: {
    color: "#fff",
    zIndex: 9999,
    borderRadius: 2,
  },
};

export default styles;
