import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { OrganizationUserContext } from "contexts/Organization";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UploadSummary } from "schemas/dashboard";
import { routes } from "schemas/routes";

import Markdown from "components/Markdown";
import { fetcherAuth } from "utils/api";
import { DISPLAYED_CONSENT_DIALOG } from "utils/localStorage";

import { CONSENT_READING_EMAIL_OPTION_SELECTED } from "../../events";
import styles from "./styles";

export default function UploadSummaryComponent() {
  const navigate = useNavigate();

  const { setErrorAlert } = useContext(AlertContext);
  const { contactUploadId } = useParams();

  const [summary, setSummary] = useState<UploadSummary>();
  const [contactExpandView, setContactExpandView] = useState<
    "new" | "skip" | "update" | "failed" | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(false);
  const [consentError, setConsentError] = useState<boolean>(false);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { emailHealth, setOpenIntegrationDialog, setIntegrationDialogText } =
    useContext(UserIntegrationsContext);
  const { getToken } = useAuth();
  const { selectedView, trackTable } = useContext(ContactViewContext);
  const { setContactViewParams } = useContext(ContactViewSearchContext);

  const handleBack = () => {
    const url = `/${routes.trackingView}/${selectedView?.id}/${routes.pitchTrackerImport}/csv/${contactUploadId}/existing`;
    navigate(url);
  };

  const handleClose = () => {
    navigate(`/${routes.trackingView}/${selectedView?.id}`);
  };

  const hasConsentBefore =
    localStorage.getItem(`${DISPLAYED_CONSENT_DIALOG}-${currentOrg?.id}`) ===
    "true";
  const startUpload = async () => {
    if (!consent && !hasConsentBefore) {
      setConsentError(true);
      return;
    }

    if (!emailHealth) {
      setIntegrationDialogText(
        "In order to import contacts into Bento, we need read access to your Google account to automatically set their status and create follow-up tasks for you ✨",
      );
      setOpenIntegrationDialog(true);
      return;
    }

    setUploadLoading(true);

    localStorage.setItem(
      `${DISPLAYED_CONSENT_DIALOG}-${currentOrg?.id}`,
      "true",
    );

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-uploads/${contactUploadId}/process`,
        "POST",
        {},
        { contactViewId: selectedView?.id, timezone: moment.tz.guess() },
      );
      setUploadComplete(res.uploadComplete);
      setSummary(res.summary);
      if (selectedView?.id)
        setContactViewParams((prev) => ({ ...prev, page: 1 }));
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setUploadLoading(false);
    }
  };

  const fetchSummary = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-uploads/${contactUploadId}/summary`,
        "GET",
        {},
      );
      setSummary(res.summary);
      setUploadComplete(res.uploadComplete);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const closeExplanationDialog = () => {
    setContactExpandView(null);
  };

  useEffect(() => {
    if (currentOrg?.id) {
      fetchSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, contactUploadId]);

  return (
    <>
      <DialogTitle>
        {uploadComplete
          ? "Congratulations, Upload Completed 🎉"
          : "Contact Import Summary"}
      </DialogTitle>
      <Backdrop
        sx={styles.backdrop}
        open={loading || uploadLoading}
        appear={false}
      >
        <Grid container>
          <Grid item xs={12} sx={{ margin: "auto", textAlign: "center" }}>
            <CircularProgress />
          </Grid>
          {uploadLoading && (
            <Grid item xs={12} sx={{ margin: "auto", textAlign: "center" }}>
              <Typography variant="caption" color="textSecondary">
                This may take a few moments
              </Typography>
            </Grid>
          )}
        </Grid>
      </Backdrop>
      <DialogContent>
        <Typography paragraph>
          {uploadComplete
            ? "Here is a quick summary of the upload:"
            : "Before we add your contacts to Bento, here's a quick summary:"}
        </Typography>
        <ul style={{ marginBottom: 24 }}>
          <li>
            <Typography gutterBottom>
              {uploadComplete
                ? "New contacts imported"
                : "New contacts that will be imported"}
              :{" "}
              {summary && summary.new.length > 0 ? (
                <Link
                  sx={styles.link}
                  onClick={() => setContactExpandView("new")}
                >
                  <strong>{summary?.new.length}</strong>
                </Link>
              ) : (
                "0"
              )}
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              {uploadComplete
                ? "Contacts updated"
                : "Contacts that will be updated"}
              :{" "}
              {summary && summary.update.length > 0 ? (
                <Link
                  sx={styles.link}
                  onClick={() => setContactExpandView("update")}
                >
                  <strong>{summary?.update.length}</strong>
                </Link>
              ) : (
                "0"
              )}
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              {uploadComplete
                ? "Contacts skipped"
                : "Contacts that will be skipped"}
              :{" "}
              {summary && summary.skip.length > 0 ? (
                <Link
                  sx={styles.link}
                  onClick={() => setContactExpandView("skip")}
                >
                  <strong>{summary?.skip.length}</strong>
                </Link>
              ) : (
                "0"
              )}
            </Typography>
          </li>
          {summary && summary.failed.length > 0 && (
            <li>
              <Typography gutterBottom>
                {uploadComplete
                  ? "Contacts that weren't imported due to errors"
                  : "Contacts that won't import due to errors"}
                :{" "}
                {summary && summary.failed.length > 0 ? (
                  <Link
                    sx={styles.link}
                    onClick={() => setContactExpandView("failed")}
                  >
                    <strong>{summary?.failed.length}</strong>
                  </Link>
                ) : (
                  "0"
                )}
              </Typography>
            </li>
          )}
        </ul>
        {!uploadComplete && !hasConsentBefore && (
          <>
            <Typography gutterBottom>
              To ensure your contacts are imported correctly, Bento connects
              with your Gmail to update statuses automatically and remind you
              about follow-up tasks by processing past email exchanges.
              <Box component="strong" sx={{ ml: 0.5 }}>
                Do you agree to let Bento process these contacts?
              </Box>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  edge="start"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConsent(event.target.checked);
                    setConsentError(false);
                    trackTable(CONSENT_READING_EMAIL_OPTION_SELECTED, {
                      "Clicked From": "Importing Contacts From CSV",
                      "User Consented": event.target.checked,
                    });
                  }}
                  checked={consent}
                  disableRipple
                  required
                  icon={
                    <Box
                      sx={[
                        styles.checkbox,
                        consentError && styles.checkboxError,
                      ]}
                      component="i"
                      className="fa-regular fa-square"
                    />
                  }
                  checkedIcon={
                    <Box
                      component="i"
                      className="fa-solid fa-square-check"
                      sx={styles.checkbox}
                    />
                  }
                />
              }
              sx={[styles.formControl, consentError && styles.error]}
              label="Yes, I agree"
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        {uploadComplete ? (
          <Button color="secondary" onClick={handleClose}>
            Close
          </Button>
        ) : (
          <>
            <Button color="secondary" onClick={handleBack}>
              Back
            </Button>
            <LoadingButton
              onClick={startUpload}
              loading={uploadLoading}
              variant="contained"
              disableElevation
            >
              Start Import
            </LoadingButton>
          </>
        )}
      </DialogActions>
      {summary && (
        <Dialog
          open={contactExpandView !== null}
          onClose={closeExplanationDialog}
        >
          <DialogTitle>
            {contactExpandView === "new"
              ? "New"
              : contactExpandView === "update"
                ? "Update"
                : contactExpandView === "skip"
                  ? "Skipped"
                  : "Failed to Import"}{" "}
            Contacts
          </DialogTitle>
          <DialogContent>
            {contactExpandView === "new" && (
              <>
                <Typography>
                  The following contacts will be imported as new contacts in
                  Bento:
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Row Number</TableCell>
                        <TableCell>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summary.new.map((rowData) => (
                        <TableRow key={rowData.row}>
                          {/* add 2 to row to consider header row + zero indexing */}
                          <TableCell>{rowData.row + 2}</TableCell>
                          <TableCell>{rowData.email}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {contactExpandView === "update" && (
              <>
                <Typography>
                  The following contacts will be updated in Bento:
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Row Number</TableCell>
                        <TableCell>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summary.update.map((rowData) => (
                        <TableRow key={rowData.row}>
                          {/* add 2 to row to consider header row + zero indexing */}
                          <TableCell>{rowData.row + 2}</TableCell>
                          <TableCell>{rowData.email}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {contactExpandView === "skip" && (
              <>
                <Typography>
                  The following contacts will not be added to Bento:
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Row Number</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Why?</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summary.skip.map((rowData) => (
                        <TableRow key={rowData.row}>
                          {/* add 2 to row to consider header row + zero indexing */}
                          <TableCell>{rowData.row + 2}</TableCell>
                          <TableCell>{rowData.email}</TableCell>
                          <TableCell>
                            {rowData.skip_reason === "existing-contact"
                              ? "Contact already exists in Bento"
                              : "Invalid email"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {contactExpandView === "failed" && (
              <>
                <Typography>
                  The following contacts will not be added to Bento:
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Row Number</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Why?</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summary.failed.map((failReason) => (
                        <TableRow key={failReason.row}>
                          {/* add 2 to row to consider header row + zero indexing */}
                          <TableCell>{failReason.row + 2}</TableCell>
                          <TableCell>{failReason.email}</TableCell>
                          <TableCell>
                            <Markdown
                              content={failReason.reason}
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={closeExplanationDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
