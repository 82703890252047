import { SetStateAction, createContext, useState } from "react";
import { AdminUser } from "schemas/admin";

interface AdminUserContextInterface {
  currentAdminUser: AdminUser | null;
  setCurrentAdminUser: React.Dispatch<SetStateAction<AdminUser | null>>;
}

const AdminUserContext = createContext<AdminUserContextInterface | null>(null);

interface AdminUserProviderProps {
  children: React.ReactNode;
}

const AdminUserProvider = ({ children }: AdminUserProviderProps) => {
  const [currentAdminUser, setCurrentAdminUser] = useState<AdminUser | null>(
    null,
  );
  return (
    <AdminUserContext.Provider
      value={{ currentAdminUser, setCurrentAdminUser }}
    >
      {children}
    </AdminUserContext.Provider>
  );
};

export { AdminUserProvider, AdminUserContext };
