export const getStyles = (isSavedBrandOpen: boolean) => {
  return {
    root: {
      position: "fixed",
      bottom: 0,
      zIndex: 3,
      right: isSavedBrandOpen ? 424 : 100,
      boxShadow: 3,
      backgroundColor: "primary.main",
      py: 1,
      px: 2,
      width: { md: 400, xl: 500 },
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      cursor: "pointer",
    },
    icon: {
      color: "white",
      fontSize: 12,
    },
    text: {
      color: "white",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: { xs: 80, md: 250 },
    },
  };
};
