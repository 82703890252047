import { CustomEvent } from "schemas/functions";

import { TemplateContentType } from "features/Influencer/ContactList/schema";

export type BentoTemplate = {
  contentType: TemplateContentType;
  id: number;
  subject?: string;
  body: BentoTemplateBody[];
  tags: string[];
};

export type BentoTemplateBody = {
  example: string;
  placeholder: string;
  type: string;
  variable: string;
  text: string;
  organization_profile_field: string;
  subtype?: string;
};

export enum SurveyStep {
  GOAL = 0,
  SIGNATURE = 1,
  PICKED_TEMPLATE = 2,
  SAVE_OUTREACH = 3,
  SAVE_FOLLOWUP = 4,
}

export enum SurveyFollowUpStep {
  GOAL = 0,
  PICKED_TEMPLATE = 1,
  SAVE_FOLLOWUP = 2,
}

export const StepNames = [
  "Goal",
  "Signature",
  "Pick Template",
  "Save Outreach",
  "Save Followup",
];

export const FollowUpStepNames = [
  "Goal",
  "Pick Follow-Up Template",
  "Save Follow-Up",
];

export type TemplateSurvey = {
  tone?: TemplateTone;
  goal?: TemplateGoal;
  highlight?: TemplateHighlight;
  about_me?: string;
  signature?: string;
  past_partnerships?: string;
  engagement_numbers?: string;
  brand_values?: string;
  content_idea_1?: string;
  content_idea_2?: string;
  content_idea_3?: string;
  post_example?: string;
  my_content?: string;
  followers?: string;
  your_industry?: string;
};

export enum TemplateTone {
  UPBEAT = "upbeat",
  CASUAL = "casual",
  PROFESSIONAL = "professional",
}

export enum TemplateGoal {
  PR = "pr_list",
  GIFT = "gift",
  PAID = "paid",
  UGC = "ugc",
}

export enum TemplateHighlight {
  CONTENT_IDEA = "content_ideas",
  ENGAGEMENT_NUMBERS = "engagement_numbers",
  PAST_PARTNERSHIPS = "past_partnerships",
  BRAND_ALIGNMENT = "love_brand",

  // Used for onboarding
  ENGAGEMENT = "engagement",
  PAST_BRANDS = "past_brands",
}

export type HandleSurveyChangeFunction = (
  e: CustomEvent,
  orgProfileField?: string,
) => void;

export type BentoTemplates = {
  outreaches: BentoTemplate[];
  followups: BentoTemplate[];
};

export type SelectedTemplates = {
  outreach: BentoTemplate | null;
  followup: BentoTemplate | null;
};

export type SelectedIndex = {
  outreach: number;
  followup: number;
};
