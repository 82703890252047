import { keyframes } from "@mui/material";
import { grey } from "@mui/material/colors";

const blink = keyframes`
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`;

const styles = {
  text: {
    color: grey[600],
  },
  dots: {
    display: "flex",
    alignItems: "center",
    ml: 1,
  },
  dot: {
    width: 4,
    height: 4,
    margin: "0 2px",
    borderRadius: "50%",
    backgroundColor: grey[600],
    animation: `${blink} 1.4s infinite both`,
  },
  dot1: {
    animationDelay: "0s",
  },
  dot2: {
    animationDelay: "0.2s",
  },
  dot3: {
    animationDelay: "0.4s",
  },
};

export default styles;
