import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { CustomEvent, Map } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

const styles = {
  autocomplete: { mb: 2 },
  textField: {
    "& .MuiInputBase-root": {
      backgroundColor: "white",
    },
  },
};

interface IProps {
  onboardingForm: Map;
  updateForm: (newForm: Map) => void;
  error?: boolean;
  helperText?: string;
}

type CategoryType = string | { title: string; value: string };

const filter = createFilterOptions<CategoryType>();

const CATEGORIES = [
  "Men's Fashion",
  "Women's Fashion",
  "Beauty",
  "Fitness",
  "Health/Wellness",
  "Travel",
  "Home",
  "Food/Beverage",
  "Pets",
  "Baby, Kids & Maternity",
  "Gaming",
  "Technology",
  "Apps",
  "Entertainment",
];

export default function CategorySelect({
  onboardingForm,
  updateForm,
  error,
  helperText,
}: IProps) {
  const handleAutocompleteChange = (e: CustomEvent, value: CategoryType[]) => {
    const copy = makeDeepCopy(onboardingForm);
    copy.selectedCategories = value.map((v: CategoryType) =>
      typeof v === "string" ? v : v.value,
    );
    updateForm(copy);
  };

  return (
    <Autocomplete
      multiple
      autoSelect
      autoHighlight
      freeSolo
      fullWidth
      ChipProps={{
        size: "small",
      }}
      slotProps={{
        popper: {
          placement: "top",
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
          ],
        },
      }}
      sx={styles.autocomplete}
      id="brand-categories"
      options={CATEGORIES}
      forcePopupIcon={false}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.title
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue.toLowerCase() === option.toLowerCase(),
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            value: inputValue,
            title: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          sx={styles.textField}
          helperText={helperText}
          required={(onboardingForm.selectedCategories || []).length === 0}
          placeholder="Add Category..."
        />
      )}
      onChange={handleAutocompleteChange}
      value={onboardingForm.selectedCategories || []}
    />
  );
}
