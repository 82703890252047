import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SavedBrandContext } from "contexts/SavedBrand";
import { debounce } from "lodash";
import { useContext, useMemo, useState } from "react";
import { BentoBrand, UserNotifications } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";

import { BrandCardSource } from "../../schema";
import BrandCardMenu from "../Menu";
import NotifyDialog from "../NotifyDialog";
import styles from "./styles";

interface Props {
  brand: BentoBrand;
  setAlert: SetAlertType;
  source?: BrandCardSource;
}

export default function BrandCardActions({ brand, setAlert, source }: Props) {
  const theme = useTheme();
  const { lowerWidth } = useContext(DiscoverViewContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);

  // Contexts
  const { isBrandInList, handleAddDebounced, handleRemoveDebounced } =
    useContext(SavedBrandContext);
  const { currentOrg, userNotifications } = useContext(OrganizationUserContext);
  const { setBrands, setRecommendedBrands } = useContext(BrandsContext);
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);

  const [openNotifyDialog, setOpenNotifyDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<UserNotifications | null>(
    userNotifications || { optInEmail: true, optInPhone: false },
  );

  const handleNotificationApiCall = useMemo(
    () =>
      debounce(async (isChecked: boolean) => {
        if (!currentOrg?.id) return;

        try {
          const url = `/api/organization/${currentOrg?.id}/brand-notifications`;
          const method = isChecked ? "PUT" : "DELETE";
          await fetcherAuth(
            getToken,
            url,
            method,
            {},
            { bentoBrandId: brand.id },
          );
        } catch (error) {
          setBrands((prevBrands: BentoBrand[]) => {
            return prevBrands.map((prevBrand: BentoBrand) =>
              brand.id === prevBrand.id
                ? { ...prevBrand, hasBrandNotification: !isChecked }
                : prevBrand,
            );
          });

          setRecommendedBrands((prevBrands: BentoBrand[]) => {
            return prevBrands.map((prevBrand: BentoBrand) =>
              brand.id === prevBrand.id
                ? { ...prevBrand, hasBrandNotification: !isChecked }
                : prevBrand,
            );
          });

          setErrorAlert(error);
        }
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, brand.id],
  );

  const handleNotification = (e: CustomEvent) => {
    const { checked } = e.target;

    if (checked && !userNotifications) {
      setOpenNotifyDialog(true);
    } else {
      updateNotificationForBrand(checked);
    }
  };

  const updateNotificationForBrand = (checked: boolean) => {
    handleNotificationApiCall(checked);
    setBrands((prevBrands: BentoBrand[]) => {
      return prevBrands.map((prevBrand: BentoBrand) =>
        brand.id === prevBrand.id
          ? { ...prevBrand, hasBrandNotification: checked }
          : prevBrand,
      );
    });

    setRecommendedBrands((prevBrands: BentoBrand[]) => {
      return prevBrands.map((prevBrand: BentoBrand) =>
        brand.id === prevBrand.id
          ? { ...prevBrand, hasBrandNotification: checked }
          : prevBrand,
      );
    });

    const alertMessage = checked
      ? `We'll notify you when we find an email address for you to reach out to for ${brand.brandName}!`
      : `Notifications for ${brand.brandName}  is turned off`;

    setAlert(alertMessage, "success");
  };

  const openQuickSend = (e: CustomEvent) => {
    e.stopPropagation();
    handleOpenQuickSendIndividual(brand.id, "Discover", undefined, true, brand);
  };

  const isSelected = brand.id ? isBrandInList(Number(brand.id)) : false;

  const buttonSectionDesktop = () => {
    if (!brand.hasContacts) {
      return (
        <Grid
          container
          justifyContent="flex-end"
          item
          xs="auto"
          alignItems="center"
          sx={{ mr: 1, ml: -1 }}
          onClick={(e: CustomEvent) => e.stopPropagation()}
        >
          <FormControlLabel
            control={
              <Switch
                checked={brand.hasBrandNotification}
                onChange={handleNotification}
                sx={styles.switch}
              />
            }
            label="Notify me"
            labelPlacement="start"
            sx={styles.notifyLabel}
          />
        </Grid>
      );
    } else {
      return (
        <div onClick={(e: CustomEvent) => e.stopPropagation()}>
          {isSelected ? (
            <IconButton
              sx={styles.savedHeart}
              onClick={(e: CustomEvent) => {
                e.stopPropagation();
                handleRemoveDebounced(brand);
              }}
            >
              <Box component="i" className="fa-solid fa-heart" />
            </IconButton>
          ) : (
            <IconButton
              onClick={(e: CustomEvent) => {
                e.stopPropagation();
                handleAddDebounced(brand);
              }}
              sx={styles.regularHeart}
            >
              <Box component="i" className="fa-regular fa-heart" />
            </IconButton>
          )}
          {source && (
            <Button
              onClick={openQuickSend}
              variant="contained"
              size="small"
              disableElevation
              sx={styles.quickSendButton}
            >
              Email
              <Box
                component="i"
                className="fa-regular fa-envelope"
                sx={{ ml: 1 }}
              />
            </Button>
          )}
          {source && (
            <BrandCardMenu setAlert={setAlert} brand={brand} source={source} />
          )}
        </div>
      );
    }
  };

  const buttonSectionMobile = () => {
    if (!brand.hasContacts) {
      return (
        <Box
          onClick={(e: CustomEvent) => {
            e.stopPropagation();
          }}
          sx={{ mr: 1, ml: -1 }}
        >
          <Switch
            checked={brand.hasBrandNotification}
            onChange={handleNotification}
            sx={styles.switch}
          />
        </Box>
      );
    }
    return (
      <Grid
        item
        xs="auto"
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        {isSelected ? (
          <IconButton
            sx={styles.savedHeart}
            onClick={(e: CustomEvent) => {
              e.stopPropagation();
              handleRemoveDebounced(brand);
            }}
          >
            <Box>
              <Box component="i" className="fa-solid fa-heart" />
            </Box>
          </IconButton>
        ) : (
          <IconButton
            onClick={(e: CustomEvent) => {
              e.stopPropagation();
              handleAddDebounced(brand);
            }}
            sx={{ fontSize: 16 }}
          >
            <Box component="i" className="fa-regular fa-heart" />
          </IconButton>
        )}
        {source && (
          <IconButton
            sx={{ color: "green.main", fontSize: 15 }}
            onClick={openQuickSend}
          >
            <Box component="i" className="fa-regular fa-envelope" />
          </IconButton>
        )}
        {source && (
          <BrandCardMenu setAlert={setAlert} brand={brand} source={source} />
        )}
      </Grid>
    );
  };

  const renderButtons = () => {
    if (isMobileScreen || lowerWidth) {
      return buttonSectionMobile();
    } else {
      return buttonSectionDesktop();
    }
  };

  return (
    <>
      {renderButtons()}
      <NotifyDialog
        open={openNotifyDialog}
        handleClose={() => setOpenNotifyDialog(false)}
        setAlert={setAlert}
        handleCreateBrandNotification={() => updateNotificationForBrand(true)}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </>
  );
}
