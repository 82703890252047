import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export default function DeleteAutomatedOptionDialog({
  open,
  handleClose,
  handleConfirm,
}: Props) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Option</DialogTitle>
      <DialogContent>
        This option is used by Bento to help organize your contacts. Are you
        sure you want to delete this option?
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Remove</Button>
      </DialogActions>
    </Dialog>
  );
}
