type BentoBrandLink = {
  icon: string;
  tooltip: string;
  prefix: string;
};

export const BentoBrandLinks: { [key: string]: BentoBrandLink } = {
  website: {
    icon: "fa-regular fa-globe",
    tooltip: "website link",
    prefix: "https://",
  },
  instagramUsername: {
    icon: "fa-brands fa-instagram",
    tooltip: "Instagram link",
    prefix: "https://instagram.com/",
  },
  twitterUsername: {
    icon: "fa-brands fa-twitter",
    tooltip: "Twitter link",
    prefix: "https://x.com/",
  },
  pinterestUsername: {
    icon: "fa-brands fa-pinterest",
    tooltip: "Pinterest link",
    prefix: "https://pinterest.com/",
  },
  facebookUsername: {
    icon: "fa-brands fa-facebook",
    tooltip: "Facebook link",
    prefix: "https://facebook.com/",
  },
  youtubeUrl: {
    icon: "fa-brands fa-youtube",
    tooltip: "YouTube channel link",
    prefix: "",
  },
  linkedinUrl: {
    icon: "fa-brands fa-linkedin",
    tooltip: "LinkedIn link",
    prefix: "",
  },
  tiktokUsername: {
    icon: "fa-brands fa-tiktok",
    tooltip: "Tiktok link",
    prefix: "https://tiktok.com/@",
  },
};
