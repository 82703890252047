import { Box, Link } from "@mui/material";
import { BentoBrand, BentoBrandContent } from "schemas/dashboard";

import { generateColorFromPalette } from "utils/color";
import { trackEvent } from "utils/tracking";

import { styles } from "./styles";

interface Props {
  index: number;
  item: BentoBrandContent;
  bentoBrand: BentoBrand | null;
  size?: number;
}

const BrandSocialPost = ({ index, item, bentoBrand, size = 180 }: Props) => {
  const postStyles = styles(size);

  return (
    <Box>
      <Link
        target="_blank"
        href={item.url}
        sx={{ textDecoration: "none" }}
        onClick={() =>
          trackEvent("Recent Brand Partnership Post Clicked", {
            bentoBrand,
            post: item,
            view: "Detailed Brand View",
          })
        }
      >
        {item.thumbnailUrl ? (
          <Box
            component="img"
            src={item.thumbnailUrl}
            loading="lazy"
            sx={[postStyles.imageBox, postStyles.img]}
          />
        ) : (
          <Box
            sx={[
              postStyles.imageBox,
              postStyles.textHolder,
              {
                backgroundColor: generateColorFromPalette(
                  index,
                  "lightColorPalette",
                ),
              },
            ]}
          >
            {item.title?.substring(0, 50)}...
          </Box>
        )}
      </Link>

      <Box sx={postStyles.postBy}>
        <Link
          target="_blank"
          href={`https://instagram.com/${item.username}`}
          onClick={() =>
            trackEvent("Recent Brand Partnership Username Clicked", {
              bentoBrand,
              post: item,
              view: "Detailed Brand View",
            })
          }
        >
          @{item.username}
        </Link>
      </Box>
    </Box>
  );
};

export default BrandSocialPost;
