import { useAuth } from "@clerk/clerk-react";
import { Button, CircularProgress } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import googleOauthImage from "assets/oauth/google.svg";
import { OrganizationUserContext } from "contexts/Organization";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useState } from "react";
import { SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

interface GoogleConnectProps {
  setAlert: SetAlertType;
}

export default function GoogleConnect({ setAlert }: GoogleConnectProps) {
  const { getToken } = useAuth();
  const { currentOrg, currentUser, setUserIntegrations } = useContext(
    OrganizationUserContext,
  );
  const { fetchIntegrationHealth } = useContext(UserIntegrationsContext);

  const [loading, setLoading] = useState<boolean>(false);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      if (!currentOrg) return;
      try {
        setLoading(true);

        const res = await fetcherAuth(
          getToken,
          `/api/oauth/google/${currentOrg?.id}/callback`,
          "POST",
          {},
          tokenResponse,
        );

        setUserIntegrations(res.userIntegrations);
        fetchIntegrationHealth(currentOrg?.id);
        setAlert("Successfully connected to Google", "success");
      } catch (error) {
        setAlert(
          error.message ? error.message : "Unable to connect with Google",
          "error",
        );
      } finally {
        setLoading(false);
      }
    },
    onError: () => {
      setAlert("Unable to connect with Google", "error");
      setLoading(false);
    },
    hint: currentUser?.email,
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
  });

  const handleConnectToGoogle = () => {
    trackEvent("Connect To Google Button Clicked");
    googleLogin();
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Button onClick={handleConnectToGoogle}>
      <img src={googleOauthImage} alt="Connect with Google" />
    </Button>
  );
}
