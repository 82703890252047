import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { QuickSendEditorContext } from "contexts/QuickSendEditor";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { PreviewMessage } from "schemas/dashboard";
import { HandleChangePreviewFunction } from "schemas/outreach";

import Editor from "components/Editor/quicksend";
import AttachmentManager from "features/Influencer/ContactList/AttachmentManager";
import {
  HandleSendEmail,
  Template,
} from "features/Influencer/ContactList/schema";
import { containsMergeTags } from "utils/string";
import { makeDeepCopy } from "utils/updateLocalState";
import { usePageSize } from "utils/usePageSize";

import BottomToolbar from "../BottomToolbar";
import SelectTemplate from "./SelectTemplate";
import styles from "./styles";

interface PreviewIndividualProps {
  preview: PreviewMessage;
  changePreview: HandleChangePreviewFunction;
  index: number;
  handleSendEmail: HandleSendEmail;
  sendLoading: boolean;
  setUpdateOrgProfile?: Dispatch<SetStateAction<boolean>>;
}

export default function PreviewIndividual({
  preview,
  changePreview,
  index,
  handleSendEmail,
  sendLoading,
  setUpdateOrgProfile,
}: PreviewIndividualProps) {
  const { isLargeRecommendedPage, isOnboardingPage } = usePageSize();

  // Contexts
  const { errors, quickSendBrandId } = useContext(QuickSendDrawerContext);
  const { setTemplateForQuickSend, previewLoading } =
    useContext(QuickSendContext);
  const { editor } = useContext(QuickSendEditorContext);

  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);

  // Image properties
  const [selectImageOpen, setSelectImageOpen] = useState<boolean>(false);

  const checkIfTemplateVarIsFilledOut = (templateVar: string) => {
    const missingVar = `{${templateVar}}`;
    return editor?.getText()?.includes?.(missingVar);
  };

  const redErrorHighlight = Number(errors?.outreachBody) === index;

  const removeAttachment = () => {
    changePreview("attachment", undefined, index);
    setTemplateForQuickSend((prev: Template[]) => {
      const copy = makeDeepCopy(prev);
      copy[index]["attachment"] = undefined;
      return copy;
    });
  };

  const renderMergeTag = (mergeTag: string) => {
    return (
      <span
        style={{
          background: `rgb(175, 191, 176)`,
          borderRadius: "0.4rem",
          boxDecorationBreak: "clone",
          padding: "0.2rem 0.3rem",
        }}
      >
        {mergeTag}
      </span>
    );
  };

  const displayWarningForEmail = () => {
    const variables = preview.missingValues?.filter(
      checkIfTemplateVarIsFilledOut,
    );
    const hasMergeTag =
      containsMergeTags(preview?.subject) || errors.mergeTag === index;

    const hasVars =
      editor && preview.missingValues && variables && variables?.length > 0;

    if (hasVars) {
      return (
        <Typography variant="body2" sx={styles.errorWarningContainer}>
          We noticed some templating characters remaining in your message.
          Please edit these before sending.
        </Typography>
      );
    }

    if (hasMergeTag) {
      return (
        <Typography variant="body2" sx={styles.errorWarningContainer}>
          Make sure you also fill out {renderMergeTag("{Brand Name}")} and{" "}
          {renderMergeTag("{First Name}")}.
        </Typography>
      );
    }
  };

  const attachment = preview?.attachment;
  const attachmentProps = {
    allowAttachment: true,
    openAttachmentManager: () => {
      setAttachmentDialogOpen(true);
    },
    attachment,
    removeAttachment,
    setSelectImageOpen,
  };

  const selectedImage = (imageUrl: string) => {
    if (editor) {
      editor.chain().focus().setImage({ src: imageUrl, size: "medium" }).run();
    }
  };

  return (
    <>
      {redErrorHighlight && (
        <Typography variant="body2" sx={styles.errorText}>
          Please fill in the body of this email
        </Typography>
      )}
      <Box
        sx={[
          { position: "relative" },
          redErrorHighlight ? styles.errorContent : {},
        ]}
      >
        <SelectTemplate index={index} preview={preview} />

        <Box sx={styles.editorWrapper}>
          {!editor || previewLoading === quickSendBrandId ? (
            <Box sx={{ p: 2 }}>
              <Skeleton
                variant="rounded"
                height={isLargeRecommendedPage || isOnboardingPage ? 600 : 400}
                width={"100%"}
              />
            </Box>
          ) : (
            <Editor editor={editor} />
          )}

          {attachment && editor && (
            <Box sx={styles.attachmentContainer}>
              <Box className="fa-regular fa-paperclip" sx={{ mr: 1 }} />{" "}
              <Typography color="text.secondary" sx={styles.attachmentName}>
                {attachment.split("/")[attachment.split("/").length - 1]}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={attachmentProps.openAttachmentManager}
              >
                <Box component="i" className="fa-solid fa-edit" />
              </IconButton>
              {removeAttachment && (
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={removeAttachment}
                >
                  <Box component="i" className="fa-solid fa-trash" />
                </IconButton>
              )}
            </Box>
          )}
          {displayWarningForEmail()}
        </Box>
      </Box>

      {/* Files Editor */}
      <AttachmentManager
        enteredSelection={(attachment: string) => {
          changePreview("attachment", attachment, index);
          setTemplateForQuickSend((prev: Template[]) => {
            const copy = makeDeepCopy(prev);
            copy[index]["attachment"] = attachment;
            return copy;
          });
        }}
        open={attachmentDialogOpen}
        setOpen={setAttachmentDialogOpen}
      />

      {/* Image Editor */}
      <AttachmentManager
        enteredSelection={selectedImage}
        open={selectImageOpen}
        setOpen={setSelectImageOpen}
        imageOnly={true}
      />

      {editor && (
        <BottomToolbar
          handleSendEmail={handleSendEmail}
          sendLoading={sendLoading}
          editor={editor}
          attachmentProps={attachmentProps}
          preview={preview}
          index={index}
          setUpdateOrgProfile={setUpdateOrgProfile}
        />
      )}
    </>
  );
}
