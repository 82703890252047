import { EditorView } from "@tiptap/pm/view";
import { useEffect, useRef } from "react";
import "styles/tippy.css";
import tippy, { Instance } from "tippy.js";

export default function useTippy() {
  const tooltipInstance = useRef<Instance[] | null>(null);

  const destroyTooltip = () => {
    if (tooltipInstance.current) {
      tooltipInstance.current[0].destroy();
      tooltipInstance.current = null;
    }
  };

  useEffect(() => {
    return () => {
      destroyTooltip();
    };
  }, []);

  useEffect(() => {
    const onScroll = () => {
      destroyTooltip();
    };
    // clean up code
    window.removeEventListener("wheel", onScroll);
    window.addEventListener("wheel", onScroll, { passive: true });
    return () => window.removeEventListener("wheel", onScroll);
  }, []);

  const getClientRect = (view: EditorView, pos: number) => {
    const coordsStart = view.coordsAtPos(pos);

    return new DOMRect(
      coordsStart.left,
      coordsStart.top,
      0,
      coordsStart.bottom - coordsStart.top,
    );
  };

  const updateTooltip = (
    view: EditorView,
    pos: number,
    tooltipContent: string,
  ) => {
    const rect = getClientRect(view, pos);

    if (tooltipInstance.current) {
      tooltipInstance.current[0].setProps({
        getReferenceClientRect: () => rect,
        content: tooltipContent,
      });
      tooltipInstance.current[0].show();
    } else {
      tooltipInstance.current = tippy("body", {
        getReferenceClientRect: () => rect,
        appendTo: () => document.body,
        content: tooltipContent,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom",
      });
    }
  };

  const toggleTooltip = (
    content: string,
    view?: EditorView | null,
    pos?: number,
  ) => {
    if (!view) {
      hideToolTipData();
    }

    if (view && pos !== undefined) {
      updateTooltip(view, pos, content);
    }
  };

  const hideToolTipData = () => {
    if (tooltipInstance.current) {
      tooltipInstance.current[0].hide();
    }
  };

  return {
    toggleTooltip,
  };
}
