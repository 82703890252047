const styles = {
  filePicker: {
    borderRadius: 20,
    padding: { xs: 1, sm: 2 },
    border: "1px solid",
    borderColor: "primary.main",
    cursor: "pointer",
    width: "max-content",
    textTransform: "uppercase",
    textAlign: "center",
    "&:hover, &:focus": {
      backgroundColor: `rgba(255, 119, 72, 0.1)`,
    },
  },
};

export default styles;
