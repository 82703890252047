import { MenuOption } from "schemas/forms";
import { Map } from "schemas/functions";

import { titleCase } from "utils/string";

import { getAllStages, getStageName } from "./influencer";

export const getEnumOptions = (enumObject: Map) => {
  const options: MenuOption[] = [];
  for (const value of Object.values(enumObject)) {
    options.push({ value, label: titleCase(value) });
  }
  return options;
};

export const getSearchStages = (isInfluencer: boolean) => {
  const options: MenuOption[] = [];
  for (const value of getAllStages(isInfluencer)) {
    options.push({ value, label: getStageName(value) || "" });
  }
  return options;
};

export const normalizePhone = (text: string) => {
  const cleaned = ("" + text).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "",
      number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
        "",
      );
    return number;
  }
};
