import { green } from "@mui/material/colors";

const styles = {
  header: {
    mt: 2,
  },
  card: {
    p: 3,
    borderRadius: 4,
    my: 2,
  },
  textField: {
    mb: 2,
  },
  connectedContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  checkBadge: {
    fontSize: 24,
    color: green[600],
  },
};

export default styles;
