import { Outlet } from "react-router-dom";

import StyledPage from "components/Styled/Page";

export default function TasksManager() {
  return (
    <StyledPage
      content={
        <>
          <Outlet />
        </>
      }
    />
  );
}
