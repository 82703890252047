import { Map } from "schemas/functions";

export const BrandDemographicFields: Map = {
  targetSummary: "Summary",
  targetPainPoint: "Target Pain Point",
  targetAge: "Target Age Group",
  targetGender: "Target Gender",
  targetLocation: "Target Location",
  targetPricePoint: "Target Price Point",
};
