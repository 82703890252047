const styles = {
  linkContainer: {
    fontSize: { xs: 12, md: 14 },
    mb: 1,
  },
  annotation: {
    fontSize: 12,
  },
  button: {
    fontSize: { xs: 12, md: 14 },
    color: "secondary.main",
  },
  link: {
    maxWidth: { xs: 300, md: 200 },
    textOverflow: "ellipsis",
    cursor: "pointer",
    color: "primary.main",
  },
};

export default styles;
