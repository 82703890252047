import { Autocomplete, Chip, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { EmailAddress } from "schemas/email";
import { CustomEvent } from "schemas/functions";

import { isInvalidEmail } from "utils/string";

interface IProps {
  options?: EmailAddress[];
  value: EmailAddress[];
  updateValue: (newValue: EmailAddress[]) => void;
  variant?: "filled" | "outlined" | "standard";
}

export default function RecipientAutocomplete({
  options,
  value,
  updateValue,
  variant = "outlined",
}: IProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const convertToRecipient = (value: EmailAddress | string): EmailAddress => {
    if (typeof value !== "string") {
      return value;
    }
    if (value.includes("<")) {
      const match = value.match(/^(.*?)\s*<(.+)>$/);
      if (match) {
        return { name: match[1].trim(), email: match[2].trim() };
      }
    }
    return { name: undefined, email: value };
  };

  const parseInputValue = (
    inputValue: string | EmailAddress,
  ): EmailAddress[] => {
    if (typeof inputValue !== "string") {
      return [inputValue];
    }
    return inputValue
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email !== "")
      .map((email) => convertToRecipient(email));
  };

  const handleRecipientChange = (
    e: CustomEvent,
    value: EmailAddress | string | (EmailAddress | string)[] | null,
  ) => {
    if (!value) {
      updateValue([]);
    } else if (Array.isArray(value)) {
      const newRecipients = value.reduce<EmailAddress[]>(
        (acc: EmailAddress[], curr: EmailAddress | string) => {
          return [...acc, ...parseInputValue(curr)];
        },
        [],
      );
      updateValue(newRecipients);
    } else {
      updateValue([convertToRecipient(value)]);
    }
  };

  const getLabel = (option: EmailAddress | string) => {
    if (typeof option === "string") return option;
    return option.name ? `${option.name} <${option.email}>` : option.email;
  };

  const parseAndSetEmails = (input: string) => {
    const emails = parseInputValue(input);
    updateValue([...value, ...emails]);
    setInputValue("");
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      autoSelect
      options={options || []}
      fullWidth
      size="small"
      getOptionLabel={(option) => getLabel(option)}
      value={value || []}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          variant={variant}
          onFocus={(e: CustomEvent) => e.stopPropagation()}
          {...params}
        />
      )}
      onChange={handleRecipientChange}
      onInputChange={(event, newInputValue, reason) => {
        if (newInputValue.endsWith(",")) {
          parseAndSetEmails(newInputValue);
        } else {
          setInputValue(newInputValue);
        }
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option: string | EmailAddress, index) => {
          const isInvalid = isInvalidEmail(
            typeof option === "string" ? option : option.email,
          );
          return (
            <Tooltip
              enterTouchDelay={0}
              title={isInvalid ? "Invalid email" : ""}
              key={index}
            >
              <Chip
                label={getLabel(option)}
                {...getTagProps({ index })}
                size="small"
                color={isInvalid ? "error" : "default"}
                variant={isInvalid ? "outlined" : "filled"}
              />
            </Tooltip>
          );
        })
      }
    />
  );
}
