import { routes } from "schemas/routes";

import { isPage } from "utils/general";

// Event names
export const PAGE_VISITED = "Page Visited";

// Page Names
export const INBOX_PAGE = "Inbox";
export const PROJECTS_PAGE = "Projects";
export const SETTINGS_PAGE = "Settings";
export const DISCOVER_PAGE = "Discover";
export const TASK_MANAGER_PAGE = "Task Manager";
export const TEMPLATES_PAGE = "Templates";
export const ONBOARDING_FORM_PAGE = "Onboarding";
export const TRACKING_PAGE = "Tracking";
export const PROFILE_PAGE = "Profile";
export const RECOMMENDATIONS_PAGE = "Recommendations";

export const getPageName = () => {
  if (isPage(routes.inbox)) {
    return INBOX_PAGE;
  } else if (isPage(routes.settings)) {
    return SETTINGS_PAGE;
  } else if (isPage(routes.brands)) {
    return DISCOVER_PAGE;
  } else if (isPage(routes.tasks)) {
    return TASK_MANAGER_PAGE;
  } else if (isPage(routes.templates)) {
    return TEMPLATES_PAGE;
  } else if (isPage(routes.onboarding)) {
    return ONBOARDING_FORM_PAGE;
  } else if (isPage(routes.tracking)) {
    return TRACKING_PAGE;
  } else if (isPage(routes.profile)) {
    return PROFILE_PAGE;
  } else if (isPage(routes.recommendations)) {
    return RECOMMENDATIONS_PAGE;
  } else {
    return "";
  }
};
