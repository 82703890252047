import { Button, Grid, Typography, capitalize } from "@mui/material";
import { StringMap } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import {
  HandleSurveyChangeFunction,
  TemplateGoal,
  TemplateHighlight,
  TemplateSurvey,
  TemplateTone,
} from "../schema";
import styles from "./styles";

const TemplateGoalWording: StringMap = {
  [TemplateGoal.PR]: "Be added to their PR list",
  [TemplateGoal.GIFT]: "Ask for any gifting opportunities",
  [TemplateGoal.PAID]: "Ask for any paid collaborations",
  [TemplateGoal.UGC]: "Ask to create UGC content",
};

const TemplateToneWording: StringMap = {
  [TemplateTone.UPBEAT]: "Upbeat and sweet 🍬",
  [TemplateTone.CASUAL]: "Casual and friendly 😊",
  [TemplateTone.PROFESSIONAL]: "Professional and thorough 💼",
};

const TemplateHighlightWording: StringMap = {
  [TemplateHighlight.CONTENT_IDEA]: "Your Content Ideas",
  [TemplateHighlight.ENGAGEMENT_NUMBERS]: "Your Engagement Numbers",
  [TemplateHighlight.PAST_PARTNERSHIPS]: "Your Past Partnerships",
  [TemplateHighlight.BRAND_ALIGNMENT]: "Your Alignment With the Brand",
};

interface GoalStepProps {
  survey: TemplateSurvey;
  handleSurveyChange: HandleSurveyChangeFunction;
  isGeneratingFollowUp?: boolean;
}

export default function GoalStep({
  survey,
  handleSurveyChange,
  isGeneratingFollowUp,
}: GoalStepProps) {
  const renderItemButton = (field: string, value: string) => {
    const isSelected = survey[field as keyof TemplateSurvey] === value;
    let wordingMap: StringMap = {};
    if (field === "goal") {
      wordingMap = TemplateGoalWording;
    } else if (field === "highlight") {
      wordingMap = TemplateHighlightWording;
    } else {
      wordingMap = TemplateToneWording;
    }

    return (
      <Button
        key={value}
        onClick={() => {
          trackEvent(`Template ${capitalize(field)} Selected`, {
            value,
          });
          handleSurveyChange({ target: { name: field, value } });
        }}
        variant="outlined"
        sx={[styles.button, isSelected ? styles.selected : {}]}
      >
        {wordingMap[value]}
      </Button>
    );
  };

  return (
    <>
      {!isGeneratingFollowUp && (
        <>
          <Typography sx={{ mt: 3, mb: 1 }}>
            <strong>Tell us what you'd like to get out of your pitch</strong>
          </Typography>

          <Grid container gap={1}>
            {Object.keys(TemplateGoalWording)?.map((goal) =>
              renderItemButton("goal", goal),
            )}
          </Grid>
        </>
      )}

      <Typography sx={{ mt: 3, mb: 1 }}>
        <strong>What type of tone would you like your email to be?</strong>
      </Typography>

      <Grid container gap={1}>
        {Object.keys(TemplateToneWording)?.map((tone) =>
          renderItemButton("tone", tone),
        )}
      </Grid>

      {!isGeneratingFollowUp && (
        <>
          <Typography sx={{ mt: 3, mb: 1 }}>
            <strong>What you would like to highlight in your pitch?</strong>
          </Typography>
          <Grid container gap={1}>
            {Object.keys(TemplateHighlightWording)?.map((highlight) =>
              renderItemButton("highlight", highlight),
            )}
          </Grid>
        </>
      )}
    </>
  );
}
