const styles = {
  container: {
    display: "grid",
    gridAutoFlow: "column",
    overflowX: "auto",
    pt: 2,
  },
};

export default styles;
