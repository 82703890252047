import { NavigateFunction } from "react-router-dom";

export const navigateBackPath = (pathName: string, position: number = 1) => {
  const pathParts = pathName.split("/");
  for (let i = 0; i < position; i++) {
    pathParts.pop();
  }
  return pathParts.join("/") || "/";
};

export const navigateBack = (
  navigate: NavigateFunction,
  pathName: string,
  position: number = 1,
) => {
  const newPath = navigateBackPath(pathName, position);
  navigate(newPath);
};
