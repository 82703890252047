import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { debounce } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CustomEvent } from "schemas/functions";

import styles from "./styles";

const SearchBar = () => {
  const inputRef = useRef<null>(null);
  const { clearSearch, contactViewParams, setContactViewParams } = useContext(
    ContactViewSearchContext,
  );
  const [textValue, setTextValue] = useState<string>(
    () => contactViewParams.query,
  );

  const handleChange = (e: CustomEvent) => {
    const value = e.target.value;
    setTextValue(value);
    updateContactParams(value);
  };

  const updateContactParams = useMemo(
    () =>
      debounce((query: string) => {
        setContactViewParams({ query, page: 1 });
      }, 250),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const handleListen = (e: CustomEvent) => {
      // Check if user press Ctrl + F, if so autofocus on Search Bar
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 70) {
        e.preventDefault();
        setTimeout(() => {
          // @ts-ignore
          inputRef.current.focus();
        }, 100);
      }
    };
    window.addEventListener("keydown", handleListen);

    return () => window.removeEventListener("keydown", handleListen);
  }, []);

  return (
    <Grid container alignItems="center" item xs={12} md>
      <Grid item xs>
        <TextField
          inputRef={inputRef}
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search brands, contacts, notes..."
          onChange={handleChange}
          value={textValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box component="i" className="fa-regular fa-search" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={() => {
            setTextValue("");
            clearSearch();
          }}
          sx={styles.clearButton}
        >
          <Box component="i" className="fa-regular fa-xmark" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
