import { blue, grey } from "@mui/material/colors";

const styles = {
  tc: (isNewRow: boolean) => ({
    p: 0,
    borderBottom: `1px solid ${grey[300]}`,
    borderRight: `1px solid ${grey[300]}`,
    "&:focus, &:focus-within": {
      boxShadow: `inset 0 0 0 2px ${blue[700]}`,
      outline: "none",
    },
    ...(isNewRow && {
      position: "sticky",
      left: 0,
      borderTop: `1px solid ${grey[300]}`,
    }),
  }),
  viewThread: { fontSize: 12, color: "primary.main" },
  disabled: {
    height: 35,
    backgroundColor: grey[100],
    pointerEvents: "none",
  },
};

export default styles;
