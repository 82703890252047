import { Map } from "schemas/functions";

export enum Page {
  RECOMMENDATIONS = "recommendations",
  BRANDS = "brands",
  INBOX = "inbox",
  TRACKING = "tracking",
  TEMPLATES = "templates",
  SETTINGS = "settings",
  PROFILE = "profile",
}

export const PageMenu: Map = {
  [Page.RECOMMENDATIONS]: {
    label: "Recommendations",
    icon: "fa-solid fa-star",
  },
  [Page.BRANDS]: {
    label: "Brands",
    icon: "fa-solid fa-search",
  },
  [Page.INBOX]: {
    label: "Inbox",
    icon: "fa-solid fa-envelope",
  },
  [Page.TRACKING]: {
    label: "Tracking",
    icon: "fa-solid fa-square-kanban",
  },
};
