import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Accordion as MuiAccordion,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { Message, MessageAttachment } from "schemas/email";
import { routes } from "schemas/routes";

import EmailActivityIcon from "components/EmailActivityIcon";
import { generateColorFromString } from "utils/color";
import { isPage } from "utils/general";
import { sanitize } from "utils/sanitize";

import { formatDate } from "../helpers";
import EmailBody from "./EmailBody";
import styles from "./styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

interface EmailProps {
  isExpanded: boolean;
  handleChange: (idx: number) => void;
  idx: number;
  message: Message;
  openAttachment: (
    messageId: string,
    attachment: MessageAttachment,
  ) => Promise<void>;
  showTopBorder?: boolean;
}

export default function Email({
  isExpanded,
  handleChange,
  idx,
  message,
  openAttachment,
  showTopBorder = true,
}: EmailProps) {
  const [mouseDownPos, setMouseDownPos] = useState({ x: 0, y: 0 });

  const snippet = sanitize(message.snippet);
  const body = sanitize(message.body);

  const handleMouseDown = (event: React.MouseEvent) => {
    setMouseDownPos({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = (event: React.MouseEvent, idx: number) => {
    const distance = Math.sqrt(
      Math.pow(event.clientX - mouseDownPos.x, 2) +
        Math.pow(event.clientY - mouseDownPos.y, 2),
    );

    if (distance < 5) {
      handleChange(idx);
    }
  };

  return (
    <Accordion
      disableGutters
      square
      expanded={isExpanded}
      onMouseDown={handleMouseDown}
      onMouseUp={(event) => handleMouseUp(event, idx)}
      elevation={0}
      sx={{
        width: "100%",
        ...(!showTopBorder ? { borderTop: "none" } : {}),
      }}
    >
      <AccordionSummary sx={styles.summary}>
        <Grid container item xs={12}>
          {message?.from_?.email && (
            <Grid item xs="auto">
              <Avatar
                sx={{
                  background: generateColorFromString(message.from_.email),
                  textTransform: "uppercase",
                  mr: 1,
                }}
              >
                {message.from_.name?.charAt(0) || message.from_.email.charAt(0)}
              </Avatar>
            </Grid>
          )}
          <Grid item xs sx={styles.summaryText}>
            {(message?.from_?.name || message?.from_?.email) && (
              <Tooltip
                title={message.from_.email}
                enterDelay={1000}
                enterNextDelay={1000}
                placement="bottom-start"
              >
                <Typography component="div">
                  {message.from_.name || message.from_.email}{" "}
                  <Typography
                    component="span"
                    color="textSecondary"
                    variant="caption"
                    sx={{ ml: 1 }}
                  >
                    {formatDate(message.date)}
                  </Typography>
                  {(message.is_sent_by_user || message.emailActivities) && (
                    <Box sx={styles.emailActivityIcon}>
                      <EmailActivityIcon
                        id={message.id || ""}
                        type="message"
                        emailActivities={message.emailActivities || []}
                        isTracked={
                          message.emailActivities !== null &&
                          message.emailActivities !== undefined
                        }
                      />
                    </Box>
                  )}
                </Typography>
              </Tooltip>
            )}

            {!isExpanded && (
              <Typography
                color="textSecondary"
                sx={styles.snippet}
                dangerouslySetInnerHTML={{
                  __html: snippet,
                }}
              ></Typography>
            )}
            {isExpanded && message?.to && (
              <Typography color="textSecondary" sx={styles.snippet}>
                to {message?.to?.map((to) => to.email).join(", ")}
                {message.cc?.length > 0
                  ? `, cc: ${message.cc.map((cc) => cc.email)}`
                  : ""}
                {message.bcc?.length > 0
                  ? `, bcc: ${message.bcc.map((bcc) => bcc.email)}`
                  : ""}
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {isPage(routes.inbox) ? (
          <EmailBody body={body} />
        ) : (
          <Box dangerouslySetInnerHTML={{ __html: body }}></Box>
        )}
        {message.attachments.length > 0 && (
          <>
            <Divider />
            <Typography sx={styles.attachmentHeader}>
              <strong>Attachments:</strong>
            </Typography>
            <List>
              {message.attachments.map((attachment) => {
                return (
                  <ListItem
                    key={attachment.attachment_id}
                    disablePadding
                    sx={styles.attachment}
                  >
                    {attachment.file_name}
                    <IconButton
                      sx={styles.attachmentIcon}
                      onClick={() => {
                        openAttachment(message.id!!, attachment);
                      }}
                    >
                      <Box
                        component="i"
                        className="fa-regular fa-external-link"
                      />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
