import { blue, grey } from "@mui/material/colors";
import { barWidth } from "styles/theme";

const styles = {
  container: {
    maxHeight: {
      xs: "calc(100vh - 260px)",
      md: "calc(100vh - 180px)",
    },
    overflow: "auto",
  },
  table: {
    borderLeft: `1px solid ${grey[300]}`,
  },
  snippet: {
    fontSize: 12,
    color: grey[700],
    width: 300,
    overflow: "hidden",
  },
  subject: {
    color: "black",
    mr: 0.5,
  },
  header: {
    position: "relative",
    zIndex: 201,
  },
  tableRow: (isNewRow: boolean) => ({
    backgroundColor: "white",
    "&:nth-last-of-type(2) td": { borderBottom: 0 },
    ...(isNewRow && { position: "sticky", bottom: "44px", zIndex: 200 }),
  }),
  info: {
    color: "info.main",
    textDecoration: "none",
    mb: 4,
    cursor: "pointer",
  },
  import: {
    backgroundColor: "primary.light",
    textTransform: "none",
    height: 30,
    px: 1,
    mb: { xs: 2, md: 0 },
  },
  fetchMoreLoader: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
    bottom: { xs: `${barWidth}px`, md: "32px" },
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
  },
  highlightedNewRow: {
    backgroundColor: `${blue[50]}`,
  },
};

export default styles;
