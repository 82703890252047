import { grey } from "@mui/material/colors";

const styles = {
  root: {
    m: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: { xs: "100%", md: "auto" },
  },
  goal: {
    display: "flex",
    justifyContent: "center",
    pt: 0.5,
  },
  goalText: {
    textAlign: "center",
    borderBottom: `1px dotted ${grey[400]}`,
  },
  title: {
    pb: 1,
    textAlign: "center",
  },
};

export default styles;
