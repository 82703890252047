import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { QuickSendOutreachTabsContext } from "contexts/QuickSendOutreachTabs";
import {
  QuickSendScheduleContext,
  ScheduleTimeDialogSource,
} from "contexts/QuickSendSchedule";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useRef, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { HandleSendEmail } from "features/Influencer/ContactList/schema";

import ScheduleDialog from "../../../ScheduleDialog";

const options = ["Send", "Schedule"];

interface Props {
  handleSendEmail: HandleSendEmail;
  sendLoading: boolean;
  noBrand: boolean;
}

export default function QuickSendButtonGroup({
  handleSendEmail,
  sendLoading,
  noBrand,
}: Props) {
  const { outreachTab } = useContext(QuickSendOutreachTabsContext);
  const { emailHealth, setOpenIntegrationDialog } = useContext(
    UserIntegrationsContext,
  );
  const {
    selectedIndex,
    hasScheduled,
    setSelectedIndex,
    presetSendAt,
    setScheduleDialogSource,
    scheduleDialogSource,
  } = useContext(QuickSendScheduleContext);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    event.stopPropagation();
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = (e: CustomEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    event.stopPropagation();
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleClick = (e: CustomEvent, index: number) => {
    e.stopPropagation();
    if (index === 0) {
      handleSendEmail(null);
    } else if (hasScheduled && index === 1) {
      handleSendEmail(presetSendAt);
    } else {
      if (!emailHealth) {
        setOpenIntegrationDialog(true);
      } else {
        setScheduleDialogSource(ScheduleTimeDialogSource.SCHEDULE_BUTTON);
      }
    }
  };

  return (
    <Grid>
      <Grid container alignItems="center">
        {outreachTab === 0 && (
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            size="small"
            disableElevation
            sx={{
              ".MuiButtonGroup-grouped:not(:last-of-type)": {
                borderColor: "secondary.light",
              },
            }}
            disabled={noBrand}
          >
            <LoadingButton
              variant="contained"
              loading={sendLoading}
              onClick={(e: CustomEvent) => handleClick(e, selectedIndex)}
              sx={{ height: 30 }}
            >
              {options[selectedIndex]}
            </LoadingButton>
            <Button size="small" onClick={handleToggle}>
              <Box component="i" className="fa-regular fa-chevron-down" />
            </Button>
          </ButtonGroup>
        )}
      </Grid>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <ScheduleDialog
        open={scheduleDialogSource !== null && emailHealth}
        onSchedule={handleSendEmail}
      />
    </Grid>
  );
}
