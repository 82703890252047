import { grey } from "@mui/material/colors";
import { paragraphFontFamily } from "styles/theme";

const styles = {
  textField: {
    border: `1px solid ${grey[400]}`,
    borderRadius: 1,
    height: 55,
    py: 2,
    px: 2,
    width: "100%",
    fontFamily: paragraphFontFamily,
    "&:hover, &:focus": {
      outline: "none",
    },
  },
};

export default styles;
