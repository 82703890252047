import { useAuth } from "@clerk/clerk-react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";

import styles from "./styles";

interface Props {
  link: string;
}

const ReportLink = ({ link }: Props) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAlert } = useContext(AlertContext);
  const { brandDetailsId } = useContext(QuickSendDrawerContext);

  const handleReport = async (e: CustomEvent) => {
    e.stopPropagation();
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${brandDetailsId}/report-link`,
        "POST",
        {},
        { link },
      );
      setAlert("Thanks for reporting an issue!", "success");
    } catch (error) {
      setAlert(
        "Something went wrong. Please reach out to hello@onbento.com for assistance",
        "error",
      );
    }
  };

  return (
    <Tooltip title="Report this link">
      <IconButton onClick={handleReport}>
        <Box component="i" className="fa-regular fa-flag" sx={styles.flag} />
      </IconButton>
    </Tooltip>
  );
};

export default ReportLink;
