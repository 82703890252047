import { Box, Chip, Typography } from "@mui/material";
import { Range } from "@tiptap/react";
import { forwardRef } from "react";

import { Match } from ".";
import styles from "./styles";

export interface SuggestionsProps {
  match: Match;
  start: number;
  command: (range: Range, value: string) => void;
}

const Suggestions = forwardRef((props: SuggestionsProps, ref) => {
  return (
    <Box component="div" sx={styles.root}>
      {props.match && (
        <>
          <Typography
            component="div"
            sx={styles.title}
            variant="caption"
            color="textSecondary"
          >
            {props.match.message} Replace with:
          </Typography>
          {props.match.replacements.map((replacement) => (
            <Chip
              key={replacement.value}
              color="primary"
              label={replacement.value}
              sx={styles.chip}
              onClick={() => {
                props.command(
                  {
                    from: props.start,
                    to: props.start + props.match.length,
                  },
                  replacement.value,
                );
              }}
            />
          ))}
        </>
      )}
    </Box>
  );
});

export default Suggestions;
