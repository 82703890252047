import { useAuth } from "@clerk/clerk-react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Message, MessageAttachment } from "schemas/email";
import { CustomEvent, SetAlertType } from "schemas/functions";

import EmailThread from "components/EmailThread";
import { fetcherAuth } from "utils/api";
import { openBase64DataInTab } from "utils/files";

import styles from "./styles";

interface AdminSearchThreadsProps {
  organizationId: number;
  setAlert: SetAlertType;
}

type Thread = {
  id: string;
  snippet: string;
  historyId: string;
};

export default function AdminSearchThreads({
  organizationId,
  setAlert,
}: AdminSearchThreadsProps) {
  const { getToken } = useAuth();
  const [search, setSearch] = useState<string>("");
  const [threadId, setThreadId] = useState<string>("");
  const [threads, setThreads] = useState<Thread[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);

  const handleSearchThreads = async () => {
    try {
      const encodedQuery = encodeURIComponent(search);
      const res = await fetcherAuth(
        getToken,
        `/api/admin/organizations/${organizationId}/threads-search?domain=${encodedQuery}`,
        "GET",
      );
      if (res.threads.length === 0) {
        setAlert(`No emails could be found`, "warning");
      }
      setThreads(res.threads);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    }
  };

  const fetchThread = async (threadId: string) => {
    setMessages([]);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/organizations/${organizationId}/threads/${threadId}`,
        "GET",
      );
      setMessages(res.messages);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    }
  };

  const onSearchChange = (event: CustomEvent) => {
    const { value } = event.target;
    setSearch(value);
  };
  const onThreadIdChange = (event: CustomEvent) => {
    const { value } = event.target;
    setThreadId(value);
  };

  const openAttachment = async (
    messageId: string,
    attachment: MessageAttachment,
  ) => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/organizations/${organizationId}/messages/${messageId}/attachments/${attachment.attachment_id}`,
        "GET",
      );
      openBase64DataInTab(res.attachment.data, attachment.mime_type);
    } catch (error) {
      setAlert(`An error occurred ${error}`, "warning");
    }
  };

  return (
    <Grid container spacing={3} sx={styles.root}>
      <Grid item xs={12}>
        <TextField
          placeholder="from:nike.com or to:nike.com"
          label="Enter a search query"
          value={search || ""}
          onChange={onSearchChange}
        />
        <Button
          variant="contained"
          sx={{ ml: 1, mb: 2, mr: 1, height: "54px" }}
          onClick={handleSearchThreads}
        >
          Enter
        </Button>
        <TextField
          label="Or, enter a thread ID"
          value={threadId}
          onChange={onThreadIdChange}
        />
        <Button
          variant="contained"
          sx={{ ml: 1, mb: 2, height: "54px" }}
          onClick={() => fetchThread(threadId)}
        >
          Enter
        </Button>
      </Grid>
      <Grid item container xs={4} sx={styles.wrapper}>
        {threads.map((thread) => (
          <Grid item sx={{ mb: 2, mr: 2 }} xs={12}>
            <Card>
              <CardContent>
                <Typography>{thread.snippet}</Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => fetchThread(thread.id)}>View</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={8} sx={styles.wrapper}>
        <EmailThread messages={messages} openAttachment={openAttachment} />
      </Grid>
    </Grid>
  );
}
