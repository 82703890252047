import { useAuth } from "@clerk/clerk-react";
import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { camelCase } from "lodash";
import moment from "moment";
import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { OutreachContact, PropertyType } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import {
  ChangedFieldMap,
  ContactViewProperty,
} from "features/Influencer/Tracking/schema";
import { fetcherAuth } from "utils/api";
import { DISPLAYED_CONSENT_DIALOG } from "utils/localStorage";
import { generateInitials, isInvalidEmail } from "utils/string";
import useTableMenu from "utils/useTableMenu";

import ConsentDialog from "../../AddRow/ConsentDialog";
import NotEditableMenu from "../../NotEditableMenu";
import OverWriteColumnDialog from "../OverwriteColumnDialog";
import { getValueOnAutomatedField, widthByType } from "../helpers";
import { noteStyles as styles } from "./styles";

const TableTextField = forwardRef(
  (
    {
      type,
      notes,
      cellLoading,
      onBlur,
      showTextField,
      isEditing,
    }: {
      type: PropertyType;
      notes: string | number;
      cellLoading: boolean;
      onBlur: (e: CustomEvent) => void;
      showTextField: boolean;
      isEditing: boolean;
    },
    ref,
  ) => {
    const isMultiline = type === PropertyType.longText;

    const getType = () => {
      if (type === PropertyType.date) {
        return "date";
      } else if (type === PropertyType.dateTime) {
        return "datetime-local";
      } else if (type === PropertyType.number) {
        return "number";
      }
      return "text";
    };

    const getDefaultValue = useCallback(() => {
      if (type === PropertyType.date && notes) {
        return moment(notes)?.format("YYYY-MM-DD");
      } else if (type === PropertyType.dateTime && notes) {
        return moment(Number(notes) * 1000)?.format("YYYY-MM-DDTHH:mm");
      } else {
        return notes;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notes, type]);

    const getDisplayValue = useCallback(() => {
      if (type === PropertyType.date && notes) {
        return moment(notes)?.format("YYYY-MM-DD");
      } else if (type === PropertyType.dateTime && notes) {
        return moment(Number(notes) * 1000)?.format("YYYY-MM-DD, h:mm A");
      } else {
        return notes;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notes, type]);

    if (!showTextField && !isEditing) {
      return (
        <Box
          sx={[
            styles.notFocused(isMultiline),
            { width: type in widthByType ? widthByType[type] : 150 },
          ]}
        >
          {getDisplayValue()}
          {isMultiline && <>&nbsp;</>}
          {cellLoading && <CircularProgress size={10} sx={styles.loader} />}
        </Box>
      );
    }

    return (
      <Box
        sx={{
          position: "relative",
          alignItems: "center",
          display: "flex",
        }}
      >
        <TextField
          sx={{
            "& fieldset": { border: "none", outline: "none" },
            width: type in widthByType ? widthByType[type] : 150,
            p: 0,
          }}
          inputRef={ref}
          inputProps={{
            readOnly: !isEditing,
            sx: styles.input,
            tabIndex: -1,
          }}
          size="small"
          defaultValue={getDefaultValue()}
          type={getType()}
          multiline={type === PropertyType.longText}
          onBlur={onBlur}
        />
        {cellLoading && <CircularProgress size={10} sx={styles.loader} />}
      </Box>
    );
  },
);

interface Props {
  notes: string | number;
  outreachContact: OutreachContact;
  property: ContactViewProperty;
  type: PropertyType;
  showTextField: boolean;
}

const TableNotes = forwardRef((props: Props, ref) => {
  const { notes, outreachContact, property, type, showTextField } = props;

  const { getToken } = useAuth();
  const textFieldInputRef = useRef<HTMLInputElement>(null);

  // Contexts
  const { emailHealth, setIntegrationDialogText, setOpenIntegrationDialog } =
    useContext(UserIntegrationsContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const {
    setOutreachContacts,
    setNewContact,
    setHighlightedNewRow,
    updateOutreachContactPropertyValues,
    updateOutreachContactDefaultValues,
    selectedView,
  } = useContext(ContactViewContext);
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const { anchorEl, setAnchorEl, open, handleClose, popperRef, menuOnBlur } =
    useTableMenu();

  // States
  const [cellLoading, setCellLoading] = useState<boolean>(false);
  const [openConsent, setOpenConsent] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [openOverwriteDialog, setOpenOverwriteDialog] = useState(false);
  const [changedFields, setChangedFields] = useState<ChangedFieldMap>({});

  const key = camelCase(property?.contactPropertyName);
  const isEditable =
    property.contactPropertyId ||
    ["contactName", "contactTitle", "brandName"]?.includes(key) ||
    (key === "email" && !notes) ||
    (key === "email" && outreachContact.id && outreachContact.id < 0);
  const isMultiline = type === PropertyType.longText;

  const handleClick = (e: CustomEvent) => {
    if (isEditable) {
      if (textFieldInputRef.current) {
        setIsEditing(true);
        textFieldInputRef.current.focus();
      }
    } else {
      setAnchorEl(anchorEl ? null : e.currentTarget);
    }
  };

  const handleKeyPressed = (e: CustomEvent) => {
    if (!isEditable) {
      setAnchorEl(anchorEl ? null : e.currentTarget);
    } else {
      const isTextFieldFocused =
        document.activeElement === textFieldInputRef.current;
      if (!isTextFieldFocused) {
        if (textFieldInputRef.current) {
          if (e.key === "Enter") {
            e.preventDefault();
          }
          setIsEditing(true);
          textFieldInputRef.current.focus();
        }
      } else if (!isMultiline && e.key === "Enter") {
        // focus on the parent cell element
        if (textFieldInputRef.current) {
          textFieldInputRef.current.closest("td")?.focus();
        }
      }
    }
  };

  useImperativeHandle(ref, () => {
    return {
      childRefClick(e: CustomEvent) {
        handleClick(e);
      },
      childRefKeyPressed(e: CustomEvent) {
        handleKeyPressed(e);
      },
      childRefOnBlur(e: CustomEvent) {
        menuOnBlur(e);
      },
    };
  });

  const onBlur = () => {
    const value = textFieldInputRef.current?.value || "";

    if (value === notes) {
      setIsEditing(false);
      return;
    }

    if (outreachContact?.id && outreachContact?.id < 0) {
      if (!value) {
        setIsEditing(false);
        return;
      }
      handleCreateOutreachContact(value);
    } else {
      if (key === "email") {
        if (!value) {
          setIsEditing(false);
          return;
        }
        if (isInvalidEmail(value)) {
          setAlert(
            "Oops, that looks like an invalid email address - please enter a valid one to change email",
            "error",
          );
          setIsEditing(false);
          return;
        }
        if (!validateAddEmail()) {
          return;
        }
        if (selectedView) {
          const changedFields = getValueOnAutomatedField(
            selectedView,
            outreachContact,
          );

          if (changedFields && Object.keys(changedFields)?.length > 0) {
            setChangedFields(changedFields);
            setOpenOverwriteDialog(true);
            return;
          }
        }
        handleSave(value);
      } else {
        handleSave(value);
      }
    }
  };

  const validateAddEmail = () => {
    if (!emailHealth) {
      setIntegrationDialogText(
        "In order to import contacts into Bento, we need read access to your Google account to automatically set their status and create follow-up tasks for you ✨",
      );
      setOpenIntegrationDialog(true);
      return false;
    }
    const hasConsent =
      localStorage.getItem(`${DISPLAYED_CONSENT_DIALOG}-${currentOrg?.id}`) ===
      "true";

    if (!hasConsent) {
      setOpenConsent(true);
      return false;
    }
    return true;
  };

  const handleCreateOutreachContact = async (value: string) => {
    if (key === "email" && isInvalidEmail(value)) {
      setAlert(
        "Oops, that looks like an invalid email address - please enter a valid one to add a new row",
        "error",
      );
      setIsEditing(false);
      return;
    }
    if (key === "email") {
      if (!validateAddEmail()) {
        return;
      }
    }
    handleConsentAndSaveOutreachContact(value);
  };

  const handleConsentAndSaveOutreachContact = async (value: string) => {
    setCellLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts`,
        "POST",
        {},
        key === "email" ? { email: value } : { brandName: value },
      );
      setOutreachContacts((prev) => {
        const indexExist = prev.findIndex(
          (x) => x.id === res.outreachContact.id,
        );
        if (indexExist && indexExist > -1) {
          return prev.map((contact, idx) => {
            if (idx === indexExist) {
              return res.outreachContact;
            } else {
              return contact;
            }
          });
        } else {
          return [...prev, res.outreachContact];
        }
      });
      setNewContact(null);
      setHighlightedNewRow(res.outreachContact.id);
      setAlert("Successfully added contact", "success");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCellLoading(false);
      setIsEditing(false);
    }
  };

  const handleSave = async (textField: string) => {
    const key = camelCase(property?.contactPropertyName);

    if (!outreachContact.id) {
      setIsEditing(false);
      return;
    }

    if (key === "email") {
      setCellLoading(true);
      const updatedContact = await updateOutreachContactDefaultValues(
        outreachContact,
        {
          [key]: textField,
        },
      );
      if (updatedContact) {
        setOutreachContacts((prev) => {
          return prev.map((contact) =>
            contact.id !== updatedContact.id ? contact : updatedContact,
          );
        });
      }
      setCellLoading(false);
      setIsEditing(false);
      return;
    }

    setOutreachContacts((prev) => {
      let newValue: string | number = "";

      const copy = prev.map((contact: OutreachContact) => {
        if (contact.id !== outreachContact.id) {
          return contact;
        }

        if (
          (type === PropertyType.shortText || type === PropertyType.longText) &&
          textField
        ) {
          newValue = textField;
        } else if (type === PropertyType.number) {
          newValue = Number(textField);
        } else if (type === PropertyType.date && textField) {
          const formatted = moment(textField);
          if (!formatted.isValid()) {
            return contact;
          }
          newValue = textField;
        } else if (type === PropertyType.dateTime && textField) {
          const dateTimeToMoment = moment.tz(
            textField,
            "YYYY-MM-DD HH:mm A",
            moment.tz.guess(),
          );
          if (!dateTimeToMoment.isValid()) {
            return contact;
          }
          newValue = dateTimeToMoment.unix();
        }

        if (property?.contactPropertyId) {
          return {
            ...contact,
            propertyValues: {
              ...contact.propertyValues,
              [property.contactPropertyId]: newValue,
            },
          } as OutreachContact;
        } else {
          if (key === "brandName") {
            return {
              ...contact,
              brand: {
                ...contact.brand,
                name: textField,
              },
            } as OutreachContact;
          } else {
            return {
              ...contact,
              [key]: textField,
            } as OutreachContact;
          }
        }
      });

      // Call API to update the property
      if (property?.contactPropertyId && outreachContact.id) {
        updateOutreachContactPropertyValues(
          outreachContact.id,
          property?.contactPropertyId,
          newValue,
        );
      } else {
        updateOutreachContactDefaultValues(outreachContact, {
          [key]: textField,
        });
      }

      setIsEditing(false);
      return copy;
    });
  };

  return (
    <Box
      sx={!isEditable ? styles.notEditable : { padding: isMultiline ? 0 : 1 }}
    >
      {!isEditable && <Box sx={styles.emptyNote}>{notes}</Box>}
      {isEditable && key === "brandName" && (
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          sx={{ maxWidth: 180, overflowX: "hidden" }}
        >
          {outreachContact?.bentoBrand?.logoUrl ? (
            <Box
              component="img"
              src={outreachContact?.bentoBrand?.logoUrl}
              sx={styles.logo}
            />
          ) : (
            <Box sx={[styles.logo, styles.textLogo]}>
              {generateInitials(
                textFieldInputRef.current?.value?.toLocaleString() ||
                  notes.toLocaleString() ||
                  "",
              )}
            </Box>
          )}
          <TableTextField
            type={type}
            notes={notes}
            cellLoading={cellLoading}
            onBlur={onBlur}
            ref={textFieldInputRef}
            showTextField={showTextField}
            isEditing={isEditing}
          />
        </Grid>
      )}
      {isEditable && key !== "brandName" && (
        <TableTextField
          type={type}
          showTextField={showTextField}
          notes={notes}
          cellLoading={cellLoading}
          onBlur={onBlur}
          ref={textFieldInputRef}
          isEditing={isEditing}
        />
      )}
      {!isEditable && (
        <NotEditableMenu
          open={open}
          ref={popperRef}
          handleClose={handleClose}
          anchorEl={anchorEl}
          message={
            key === "email"
              ? "Once a contact has an email, it cannot be changed"
              : undefined
          }
        />
      )}
      {openConsent && (
        <Box onClick={(e) => e.stopPropagation()}>
          <ConsentDialog
            open={openConsent}
            handleClose={() => {
              setIsEditing(false);
              setOpenConsent(false);
            }}
            handleConfirm={() => {
              onBlur();
            }}
          />
        </Box>
      )}
      {openOverwriteDialog && (
        <OverWriteColumnDialog
          open={openOverwriteDialog}
          handleClose={(e) => {
            e.stopPropagation();
            setOpenOverwriteDialog(false);
            if (textFieldInputRef && textFieldInputRef?.current)
              textFieldInputRef.current.value = "";
          }}
          changedFields={changedFields}
          loading={cellLoading}
          handleConfirm={(e) => {
            e.stopPropagation();
            handleSave(textFieldInputRef.current?.value || "");
            setOpenOverwriteDialog(false);
          }}
        />
      )}
    </Box>
  );
});

export default TableNotes;
