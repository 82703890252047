import { grey } from "@mui/material/colors";

const styles = {
  closeIcon: {
    color: "primary.main",
    fontSize: 14,
    position: "absolute",
    right: 10,
    top: 10,
  },
  patch: {
    ml: 1,
    display: "inline-block",
    border: `1px solid ${grey[700]}`,
    width: "20px",
    height: "20px",
    borderRadius: "4px",
  },
  pickerWrapper: {
    border: `1px solid ${grey[300]}`,
    borderRadius: "3px",
    overflow: "hidden",
  },
  paletteOption: {
    cursor: "pointer",
    border: `1px solid ${grey[400]}`,
    width: "20px",
    height: "20px",
    borderRadius: "4px",
  },
};

export default styles;
