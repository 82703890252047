const styles = {
  page: {
    pt: 4,
    pb: { xs: 8, md: 4 },
    px: { xs: 2, md: 10 },
  },
  button: (tabWidth: number, toolBarOpen: boolean) => ({
    position: "fixed",
    right: {
      xs: 30,
      sm: toolBarOpen ? 30 + tabWidth : 30,
      md: toolBarOpen ? 100 + tabWidth : 100,
    },
    bottom: { xs: 80, md: 50 },
  }),
};
export default styles;
