const styles = {
  referralCode: {
    px: 2,
    py: 1,
    display: "flex",
    border: "2px dashed",
    borderColor: "secondary.main",
    backgroundColor: "secondary.light",
    borderRadius: 2,
    cursor: "pointer",
  },
  code: {
    flexGrow: 1,
    mr: 4,
  },
};

export default styles;
