import { grey } from "@mui/material/colors";

const styles = {
  rowPinned: {
    background: "white",
  },
  email: {
    fontSize: 12,
    color: grey[500],
  },
  accordion: {
    cursor: "pointer",
    position: "relative",
    background: grey[50],
    p: 1.5,
    borderTop: "1px solid",
    borderColor: grey[200],
    transition: "box-shadow 0.2s", // smooth transition for the boxShadow
    "&:last-child": { borderBottom: `1px solid ${grey[200]}` },
    "&:hover": {
      boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.1)", // subtle boxShadow on hover
      zIndex: 2,
    },
  },
  selected: {
    backgroundColor: grey[200],
  },
  toFieldWrapper: { width: "120px", overflow: "hidden" },
  toField: {
    maxWidth: "95px",
    display: "inline-block",
    verticalAlign: "middle",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  subjectAndSnippet: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: grey[500],
  },
  formattedDate: { width: "75px", textAlign: "center" },
  toFieldWrapperMobile: { width: "175px", overflow: "hidden" },
  toFieldMobile: {
    maxWidth: "150px",
    display: "inline-block",
    verticalAlign: "middle",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  numberOfEmails: { fontSize: 11, ml: 0.75 },
};

export default styles;
