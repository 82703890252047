import { createTheme } from "@mui/material/styles";

export const paragraphFontFamily = `"Open Sans", Arial, sans-serif`;
export const headerFontFamily = `stolzl, "Open Sans", Arial, sans-serif`;
export const barWidth = 68;

// define custom colors: https://material-ui.com/customization//=
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    green: Palette["primary"];
    yellow: Palette["primary"];
  }
  interface PaletteOptions {
    green: PaletteOptions["primary"];
    yellow: PaletteOptions["primary"];
  }
}

declare module "@mui/material/styles/createTypography" {
  interface TypographyPropsVariantOverrides {
    primary: {
      fontFamily: `stolzl, "Open Sans", Arial, sans-serif`;
    };
    secondary: {
      fontFamily: `stolzl, "Open Sans", Arial, sans-serif`;
    };
  }
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#DBEFDD",
      main: "#014339",
      dark: "#151F16",
      contrastText: "#f7f6f0",
    },
    secondary: {
      light: "#F3EEEA",
      main: "#B3A492",
      dark: "#776B5D",
      contrastText: "#1D2B3F",
    },
    green: {
      light: "#afbfb1",
      main: "#014339",
      dark: "#151F16",
    },
    yellow: {
      light: "#f7f6f0",
      main: "#FFFAED",
    },
    text: {
      primary: "#04060D",
    },
  },
  typography: {
    fontFamily: paragraphFontFamily,
    body1: {
      color: "#151e16",
      fontSize: 14,
    },
    body2: {
      color: "#151e16",
      fontSize: 12,
    },
    caption: {
      color: "#151e16",
    },
    subtitle1: {
      lineHeight: 1.5,
    },
    h6: {
      fontFamily: headerFontFamily,
      fontSize: 18,
    },
    h5: {
      fontFamily: headerFontFamily,
      fontSize: 20,
      lineHeight: "28px",
    },
    h4: {
      fontFamily: headerFontFamily,
      fontSize: 24,
      lineHeight: "32px",
    },
    h3: {
      fontFamily: headerFontFamily,
      fontSize: 32,
      lineHeight: "44px",
    },
    h2: {
      fontFamily: headerFontFamily,
      fontSize: 40,
      lineHeight: "56px",
    },
    h1: {
      fontFamily: `Balgin, "Open Sans", Arial, sans-serif`,
      fontSize: 45,
      margin: "revert",
    },
    button: {
      fontFamily: headerFontFamily,
    },
  },
});

export default theme;
