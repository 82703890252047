import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, DialogContent } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendContext } from "contexts/QuickSend";
import { useContext, useEffect, useState } from "react";
import { unstable_usePrompt, useNavigate, useParams } from "react-router-dom";

import StyledDialog from "components/Styled/Dialog";
import StyledDialogTitle from "components/Styled/Dialog/DialogTitle";
import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { containsMergeTagsTemplates } from "utils/string";
import { makeDeepCopy, updateList } from "utils/updateLocalState";

import TemplateBody from "../../SharedComponents/TemplateBody";
import { validateTemplateBody } from "../../SharedComponents/helpers";
import NoMergeTagsWarningDialog from "../NoMergeTagsWarningDialog";

interface EditTemplateDialogProps {
  isNew?: boolean;
  setIsImport?: (isImport: boolean) => void;
  onSuccess?: () => void;
}

export default function EditTemplateDialog({
  isNew = false,
  setIsImport,
  onSuccess,
}: EditTemplateDialogProps) {
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { getToken } = useAuth();

  const { currentOrg } = useContext(OrganizationUserContext);
  const {
    templates,
    setTemplates,
    userHasNoTemplates,
    setDefaultTemplates,
    setUserHasNoTemplates,
  } = useContext(OutreachTemplatesContext);
  const {
    setTemplateForQuickSend,
    newlyAddedTemplateIndex,
    handleRefetchPreviews,
  } = useContext(QuickSendContext);

  const defaultTemplate: Template = {
    name: "",
    subject: "",
    body: "",
    type: "email",
    contentType:
      newlyAddedTemplateIndex > 0
        ? TemplateContentType.FOLLOWUP
        : TemplateContentType.OUTREACH,
    attachment: undefined,
  };

  // States
  const [editTemplate, setEditTemplate] = useState<Template>(defaultTemplate);
  const [fetchTemplateLoading, setFetchTemplateLoading] = useState(
    Boolean(templateId),
  );
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openMergeTagWarning, setOpenMergeTagWarning] =
    useState<boolean>(false);

  const fetchTemplate = async () => {
    if (!currentOrg?.id || !templateId) {
      return;
    }
    setFetchTemplateLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/${templateId}`,
      );

      setEditTemplate(res.template);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setFetchTemplateLoading(false);
    }
  };

  const handleCreateOrSave = () => {
    const { isValidTemplate, errorMessage } =
      validateTemplateBody(editTemplate);
    if (!isValidTemplate && errorMessage) {
      setAlert(errorMessage, "error");
      return;
    }
    if (
      templates.find(
        (t: Template) =>
          t.name === editTemplate.name &&
          t.contentType === editTemplate.contentType &&
          t.id !== editTemplate.id,
      )
    ) {
      setAlert("You already have a template with the same name", "error");
      return;
    }

    if (!containsMergeTagsTemplates(editTemplate.body || "")) {
      setOpenMergeTagWarning(true);

      return;
    }

    if (isNew) {
      createTemplate();
    } else {
      saveTemplate();
    }
  };

  const createTemplate = async () => {
    if (!currentOrg?.id) return;

    setIsDirty(false);

    try {
      setCreateLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates`,
        "POST",
        {},
        editTemplate,
      );
      setEditTemplate(res.template);
      updateList(templates, setTemplates, res.template, "id");
      if (userHasNoTemplates) {
        setDefaultTemplates([res.template]);
        setUserHasNoTemplates(false);
      }
      setAlert("Template Saved", "success");
      // User add new template from QuickSend mode
      if (newlyAddedTemplateIndex > -1) {
        setTemplateForQuickSend((prev) => {
          const copy = makeDeepCopy(prev);
          copy[newlyAddedTemplateIndex] = res.template;
          handleRefetchPreviews(copy);
          return copy;
        });
      }
      if (onSuccess) onSuccess();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const saveTemplate = async () => {
    if (!currentOrg?.id || !templateId) return;
    setIsDirty(false);

    try {
      setCreateLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/${templateId}`,
        "PUT",
        {},
        {
          ...editTemplate,
        },
      );
      setEditTemplate(res.template);
      updateList(templates, setTemplates, res.template, "id");
      setAlert("Template Saved", "success");
      handleClose();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleClose = () => {
    if (isNew && setIsImport) {
      setIsImport(false);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (currentOrg?.id) {
      fetchTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, currentOrg?.id]);

  unstable_usePrompt({
    message:
      "You have an unsaved template. Are you sure you want to navigate away from this page?",
    when: ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  });

  const { isValidTemplate } = validateTemplateBody(editTemplate);

  return (
    <>
      {isNew ? (
        <>
          <StyledDialogTitle
            title={"Create Template"}
            leftAction={
              <Button color="secondary" onClick={handleClose}>
                Cancel
              </Button>
            }
            rightAction={
              <LoadingButton
                onClick={handleCreateOrSave}
                loading={createLoading}
                variant="contained"
                disableElevation
                disabled={!isValidTemplate}
              >
                Create
              </LoadingButton>
            }
          />
          <DialogContent>
            <TemplateBody
              editTemplate={editTemplate}
              setEditTemplate={setEditTemplate}
              setIsDirty={setIsDirty}
              isNew={isNew}
              allowChangeType={true}
            />
          </DialogContent>
        </>
      ) : (
        <StyledDialog
          open={true}
          handleClose={handleClose}
          title={`${isNew ? "Create" : "Edit"} Template`}
          rightAction={
            <LoadingButton
              onClick={handleCreateOrSave}
              loading={createLoading}
              disabled={!isValidTemplate}
            >
              Save
            </LoadingButton>
          }
          leftAction={
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          }
        >
          {Boolean(fetchTemplateLoading) ? (
            <CircularProgress />
          ) : (
            <TemplateBody
              editTemplate={editTemplate}
              setEditTemplate={setEditTemplate}
              setIsDirty={setIsDirty}
              isNew={isNew}
              allowChangeType={true}
            />
          )}
        </StyledDialog>
      )}

      <NoMergeTagsWarningDialog
        open={openMergeTagWarning}
        handleClose={() => setOpenMergeTagWarning(false)}
        onProceed={isNew ? createTemplate : saveTemplate}
      />
    </>
  );
}
