import { Box, Grid, MenuItem, Tooltip } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { COMPANY_SIZES, SearchParams } from "schemas/dashboard";

import DropdownSelect from "components/DropdownSelect";

import styles from "./styles";

interface Props {
  handleAddOption: (name: string, value: string) => void;
  close: boolean;
  setClose: Dispatch<SetStateAction<boolean>>;
  selectedOptionIcon: JSX.Element;
  isOptionSelected: (key: string, params: string) => boolean;
}

export default function CompanySizeFilter({
  handleAddOption,
  close,
  setClose,
  selectedOptionIcon,
  isOptionSelected,
}: Props) {
  const renderDollarSigns = (total: number) => {
    const sign = (
      <Box
        component="i"
        className="fa-regular fa-dollar-sign"
        sx={styles.dollarSign}
      />
    );
    const allSigns = Array(total).fill(sign);
    return allSigns;
  };

  return (
    <DropdownSelect
      label={"Company Size"}
      id={"company-size"}
      close={close}
      setClose={setClose}
    >
      {COMPANY_SIZES.map((companySize) => (
        <MenuItem
          key={companySize.key}
          value={companySize.key}
          onClick={() =>
            handleAddOption(SearchParams.SIZE_DISCOVER, companySize.key)
          }
        >
          <Tooltip title={companySize?.label} placement="right">
            <Grid container>
              {isOptionSelected(companySize.key, SearchParams.SIZE_DISCOVER) &&
                selectedOptionIcon}{" "}
              {companySize.name}
              <Box sx={{ ml: 1 }}>
                {companySize?.dollarSign &&
                  renderDollarSigns(companySize?.dollarSign)?.map(
                    (x, index) => (
                      <Box component="span" key={index}>
                        {x}
                      </Box>
                    ),
                  )}
              </Box>
            </Grid>
          </Tooltip>
        </MenuItem>
      ))}
    </DropdownSelect>
  );
}
