import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { OrganizationProfile } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";

const EmailTracking = () => {
  const { setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const { profile, setProfile } = useContext(OrganizationUserContext);

  const handleChange = (event: CustomEvent) => {
    const { name, value } = event.target;
    setProfile((profile: OrganizationProfile) => {
      return {
        ...profile,
        [name]: value,
      };
    });
  };

  const handleSave = async () => {
    if (!profile) return;
    setLoading(true);

    try {
      const response = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/settings`,
        "PUT",
        {},
        profile,
      );
      setProfile(response.organizationProfile);
      setAlert("Profile saved", "success");
    } catch (error) {
      setAlert(
        error.message || "An error occured when updating profile",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom>Track emails by default</Typography>
        <Typography
          component="div"
          gutterBottom
          variant="caption"
          color="textSecondary"
        >
          Bento tracks all email opens, including when you open them in Gmail.
          To avoid tracking your own opens,{" "}
          <strong>view the thread directly in Bento instead</strong>.
        </Typography>
        <FormControl>
          <FormLabel id="email-tracking-enabled"></FormLabel>
          <RadioGroup
            onChange={handleChange}
            name="emailTrackingEnabled"
            row
            aria-labelledby="email-tracking-enabled"
            value={profile?.emailTrackingEnabled}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>

      <LoadingButton
        loading={loading}
        variant="contained"
        sx={{ mt: 2 }}
        disableElevation
        onClick={handleSave}
      >
        Save
      </LoadingButton>
    </>
  );
};

export default EmailTracking;
