import { useAuth } from "@clerk/clerk-react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { APIProvider } from "@vis.gl/react-google-maps";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import {
  BentoBrand,
  FollowingSizeRange,
  SearchParams,
  Sort,
} from "schemas/dashboard";

import { useAutoCompletePrediction } from "components/LocationAutocomplete/useAutoCompletePrediction";
import { PAGE_VISITED } from "constants/trackingProps";
import { arrayQuery, fetcherAuth } from "utils/api";
import {
  DISCOVER_SEARCH_PARAMS,
  DISPLAYED_CITY_DIALOG,
} from "utils/localStorage";
import { trackEvent } from "utils/tracking";

import BrandCard from "./BrandCard";
import CategorySelect from "./CategorySelect";
import CityDialog from "./Dialogs/CityDialog";
import OpenSendDialog from "./OpenSendDialog";
import RequestBrand from "./RequestBrand";
import VirtualAssistant from "./VirtualAssistant";
import { BrandCardSource } from "./schema";
import styles from "./styles";

const RESULTS_PER_PAGE = 20;
const REF_INDEX = 9; // The index used to start loading more recommendations

export default function DiscoverResults() {
  const { API_KEY } = useAutoCompletePrediction();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { setAlert } = useContext(AlertContext);
  const { currentOrg, profile, hideCityDialog } = useContext(
    OrganizationUserContext,
  );

  const [ref, isBrandsRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const [similarBrandsRef, isSimilarBrandsRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const headRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const brandQuery = searchParams.get(SearchParams.QUERY_DISCOVER);

  const {
    brands,
    setBrands,
    abortController,
    similarBrands,
    setSimilarBrands,
    similarBrandsCursor,
    setSimilarBrandsCursor,
    discoverFilterParams,
    cursor,
    setCursor,
  } = useContext(BrandsContext);

  // State variables
  const [brandsLoading, setBrandsLoading] = useState<boolean>(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState<boolean>(false);
  const [hitsLimit, setHitsLimit] = useState<boolean>(false);
  const [similarBrandsLimit, setSimilarBrandsLimit] = useState<boolean>(false);

  const [openCityDialog, setOpenCityDialog] = useState(false);

  const resultsRef = useRef<HTMLDivElement>(null);

  const {
    selectedSort,
    selectedCategories,
    selectedCompanyTypes,
    selectedCompanySizes,
    selectedInstagramFollowings,
    selectedLocations,
    selectedCity,
    selectedGeopoints,
  } = { ...discoverFilterParams };

  const isSearching = brandQuery && brandQuery?.length > 0;
  const query = sessionStorage.getItem(DISCOVER_SEARCH_PARAMS);

  const fetchInspiredBrands = async (
    bentoBrands: BentoBrand[],
    avoidBrands: BentoBrand[],
    cursor: number,
  ) => {
    if (!currentOrg?.id) {
      setFetchMoreLoading(false);
      return;
    }

    setFetchMoreLoading(true);

    try {
      abortController.current = new AbortController();
      const brandIds = bentoBrands.map((b) => b.id).join(",");
      const avoidIds = avoidBrands.map((b) => b.id).join(",");
      const url = `/api/organization/${currentOrg?.id}/bento-brands/inspired-by?ids=${brandIds}&avoid=${avoidIds}&size=${RESULTS_PER_PAGE}&cursor=${cursor}`;
      const res = await fetcherAuth(
        getToken,
        url,
        "GET",
        {},
        false,
        false,
        false,
        false,
        abortController.current.signal,
      );
      setSimilarBrands((prevBrands: BentoBrand[]) => [
        ...prevBrands,
        ...res.bentoBrands,
      ]);

      if (
        (res.cursor >= res.totalResults && res.totalResults !== 0) ||
        (res.cursor <= res.totalResults && res.totalResults < RESULTS_PER_PAGE)
      ) {
        trackEvent("Discover Page Reached End of Similar Brands List");
        setSimilarBrandsLimit(true);
      } else {
        setSimilarBrandsLimit(false);
      }
      setSimilarBrandsCursor(res.cursor);
    } catch (error) {
      if (error?.message?.includes("aborted")) {
        return;
      }
      setAlert(
        error?.message ||
          "Something went wrong when fetching brands, please reload and try again",
        "error",
      );
    } finally {
      setFetchMoreLoading(false);
    }
  };

  const callFetchInspiredBrands = (brands: BentoBrand[], cursor: number) => {
    const firstBrandName = brands[0]?.brandName;
    if (!firstBrandName) {
      return;
    }
    if (
      firstBrandName.toLowerCase() ===
      searchParams.get(SearchParams.QUERY_DISCOVER)?.toLowerCase()
    ) {
      fetchInspiredBrands([brands[0]], brands, cursor);
    } else {
      fetchInspiredBrands(brands.slice(0, 3), brands, cursor);
    }
  };

  const fetchBrands = async (
    searchQuery: string | null,
    fetchCursor: number[] | null,
    useFetchMoreLoading?: boolean,
    appendToList?: boolean,
  ) => {
    if (!currentOrg) {
      setBrandsLoading(false);
      return;
    }

    if (abortController?.current) {
      abortController.current?.abort();
    }

    try {
      abortController.current = new AbortController();
      const encodedBrandQuery = encodeURIComponent(searchQuery || "");

      let url = `/api/organization/${currentOrg?.id}/bento-brands?query=${encodedBrandQuery}&size=${RESULTS_PER_PAGE}`;
      if (selectedSort) {
        url += `&sort=${selectedSort?.key}`;
      } else {
        url += `&sort=${Sort.Recommended}`;
      }
      if (selectedCategories?.length > 0) {
        url += `${arrayQuery(
          selectedCategories?.map((x) => x.key),
          "categories",
        )}`;
      }
      if (selectedCompanySizes?.length > 0) {
        url += `${arrayQuery(
          selectedCompanySizes?.map((x) => x.key),
          "company_sizes",
        )}`;
      }
      if (selectedCompanyTypes?.length > 0) {
        url += `${arrayQuery(
          selectedCompanyTypes?.map((x) => x.key),
          "company_types",
        )}`;
      }

      if (profile?.city && selectedCity?.key) {
        url += `&city=${profile?.city}`;
      }

      if (selectedLocations?.length > 0) {
        url += `${arrayQuery(
          selectedLocations?.map((x) => x.key),
          "locations",
        )}`;
      }

      if (selectedGeopoints?.length > 0) {
        url += `${arrayQuery(
          selectedGeopoints?.map((x) => x.key),
          "geopoints",
        )}`;
      }

      if (selectedInstagramFollowings?.length > 0) {
        const min = Math.min(
          ...selectedInstagramFollowings?.map(
            (x) => FollowingSizeRange[x.key]?.min,
          ),
        );
        const max = Math.max(
          ...selectedInstagramFollowings?.map(
            (x) => FollowingSizeRange[x.key]?.max,
          ),
        );
        if (min && !isNaN(min)) {
          url += `&min_instagram_followers=${min}`;
        }
        if (max !== Math.max() && !isNaN(max)) {
          url += `&max_instagram_followers=${max}`;
        }
      }

      if (fetchCursor) {
        url += `&cursor=${JSON.stringify(fetchCursor)}`;
      }

      if (useFetchMoreLoading) {
        setFetchMoreLoading(true);
      } else {
        setBrandsLoading(true);
      }

      const res = await fetcherAuth(
        getToken,
        url,
        "GET",
        {},
        {},
        false,
        false,
        true,
        abortController.current.signal,
      );
      if (res.bentoBrands.length < RESULTS_PER_PAGE) {
        setHitsLimit(true);
        if (res.totalResults !== 0) {
          if (appendToList) {
            callFetchInspiredBrands([...brands, ...res.bentoBrands], 0);
          } else {
            callFetchInspiredBrands(res.bentoBrands, 0);
          }
        } else {
          setFetchMoreLoading(false);
        }
      } else {
        setHitsLimit(false);
        setFetchMoreLoading(false);
      }
      if (appendToList) {
        setBrands((prevBrands: BentoBrand[]) => [
          ...prevBrands,
          ...res.bentoBrands,
        ]);
      } else {
        setBrands([...res.bentoBrands]);
      }

      setCursor(res.cursor);
    } catch (error) {
      if (error?.message?.includes("aborted")) {
        return;
      }
      setAlert(
        error?.message ||
          "Something went wrong when fetching brands, please reload and try again",
        "error",
      );
    } finally {
      setBrandsLoading(false);
    }
  };

  const handleSearch = async () => {
    scrollToTop();
    setSimilarBrands([]);
    setBrandsLoading(true);
    setSimilarBrandsCursor(0);
    setCursor(null);
    setSimilarBrandsLimit(false);
    setHitsLimit(false);
    fetchBrands(brandQuery, null, false, false);
  };

  const scrollToTop = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isBrandsRefVisible) {
      if (!hitsLimit && !brandsLoading) {
        fetchBrands(brandQuery, cursor, true, true);
        trackEvent("Discover Page Scrolled To Bottom", {
          keyword: brandQuery,
          category: selectedCategories,
          "Company Type": selectedCompanyTypes,
          sort: selectedSort,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrandsRefVisible, brandsLoading]);

  useEffect(() => {
    if (isSimilarBrandsRefVisible) {
      if (!similarBrandsLimit && !fetchMoreLoading && !brandsLoading) {
        callFetchInspiredBrands(brands, similarBrandsCursor);
        trackEvent("Discover Page Scrolled To Bottom of Similar Brands List", {
          keyword: brandQuery,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSimilarBrandsRefVisible]);

  useEffect(() => {
    const isElementInView = () => {
      const rect = headRef?.current?.getBoundingClientRect();
      if (!rect) {
        return false;
      }
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    if (headRef.current && !isElementInView()) {
      headRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
    if (query && !window.location.href?.includes("?")) {
      navigate(`?${query}`);
    } else {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandQuery, JSON.stringify(discoverFilterParams), currentOrg?.id]);

  useEffect(
    () => () => {
      if (abortController.current) {
        abortController.current?.abort();
      }
      if (query) {
        setBrands([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const alreadyDisplayedCityDialog =
      localStorage.getItem(`${DISPLAYED_CITY_DIALOG}-${currentOrg?.id}`) ===
      "true";
    if (
      profile &&
      !profile?.city &&
      !alreadyDisplayedCityDialog &&
      !hideCityDialog
    ) {
      setOpenCityDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.city, currentOrg?.id]);

  const handleCloseCityDialog = (city?: string) => {
    localStorage.setItem(`${DISPLAYED_CITY_DIALOG}-${currentOrg?.id}`, "true");
    setOpenCityDialog(false);
    if (city) {
      searchParams.set(SearchParams.LOCATION_DISCOVER, city);
      setSearchParams(searchParams);
    }
  };

  return (
    <APIProvider apiKey={API_KEY || ""}>
      <Box>
        <Grid container item xs={12}>
          <Grid container rowGap={2} sx={styles.cardContainer}>
            <CategorySelect />

            <Grid
              container
              item
              sx={{ mt: -2 }}
              rowGap={1}
              justifyContent="flex-end"
            >
              <VirtualAssistant />
              <RequestBrand setAlert={setAlert} />
            </Grid>
            <div ref={resultsRef} />
            <Grid item xs={12}>
              <Typography
                sx={styles.searchingText}
                variant="h6"
                textAlign="left"
              >
                {isSearching && `Searching "${brandQuery}"`}
              </Typography>
            </Grid>
            <>
              {!brandsLoading && !fetchMoreLoading && brands.length === 0 && (
                <Grid container item rowGap={2}>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    No results found{" "}
                    {isSearching && (
                      <RequestBrand isToggle={true} setAlert={setAlert} />
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    Try changing the filters above to see more results.
                  </Grid>
                </Grid>
              )}

              {brandsLoading && (
                <Grid container justifyContent="center" gap={2} sx={{ px: 6 }}>
                  {Array.from({ length: 20 }, (x, i) => (
                    <Skeleton
                      key={i}
                      variant="rounded"
                      width={"100%"}
                      height={100}
                    />
                  ))}
                </Grid>
              )}
              <Grid container justifyContent="center" gap={1}>
                {!brandsLoading &&
                  brands?.map((brand: BentoBrand, idx: number) => (
                    <BrandCard
                      ref={
                        (
                          brands.length > REF_INDEX
                            ? idx === brands.length - REF_INDEX
                            : idx === brands.length - 1
                        )
                          ? ref
                          : undefined
                      }
                      key={`brand-card-${brand.id}-${idx}`}
                      brand={brand}
                      setAlert={setAlert}
                      source={BrandCardSource.DISCOVER_BRAND}
                    />
                  ))}
              </Grid>
            </>
            {similarBrands.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={styles.searchingText}
                    gutterBottom
                    textAlign="left"
                  >
                    {isSearching
                      ? `Similar to "${brandQuery}"`
                      : "Similar Brands"}
                  </Typography>
                </Grid>
                {!brandsLoading &&
                  !fetchMoreLoading &&
                  similarBrands.length === 0 && <>No similar brands found</>}

                <Grid container justifyContent="center" gap={1}>
                  {similarBrands?.map((brand: BentoBrand, idx: number) => (
                    <BrandCard
                      /* This ref tracks when user scroll to bottom of the page */
                      ref={
                        (
                          similarBrands.length > REF_INDEX
                            ? idx === similarBrands.length - REF_INDEX
                            : idx === similarBrands.length - 1
                        )
                          ? similarBrandsRef
                          : undefined
                      }
                      key={`brand-card-similar-${brand.id}-${idx}`}
                      brand={brand}
                      setAlert={setAlert}
                      source={BrandCardSource.SIMILAR_BRANDS}
                    />
                  ))}
                </Grid>
              </>
            )}

            {!brandsLoading && fetchMoreLoading && (
              <Grid container gap={2} sx={{ px: 6 }}>
                {Array.from({ length: 3 }, (x, i) => (
                  <Skeleton variant="rounded" width={"100%"} height={100} />
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>

        <CityDialog open={openCityDialog} handleClose={handleCloseCityDialog} />

        <Routes>
          <Route path="send/:bentoBrandId/*" element={<OpenSendDialog />} />
        </Routes>
      </Box>
    </APIProvider>
  );
}
