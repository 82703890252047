import { Backdrop, CircularProgress } from "@mui/material";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { routes } from "schemas/routes";

import { PAGE_VISITED } from "constants/trackingProps";
import { trackEvent } from "utils/tracking";

const ContactViews = () => {
  const { contactViewId, viewLoading } = useContext(ContactViewSearchContext);

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (contactViewId) {
    return <Navigate to={`/${routes.trackingView}/${contactViewId}`} replace />;
  }

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={viewLoading}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default ContactViews;
