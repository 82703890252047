import { grey } from "@mui/material/colors";

export const getStyles = (sideBarIsOpen: boolean, quickSendIsOpen: boolean) => {
  const calculateRight = () => {
    const wq = quickSendIsOpen ? { md: 450, xl: 550 } : { md: 0, xl: 0 };
    const ws = sideBarIsOpen ? 350 : 0;
    return {
      md: 80 + wq["md"] + ws,
      xl: 80 + wq["xl"] + ws,
    };
  };

  const calculateWidth = () => {
    if (quickSendIsOpen && sideBarIsOpen) {
      return { md: 328, lg: 328, xl: 500 };
    }
    return { md: 380, lg: 450, xl: 500 };
  };

  return {
    root: {
      position: "fixed",
      bottom: 0,
      right: calculateRight(),
      width: calculateWidth(),
      top: 90,
      boxShadow: 3,
      backgroundColor: "white",
      border: `1px solid ${grey[300]}`,
      zIndex: 250,
      overflowY: "auto",
      ...(quickSendIsOpen &&
        sideBarIsOpen && {
          "@media (min-width:1300px)": {
            width: 400,
          },
        }),
    },
  };
};
