import { useAuth } from "@clerk/clerk-react";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SetAlertType } from "schemas/functions";
import { routes } from "schemas/routes";

import { fetcherAuth } from "utils/api";
import { ON_BEHALF_OF_TOKEN } from "utils/localStorage";

interface AdminLoginAsProps {
  organizationId: number;
  setAlert: SetAlertType;
}

export default function AdminLoginAs({
  organizationId,
  setAlert,
}: AdminLoginAsProps) {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const handleLoginAsUser = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/organizations/${organizationId}/login-as`,
        "POST",
      );
      if (res.token) {
        navigate(`/${routes.dashboard}`);
        sessionStorage.setItem(ON_BEHALF_OF_TOKEN, res.token);
      }
    } catch (error) {
      setAlert(
        "An error occurred, you do not have access to this page",
        "warning",
      );
    }
  };

  return (
    <Grid>
      <Button
        variant="outlined"
        onClick={handleLoginAsUser}
        disabled={!organizationId}
      >
        Login As User
      </Button>
    </Grid>
  );
}
