const styles = {
  color: {
    width: 20,
    height: 20,
    borderRadius: 2,
    mr: 1,
  },
  remove: {
    fontSize: 12,
    width: 20,
    height: 20,
  },
};

export default styles;
